import { useState, useEffect } from 'react'
import styles from '../../../pages/Badging/BadgeToERSPropertyMapping/BadgeToERSPropertyMapping.module.css'

const EROButton = ({
    ERO,
    index,
    selections,
    setSelections,
    isActive,
}) => {

    // State
    const [selected, setSelected] = useState(false)
    const [selectTrigger, setSelectTrigger] = useState(false) // for useEffect dependency array --- isolated variable to maintain selected state integrity

    // Helper functions
    const handleFieldSelect = e => {
        e.preventDefault()
        setSelected(selected => !selected)
        setSelectTrigger(selectTrigger => !selectTrigger)
    }

    // useEffect - Ensures selections' state update timing to correctly adjust fields selected number
    useEffect(() => {
        selected
            ? setSelections([...selections, ERO])
            : setSelections(selections.filter(field => field !== ERO))
    }, [selectTrigger])

    // useEffect - Maintains selected state when user collapses section
    useEffect(() => {
        selections.includes(ERO)
            ? setSelected(true)
            : setSelected(false)
    }, [isActive])

    return (
        <>
            <button
                id={selected ? styles.EROHeaderSelected : styles.EROHeader}
                onClick={e => handleFieldSelect(e)}
                value={ERO}
                key={index}
            >
                {ERO}
            </button>  
        </>
    );
}

export default EROButton;