import ChangePasswordForm from '../../../components/Account/ChangePasswordForm/ChangePasswordForm'

const ChangePassword = ({updateMessage}) => {
    return (
        <>
            <ChangePasswordForm updateMessage={updateMessage} />
        </>
  );
}

export default ChangePassword