import { useState, useEffect, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import styles from './PrintPreview.module.css'
import * as clientEventService from '../../../services/clientEventService'

const PrintPreview = () => {

    const [printData, setPrintData] = useState([])

    const [clientEventInfo, setClientEventInfo] = useState({})
    const [data, setData] = useState({})

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Print that stuff",
        onAfterPrint: () => alert("Print Success")
    })

    useEffect(() => {
        
    }, [])

    useEffect(() => {
        if (data && data.clientId !== undefined && data.clientName) {
            setPrintData(data)
        }

    }, [data])
    useEffect(() => {
        // store into local object
        let localclientevent = clientEventService.getClientEvent()
        if (localclientevent) {
            setClientEventInfo(localclientevent)
            setData(localclientevent)
        }
    }, [])

    return (
        <div className={styles.printPage}>

            <h1>Badge Print Preview</h1>

            <button
                onClick={handlePrint}
                id={styles.printButton}
            >
                Print
            </button>

            <div className={styles.printArea} ref={componentRef}>
                {printData.length ?
                    printData.map((data, index) => (
                        <div className={styles.badgeContainer} key={index}>
                            <p className={styles.badgeLines} id={styles.firstName}>{data.firstName}</p>
                            <p className={styles.badgeLines} id={styles.lastName}>{data.lastName}</p>
                            <p className={styles.badgeLines} id={styles.company}>{data.company}</p>
                        </div>
                    ))
                    :
                    <></>
                }
            </div>

        </div>
    );
}

export default PrintPreview;