import * as tokenService from './tokenService'
//const BASE_URL = `http://localhost:5094/api/auth`
//const BASE_URL = `https://localhost:7022/auth`

async function signup(user) {
    try {
        let error = new Error();
        //const res = await fetch(`${BASE_URL}/register`, {
        const res = await fetch('/Home/Register', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(user),
        })
        const json = await res.json()
        if (json.token) {
            tokenService.setToken(json.token)
        }
        if (json.errors) {
            // create own object to pass errors correctly
            Object.assign(error, { internalError: json.errors });
            throw error;
        }
    } catch (err) {
        throw err
    }
}


function getUser() {
    return tokenService.getUserFromToken()
}

function getRole() {
    return tokenService.getRoleFromToken()
}
async function logout() {
    try {
        tokenService.removeToken()

        const res = await fetch('/Home/Logout', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' })
        })

    } catch (err) {
        console.log("error: " + err)
        throw err
    }
}

async function login(credentials) {
    try {
        let error = new Error();
        const res = await fetch('/Home/Login', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(credentials),
        });
        if (res.status == 401) {
            Object.assign(error, { internalError: JSON.parse('{"Error":["Either username or password is incorrect."]}' )});
            throw error;
        }
        const json = await res.json()
        //console.log(json)
        if (json.errors) {
            // create own object to pass errors correctly
            Object.assign(error, { internalError: json.errors });
            throw error;
        }
        if (json.token) {
            tokenService.setToken(json.token)
        }
       
    } catch (err) {
        //console.log("error: " + err)
        throw err
    }
}


async function forgotPassword(credentials) {
    try {
        let error = new Error();
        console.log(credentials)
        const res = await fetch('/Home/ForgotPassword', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(credentials),
        })

        const json = await res.json()
        console.log(json)
        if (json.errors) {
            // create own object to pass errors correctly
            Object.assign(error, { internalError: json.errors });
            throw error;
        }
    } catch (err) {
        console.log("error: " + err)
        throw err
    }
}

async function resetPassword(credentials) {
    try {
        let error = new Error();
        console.log(credentials)
        const res = await fetch('/Home/ResetPassword', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(credentials),
        })

        const json = await res.json()
        console.log(json)
        if (json.errors) {
            // create own object to pass errors correctly
            Object.assign(error, { internalError: json.errors });
            throw error;
        }
    } catch (err) {
        console.log("error: " + err)
        throw err
    }
}

export { signup, getUser, getRole, logout, login, forgotPassword, resetPassword }