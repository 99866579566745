import { useState, useEffect, useRef } from 'react'
import { useLocation, Link, useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styles from './EventSpecificStatusPage.module.css'

// Pages/components
import EventSetupStatusManager from '../../../pages/Home/StatusSubpages/EventSetupStatusManager'
import EventBadgesStatusManager from '../../../pages/Home/StatusSubpages/EventBadgesStatusManager'
import ExtraBadgesStatusManager from '../../../pages/Home/StatusSubpages/ExtraBadgesStatusManager'
import KioskSetupStatusManager from '../../../pages/Home/StatusSubpages/KioskSetupStatusManager'

const EventSpecificStatusPage = () => {

    const location = useLocation()
    const stateEvent = location.state
    console.log(stateEvent)

    const [tabIndex, setTabIndex] = useState(0)
    const tabLabels = [
        "Event Setup",
        "Event Badges Setup",
        "Extra Badges Setup",
        "Kiosk Setup"
    ]

    return (
        <div id={styles.ESSPage}>
            <div className={styles.headingArea}>
                <h1>{stateEvent.eventName} - Status Manager</h1>
            </div>
            <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
                id={styles.tabsSection}
            >
                <TabList id={styles.tabsList}>
                    {tabLabels.map((label, index) => (
                        <Tab key={index} className={index === tabIndex ? styles.selected : styles.tabs}>
                            {label}
                        </Tab>
                    ))}
                </TabList>

                <TabPanel>
                    <EventSetupStatusManager />
                </TabPanel>
                <TabPanel>
                    <EventBadgesStatusManager />
                </TabPanel>
                <TabPanel>
                    <ExtraBadgesStatusManager />
                </TabPanel>
                <TabPanel>
                    <KioskSetupStatusManager />
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default EventSpecificStatusPage;