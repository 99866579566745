import { useState, useEffect } from 'react'
import styles from "../../../pages/Home/ClientConfig/ClientConfig.module.css"
import HoverButton from '../../../components/Miscellaneous/HoverButton'
import * as eventManagementService from '../../../services/eventManagementService'

const RhythmConfig = ({
    formData,
    rhythmClientConfig,
    setRhythmClientConfig,
}) => {

    const isFormValid = () => {
        return formData.ClientName
            && formData.EventRegistrationSystem
            && formData.Industry
            && formData.Categories.length > 0
            && rhythmClientConfig.RythmClientID
            && rhythmClientConfig.ClientSecret
            && rhythmClientConfig.TenantID
            && rhythmClientConfig.Auth0Domain
            && rhythmClientConfig.Auth0Audience
            ? true
            : false
    }
    const isTestFormValid = () => {
        return rhythmClientConfig.RythmClientID
            && rhythmClientConfig.ClientSecret
            && rhythmClientConfig.TenantID
            && rhythmClientConfig.Auth0Domain
            && rhythmClientConfig.Auth0Audience
            ? true
            : false
    }

    const handleChange = e => {
        setRhythmClientConfig({
            ...rhythmClientConfig,
            [e.target.name]: e.target.value,
        })
        console.log(rhythmClientConfig)
    }
    const [error, setError] = useState(null)
    const errorDiv = (error)
        ?
        <ul>
            {Object.entries(error).length > 0 && Object.entries(error) &&
                Object.entries(error).map(([key, value]) => {
                    console.log("error: " + error)
                    return <div className={value == 'Test Failure' ? 'errorMSG' : 'successMSG'} key={key} >{value}</div>;
                })}
        </ul>
        : '';
    const handleTestConnection = async e => {
        e.preventDefault()
        var holder = { rhythmClientConfig };
        setError(null)
        try {
            await eventManagementService.testSetup(holder)
        } catch (err) {
            console.log(err.message)
            setError(err.internalError);
        }
    }

    return (
        <>
            <div className={styles.inputContainer}>
                <label htmlFor="RhythmClientID" className={styles.label}>
                    Client Id
                    <HoverButton
                        title={"Client id client credential"}
                        messageWidth={200}
                    />
                    :
                </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={rhythmClientConfig.RhythmClientID}
                    name="RhythmClientID"
                    onChange={handleChange}
                />
            </div>
            <div className={styles.inputContainer}>
                <label htmlFor="ClientSecret" className={styles.label}>
                    Client Secret
                    <HoverButton
                        title={"Client Secret client credential"}
                        messageWidth={200}
                    />
                    :
                </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={rhythmClientConfig.ClientSecret}
                    name="ClientSecret"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="TenantID" className={styles.label}>TenantID: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={rhythmClientConfig.TenantID}
                    name="TenantID"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="Auth0Domain" className={styles.label}>Auth0Domain: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={rhythmClientConfig.Auth0Domain}
                    name="Auth0Domain"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="Auth0Audience" className={styles.label}>Auth0Audience: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={rhythmClientConfig.Auth0Audience}
                    name="Auth0Audience"
                    onChange={handleChange}
                />
            </div>
            {errorDiv}
            <div className={styles.inputContainer}>
                <button disabled={!isFormValid()} className={styles.button}>
                    Submit
                </button>
                <button onClick={handleTestConnection} disabled={!isTestFormValid()} className={styles.buttonRight}>
                    Test Connection
                </button>
            </div>
        </>
    );
}

export default RhythmConfig;