import React from 'react';
import styles from "../../../pages/Home/SiteFilterList/SiteFilterList.module.css"
import { useState, useEffect } from 'react'
import * as siteService from "../../../services/siteManagementService"


const SiteFilters = ({
    siteData
}) => {
    // state
    const [selectedShowInUIScreens, setSelectedShowInUIScreens] = useState("")
    const [message, setMessage] = useState([''])
    // state --- change functions
    const handleShowInUIScreensSelect = e => {
        const value = e.target.value
        for (let i = 0; i < siteData.length; i++) {
            if (siteData[i].id === value) {
                siteData[i].showInUIScreens = e.target.checked;
                break;
            }
        } 
        setSelectedShowInUIScreens(true)
    }    

    const handleTableNameEdit = (e, idNumber) => {
        const value = e.target.value

        for (let i = 0; i < siteData.length; i++) {
            if (siteData[i].id === idNumber) {
                siteData[i].tableName = value;
                break;
            }
        }
        setSelectedShowInUIScreens(true)
    }
    const handleTableSchemaEdit = (e, idNumber) => {
        const value = e.target.value

        for (let i = 0; i < siteData.length; i++) {
            if (siteData[i].id === idNumber) {
                siteData[i].schemaName = value;
                break;
            }
        }
        setSelectedShowInUIScreens(true)
    }
    const cancelSubmit = e => {
        e.preventDefault()
    }

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            await siteService.saveSiteFields(siteData)
        } catch (err) {
            setMessage(err.message)
        }
        setSelectedShowInUIScreens(true)
    }
    useEffect(() => {
        //for (let i = 0; i < siteData.length; i++) {
        //    tester.push(siteData[i])
        //} 
        setSelectedShowInUIScreens(false)
    }, [selectedShowInUIScreens])
    return (
        siteData.map(({ id, schemaName, tableName, showInUIScreens }, index) => (
            <div className={styles.filterFieldsListContainer} key={index}>
               {/* <h3>{id}</h3>*/}
                <h3> <label key={id} title={schemaName}>
                    <input
                        type="text"
                        value={schemaName}
                        onChange={e => handleTableSchemaEdit(e, id)}
                    />
                </label></h3>
                <h3> <label key={id} title={tableName}>
                    <input
                        type="text"
                        value={tableName}
                        onChange={e => handleTableNameEdit(e, id)}
                    />
                </label></h3>
                <h3>
                    <label key={id} title={tableName}>
                        <input
                            type="checkbox"
                            value={id}
                            checked={siteData[index].showInUIScreens}
                            onChange={handleShowInUIScreensSelect}
                        />
                        Show in UI
                    </label>
                </h3>
                
            </div>
             
        ))
    );
}

export default SiteFilters;