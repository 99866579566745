import { useState, useEffect } from 'react'
import styles from '../../../pages/Badging/AttendanceReport/AttendanceReport.module.css'
// ------ Services ------ //
import * as badgeService from "../../../services/badgeManagementService"

// ------ Components ------ //
import SearchBar from "../../../components/Miscellaneous/SearchBar"
import LoadingSpinner from "../../../components/Miscellaneous/LoadingSpinner"
import AdHocAttendee from "../../../components/Badging/AttendanceReport/AdHocAttendee"
import HoverButton from '../../../components/Miscellaneous/HoverButton'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCaretUp, faSquareCaretDown } from '@fortawesome/free-solid-svg-icons'
// ------ Image Elements ------ //
const up = <FontAwesomeIcon icon={faSquareCaretUp} />
const down = <FontAwesomeIcon icon={faSquareCaretDown} />

const EventAdHocAttendees = ({ clientEventInfo, setEventAdHocAttendees, eventAdHocAttendees }) => {
    // ------ State Variables ------ //
    const [isCleared, setIsCleared] = useState(false)
    const [loaded, setLoaded] = useState(false)
    // ------ Constants ------ //
    const [registrants, setRegistrants] = useState({})
    const [registrantNameFilter, setRegistrantNameFilter] = useState("")
    const [combinedFilteredRegistrants, setCombinedFilteredRegistrants] = useState([])
    const [registrationTypeFilter, setRegistrationTypeFilter] = useState("")
    const [totalRegistrants, setTotalRegistrants] = useState(0)
    const [sorting, setSorting] = useState({ key: "lastName", ascending: true });
    // ------ Name Search ------ //
    const NameFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        registrants.forEach((registrant) => {
            const nameFirst = registrant.firstName.toLowerCase()
            const nameLast = registrant.lastName.toLowerCase()
            if (!filterString || nameFirst.includes(filterString) || nameLast.includes(filterString)) {
                filtered.push(registrant)
            }
        })
        return filtered
    }

    const filteredByName = registrants && registrants.length ? NameFilter(registrantNameFilter) : []

    // ------ RegistrationType Search ------ //
    const RegistrationTypeFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        registrants.forEach((registrant) => {
            const regtype = registrant.registrationType ? registrant.registrationType.toLowerCase() : ''
            if (!filterString || regtype.includes(filterString)) {
                filtered.push(registrant)
            }
        })
        return filtered
    }

    const filteredByRegistrationType = registrants && registrants.length ? RegistrationTypeFilter(registrationTypeFilter) : []

    // ------ Helper Functions ------ //
    const clearFilters = () => {
        setIsCleared(true)
        setRegistrantNameFilter("")
        setRegistrationTypeFilter("")
        setIsCleared(false)
    }

    const renderHeader = () => {
        let result = {}
        result = {
            clientId: clientEventInfo.clientId,
            eventid: clientEventInfo.eventId,
        }
        return result
    }
    function applySorting(key) {
        if (sorting.key === key) {
            setSorting({ ...sorting, ascending: !sorting.ascending });
        } else {
            setSorting({ key: key, ascending: true });
        }
    }

    // ------ useEffect ------ //
    useEffect(() => {
        if (registrants && registrants.length > 0) {
            let regs = [...registrants];
            let filteredResults = []
            // apply filtering
            regs.forEach((reg) => {
                if (filteredByName.includes(reg)) {
                    if (filteredByRegistrationType.includes(reg)) {
                        filteredResults.push(reg)
                    }
                }
            })
            // apply sorting
            let sortedResults = filteredResults.sort((a, b) => {
                const optionA = a[sorting.key] && a[sorting.key] !== undefined ? a[sorting.key].toString().toLowerCase() : ''

                const optionB = b[sorting.key] && b[sorting.key] !== undefined ? b[sorting.key].toString().toLowerCase() : ''

                if (optionA < optionB) {
                    return sorting.ascending ? -1 : 1;
                }
                if (optionA > optionB) {
                    return sorting.ascending ? 1 : -1;
                }
                return 0;
            })
            setCombinedFilteredRegistrants(sortedResults);
        }

    }, [registrants, registrantNameFilter, registrationTypeFilter, sorting])

     useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            const fetchData = async () => {
                if (eventAdHocAttendees && eventAdHocAttendees.length > 0) {
                    setRegistrants(eventAdHocAttendees)
                    setEventAdHocAttendees(eventAdHocAttendees)
                    setTotalRegistrants(eventAdHocAttendees.length)
                    setLoaded(true)
                }
                else {
                    try {
                        let registrants = await badgeService.retrieveAdHocAttendanceReport(renderHeader())
                        if (!registrants || registrants.length === 0) {
                            setTotalRegistrants(0)
                            setRegistrants({})
                            setEventAdHocAttendees({})
                            setLoaded(true)
                        }
                        else {
                            setRegistrants(registrants)
                            setEventAdHocAttendees(registrants)
                            setTotalRegistrants(registrants.length)
                        }
                        clearFilters()
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                }
                

            }
            fetchData()
        }

    }, [])

    useEffect(() => {
        if (registrants.length) {
            setLoaded(true)
        }
    }, [registrants])
    // export should be an button for all DS FIX Add charting as user action to each column header, navigate to new page
    return (
        <>{loaded ? <div className="page-area">
            <><SearchBar
                searchName={registrantNameFilter}
                handleSearchName={setRegistrantNameFilter}
                placeholder="Registrant First/Last Name"
                label="Name"
                isCleared={isCleared === true || isCleared === false ? isCleared : false}
            />
                <SearchBar
                    searchName={registrationTypeFilter}
                    handleSearchName={setRegistrationTypeFilter}
                    placeholder="Registration type"
                    label="RegType"
                    isCleared={isCleared === true || isCleared === false ? isCleared : false}
                />

                {registrantNameFilter || registrationTypeFilter ?
                    <button onClick={clearFilters} id={styles.clearFilters}>
                        Clear
                    </button>
                    :
                    <>
                    </>
                }
                {combinedFilteredRegistrants.length ?
                    <div>
                        <table className={styles.tableReport}>
                            <thead>
                                <tr>
                                    <th colSpan={8} className={styles.tableReportHeader} >
                                        <h2>{totalRegistrants} Total Registrations</h2> </th></tr>
                                <tr className={styles.trReport}>
                                    <th className={styles.thReport} onClick={() => applySorting('firstName')}>
                                        First Name {sorting.key === 'firstName' && (sorting.ascending ? up : down)}
                                        <HoverButton
                                            title={'Registrant first name'}
                                            messageWidth={75}
                                        />
                                    </th>
                                    <th className={styles.thReport} onClick={() => applySorting('lastName')}>
                                        Last Name {sorting.key === 'lastName' && (sorting.ascending ? up : down)}
                                        <HoverButton
                                            title={'Registrant last name'}
                                            messageWidth={75}
                                        />
                                    </th>
                                    <th className={styles.thReport} onClick={() => applySorting('organization')}>
                                        Organization {sorting.key === 'organization' && (sorting.ascending ? up : down)}
                                        <HoverButton
                                            title={'Registrant organization'}
                                            messageWidth={75}
                                        />
                                    </th>
                                    <th className={styles.thReport} onClick={() => applySorting('registrationType')}>
                                        Registration Type {sorting.key === 'registrationType' && (sorting.ascending ? up : down)}
                                        <HoverButton
                                            title={'Registrant registration type'}
                                            messageWidth={75}
                                        />
                                    </th>
                                    <th className={styles.thReport} onClick={() => applySorting('latestPrinted')}>
                                        Print Date {sorting.key === 'latestPrinted' && (sorting.ascending ? up : down)}
                                        <HoverButton
                                            title={'Registrant printed date'}
                                            messageWidth={75}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody key={combinedFilteredRegistrants.length}>
                                {combinedFilteredRegistrants.map((reg, idx) => (
                                    <tr className={styles.trAccounts}>
                                        <AdHocAttendee
                                            registrant={reg}
                                        ></AdHocAttendee>
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                        <br /></div>
                    : <>
                        <div className={styles.noneFound}>
                            No match results found
                        </div>
                    </>
                }
            </>
        </div>
            :
            <LoadingSpinner />
        }
        </>

    );
}

export default EventAdHocAttendees;