import React from 'react';
import styles from "../../../pages/Home/ClientList/ClientList.module.css"
import UserActions from "../../../components/Home/ClientList/UserActions"

const Clients = ({
    clientData,
    setConfirmationVisible,
}) => {
  return (
      clientData.map((client, index) => (
          <div className={styles.rowIndex} key={index}>
              <div className={styles.colMD4}>{client.clientName}</div>
              <div className={styles.colMD4}>{client.ersList.join('; ')}</div>
              <UserActions
                  styles={styles}
                  columnWidth="colMD4"
                  setConfirmationVisible={setConfirmationVisible}
                  description={`${client.clientName} Events`}
                  contentlink={`/ClientEventData/${client.clientId}`}
                  description2={`Edit ${client.clientName}`}
                  contentlink2={`/ClientConfig/${client.clientId}`}
                  description3={`${client.clientName} Settings`}
                  contentlink3={`/EventSetting/${client.clientId}`}
                  stateData={client}
              />
          </div>
      ))
  );
}

export default Clients;