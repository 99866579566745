import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'
import styles from './BadgeImageManager.module.css'
// ------ Services ------ //
import * as clientEventService from '../../../services/clientEventService'
// Components
import UploadImage from '../../../components/Badging/BadgeImageManager/UploadImage'
import DropZone from '../../../components/Badging/BadgeImageManager/Dropzone'

const BadgeImageManager = () => {

    // useLocation & useNavigate --- Client state from previous page
    const location = useLocation()
    const passedState = location.state

    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }
    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])

    return (
        <div id={styles.imageUploadPage}>
            <h1>Badge Image Manager</h1>

            <DropZone
                className={styles.dropZone}
                styles={styles}
                clientData={clientEventInfo}
            />
        </div>
    );
}

export default BadgeImageManager;