import styles from './PrintPage.module.css'
import PrintExtraBadge from './PrintExtraBadge'

const PrintAttendee = ({
    stylingForm, // print attendee badge object
    CSSValues, // css values for each attendee badge object
    specialCSSRules, // smaller, smallest conditions
    fieldOrdering, // attendee badge field ordering
    extraBadges, // array of extrabadges
    extraBadgesPrintSettings, // cssvalues,specialcssrules,fieldordering, printconditions
}) => {
    const getPropName = function (obj, propname) {
        var key = this;
        for (key in obj) {
            if (key.toLowerCase() === propname.toLowerCase()) {
                return key.toString();
            }
        }
    };
    const renderSwitch = (field) => {
        let trueField = '';
        // specialCSSRules only for certain fields (smallest, smaller, etc.)
        switch (field.cssFieldName) {
            case 'ScannerField':
                // update field
                trueField = getPropName(stylingForm, 'ScannerField');
                return CSSValues.ScannerField && stylingForm[trueField] ? <p style={CSSValues.ScannerField}>
                    {stylingForm[trueField]}
                </p> : null
            case 'FirstName':
                trueField = getPropName(stylingForm, 'FirstName');
                return CSSValues.FirstName && stylingForm[trueField] ?
                    <p style={specialCSSRules.FirstNameSmallest.active && stylingForm[trueField].length > specialCSSRules.FirstNameSmallest.length ? CSSValues.FirstNameSmallest :
                        specialCSSRules.FirstNameSmaller.active && stylingForm[trueField].length > specialCSSRules.FirstNameSmaller.length ?
                            CSSValues.FirstNameSmaller :
                            CSSValues.FirstName
                    }>
                        {stylingForm[trueField]}
                    </p> : null
            case 'LastName':
                trueField = getPropName(stylingForm, 'LastName');
                return CSSValues.LastName && stylingForm[trueField] ?
                    <p style={specialCSSRules.LastNameSmallest.active && stylingForm[trueField].length > specialCSSRules.LastNameSmallest.length ? CSSValues.LastNameSmallest :
                        specialCSSRules.LastNameSmaller.active && stylingForm[trueField].length > specialCSSRules.LastNameSmaller.length ?
                            CSSValues.LastNameSmaller :
                            CSSValues.LastName
                    }>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Organization':
                trueField = getPropName(stylingForm, 'Organization');
                return CSSValues.Organization && stylingForm[trueField] ?
                    <p style={specialCSSRules.OrganizationSmallest.active && stylingForm[trueField].length > specialCSSRules.OrganizationSmallest.length ? CSSValues.OrganizationSmallest :
                        specialCSSRules.OrganizationSmaller.active && stylingForm[trueField].length > specialCSSRules.OrganizationSmaller.length ?
                            CSSValues.OrganizationSmaller :
                            CSSValues.Organization
                    }>
                        {stylingForm[trueField]}
                    </p> : null
            case 'RegistrationType':
                trueField = getPropName(stylingForm, 'RegistrationType');
                return CSSValues.RegistrationType && stylingForm[trueField] ?
                    <p style={CSSValues.RegistrationType}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Title':
                trueField = getPropName(stylingForm, 'Title');
                return CSSValues.Title && stylingForm[trueField] ?
                    <p style={CSSValues.Title}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'City':
                trueField = getPropName(stylingForm, 'City');
                return CSSValues.City && stylingForm[trueField] ?
                    <p style={CSSValues.City}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'State':
                trueField = getPropName(stylingForm, 'State');
                return CSSValues.State && stylingForm[trueField] ?
                    <p style={CSSValues.State}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Country':
                trueField = getPropName(stylingForm, 'Country');
                return CSSValues.Country && stylingForm[trueField] ?
                    <p style={CSSValues.Country}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Credentials':
                trueField = getPropName(stylingForm, 'Credentials');
                return CSSValues.Credentials && stylingForm[trueField] ?
                    <p style={CSSValues.Credentials}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Pronouns':
                trueField = getPropName(stylingForm, 'Pronouns');
                return CSSValues.Pronouns && stylingForm[trueField] ?
                    <p style={CSSValues.Pronouns}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'RegistrationColor':
                trueField = getPropName(stylingForm, 'RegistrationColor');
                return CSSValues.RegistrationColor && stylingForm[trueField] ?
                    <p style={CSSValues.RegistrationColor}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Prefix':
                trueField = getPropName(stylingForm, 'Prefix');
                return CSSValues.Prefix && stylingForm[trueField] ?
                    <p style={CSSValues.Prefix}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Suffix':
                trueField = getPropName(stylingForm, 'Suffix');
                return CSSValues.Suffix && stylingForm[trueField] ?
                    <p style={CSSValues.Suffix}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Salutation':
                trueField = getPropName(stylingForm, 'Salutation');
                return CSSValues.Salutation && stylingForm[trueField] ?
                    <p style={CSSValues.Salutation}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'DiscountCode':
                trueField = getPropName(stylingForm, 'DiscountCode');
                return CSSValues.DiscountCode && stylingForm[trueField] ?
                    <p style={CSSValues.DiscountCode}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'EmailAddress':
                trueField = getPropName(stylingForm, 'EmailAddress');
                return CSSValues.EmailAddress && stylingForm[trueField] ?
                    <p style={CSSValues.EmailAddress}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'MemberType':
                trueField = getPropName(stylingForm, 'MemberType');
                return CSSValues.MemberType && stylingForm[trueField] ?
                    <p style={CSSValues.MemberType}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Designation':
                trueField = getPropName(stylingForm, 'Designation');
                return CSSValues.Designation && stylingForm[trueField] ?
                    <p style={CSSValues.Designation}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Industry':
                trueField = getPropName(stylingForm, 'Industry');
                return CSSValues.Industry && stylingForm[trueField] ?
                    <p style={CSSValues.Industry}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Gender':
                trueField = getPropName(stylingForm, 'Gender');
                return CSSValues.Gender && stylingForm[trueField] ?
                    <p style={CSSValues.Gender}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Category':
                trueField = getPropName(stylingForm, 'Category');
                return CSSValues.Category && stylingForm[trueField] ?
                    <p style={CSSValues.Category}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Ribbon':
                trueField = getPropName(stylingForm, 'Ribbon');
                return CSSValues.Ribbon && stylingForm[trueField] ?
                    <p style={CSSValues.Ribbon}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Region':
                trueField = getPropName(stylingForm, 'Region');
                return CSSValues.Region && stylingForm[trueField] ?
                    <p style={CSSValues.Region}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'Department':
                trueField = getPropName(stylingForm, 'Department');
                return CSSValues.Department && stylingForm[trueField] ?
                    <p style={CSSValues.Department}>
                        {stylingForm[trueField]}
                    </p> : null
            case 'CityState_Combo':
                trueField = getPropName(stylingForm, 'CityState_Combo');
                return CSSValues.CityState_Combo && stylingForm[trueField] ?
                    <p style={CSSValues.CityState_Combo}>
                        {stylingForm[trueField]}
                    </p> : null
            default:
                return;
        }
    };
    const shouldPrint = () => {
        // check extra badges conditions and see if it should print or not
        return true;
        //return !(PasswordOld && PasswordNew && PasswordNew === PasswordConfirmation)
    };
    return (
        <>
            {fieldOrdering.map((field, index) => (renderSwitch(field)))}
            {extraBadges && extraBadges.length && shouldPrint() ? extraBadges.map((extraBadge, index) => (
                <PrintExtraBadge
                    stylingForm={extraBadge}
                    CSSValues={extraBadgesPrintSettings.cSSValues}
                    specialCSSRules={extraBadgesPrintSettings.specialCSSRules}
                    fieldOrdering={extraBadgesPrintSettings.fieldOrdering}
                />
            )) : <></>}
        </>
        )
}

export default PrintAttendee;