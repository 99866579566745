import { useNavigate } from 'react-router-dom'
import * as clientEventService from '../../../services/clientEventService'
// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrashCan, faCalendarDays } from '@fortawesome/free-solid-svg-icons'

// image elements
const pencil = <FontAwesomeIcon icon={faPencil} />
const trash = <FontAwesomeIcon icon={faTrashCan} />
const calendar = <FontAwesomeIcon icon={faCalendarDays} />

const UserActions = ({
    styles,
    columnWidth,
    setConfirmationVisible,
    description,
    contentlink,
    description2,
    contentlink2,
    stateData,
}) => {

    const handleClick = () => {
        setConfirmationVisible(true)
    }

    const navigate = useNavigate()
    const navigateAndStoreEvent = e => {
        e.preventDefault()
        if (stateData.clientName && stateData.clientId && stateData.eventId && stateData.name) {
            // save to localstorage
            clientEventService.setClientEvent({
                clientId: stateData.clientId,
                eventId: stateData.eventId,
                clientName: stateData.clientName,
                eventName: stateData.name,
            })
        }
        navigate(`${contentlink}`, { state: stateData });
    }
    return (
        <div className={`${styles.inputContainer} ${styles[columnWidth]}`}>

            <button
                className={styles.UAButtons}
                onClick={navigateAndStoreEvent}
                title={description}
            >
                <i id={styles.UAEvents}>
                    {calendar}
                </i>
            </button>

            <button
                className={styles.UAButtons}
                onClick={() => navigate(`${contentlink2}`, { state: stateData })}
                title={description2}
            >
                <i id={styles.UAEdit}>
                    {pencil}
                </i>
            </button>

            <button className={styles.UAButtons} onClick={handleClick}>
                <i id={styles.UADelete}>
                    {trash}
                </i>
            </button>

        </div>
    );
}

export default UserActions;