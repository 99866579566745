import { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import styles from './StaticFieldManager.module.css'

// ------ Services ------ //
import * as badgeService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'
// ------ Components ------ //
import StaticField from '../../../components/Badging/StaticFieldManager/StaticField'
import HoverButton from '../../../components/Miscellaneous/HoverButton'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'

// image elements
const circleCheck = <FontAwesomeIcon icon={faCircleCheck} />
const circleXmark = <FontAwesomeIcon icon={faCircleXmark} />

const StaticFieldManager = () => {

    // State
    const [extraBadgeFields, setExtraBadgeFields] = useState([])
    const [dataValidationObj, setDataValidationObj] = useState([])
    const [extraBadgesTable, setExtraBadgesTable] = useState([]) // compiled object of all badges for save process
    const [continueButtonVisibility, setContinueButtonVisibility] = useState(false)
    const [saveBody, setSaveBody] = useState({})

    const [clientEventInfo, setClientEventInfo] = useState({})
    const [client, setClient] = useState({})
    const navigate = useNavigate()

    // --- REST --- //
    const createRequestBody = async () => {

        let requestBody = {}

        if (client.clientId && client.eventId) {
            requestBody =
            {
                "ClientId": `${client.clientId}`,
                "EventId": `${client.eventId}`
            }
        }

        // Set future save request body
        setSaveBody({
            ...saveBody,
            ...requestBody
        })

        return await requestBody;
    }

    const updateCompiledObject = (index, updatedObj) => {
        setExtraBadgesTable(prevState => {
            const newState = [...prevState]
            newState[index] = updatedObj
            return newState
        })
    }

    const updateDataValidationObject = (index, updatedObj) => {
        setDataValidationObj(prevState => {
            const newState = [...prevState]
            newState[index] = updatedObj
            return newState
        })
    }

    const handleContinueButtonVisiblity = () => {
        dataValidationObj.includes(false)
            ? setContinueButtonVisibility(false)
            : setContinueButtonVisibility(true)
    }

    const handleSave = async e => {
        e.preventDefault()

        const getSaveObj = await buildSaveObject()

        const fullSaveObject = {
            ...saveBody,
            ExtraBadgeStaticFields: getSaveObj // append extraBadgeSmalls here
        }
        console.log(fullSaveObject)
        await badgeService.saveExtraBadgesStaticFields(fullSaveObject)
    }

    const buildSaveObject = () => {
        // Loop through extraBadgesTable to build each extraBadgeSmall object
        let returnArr = []
        let staticFields = []
        for (let i = 0; i < extraBadgeFields.length; i++) {
            console.log(extraBadgeFields[i])
            let keys = Object.keys(extraBadgesTable[i])
            let fieldsTemp = keys[0]
            let staticFieldsTemp = extraBadgesTable[i][fieldsTemp]
            let extraBadgeSmallsObj = {
                CSSField: extraBadgeFields[i].cssField,
                CSSFieldName: extraBadgeFields[i].cssFieldName,
                BadgeName: extraBadgeFields[i].badgeName ? extraBadgeFields[i].badgeName : "No overwrite name found",
                CSSExtraBadgeFieldName: extraBadgeFields[i].cssExtraBadgeFieldName,
                Fields: fieldsTemp,
                TextValues: staticFieldsTemp,
            }

            returnArr.push(extraBadgeSmallsObj)
        }

        return returnArr
    }
    useEffect(() => {
        if (client && client.clientId !== undefined && client.clientName) {
            const fetchData = async () => {
                const getReqBody = await createRequestBody()
                const EEBFs = await badgeService.getExtraBadgesStaticFields(getReqBody)
                setExtraBadgeFields(EEBFs)
            }
            fetchData()
        }

    }, [client])
    useEffect(() => {
        // store into local object
        let localclientevent = clientEventService.getClientEvent()
        if (localclientevent) {
            setClientEventInfo(localclientevent)
            setClient(localclientevent)
        }
    }, [])

    useEffect(() => {
        if (dataValidationObj.length) handleContinueButtonVisiblity()
    }, [dataValidationObj])
    
    return (
        <div id={styles.EBFPage}>
            <h1>Static Field Manager</h1>

            <button id={styles.backButton} onClick={() => navigate(-1)}> Back </button>
            {continueButtonVisibility ?
                <button id={styles.saveButton} onClick={(e) => handleSave(e)}>
                    Save
                </button>
                :
                <p id={styles.invalidDataHeader}>
                    Please provide values for <b>Badge Text for Selection</b> for each available selection
                </p>
            }

            <div id={styles.extraBadgeContainer}>
                {extraBadgeFields.length ?
                    <div id={styles.regTypeTableSection}>
                        <table id={styles.regTypeTable}>
                            <thead>
                                <tr id={styles.tableHeading}>
                                    <th className={styles.tableHeaders}>
                                        Badge Name
                                        <HoverButton
                                            title={'Values from ERS'}
                                            messageWidth={200}
                                        />
                                    </th>
                                    <th className={styles.tableHeaders}>
                                        Available Selections
                                        <HoverButton
                                            title={'Dropdown with available selections'}
                                            messageWidth={200}
                                        />
                                    </th>
                                    <th className={styles.tableHeaders}>
                                        Badge Text for Selection
                                        <HoverButton
                                            title={'Text to display on the extra badge for chosen selection'}
                                            messageWidth={200}
                                        />
                                    </th>
                                    <th>
                                        <i className={styles.buttonImage} id={styles.validIconHeader}>
                                            {circleCheck}
                                        </i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {extraBadgeFields.map((item, index) => (
                                    <StaticField
                                        index={index}
                                        item={item}
                                        updateCompiledObject={updateCompiledObject}
                                        updateDataValidationObject={updateDataValidationObject}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    :
                    <div>
                        <h2>No Extra Badges Selected - Please Select from Mapping page</h2>
                        <Link to="/BadgeToERSPropertyMapping"
                            state={clientEventInfo}
                        >
                            <h3>Go to Mapping Page</h3>
                        </Link>
                    </div>
                }
            </div>
        </div>
    );
}

export default StaticFieldManager;