import styles from '../../../pages/Badging/AdminPrint/AdminPrint.module.css'
// components
import { AsyncPaginate } from 'react-select-async-paginate';

const PaidAfterDate = ({ formData, handleChange, onBlur }) => {

    return (
        <div className={styles.inputContainer}>
            <input
                className={styles.datePicker}
                type="date"
                value={formData.paidAfterDate ? formData.paidAfterDate.split('T')[0] : ''}
                onChange={handleChange} // option selected
                name="paidAfterDate"
                onBlur={onBlur}
            />
        </div>
    );
}

export default PaidAfterDate;