/* dependencies to install in CLI --> must change directory into eventbadgescorereact project first
    - npm install react react-router-dom buffer
    - npm i --save @fortawesome/fontawesome-svg-core
    - npm install react-to-print

    # Free icons styles
    npm i --save @fortawesome/free-solid-svg-icons
    npm i --save @fortawesome/free-regular-svg-icons
    npm i --save @fortawesome/react-fontawesome@latest

    # Drag and drop
    npm install react-beautiful-dnd

    # MultiSelect Dropdown 
    npm install multiselect-react-dropdown  

    # Drag and drop images
    npm install --save react-dropzone

    # cookies
    npm install js-cookie

    # select that supports pagination on menu scroll
    npm install react-select react-select-async-paginate

    # tabs component
    npm install react-tabs
*/

import React, { useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

// pages
import Landing from './pages/Landing/Landing'
import Index from './pages/Index/Index'
import Home from './pages/Home/Home'
import ChangePassword from './pages/Account/ChangePassword/ChangePassword'
import ManageAccountSettings from './pages/Account/ManageAccountSettings/ManageAccountSettings'
import ForgotPassword from './pages/Home/ForgotPassword/ForgotPassword'
import ResetPassword from './pages/Home/ResetPassword/ResetPassword'
import EventRegSystemsList from './pages/Home/EventRegSystemsList/EventRegSystemsList'
import ClientCategoryList from './pages/Home/ClientCategoryList/ClientCategoryList'
import SiteFilterList from './pages/Home/SiteFilterList/SiteFilterList'
import ClientConfig from './pages/Home/ClientConfig/ClientConfig'
import ClientEventData from './pages/Home/ClientEventData/ClientEventData'
import ClientList from './pages/Home/ClientList/ClientList'
import EventLoad from './pages/Home/EventLoad/EventLoad'
import ViewGenerate from './pages/Home/ViewGenerate/ViewGenerate'
import EventSetting from './pages/Home/EventSetting/EventSetting'
import EventFilterList from './pages/Home/EventFilterList/EventFilterList'
import WebHookList from './pages/Home/WebHookList/WebHookList'
import BadgeTemplateIndex from './pages/Badging/BadgeTemplateIndex/BadgeTemplateIndex'
import BadgeToERSPropertyMapping from './pages/Badging/BadgeToERSPropertyMapping/BadgeToERSPropertyMapping'
import PrintPreview from './pages/Badging/PrintPreview/PrintPreview'
import EventBadgesManager from './pages/Home/EventBadgesManager/EventBadgesManager'
import MainBadgeTemplateStyling from './pages/Badging/MainBadgeTemplateStyling/MainBadgeTemplateStyling'
import ExtraBadgeTemplateStyling from './pages/Badging/ExtraBadgeTemplateStyling/ExtraBadgeTemplateStyling'
import RegTypeMapping from './pages/Badging/RegTypeMapping/RegTypeMapping'
import ExtraBadgeManager from './pages/Badging/ExtraBadgeManager/ExtraBadgeManager'
import BadgeImageManager from './pages/Badging/BadgeImageManager/BadgeImageManager'
import BadgeQrCodeManager from './pages/Badging/BadgeQrCodeManager/BadgeQrCodeManager'
import KioskManager from './pages/Badging/KioskManager/KioskManager'
import BadgeTemplateFieldOrdering from './pages/Badging/BadgeTemplateFieldOrdering/BadgeTemplateFieldOrdering'
import BadgeTemplateManager from './pages/Badging/BadgeTemplateManager/BadgeTemplateManager'
import KioskSetup from './pages/Badging/KioskSetup/KioskSetup'
import KioskLandingPageSetup from './pages/Badging/KioskLandingPageSetup/KioskLandingPageSetup'
import KioskScannerPageSetup from './pages/Badging/KioskScannerPageSetup/KioskScannerPageSetup'
import KioskEditScreenLayout from './pages/Badging/KioskEditScreenLayout/KioskEditScreenLayout'
import StaticFieldManager from './pages/Badging/StaticFieldManager/StaticFieldManager'
import ExtraBadgeTemplateManager from './pages/Badging/ExtraBadgeTemplateManager/ExtraBadgeTemplateManager'
import ExtraBadgeTemplateFieldOrdering from './pages/Badging/ExtraBadgeTemplateFieldOrdering/ExtraBadgeTemplateFieldOrdering'
import ManageSiteAccounts from './pages/Home/ManageSiteAccounts/ManageSiteAccounts'
import KioskIndex from './pages/Kiosk/KioskIndex/KioskIndex'
import KioskEdit from './pages/Kiosk/KioskEdit/KioskEdit'
import KioskPrint from './pages/Kiosk/KioskPrint/KioskPrint'
import Logoff from './pages/Landing/Logoff'
import EventStatusManager from './pages/Home/EventStatusManager/EventStatusManager'
import EventSetupStatusManager from './pages/Home/StatusSubpages/EventSetupStatusManager'
import EventBadgesStatusManager from './pages/Home/StatusSubpages/EventBadgesStatusManager'
import ExtraBadgesStatusManager from './pages/Home/StatusSubpages/ExtraBadgesStatusManager'
import KioskSetupStatusManager from './pages/Home/StatusSubpages/KioskSetupStatusManager'
import EventSpecificStatusPage from './pages/Home/StatusSubpages/EventSpecificStatusPage'
import AttendanceReport from './pages/Badging/AttendanceReport/AttendanceReport'
import AdminPrint from './pages/Badging/AdminPrint/AdminPrint'
import AdHocPrint from './pages/Badging/AdHocPrint/AdHocPrint'
// components
/*import Signup from './components/Signup/Signup'*/
import NavBar from './components/NavBar/NavBar'
import LoginForm from './components/Home/LoginForm/LoginForm'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'

// services
import *  as homeService from './services/homeService'

const App = () => {
    const navigate = useNavigate()
    const [user, setUser] = useState(homeService.getUser())
    const [role, setRole] = useState([''])
    const [clients, setClients] = useState(["APRA", "LAMAZE", "AUGS", "ISSX", "ICE", "SHARE", "VMUG", "HEUG", "COE", "PSDA", "ISTAT", "NAON", "FTTH", "OHUG", "HIUG", "AEA", "FORUM400",
        "INSIGHT", "SIDM", "IDUG", "SmithBucklin", "GCFD", "NAMSS", "VEEAM", "DSCOOP", "NAWLA", "Acehp", "TSHA", "CLMA", "WCIO", "ALFA", "RAA", "LMA", "AACVPR", "PRSM", "NSGC", "HSCA", "AMA",
        "ACN", "AmSect", "NDA", "SPN", "VRMA", "SGNA", "IAC", "ANNCON", "BSCAI", "FUEL", "TNNA", "ZACNadhoc", "EMBS", "RCA", "COMPTEL", "RIA", "NAHAM", "AAOHN", "ACTS", "BSN", "NER", "FICP",
        "ANPD", "ILEA", "HRMAC", "Neurocriticalcare", "SITE", "ACC", "INCOMPAS", "HIPOCT", "ISTATSoc", "CYBERARK", "AAST", "HRMACER", "ESX", "TNNAHL", "MSA", "PSDAER", "ISSXAv", "NAWLAER",
        "ANNCONER", "MIMECAST", "GCFDAV", "NDAMS", "BISA", "ASBMT", "NSGCIMP", "ICEIMP", "LMAAv", "INACSL", "DAC", "ACTSIMP", "IAADFS", "SHAREIMP", "ABA", "AEAIMP", "NAMSSAV", "ISEH", "SVIN", "ISTATMs"])

    const handleLogout = () => {
        homeService.logout()
        setUser(null)
		setRole(null)
        navigate('/')
    }

    const handleSignupOrLogin = () => {
        setUser(homeService.getUser())
    }

    const [message, setMessage] = useState([''])

    const handleChange = e => {
        console.log(e.target.value)
    }

    const updateMessage = msg => {
        setMessage(msg)
    }

    return (
        <>
            <NavBar 
                user={user}
                handleLogout={handleLogout}
            />

            <Routes>
                <Route path="/" element={
                    <Landing
                        user={user}
                        handleSignupOrLogin={handleSignupOrLogin}
                        LoginForm={LoginForm}
                        handleChange={handleChange}
                        message={message}
                        setMessage={setMessage}
                        updateMessage={updateMessage}
                    />}
                />
                <Route
                    path="/logoff"
                    element={
                        <Logoff
                            user={user}
                        />
                    }
                />
                <Route
                    path="/home"
                    element={
                        <ProtectedRoute user={user}>
                            <Home
                                user={user}
                                clients={clients}
                                handleChange={handleChange}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/index"
                    element={
                        <ProtectedRoute user={user}>
                            <Index
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/changepassword"
                    element={
                        <ProtectedRoute user={user}>
                            <ChangePassword
                                user={user}
                                updateMessage={updateMessage}
                                handleSignupOrLogin={handleSignupOrLogin}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/forgotpassword"
                    element={
                        <ForgotPassword />
                    }
                />
                <Route
                    path="/resetpassword"
                    element={
                        <ResetPassword />
                    }
                />
                <Route
                    path="/ManageAccountSettings"
                    element={
                        <ProtectedRoute user={user}>
                            <ManageAccountSettings
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventRegSystemsList"
                    element={
                        <ProtectedRoute user={user}>
                            <EventRegSystemsList
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ClientCategoryList"
                    element={
                        <ProtectedRoute user={user}>
                            <ClientCategoryList
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/SiteFilterList"
                    element={
                        <ProtectedRoute user={user}>
                            <SiteFilterList
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ClientConfig"
                    element={
                        <ProtectedRoute user={user}>
                            <ClientConfig
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ClientConfig/:ClientId"
                    element={
                        <ProtectedRoute user={user}>
                            <ClientConfig
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ClientConfig/:ClientId/edit"
                    element={
                        <ProtectedRoute user={user}>
                            <ClientConfig
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
				<Route
                    path="/ClientEventData"
                    element={
                        <ProtectedRoute user={user}>
                            <ClientEventData
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ClientEventData/:ClientId"
                    element={
                        <ProtectedRoute user={user}>
                            <ClientEventData
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ClientList"
                    element={
                        <ProtectedRoute user={user}>
                            <ClientList
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventLoad"
                    element={
                        <ProtectedRoute user={user}>
                            <EventLoad
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventLoad/:EventId"
                    element={
                        <ProtectedRoute user={user}>
                            <EventLoad
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ViewGenerate"
                    element={
                        <ProtectedRoute user={user}>
                            <ViewGenerate
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventSetting"
                    element={
                        <ProtectedRoute user={user}>
                            <EventSetting
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventSetting/:EventId"
                    element={
                        <ProtectedRoute user={user}>
                            <EventSetting
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                 <Route
                    path="/EventSetting/WebHookList"
                    element={
                        <ProtectedRoute user={user}>
                            <WebHookList
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventSetting/WebHookList/:EventId"
                    element={
                        <ProtectedRoute user={user}>
                            <WebHookList
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventSetting/EventFilterList"
                    element={
                        <ProtectedRoute user={user}>
                            <EventFilterList
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/BadgeTemplateIndex"
                    element={
                        <ProtectedRoute user={user}>
                            <BadgeTemplateIndex
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/BadgeToERSPropertyMapping"
                    element={
                        <ProtectedRoute user={user}>
                            <BadgeToERSPropertyMapping
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/PrintPreview"
                    element={
                        <ProtectedRoute user={user}>
                            <PrintPreview
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/MainBadgeTemplateStyling"
                    element={
                        <ProtectedRoute user={user}>
                            <MainBadgeTemplateStyling
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ExtraBadgeTemplateStyling"
                    element={
                        <ProtectedRoute user={user}>
                            <ExtraBadgeTemplateStyling
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventBadgesManager"
                    element={
                        <ProtectedRoute user={user}>
                            <EventBadgesManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/RegTypeMapping"
                    element={
                        <ProtectedRoute user={user}>
                            <RegTypeMapping
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ExtraBadgeManager"
                    element={
                        <ProtectedRoute user={user}>
                            <ExtraBadgeManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/BadgeImageManager"
                    element={
                        <ProtectedRoute user={user}>
                            <BadgeImageManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/BadgeQrCodeManager"
                    element={
                        <ProtectedRoute user={user}>
                            <BadgeQrCodeManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/KioskManager"
                    element={
                        <ProtectedRoute user={user}>
                            <KioskManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/BadgeTemplateFieldOrdering"
                    element={
                        <ProtectedRoute user={user}>
                            <BadgeTemplateFieldOrdering
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/KioskSetup"
                    element={
                        <ProtectedRoute user={user}>
                            <KioskSetup
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/KioskScannerPageSetup"
                    element={
                        <ProtectedRoute user={user}>
                            <KioskScannerPageSetup
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/KioskLandingPageSetup"
                    element={
                        <ProtectedRoute user={user}>
                            <KioskLandingPageSetup
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/BadgeTemplateManager"
                    element={
                        <ProtectedRoute user={user}>
                            <BadgeTemplateManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/KioskEditScreenLayout"
                    element={
                        <ProtectedRoute user={user}>
                            <KioskEditScreenLayout
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/StaticFieldManager"
                    element={
                        <ProtectedRoute user={user}>
                            <StaticFieldManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ExtraBadgeTemplateManager"
                    element={
                        <ProtectedRoute user={user}>
                            <ExtraBadgeTemplateManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ExtraBadgeTemplateFieldOrdering"
                    element={
                        <ProtectedRoute user={user}>
                            <ExtraBadgeTemplateFieldOrdering
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ManageSiteAccounts"
                    element={
                        <ProtectedRoute user={user}>
                            <ManageSiteAccounts
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="Kiosk/Index"
                    element={
                        <ProtectedRoute user={user}>
                            <KioskIndex
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="Kiosk/Edit"
                    element={
                        <ProtectedRoute user={user}>
                            <KioskEdit
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="Kiosk/Print"
                    element={
                        <ProtectedRoute user={user}>
                            <KioskPrint
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventStatusManager"
                    element={
                        <ProtectedRoute user={user}>
                            <EventStatusManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventSetupStatusManager"
                    element={
                        <ProtectedRoute user={user}>
                            <EventSetupStatusManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventSetupStatusManager/:EventId"
                    element={
                        <ProtectedRoute user={user}>
                            <EventSetupStatusManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventBadgesStatusManager"
                    element={
                        <ProtectedRoute user={user}>
                            <EventBadgesStatusManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventBadgesStatusManager/:EventId"
                    element={
                        <ProtectedRoute user={user}>
                            <EventBadgesStatusManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ExtraBadgesStatusManager"
                    element={
                        <ProtectedRoute user={user}>
                            <ExtraBadgesStatusManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/ExtraBadgesStatusManager/:EventId"
                    element={
                        <ProtectedRoute user={user}>
                            <ExtraBadgesStatusManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventSpecificStatusPage"
                    element={
                        <ProtectedRoute user={user}>
                            <EventSpecificStatusPage
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/EventSpecificStatusPage/:EventId"
                    element={
                        <ProtectedRoute user={user}>
                            <EventSpecificStatusPage
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/KioskSetupStatusManager"
                    element={
                        <ProtectedRoute user={user}>
                            <KioskSetupStatusManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/KioskSetupStatusManager/:EventId"
                    element={
                        <ProtectedRoute user={user}>
                            <KioskSetupStatusManager
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/AttendanceReport"
                    element={
                        <ProtectedRoute user={user}>
                            <AttendanceReport
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/AdminPrint"
                    element={
                        <ProtectedRoute user={user}>
                            <AdminPrint
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/AdHocPrint"
                    element={
                        <ProtectedRoute user={user}>
                            <AdHocPrint
                                user={user}
                            />
                        </ProtectedRoute>
                    }
                />
                {/*<Route*/}
                {/*    path="/signup"*/}
                {/*    element={<Signup handleSignupOrLogin={handleSignupOrLogin} />}*/}
                {/*/>*/}

                {/*<Route path="/clients"*/}

                {/*/>*/}
            </Routes>
        </>
    )
}

export default App
