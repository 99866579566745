import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './LoginForm.css'
import * as homeService from '../../../services/homeService'

// ------ Components ------ //
import LoadingSpinner from "../../../components/Miscellaneous/LoadingSpinner"

// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'

// image elements
const visibleEye = <FontAwesomeIcon icon={faEye} />
const hiddenEye = <FontAwesomeIcon icon={faEyeSlash} />

const LoginForm = ({
    updateMessage,
    handleSignupOrLogin,
}) => {
    const [loaded, setLoaded] = useState(false)
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    })

    const [passwordShown, setPasswordShown] = useState(false)

    const togglePasswordVisiblity = e => {
        e.preventDefault()
        setPasswordShown(passwordShown ? false : true);
    }

    const navigate = useNavigate()

    const handleChange = e => {
        //updateMessage('')
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const [error, setError] = useState(null)
    const errorDiv = (error)
        ?
        <ul>
            {Object.entries(error).length > 0 && Object.entries(error) &&
                Object.entries(error).map(([key, value]) => {
                    return <div className='errorMSG' key={value[0]}>{value[0]}</div>;
                })}
        </ul>
        : '';
    const handleSubmit = async e => {
        e.preventDefault()
        setLoaded(true)
        setError(null)
        try {
            await homeService.login(formData)
            setLoaded(false)
            handleSignupOrLogin()
            navigate('/')
        } catch (err) {
            updateMessage(err.message)
            setError(err.internalError);
            setLoaded(false)
        }
    }

    return (
        <>{loaded ? <LoadingSpinner /> : <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className='login-signup-form-container'
        >
            <div>
                <br />
                <input
                    type="text"
                    autoComplete="off"
                    className="input-container"
                    value={formData.username}
                    name="username"
                    onChange={handleChange}
                    placeholder="Username"
                />
            </div>
            <div className='pass-wrapper'>
                <br />
                <input
                    type={passwordShown ? "text" : "password"}
                    autoComplete="off"
                    className="input-container"
                    value={formData.password}
                    name="password"
                    onChange={handleChange}
                    placeholder="Password"
                />
                <button onClick={togglePasswordVisiblity} className="hs-password" tabIndex="-1">
                    {passwordShown ? hiddenEye : visibleEye}
                </button>
            </div>
            {errorDiv}
            <div className='login-signup-btns-container'>
                <button id='log-in-btn'>
                    Log In
                </button>
                <Link to="/forgotpassword">
                    <button id='forgot-pw-btn'>
                        Forgot Password?
                    </button>
                </Link>
            </div>
        </form> 
        }
        </>
    )
}

export default LoginForm