import styles from './PrintPage.module.css'
import PrintAttendee from './PrintAttendee'
const PrintPage = ({
    registrants, // array of registrants
    registrantPrintSettings, // cssvalues,specialcssrules,fieldordering
    extraBadges, // array of extrabadges
    extraBadgesPrintSettings, // cssvalues,specialcssrules,fieldordering, printconditions
    badgeDimensions, // print badge height and width
    componentRef, // print component
}) => {
    let printingPages = [];
    if (registrants && registrants.length > 0) {
        for (const registrant of registrants) {
            console.log(registrant);
            const tempTemplate =
                <div className={styles.badgeContainer} style={badgeDimensions}>
                    <PrintAttendee
                        stylingForm={registrant}
                        CSSValues={registrantPrintSettings.cSSValues}
                        specialCSSRules={registrantPrintSettings.specialCSSRules}
                        fieldOrdering={registrantPrintSettings.fieldOrdering}
                        extraBadges={extraBadges}
                        extraBadgesPrintSettings={extraBadgesPrintSettings}
                    />
                    <div className={styles.pagebreak}>&nbsp;</div>
            </div>
              
            printingPages.push(tempTemplate);
        }
    }
    
    return (
        <div className={styles.printArea} style={badgeDimensions} ref={componentRef}>
            {/* map and create single component for each page */}
            {printingPages}
            
        </div>

    );
}

export default PrintPage;