import React from 'react';
import styles from "../../../pages/Home/EventFilterList/EventFilterList.module.css"

const FilterContainsSelection = ({
    formData,
    handleChange
}) => {
    
    
    return (
<div>
    <label htmlFor="valuesContain" className={styles.label}>Contains: </label>
    <input
        type="text"
        autoComplete="off"
        value={formData.ValuesContain}
        name="ValuesContain"
                id={formData.Id}
        onChange={e => handleChange(e, formData.Id)}
            />
        </div>

    );
}
export default FilterContainsSelection;