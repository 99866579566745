const ContinueButton = ({
    continueButtonVisibility,
    handleSaveAndNavigate,
    requiredBadgeFields,
    styles
}) => {
    return (
        <>
            {continueButtonVisibility ?
                <button className={styles.CBTbuttons} id={styles.CBTContinue} onClick={handleSaveAndNavigate}>
                    Continue
                </button>
                :
                requiredBadgeFields ?
                    <div className={styles.incomplete} >
                        Please select fields for required properties:
                        <br />
                        <b>
                            {requiredBadgeFields}
                        </b>
                    </div >
                    : <></>
               
            }
        </>
    );
}

export default ContinueButton;