import { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from "react-router-dom"
// ------ Services ------ //

import * as badgeService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'
import styles from './BadgeTemplateIndex.module.css'

const BadgeTemplateIndex = () => {
    // useLocation & useNavigate --- Client state from previous page
    const location = useLocation()
    const passedState = location.state
    // State
    const [templates, setTemplates] = useState([])
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }

    /* -------- Functions -------- */

    // --- REST --- //
    const createRequestBody = async () => {

        let requestBody = {}

        if (clientEventInfo.clientId && clientEventInfo.eventId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`
            }
        }

        return await requestBody;
    }
    const fetchTemplateData = async () => {
        const data = await badgeService.getSavedTemplates(createRequestBody())
        setTemplates(data)
    }

    // useEffect - placeholder fetch function until controller & backend func is created
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            fetchTemplateData()
        }

    }, [clientEventInfo])
    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])

    return (
        <div className={styles.templateIndex}>
            <h1>Template Index</h1>

            {
                templates.length ?
                    templates.map((template, index) => (
                        <div>
                            <p key={index}>{template}</p>
                        </div>                
                    ))
                :
                    <div id={styles.noTemplates}>
                        <p>No templates found</p>
                
                        <Link to="/BadgeToERSPropertyMapping"
                            className={styles.templateLink}
                            state={clientEventInfo}
                        >
                            Create a template
                        </Link>
                    </div>
            }
        </div>
    );
}

export default BadgeTemplateIndex;