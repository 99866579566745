import { useState, useEffect } from 'react'
import styles from '../../../pages/Badging/BadgeToERSPropertyMapping/BadgeToERSPropertyMapping.module.css'
import EROButton from './EROButton'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-solid-svg-icons'

// ------ Image Elements ------ //
const plus = <FontAwesomeIcon icon={faSquarePlus} />
const minus = <FontAwesomeIcon icon={faSquareMinus} />

const BadgeField = ({
    field,
    selectedFields,
    EROs,
    compileSelections,
/*    handleRequiredFieldDisplayObj,*/
}) => {

    const [isActive, setIsActive] = useState(false)
    const [selections, setSelections] = useState([])

    const length = selections.length

    useEffect(() => {
        compileSelections(field, selections)
/*        handleRequiredFieldDisplayObj(field, selections)*/
    }, [length])

    useEffect(() => {
        if (selectedFields.length) {
            setSelections(selectedFields)
            compileSelections(field, selectedFields)
        }
    }, [])

    return (
        <>
            <div className={styles.badgingContainerSection} id={styles.EROIndex}>

                <div className={styles.badgeField} onClick={() => setIsActive(!isActive)}>
                    {field} ({length > 0 ? length + " fields" : "None selected"}) {isActive ? minus : plus}
                </div>

                {isActive &&
                    <div className={styles.EROContainer}>

                        {
                            EROs.length ?
                                EROs
                                    .sort((a, b) => a > b ? 1 : -1)
                                    .map((ERO, index) => (
                                    <EROButton
                                        index={index}
                                        ERO={ERO}
                                        selections={selections}
                                        setSelections={setSelections}
                                        isActive={isActive}
                                    />
                                ))
                        :
                            <h2>
                                No Available Data
                            </h2>
                    }

                    </div>
                }

            </div>
        </>
    );
}

export default BadgeField;