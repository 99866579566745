import { useState, useEffect } from 'react'
import * as accountService from '../../../services/accountService'
import * as clientManagementService from '../../../services/clientManagementService'
import * as eventManagementService from '../../../services/eventManagementService'
import styles from './ManageSiteAccounts.module.css'
import HoverButton from '../../../components/Miscellaneous/HoverButton'
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'
import ManageAccount from '../../../components/Account/ManageAccountSettings/ManageAccount'
import SelectEvent from '../../../components/Account/ManageAccountSettings/SelectEvent'
import SearchBar from "../../../components/Miscellaneous/SearchBar"

const ManageSiteAccounts = ({user }) => {

    // State
    const [loaded, setLoaded] = useState(false)
    const [clientOptions, setClientOptions] = useState([])
    const [eventOptions, setEventOptions] = useState([])
    const [accountNameFilter, setAccountNameFilter] = useState("")
    const [isCleared, setIsCleared] = useState(false)
	const [editingId, setId] = useState();

    const [Accounts, setAccounts] = useState([])
    const [create, setCreate] = useState(false)
    const [createRefresh, setCreateRefresh] = useState(false)
    const [formData, setFormData] = useState(
        {
            firstName: null,
            lastName: null,
            userName: null,
            roleName: null,
            defaultURL: 'https://sb-eventbadgescore.dev.itswebs.com/',
            event1: {},
            eventName: null,
            event2: {},
            eventName2: null,
            event3: {},
            eventName3: null,
            event4: {},
            eventName4: null,
            clientName: null,
            client: {},
            credentialsEmail: null,
        }
    )
    const [roles, setRoles] = useState([
        "Admin",
        "SiteUser",
    ])
    const [firstEvent, setFirstEvent] = useState({
        event: {},
        eventName: null,
    })

    const [secondEvent, setSecondEvent] = useState({
        event: {},
        eventName: null,
    })

    const [thirdEvent, setThirdEvent] = useState({
        event: {},
        eventName: null,
    })

    const [fourthEvent, setFourthEvent] = useState({
        event: {},
        eventName: null,
    })
    /*   Functions  */
    const clearFirstEvent = () => setFirstEvent({
        event: null,
        eventName: null,
    })
    const clearSecondEvent = () => setSecondEvent({
        event: null,
        eventName: null,
    })
    const clearThirdEvent = () => setThirdEvent({
        event: null,
        eventName: null,
    })
    const clearFourthEvent = () => setFourthEvent({
        event: null,
        eventName: null,
    })

    const ClearAllEvents = () => {
        clearFirstEvent();
        clearSecondEvent();
        clearThirdEvent();
        clearFourthEvent();
    }

    // ------ Name Search ------ //
    const accountFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        Accounts.forEach((account) => {
            const firstName = account.firstName ? account.firstName.toLowerCase() : "";
            const lastName = account.lastName ? account.lastName.toLowerCase() : "";
            const userName = account.userName ? account.userName.toLowerCase() : "";
            const clientName = account.client && account.client.clientName !== undefined && account.client.clientName !== '' ? account.client.clientName.toLowerCase() : "";

            if (!filterString || firstName.includes(filterString) || lastName.includes(filterString)
                || userName.includes(filterString) || clientName.includes(filterString)) {
                filtered.push(account)
            }
        })
        return filtered
    }

    const filteredByAccountName = accountFilter(accountNameFilter)

    const clearFilters = () => {
        setIsCleared(true)
        setAccountNameFilter("")
        setIsCleared(false)
    }

    const createAccount = e => {
        e.preventDefault()
        // keeps previous entry, if cancelled
        setCreate(create ? false : true)
    }

    const editAccount = e => {
        e.preventDefault()
        let divId = e.currentTarget.id
        //if (!divId) {
        //    e.current.id
        //}
        const items = Array.from(Accounts)
        let filtered = items[divId]

        // Have to add this to maintain one edit at a time, since all will share formData for editing state
        const editableCheck = i => i.editable
        if (items.some(editableCheck)) {
            return
        }
        filtered.editable = true
        setId(divId)
    }

    const cancelEdit = e => {
        e.preventDefault()
        let divId = e.currentTarget.id
        //if (!divId) {
        //    e.current.id
        //}
        const items = Array.from(Accounts)
        let filtered = items[divId]

        // Have to add this to maintain one edit at a time, since all will share formData for editing state
        const editableCheck = i => i.editable
        if (!items.some(editableCheck)) {
            return
        }
        filtered.editable = false
        setId(null)
    }
    
    const handleSubmit = async e => {
        e.preventDefault()
        let saverequest = createSaveRequest();
        try {
            await accountService.addAccountSetting(saverequest)
        } catch (err) {
            console.log(err)
        }
        setCreate(false)
        setFormData({
            firstName: null,
            lastName: null,
            userName: null,
            roleName: null,
            defaultURL: 'https://sb-eventbadgescore.dev.itswebs.com/',
            event1: {},
            eventName: null,
            event2: {},
            eventName2: null,
            event3: {},
            eventName3: null,
            event4: {},
            eventName4: null,
            clientName: null,
            client: {},
            credentialsEmail: null,
        })
        setCreateRefresh(true)
        fetchAccountSettings()
    }

    const createSaveRequest = (items) => {
        if (firstEvent) {
            formData.event1 = firstEvent.event;
            formData.eventName = firstEvent.eventName;
        }
        if (secondEvent) {
            formData.event2 = secondEvent.event;
            formData.eventName2 = secondEvent.eventName;
        }
        if (thirdEvent) {
            formData.event3 = thirdEvent.event;
            formData.eventName3 = thirdEvent.eventName;
        }
        if (fourthEvent) {
            formData.event4 = fourthEvent.event;
            formData.eventName4 = fourthEvent.eventName;
        }

        const fieldsSave = {
            'FirstName': formData.firstName,
            'LastName': formData.lastName,
            'UserName': formData.userName,
            'DefaultURL': formData.defaultURL,
            'RoleName': formData.roleName,
            'ClientId': formData.client.clientId,
            'ClientName': formData.client.clientName,
            'EventId1': formData.event1 && formData.event1.eventId ? formData.event1.eventId : null,
            'EventName1': formData.eventName ? formData.eventName : null,
            'EventId2': formData.event2 && formData.event2.eventId ? formData.event2.eventId : null,
            'EventName2': formData.eventName2 ? formData.eventName2 : null,
            'EventId3': formData.event3 && formData.event3.eventId ? formData.event3.eventId : null,
            'EventName3': formData.eventName3 ? formData.eventName3 : null,
            'EventId4': formData.event4 && formData.event4.eventId ? formData.event4.eventId : null,
            'EventName4': formData.eventName4 ? formData.eventName4 : null,
            'CredentialsEmail': formData.credentialsEmail ? formData.credentialsEmail : null,
        }
        return fieldsSave
    }

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }
    const isFormInvalid = () => {
        return !(formData.firstName && formData.lastName && formData.userName && formData.roleName);
    }
    const createEventsRequestBody = async () => {

        let requestBody = {}

        if (formData.client && formData.client.clientId) {
            requestBody =
            {
                clientId: formData.client.clientId,
                clientName: formData.client.clientName,
            }
        }

        return await requestBody;
    }

    // --- REST --- //
    const fetchAccountSettings = async () => {
        const accountSettings = await accountService.getAllAccountSettings();
        const items = Array.from(accountSettings)
        for (let i = 0; i < items.length; i++) {
            items[i].editable = false;
        }
        setAccounts(accountSettings)
        setCreateRefresh(false)
        setLoaded(true)
    }
    const fetchClients = async () => {
        const clients = await clientManagementService.getClients();
        setClientOptions(clients);
    }
    const fetchEvents = async () => {
        let req = await createEventsRequestBody();
        const events = await eventManagementService.loadEvents(req);
        setEventOptions(events);
    }
    useEffect(() => {
        if (formData.clientName && eventOptions && eventOptions.length > 0) {
            if (formData.eventName) {
                var foundEvent = eventOptions.find((item) => item.name === formData.eventName);
                if (foundEvent) {
                    let eventObject = ({
                        event: foundEvent,
                        eventName: formData.eventName
                    })
                    setFirstEvent(eventObject);
                    formData.event1 = foundEvent;
                }
                else {
                    formData.event1 = null;
                }
            }

            if (formData.eventName2) {
                var foundEvent2 = eventOptions.find((item) => item.name === formData.eventName2);
                if (foundEvent2) {
                    let eventObject = ({
                        event: foundEvent,
                        eventName: formData.eventName
                    })
                    setSecondEvent(eventObject);
                    formData.event2 = foundEvent2;
                }
                else {
                    formData.event2 = null;
                }
            }

            if (formData.eventName3) {
                var foundEvent3 = eventOptions.find((item) => item.name === formData.eventName3);
                if (foundEvent3) {
                    let eventObject = ({
                        event: foundEvent,
                        eventName: formData.eventName
                    })
                    setThirdEvent(eventObject);
                    formData.event3 = foundEvent3;
                }
                else {
                    formData.event3 = null;
                }
            }

            if (formData.eventName4) {
                var foundEvent4 = eventOptions.find((item) => item.name === formData.eventName4);
                if (foundEvent4) {
                    let eventObject = ({
                        event: foundEvent,
                        eventName: formData.eventName
                    })
                    setFourthEvent(eventObject);
                    formData.event4 = foundEvent4;
                }
                else {
                    formData.event4 = null;
                }
            }
        }

    }, [eventOptions && eventOptions.length > 0])

    useEffect(() => {
        if (formData.clientName && clientOptions && clientOptions.length > 0) {
            var foundClient = clientOptions.find((item) => item.clientName === formData.clientName);
            if (foundClient) {
                formData.client = foundClient;
                fetchEvents();
            }
            else {
                formData.clientName = null;
            }
        }

    }, [formData.clientName])

    useEffect(() => {
        fetchAccountSettings();
        fetchClients();
    }, [])


    return (
        <>
            {loaded ?
                <div className={styles.formAccountContainer}>
                    <h1>Manage Account Settings</h1>
                    <h2>Assign clients and events to accounts.<br />Only includes Admin and SiteUser roles.</h2>
                    <h3>{user ? "Current account is: " + user.email : null}</h3><br />
                    <div className={styles.formAccountContainer}>
                        <button onClick={createAccount} >
                            {!create ? "Add New User Account" : "Cancel"}
                        </button><br />
                    </div>
                    {
                        create ?
                            <>
                                <div className={styles.formAccountContainer}>
                                    <form
                                        autoComplete="off"
                                        onSubmit={handleSubmit}
                                        className={styles.container}
                                    >

                                        <div className={styles.inputContainer}>
                                            <label htmlFor="firstName" title='Account first name.' className={styles.label}>User First Name:</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                value={formData.firstName}
                                                name="firstName"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <label htmlFor="lastName" title='Account last name.' className={styles.label}>User Last Name:</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                value={formData.lastName}
                                                name="lastName"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <label htmlFor="userName" title='Account login. Can be anything, not just email.' className={styles.label}>User Name:</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                value={formData.userName}
                                                name="userName"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <label htmlFor="roleName" className={styles.label}>Role Name:</label>
                                            <select
                                                name="roleName"
                                                value={formData.roleName}
                                                onChange={handleChange}
                                            >
                                                <option disabled={true} value="" selected="selected">
                                                    -- Select an option --
                                                </option>
                                                {
                                                    roles.map((item, idx) => (
                                                        <option key={idx}>{item}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        <div className={styles.inputContainer}>
                                            <label htmlFor="defaultURL" className={styles.label}>Default URL:</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                value={formData.defaultURL}
                                                name="defaultURL"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div >
                                            <label htmlFor="defaultURL" className={styles.label}>Select Client:</label>
                                            <select
                                                name="clientName"
                                                value={formData.clientName}
                                                onChange={handleChange}
                                            >
                                                <option disabled={true} value="" selected="selected">
                                                    -- Select an option --
                                                </option>
                                                {
                                                    clientOptions.map((ci, idx) => (
                                                        <option key={idx}>{ci.clientName}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        {formData.clientName && formData.client ?
                                            <div>
                                                <SelectEvent
                                                    formData={formData}
                                                    eventInfo={firstEvent}
                                                    setEvent={setFirstEvent}
                                                    listEvents={eventOptions}
                                                    key={formData.clientName ? formData.clientName + "1" : "1"}
                                                />
                                            </div>

                                            : <></>
                                        }
                                        {formData.clientName && formData.client && firstEvent && firstEvent.eventName ?
                                            <div>
                                                <SelectEvent
                                                    formData={formData}
                                                    eventInfo={secondEvent}
                                                    setEvent={setSecondEvent}
                                                    listEvents={eventOptions}
                                                    key={formData.clientName ? formData.clientName + "2" : "2"}
                                                />
                                            </div>

                                            : <></>
                                        }
                                        {formData.clientName && formData.client && secondEvent && secondEvent.eventName ?
                                            <div >
                                                <SelectEvent
                                                    formData={formData}
                                                    eventInfo={thirdEvent}
                                                    setEvent={setThirdEvent}
                                                    listEvents={eventOptions}
                                                    key={formData.clientName ? formData.clientName + "3" : "3"}
                                                />
                                            </div>

                                            : <></>
                                        }
                                        {formData.clientName && formData.client && thirdEvent && thirdEvent.eventName ?
                                            <div>
                                                <SelectEvent
                                                    formData={formData}
                                                    eventInfo={fourthEvent}
                                                    setEvent={setFourthEvent}
                                                    listEvents={eventOptions}
                                                    key={formData.clientName ? formData.clientName + "4" : "4"}
                                                />
                                            </div>

                                            : <></>
                                        }
                                        <div className={styles.inputContainer}>
                                            <label htmlFor="credentialsEmail" title='Valid email to recieve login information.' className={styles.label}>Where to email account login info:</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                value={formData.credentialsEmail}
                                                name="credentialsEmail"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <button disabled={isFormInvalid()} className={styles.button}>
                                                Submit
                                            </button>
                                        </div>

                                    </form>

                                </div>
                            </>
                            :
                            <></>
                    }
                    <div className={styles.containerAccounts} key={editingId}>
                        <SearchBar
                        searchName={accountNameFilter}
                        handleSearchName={setAccountNameFilter}
                        placeholder="First, Last, UserName, ClientName"
                        label="Name"
                        isCleared={isCleared === true || isCleared === false ? isCleared : false}
                    />
                        {accountNameFilter ?
                            <button onClick={clearFilters} id={styles.clearFilters}>
                                Clear
                            </button>
                            :
                            <>
                            </>
                        }
                    </div>
                    
                    <div className={styles.containerAccounts}>
                        {filteredByAccountName.length ?
                            <div className={styles.tableAccountsContainer}>
                                <table className={styles.tableAccounts}>
                                    <thead>
                                        <tr className={styles.trAccounts}>
                                            <th className={styles.thAccounts}>
                                                First Name
                                                <HoverButton
                                                    title={'Account first name'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Last Name
                                                <HoverButton
                                                    title={'Account last name'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                UserName
                                                <HoverButton
                                                    title={'Account username'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Default URL
                                                <HoverButton
                                                    title={'Account default url'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Client Name
                                                <HoverButton
                                                    title={'Account client name selected'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Event 1
                                                <HoverButton
                                                    title={'Designated event 1'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Event 2
                                                <HoverButton
                                                    title={'Designated event 2'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Event 3
                                                <HoverButton
                                                    title={'Designated event 3'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Event 4
                                                <HoverButton
                                                    title={'Designated event 4'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.validData}></th>
                                        </tr>
                                    </thead>
                                    <tbody key={filteredByAccountName.length}>
                                        {filteredByAccountName.map((item, index) => (
                                            <ManageAccount
                                                index={index}
                                                accountSetting={item}
                                                clients={clientOptions}
                                                events={eventOptions}
                                                editAccount={editAccount}
                                                cancelEdit={cancelEdit}
                                                key={index}
                                                editingId={editingId}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div>
                                <h2>No Editable Accounts or Missing Permissions</h2>
                            </div>
                        }
                    </div>

                </div>
                :
                <LoadingSpinner />
            }
        </>
    );
}

export default ManageSiteAccounts;
