import { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import styles from './AdHocPrint.module.css'

// ------ Services ------ //
import * as badgeManagementService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'
import * as badgeService from '../../../services/badgeManagementService'
import * as kioskService from '../../../services/kioskService'
// components
import HoverButton from '../../../components/Miscellaneous/HoverButton'
import PrintPage from '../../../components/Miscellaneous/PrintPage'

const AdHocPrint = () => {

    // State
    const [stylingForm, setStylingForm] = useState();
    const [cSSValues, setCSSValues] = useState();
    const [badgeDimensions, setBadgeDimensions] = useState();
    const [specialCSSRules, setSpecialCSSRules] = useState();
    const [fieldOrdering, setFieldOrdering] = useState({})
    const [registrantPrintSettings, setRegistrantPrintSettings] = useState();
    const [blankForm, setBlankForm] = useState([])
    const [registrants, setRegistrants] = useState([])
    const location = useLocation()
    const passedState = location.state
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }

    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }

    useEffect(() => {
        setupForm()

    }, [fieldOrdering])
    const setupForm = async () => {
        if (cSSValues && stylingForm) {
            let stylingFields = stylingForm;
            let filteredResults = []
            // apply filtering, only want name, clear value
            // check if there's a cSSValues as well
            Object.keys(stylingFields).forEach(function (stylingFieldName) {
                if (cSSValues[stylingFieldName] && !stylingFieldName.toLowerCase().includes('smaller') && !stylingFieldName.toLowerCase().includes('smallest')) {
                    let tempHolder = {
                        fieldName: stylingFieldName,
                        fieldValue: '',
                    }
                    filteredResults.push(tempHolder)
                }
                stylingFields[stylingFieldName] = '';
            });
            stylingFields.clientId = clientEventInfo.clientId;
            stylingFields.eventId = clientEventInfo.eventId;
            // store clear entries
            // clear out entries
            setStylingForm(stylingFields)
            setBlankForm(filteredResults);
            let regTemp = [
                stylingForm
            ]
            setRegistrants(regTemp)
        }
        
    }

    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            const fetchData = async () => {
                // retrieve all elements print needs
                // set template settings
                const req = await createRequestBody();
                const templateSettings = await badgeService.getMainTemplate(req)
                //comes through as strings
                var savedStylingForm = templateSettings && templateSettings.stylingForm ? JSON.parse(templateSettings.stylingForm) : null;
                setStylingForm(savedStylingForm)
                var savedCssValues = templateSettings && templateSettings.cssValues ? JSON.parse(templateSettings.cssValues) : null;
                setCSSValues(savedCssValues)
                var savedBadgeDimensions = templateSettings && templateSettings.badgeDimensions ? JSON.parse(templateSettings.badgeDimensions) : null;
                setBadgeDimensions(savedBadgeDimensions)
                var savedSpecialCssRules = templateSettings && templateSettings.specialCSSRules ? JSON.parse(templateSettings.specialCSSRules) : null;
                setSpecialCSSRules(savedSpecialCssRules)
                // set field ordering
                const data = await badgeService.getRegFieldsOrdering(req)
                const items = Array.from(data)
                setFieldOrdering(items)
                let regPrintSettings = {
                    cSSValues: savedCssValues,
                    specialCSSRules: savedSpecialCssRules,
                    fieldOrdering: items
                }
                setRegistrantPrintSettings(regPrintSettings)
            }
            fetchData()
        }

    }, [clientEventInfo])

    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])
    // --- REST --- //
    const createRequestBody = async () => {

        let requestBody = {}

        if (clientEventInfo.clientId && clientEventInfo.eventId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`
            }
        }

        return await requestBody;
    }
    
    const handleSubmit = async e => {
        e.preventDefault()

        // call back end service here to POST form data
        try {
            //badgeManagementService.saveQrCodeSetup(appended)
        } catch (err) {
            console.log(err)
        }
    }

    const handleChange = (e, index) => {
        let temp = stylingForm;
        if (temp ) {
            //update entry
            temp[e.target.name] = e.target.value;
        }
        setStylingForm(temp)
        let tempHolder = {
            fieldName: e.target.name,
            fieldValue: e.target.value,
        }
        setBlankForm((prevState) => {
            const newState = [...prevState]
            newState[index] = tempHolder
            return newState
        });

    }
    // React-to-Print
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Badge Styling - Print Preview",
        removeAfterPrint: true,
        onAfterPrint: () => {
            kioskService.recordAdHocPrint(stylingForm);
            // reset page
            setupForm();
        }
    })

    const handlePrintTrigger = async e => {
        e.preventDefault()
        handlePrint()
    }

    return (
        <div className="page-area">
            <h1>AdHoc Print</h1>
            <h2>{clientEventInfo.clientName} ({clientEventInfo.eventName})</h2>
            <form
                autoComplete="off"
                onSubmit={handleSubmit}
                className={styles.formContainer}
            >
                <div className={styles.inputContainer}>
                    {blankForm && blankForm.length > 0 ?
                        blankForm.map((blankFormField, index) => (
                            <div>
                                <label>{blankFormField.fieldName}: </label>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    value={blankFormField.fieldValue}
                                    name={blankFormField.fieldName}
                                    onChange={(e) => handleChange(e, index )}
                                    className={styles.formInput}
                                    key={index}
                                />
                            </div>
                            
                        ))
                        :
                        <div>
                            No matching results
                        </div>
                    }
                    {badgeDimensions && fieldOrdering ?

                        <div>
                            <div className={styles.adHocPrintContainer}>
                                <PrintPage
                                    badgeDimensions={badgeDimensions}
                                    registrants={registrants}
                                    registrantPrintSettings={registrantPrintSettings}
                                    componentRef={componentRef}
                                />
                            </div>
                           
                            <button className={styles.PrintForm} id={styles.PrintButton} onClick={handlePrintTrigger}>
                                Print
                            </button>
                        </div>

                        : <></>}
                </div>
            </form>
        </div>

    );
}

export default AdHocPrint;