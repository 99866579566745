import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'

// Components
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'

import * as badgeService from '../../../services/badgeManagementService'

const Dropzone = ({ className, styles, clientData }) => {

    // Constants
    const maxTotalFiles = 10
    const navigate = useNavigate()

    const [files, setFiles] = useState([])
    const [rejected, setRejected] = useState([])
    const [CSSImageFields, setCSSImageFields] = useState([])
    const [CSStoImageMapping, setCSStoImageMapping] = useState([])
    const [saveBody, setSaveBody] = useState({
        clientId: clientData.clientId,
        eventId: clientData.eventId,
        imageMaps: []
    })
    const [imageMappings, setImageMappings] = useState([])

    const [imageCount, setImageCount] = useState(0) // current count of images to limit mapping dropdowns
    const [selectedValues, setSelectedValues] = useState(Array(imageCount).fill(''))
    const [selectionBodyForSave, setSelectionBodyForSave] = useState([])
    const [showSave, setSave] = useState(false)
    const [showSavedImagesOnly, setShowSavedImagesOnly] = useState(false) // show page with saved images only if there are saved options
    const [loaded, setLoaded] = useState(false)

    const [editingIndex, setEditingIndex] = useState(-1) // Index of the file currently being edited
    const [editedName, setEditedName] = useState('') // Name being edited
    const [filePreviews, setFilePreviews] = useState({}) // Object to store file previews

    const [imageURLs, setImageURLs] = useState([])

    const handleFileNameClick = index => {
        setEditingIndex(index)
        setEditedName(files[index].name)
    }

    const handleInputChange = e => {
        setEditedName(e.target.value)
    }

    const updatedSelectedValue = (lookupVar, editedValue) => {
        const updatedItems = selectedValues.map(selectedVal => {
            if (selectedVal === lookupVar) {
                return editedValue
            }
            return selectedVal
        })
        return updatedItems
    }

    const handleInputBlur = async index => {

        if (editedName.length) {
            const updatedFiles = [...files]
            const file = updatedFiles[index]
            const oldName = updatedFiles[index].name

            // Handle selected value state change to maintain file name integrity for save process
            if (oldName !== editedName) {
                const updatedSelectedVals = await updatedSelectedValue(oldName, editedName)
                setSelectedValues(updatedSelectedVals)
            }

            // Handle new file object creation to push back into files state
            const newFile = new File([file], editedName, { type: file.type })
            updatedFiles[index] = newFile
            setFiles(updatedFiles)

            // Preserve the preview URL
            const preview = filePreviews[file.name]
            const updatedPreviews = { ...filePreviews, [editedName]: preview }
            delete updatedPreviews[file.name] // Remove the old file name entry

            // Update the preview URL associated with the new file name
            const newPreview = filePreviews[editedName]
            if (newPreview) {
                URL.revokeObjectURL(newPreview) // Revoke the old blob URL
            }
            updatedPreviews[editedName] = URL.createObjectURL(newFile) // Create a new blob URL for the edited file
            setFilePreviews(updatedPreviews)

            setEditingIndex(-1)
        }
    }

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {

        if (acceptedFiles?.length) {
            // Append newly dropped files to the existing files array            
            setFiles(prevFiles => [...prevFiles, ...acceptedFiles])

            // Generate preview URLs for all files and store them in filePreviews state
            const updatedPreviews = acceptedFiles.reduce((prev, file) => {
                prev[file.name] = URL.createObjectURL(file)
                return prev
            }, { ...filePreviews }) // Merge existing previews with new previews
            setFilePreviews(updatedPreviews)
        }

        if (rejectedFiles?.length) {
            setRejected(previousFiles => [...previousFiles, ...rejectedFiles])
        }

    }, [filePreviews])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': []
        },
        maxSize: 1024 * 1000,
    })

    const removeFile = (name, index) => {
        const preview = filePreviews[name]
        if (preview) {
            URL.revokeObjectURL(preview) // Revoke the preview URL
        }
        setFiles(files.filter(file => file.name !== name))
        handleImageDeletion(index)
    }

    const handleImageDeletion = deletedIndex => {
        const newSelectedValues = selectedValues.filter((value, index) => index !== deletedIndex)
        setSelectedValues(newSelectedValues)
    }

    const removeRejected = name => {
        setRejected(files => files.filter(({ file }) => file.name !== name))
    }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                const base64 = reader.result.split(',')[1]
                resolve(base64)
            }
            reader.onerror = (error) => {
                reject(error)
            }
        })
    }

    const mapCSSFieldsToImageSelections = async () => {
        let returnArr = []

        for (let i = 0; i < imageCount; i++) {
            let newObj = {}

            const cssField = CSSImageFields[i].cssField
            const selected = selectedValues[i]

            // ADD LOGIC - need to marry selected value to the one that matches in files state
            let foundObject = files.find(obj => obj.name === selected)
            let fileSize = foundObject.size
            let fileExtension = foundObject.type
            fileExtension = fileExtension.substring(fileExtension.indexOf("/") + 1)

            const base64encoded = await getBase64(foundObject)

            newObj = {
                CSSField: cssField,
                Name: selected,
                Base64Image: base64encoded,
                Size: fileSize,
                Extension: fileExtension
            }

            returnArr.push(newObj)
        }

        setImageMappings(returnArr)
        console.log("return arr:", returnArr)
        return returnArr
    }

    const saveImages = async e => {
        e.preventDefault()
        let obj = {}
        const imageSelectionsMapped = await mapCSSFieldsToImageSelections()
        console.log(imageSelectionsMapped)
        if (saveBody.clientId === undefined) {
            saveBody.clientId = clientData.clientId;
            saveBody.eventId = clientData.eventId;
        }
        obj = {
            ...saveBody,
            imageMaps: imageSelectionsMapped
        }
        await setSaveBody(obj)

        return obj
    }

    const handleSave = async e => {
        const getSaveBody = await saveImages(e)
        const imageSave = await badgeService.saveBadgeImages(getSaveBody)
        console.log(imageSave)
    }

    // Function to check if all select elements have a value chosen
    const areAllSelectsFilled = async () => {
        const checkSelects = await selectedValues.every(value => value !== '') && selectedValues.length === imageCount
        setSave(checkSelects)
    }

    const handleSelectChange = (e, index) => {
        const newSelectedValues = [...selectedValues]
        newSelectedValues[index] = e.target.value
        setSelectedValues(newSelectedValues)
    }

    const convertBase64StringToImage = (str) => {
        const binaryString = window.atob(str)
        const blob = new Blob([binaryString], { type: 'image/png' })
        const imageUrl = URL.createObjectURL(blob)
        return imageUrl
    }

    const getClearedSaveBody = () => {
        let arr = []

        for (let i = 0; i < CSSImageFields.length; i++) {
            let obj = {}
            obj.cssField = CSSImageFields[i].cssField
            obj.cssFieldName = CSSImageFields[i].cssField
            obj.imageURL = CSSImageFields[i].cssField

            arr.push(obj)
        }

        console.log(arr)
        return arr
    }

    const clearSavedImages = async e => {
        e.preventDefault()
        // const clearSaveBody = await getClearedSaveBody()
        let obj = ({
            clientId: clientData.clientId,
            eventId: clientData.eventId
        })
        badgeService.clearBadgeImages(obj)
        navigate('/EventBadgesManager', { state: clientData })
    }

    /* ------------ useEffects ------------  */
    useEffect(() => {
        const fetchData = async () => {
            if (clientData.clientId !== undefined) { 
                const imageFields = await badgeService.getImageFields({
                    clientId: clientData.clientId,
                    eventId: clientData.eventId
                })

                for (let i = 0; i < imageFields.length; i++) {
                    let b64String = imageFields[i].base64Image ? imageFields[i].base64Image : null
                    imageFields[i].imageURL = b64String ? convertBase64StringToImage(b64String) : null
                }

                console.log(imageFields)
                setCSSImageFields(imageFields)
                if (imageFields.some(obj => obj.hasOwnProperty('base64Image'))) setShowSavedImagesOnly(true) // handles saved images page vs. new image upload/selection UI

                // Set initial client-specific save body params
                setSaveBody({
                    ...saveBody,
                    clientId: clientData.clientId,
                    eventId: clientData.eventId
                })

                setLoaded(true)
            }

        }
        fetchData()
    }, [clientData])

    useEffect(() => {
        setImageCount(files.length)
    }, [files.length])

    // Initialize the state variable with the current selected values on initial render
    useEffect(() => {
        const initialSelectedValues = Array(imageCount).fill('')
        setSelectedValues(initialSelectedValues)
    }, [imageCount])

    useEffect(() => {
        areAllSelectsFilled()
    }, [selectedValues])

    useEffect(() => {

    })

    return (
        <>
            {loaded ?
                showSavedImagesOnly ?
                    <>
                        <form id={styles.dropZoneForm}>

                            <h5>*Clear current saved images to alter uploads/selections</h5>

                            <button id={styles.clearSavedButton} onClick={(e) => clearSavedImages(e)}>
                                Clear Images
                            </button>
                            <h3 id={styles.acceptedHeader}>
                                Saved Files
                            </h3>

                            <ul id={styles.acceptedList}>
                                {CSSImageFields.map((image, index) => (
                                    <li key={image.name} className={styles.dropZoneListItem}>
                                        {image.base64Image && image.name && (
                                            <>
                                                <img
                                                    key={index}
                                                    className={styles.acceptedImage}
                                                    src={`data:image/jpeg;base64, ${image.base64Image}`}
                                                    alt="Converted from base64"
                                                    width={150}
                                                    height={150}
                                                />
                                                <p className={styles.savedImageName}>
                                                    {image.name}
                                                </p>
                                                <p className={styles.savedImageField}>
                                                    Mapped to: <b>{image.cssFieldName}</b>
                                                </p>
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </form>
                    </>
                    :
                    <>
                        <form id={styles.dropZoneForm}>

                            <h2>Upload Files</h2>

                            <div {...getRootProps({
                                className: className
                            })}>
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <>
                                            <p className={styles.dndMessage}>Drop the files here...</p>
                                        </>
                                        :
                                        <>
                                            <p className={styles.dndMessage}>Drag & drop some files here, or click to select files</p>
                                            <em className={styles.dndMessage}>({maxTotalFiles} files max may be mapped)</em>
                                        </>
                                }
                            </div>

                            {files.length ?
                                <>
                                    <h3 id={styles.acceptedHeader}>
                                        Accepted Files
                                    </h3>

                                    <ul id={styles.acceptedList}>
                                        {files.map((file, index) => (

                                            <li key={file.name} className={styles.dropZoneListItem}>
                                                {filePreviews[file.name] && (
                                                    <img
                                                        className={styles.acceptedImage}
                                                        src={filePreviews[file.name]}
                                                        alt=''
                                                        width={150}
                                                        height={150}
                                                        onLoad={() => {
                                                            URL.revokeObjectURL(filePreviews[file.name])
                                                        }}
                                                    />
                                                )}
                                                <button
                                                    type='button'
                                                    className={styles.imageButton}
                                                    onClick={() => removeFile(file.name, index)}
                                                >
                                                    X
                                                </button>

                                                {editingIndex === index ? (
                                                    <input
                                                        type="text"
                                                        value={editedName}
                                                        onChange={handleInputChange}
                                                        onBlur={() => handleInputBlur(index)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                e.preventDefault() // Prevent the default action of refreshing the page
                                                                handleInputBlur(index) // Call the function to handle input blur
                                                            }
                                                        }}
                                                        autoFocus
                                                        className={styles.editImageName}
                                                    />
                                                ) : (
                                                    <p className={styles.imageName} onClick={() => handleFileNameClick(index)}>
                                                        {file.name}
                                                    </p>
                                                )}
                                            </li>

                                        ))}
                                    </ul>
                                </>
                                :
                                <></>
                            }

                            {rejected.length ?
                                <>
                                    <h3 id={styles.rejectedHeader}>
                                        Rejected Files
                                    </h3>

                                    <ul id={styles.rejectedList}>
                                        {rejected.map(({ file, errors }) => (

                                            <li key={file.name} className={styles.dropZoneRejectedItem}>
                                                <div id={styles.rejectedSection}>
                                                    <p className={styles.rejectedName}>
                                                        {file.name}
                                                    </p>
                                                    <ul id={styles.errorList}>
                                                        {errors.map(error => (
                                                            <li key={error.code} className={styles.rejectedItemErrorMessage}>{error.message}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <button
                                                    type='button'
                                                    className={styles.removeRejectedButton}
                                                    onClick={() => removeRejected(file.name)}
                                                >
                                                    Remove
                                                </button>
                                            </li>

                                        ))}
                                    </ul>
                                </>
                                :
                                <></>
                            }

                            {
                                files.length ||
                                    (CSSImageFields.length
                                        && CSSImageFields.some(obj => obj.hasOwnProperty('base64Image'))
                                        && CSSImageFields.some(obj => obj.hasOwnProperty('name')))
                                    ?
                                    <>
                                        <h3 id={styles.fileMappingHeader}>
                                            File Mapping
                                        </h3>

                                        {
                                            !showSave &&
                                            <p id={styles.fileMappingPrompt}>
                                                Please select an option for each Image field
                                            </p>
                                        }

                                        <div id={styles.CSSFieldMapping}>
                                            <table id={styles.mappingTable}>
                                                {CSSImageFields.slice(0, imageCount).map((field, index) => (
                                                    <tr key={field.cssField}>
                                                        <td className={styles.mappingRow}>{field.cssFieldName}</td>
                                                        <td className={styles.mappingRow}>
                                                            <select
                                                                key={index}
                                                                name="imageSelect"
                                                                className={styles.formInput}
                                                                value={selectedValues[index]}
                                                                onChange={(e) => handleSelectChange(e, index)}
                                                            >
                                                                <option disabled={true} value="" selected="selected">
                                                                    -- Select an option --
                                                                </option>
                                                                {
                                                                    files.map((file, idx) => (
                                                                        <option key={idx}>{file.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                            }

                            {files.length && showSave ?
                                <button id={styles.saveButton} onClick={(e) => handleSave(e)}>
                                    Save
                                </button>
                                :
                                <></>
                            }
                        </form>
                    </>
                :
                <LoadingSpinner />
            }
        </>
    );
}

export default Dropzone;