import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import styles from './EventBadgesManager.module.css'
import { useNavigate, useLocation } from 'react-router-dom'
// ------ Services ------ //
import * as clientEventService from '../../../services/clientEventService'

const EventBadgesManager = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const passedState = location.state
    // useLocation & useNavigate --- Client state from previous page
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current =passedData
        }
       
    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId!==undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }

    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])
    return (
        <div id={styles.eventConfigurePage}>
            <h1>Event Badges Management</h1><br />
            <h2>Control event badge setup.</h2><br />
            <div id={styles.eventConfigureContainer}>
                <Link to={`/BadgeToERSPropertyMapping`} className={styles.eventLinks}
                    state={clientEventInfo}
                    title="Map ERS fields to badge fields.">
                    <h3>Badge to ERS Property Mapping</h3>
                </Link>
                <Link to={`/BadgeTemplateManager`} className={styles.eventLinks}
                    state={clientEventInfo}
                    title="Create/edit badge templates - style, order, editing, etc.">
                    <h3>Template Manager</h3>
                </Link>
                <Link to={`/ExtraBadgeTemplateManager`} className={styles.eventLinks}
                    state={clientEventInfo}
                    title="Extra badges template management.">
                    <h3>Extra Badges Template Manager</h3>
                </Link>
                <Link to={`/KioskManager`} className={styles.eventLinks}
                    state={clientEventInfo}
                    title="Create/edit kiosk settings - landing page, scanner page, header and layout management.">
                    <h3>Kiosk Setup Manager</h3>
                </Link>
            </div>

            <button id={styles.backButton} onClick={() => navigate(-1)}> Back </button>
        </div>
    );
}

export default EventBadgesManager;