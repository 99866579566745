import { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './ClientEventData.module.css'
//import UserActions from "../../../pages/Home/ClientEventData/UserActions"

// ------ Services ------ //
import * as eventService from "../../../services/eventManagementService"
import * as siteManagementService from '../../../services/siteManagementService'
import * as clientEventService from '../../../services/clientEventService'

// ------ Components ------ //
import SearchBar from "../../../components/Miscellaneous/SearchBar"
import EventsIndex from "../../../components/Home/ClientEventData/EventsIndex"
import LoadingSpinner from "../../../components/Miscellaneous/LoadingSpinner"
import ReloadERSNoneFound from '../../../components/Home/ClientEventData/ReloadERSNoneFound'

const ClientEventData = () => {
    const location = useLocation()
    const passedState = location.state

    // ------ State Variables ------ //
    const [events, setEvents] = useState([])
    const [eventDataReloadStatusEnum, setEventDataReloadStatusEnum] = useState([])
    const [ERSList, setERSList] = useState([])
    const [eventNameFilter, setEventNameFilter] = useState("")
    const [eventIDFilter, setEventIDFilter] = useState("")
    const [isCleared, setIsCleared] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [eventsReloadStarted, setEventsReloadStarted] = useState(false)
    const [loadEventsObj, setLoadEventsObj] = useState({})
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            setLoadEventsObj(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
            // clear cacheddata as well
            setEvents([])
            localStorage.removeItem('cachedData')
        }
    }

    // ------ Constants ------ //
    const combinedFilteredEvents = []

    // ------ Name Search ------ //
    const nameFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        events.forEach((event) => {
            const name = event.name.toLowerCase()

            if (!filterString || name.includes(filterString)) {
                filtered.push(event)
            }
        })
        return filtered
    }

    const filteredByNameEvents = nameFilter(eventNameFilter)

    // ------ ID Search ------ //
    const IDFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        events.forEach((event) => {
            const evtID = event.eventId.toLowerCase()

            if (!filterString || evtID.includes(filterString)) {
                filtered.push(event)
            }
        })
        return filtered
    }

    const filteredByIDEvents = IDFilter(eventIDFilter)

    // ------ Combine Search Arrays ------ //
    filteredByNameEvents.forEach((event) => {
        if (filteredByIDEvents.includes(event)) {
            combinedFilteredEvents.push(event)
        }
    })

    // ------ Handle ERS Split ------ //
    const categorizedData = combinedFilteredEvents.reduce((acc, obj) => {
        const ERS = obj.eventRegistrationSystemOptionSmall.eventRegistrationSystem

        // Check if the registration system category already exists in the accumulator
        if (!acc[ERS]) {
            acc[ERS] = []
        }

        // Push the current object into the category array
        acc[ERS].push(obj)

        return acc
    }, {})

    // ------ Helper Functions ------ //
    const clearFilters = () => {
        setIsCleared(true)
        setEventNameFilter("")
        setEventIDFilter("")
        setIsCleared(false)
    }

    const trimEventName = name => {
        const charCutoff = 40
        const trimmed = name.substring(0, charCutoff) + "..."

        if (name.length <= charCutoff) return name
        return trimmed
    }

    const changeDateFormat = dateStr => {
        const parsedDate = Date.parse(dateStr)
        const date = new Date(parsedDate)
        const options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };
        const dateFormatted = date.toLocaleDateString('en-us', options);
        const returnDate = dateFormatted.split(' ').slice(1).join('/')
        return returnDate
    }

    const changeDateTimeFormat = dateStr => {
        const parsedDate = Date.parse(dateStr)
        const date = new Date(parsedDate)
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        return date.toLocaleDateString('en-us', options);
    }

    const findFirstEventByName = (data, name) => {
        for (const key in data) {
            if (data.hasOwnProperty(name)) {
                const events = data[name]
                return events[0]
            }
        }
    }

    const fetchEvents = async body => {
        console.log("body check is: ", body)
        await eventService.reloadEventsByERS(body)
        console.log(events)
    }

    const reloadByERS = async e => {
        setEventsReloadStarted(true)
        const ERS = e.target.value.trim()
        const searchData = categorizedData
        const eventForReqBody = await findFirstEventByName(searchData, ERS)
        let reqBody = ""
        if (!eventForReqBody) {
            reqBody = {
                client: {
                    ClientId: loadEventsObj.clientId,
                    ClientName: loadEventsObj.clientName,
                },
                eventRegistrationSystemOptionSmall: {
                    Id: ERSList.find((item) => item.eventRegistrationSystem === ERS).id,
                    EventRegistrationSystem: ERS
                }
            }
        }
        else {
            reqBody = {
                client: {
                    ClientId: eventForReqBody.clientId,
                    ClientName: eventForReqBody.clientName,
                },
                eventRegistrationSystemOptionSmall: {
                    Id: eventForReqBody.eventRegistrationSystemOptionSmall.id,
                    EventRegistrationSystem: eventForReqBody.eventRegistrationSystemOptionSmall.eventRegistrationSystem
                }
            }
        }
        console.log("request body is: ", reqBody)

        fetchEvents(reqBody)
    }

    const fetchEventsData = async () => {
        if (eventDataReloadStatusEnum.length > 0) {
            if (loadEventsObj && loadEventsObj.clientId !== undefined && loadEventsObj.clientName) {
                const eventsData = await eventService.loadEvents(loadEventsObj)
                eventsData.forEach((event) => {
                    console.log("event: ", event)
                    if (event.eventDataReloadStatus) {
                        event.eventDataReloadStatusName = eventDataReloadStatusEnum[event.eventDataReloadStatus]
                    }
                    else {
                        event.eventDataReloadStatusName = eventDataReloadStatusEnum[0]
                    }
                })
                if (!eventsData || eventsData.length === 0) {
                    setLoaded(true)
                }
                else {
                    setEvents(eventsData)
                    setLoaded(true)
                }
                console.log(`There are ${events.length} events`)
            }
            else {
                setLoaded(true)
                console.log(`There are no events found. Select client and event.`)
            }
            
        }
        //else {
        //    fetchEventDataReloadStatus()
        //    fetchEventsData()
        //}
    }

    const fetchEventDataReloadStatus = async () => {
        const eventDataReloadStatus = await eventService.loadEventDataReloadStatusObjects()
        await setEventDataReloadStatusEnum(eventDataReloadStatus)
		const ERSListData = await siteManagementService.getAll()
        await setERSList(ERSListData)
    }

     // ------ useEffect ------ //
    useEffect(() => {
        if (loadEventsObj && loadEventsObj.clientId !== undefined && loadEventsObj.clientName) {
            const fetchData = async () => {
                try {
                    const cachedData = JSON.parse(localStorage.getItem('cachedData'))

                    if (cachedData && cachedData.events.length > 0) {
                        setEvents(cachedData.events)
                        await localStorage.setItem(
                            'cachedData',
                            JSON.stringify({ eventDataReloadStatusEnum, ERSList, events })
                        )
                    }
                    else {
                        if (cachedData) {
                            setEventDataReloadStatusEnum(cachedData.eventDataReloadStatusEnum)
                            setERSList(cachedData.ERSList)
                        }
                        
                        await fetchEventsData()
                        await localStorage.setItem(
                            'cachedData',
                            JSON.stringify({ eventDataReloadStatusEnum, ERSList, events })
                        )
                    }

                    //if (cachedData && cachedData.events.length > 0) {
                    //    if (cachedData.events[0].clientId === loadEventsObj.clientId
                    //        && cachedData.eventDataReloadStatusEnum.length
                    //        && cachedData.ERSList.length
                    //    ){
                    //        setEvents(cachedData.events)
                    //        await localStorage.setItem(
                    //            'cachedData',
                    //            JSON.stringify({ eventDataReloadStatusEnum, ERSList, events })
                    //        )
                    //    }
                    //    else {
                    //        await setEventDataReloadStatusEnum(cachedData.eventDataReloadStatusEnum)
                    //        await setERSList(cachedData.ERSList)
                    //        await fetchEventsData()
                    //        await localStorage.setItem(
                    //            'cachedData',
                    //            JSON.stringify({ eventDataReloadStatusEnum, ERSList, events })
                    //        )
                    //    }
                    //}
                    //else {
                    //    await fetchEventDataReloadStatus()
                    //    await fetchEventsData()
                    //    await localStorage.setItem(
                    //        'cachedData',
                    //        JSON.stringify({ eventDataReloadStatusEnum, ERSList, events })
                    //    )
                    //}
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            fetchData()
        }

    }, [loadEventsObj])

    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
                setLoadEventsObj(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            setLoadEventsObj(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])

    useEffect(() => {
        fetchEventDataReloadStatus()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => fetchEventsData(), (5000)) // run events fetch every 30 seconds
        return () => clearInterval(interval)
    }, [eventDataReloadStatusEnum])


    // ------ useMemo (caches state when navigating back to this page after first render) ------ //
    //useMemo(() => {
    //    localStorage.setItem(
    //        'cachedData',
    //        JSON.stringify({ eventDataReloadStatusEnum, ERSList, events })
    //    )
    //}, [eventDataReloadStatusEnum, ERSList, events])

    return (
        <div className="page-area">
            {loaded ?
                <>
                    {
                        loadEventsObj ?
                            <h1>{loadEventsObj.clientName} Events</h1>
                            :
                            <h1>No client selected</h1>

                    }

                    <SearchBar
                        searchName={eventNameFilter}
                        handleSearchName={setEventNameFilter}
                        placeholder="Event Name"
                        label="Name"
                        isCleared={isCleared === true || isCleared === false ? isCleared : false}
                    />

                    <SearchBar
                        searchName={eventIDFilter}
                        handleSearchName={setEventIDFilter}
                        placeholder="Event ID"
                        label="ID"
                        isCleared={isCleared === true || isCleared === false ? isCleared : false}
                    />

                    {eventNameFilter || eventIDFilter ?
                        <button onClick={clearFilters} id={styles.clearFilters}>
                            Clear
                        </button>
                        :
                        <>
                        </>
                    }

                    {
                        categorizedData &&
                            ((categorizedData.EdgeReg && categorizedData.EdgeReg.length) ||
                            (categorizedData.Impexium && categorizedData.Impexium.length) ||
                            (categorizedData.HigherLogic && categorizedData.HigherLogic.length) ||
                            (categorizedData.Cvent && categorizedData.Cvent.length) ||
                            (categorizedData.EPly && categorizedData.EPly.length) ||
                            (categorizedData.GTR && categorizedData.GTR.length) ||
                            (categorizedData.MemberSuite && categorizedData.MemberSuite.length) ||
                            (categorizedData.Stova && categorizedData.Stova.length) ||
                            (categorizedData.Rhythm && categorizedData.Rhythm.length) ||
                            (categorizedData.YourMembership && categorizedData.YourMembership.length))
                        ?
                            Object.keys(categorizedData).map(category => (
                                <EventsIndex
                                    categorizedData={categorizedData}
                                    category={category}
                                    changeDateTimeFormat={changeDateTimeFormat}
                                    changeDateFormat={changeDateFormat}
                                    reloadByERS={reloadByERS}
                                    trimEventName={trimEventName}
                                />
                            ))
                        :
                            <>
                                <div className={styles.eventsIndex}>
                                    <div className={styles.topBar}>
                                        <div className={`${styles.indexHeader} ${styles.colMD3}`}>Event Name</div>
                                        <div className={`${styles.indexHeader} ${styles.colMD2}`}>Date</div>
                                        <div className={`${styles.indexHeader} ${styles.colMD1}`} title="Current total number of registrants.">Count</div>
                                        <div className={`${styles.indexHeader} ${styles.colMD2}`} title="Current loading status.">Reloaded Status</div>
                                        <div className={`${styles.indexHeader} ${styles.colMD2}`} title="Time it took to load event data.">Reload TimeSpan</div>
                                        <div className={`${styles.indexHeader} ${styles.colMD2}`} title="Available actions for the event.">User Actions</div>
                                    </div>
                                    <div className={styles.noneFound}>
                                        No match results found
                                    </div>
                                </div>

                                <ReloadERSNoneFound
                                    categorizedData={categorizedData}
                                    loadEventsObj={loadEventsObj}
                                    styles={styles}
                                    reloadByERS={reloadByERS}
                                />
                            </>
                    }
                </>
                :
                <LoadingSpinner />
            }
        </div>
    );
}

export default ClientEventData;