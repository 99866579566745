import { useEffect, useState } from 'react';

// Styling
import styles from '../../../pages/Badging/StaticFieldManager/StaticFieldManager.module.css'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'

// image elements
const circleCheck = <FontAwesomeIcon icon={faCircleCheck} />
const circleXmark = <FontAwesomeIcon icon={faCircleXmark} />

// ------ Components ------ //

const StaticField = ({
    index,
    item,
    updateCompiledObject,
    updateDataValidationObject,
}) => {
    console.log("item", item)

    // Constants
    const options = item.availableSelections && item.availableSelections.length > 0 ? item.availableSelections : []

    // State
    const [validData, setValidData] = useState(false)
    const [selectedOption, setSelectedOption] = useState(options[0] || '')
    const [textValues, setTextValues] = useState(() => {
        const initialValues = {}
        options.forEach(option => {
            initialValues[option] = [
                item.extraBadgeStaticFields[0].textValue,
                item.extraBadgeStaticFields[1].textValue,
                item.extraBadgeStaticFields[2].textValue,
                item.extraBadgeStaticFields[3].textValue,
                item.extraBadgeStaticFields[4].textValue,
                item.extraBadgeStaticFields[5].textValue,
                item.extraBadgeStaticFields[6].textValue,
                item.extraBadgeStaticFields[7].textValue,
                item.extraBadgeStaticFields[8].textValue,
                item.extraBadgeStaticFields[9].textValue]
        })
        return initialValues
    })

    const handleSelectChange = e => {
        const newOption = e.target.value
        setSelectedOption(newOption)
        if (!textValues[newOption]) {
            setTextValues(prevValues => ({
                ...prevValues,
                [newOption]: ['', '', '', '', '', '', '', '', '', ''],
            }))
        }
    }

    const handleTextChange = (e, index) => {
        const newValue = e.target.value;
        console.log(textValues)
        console.log('first:' + textValues[selectedOption])
        let arrayHolder = textValues[selectedOption].map((val, idx) => idx === index ? newValue : val);
        textValues[selectedOption] = arrayHolder
        setTextValues(textValues);
        console.log(textValues)
        console.log('second:' + textValues[selectedOption])
        //setTextValues(prevValues => ({
        //    ...prevValues,
        //    [item.fields]: prevValues[item.fields].map((val, idx) => idx === index ? newValue : val)
        //}))

    }

    const handleValidData = (obj) => {
        return true;
        //return Object.values(obj).every(array => array.some(item => item.trim() !== ""))
    }

    useEffect(() => {
        const handleTextValuesChange = async () => {
            updateCompiledObject(index, textValues)
        }
        const validityCheck = async () => {
            const validBool = await handleValidData(textValues)
            setValidData(validBool)
            updateDataValidationObject(index, validBool)
        }
        handleTextValuesChange()
        validityCheck()
    }, [textValues])

    useEffect(() => {
        // setup static array
        const newOption = item.availableSelections
        if (item.extraBadgeStaticFields && item.extraBadgeStaticFields.length === 10) {
            setTextValues(prevValues => ({
                ...prevValues, [newOption]: [
                    item.extraBadgeStaticFields[0].textValue,
                    item.extraBadgeStaticFields[1].textValue,
                    item.extraBadgeStaticFields[2].textValue,
                    item.extraBadgeStaticFields[3].textValue,
                    item.extraBadgeStaticFields[4].textValue,
                    item.extraBadgeStaticFields[5].textValue,
                    item.extraBadgeStaticFields[6].textValue,
                    item.extraBadgeStaticFields[7].textValue,
                    item.extraBadgeStaticFields[8].textValue,
                    item.extraBadgeStaticFields[9].textValue],
            }))
        }
        else /*(!textValues[newOption]) */ {
            setTextValues(prevValues => ({
                ...prevValues,
                [newOption]: ['', '', '', '', '', '', '', '', '', ''],
            }))
        }
    }, [])

    return (
        <tr key={index} className={styles.SFTableRow}>
            <td className={styles.tableData}>
                {
                    item.badgeName
                        ? item.badgeName
                        : item.cssFieldName
                            ? item.cssFieldName
                            : ""
                }
            </td>
            <td className={styles.tableData}>
                {item.availableSelections}
            </td>
            <div className={styles.textSection}>
                {
                    [...Array(10).keys()].map(i => (
                    <input
                        key={i}
                        value={textValues[item.availableSelections] ? textValues[item.availableSelections][i] : ''}
                        onChange={e => handleTextChange(e, i)}
                        placeholder={`Enter text to display on line ${i + 1}`}
                        className={styles.selectionText}
                    />
                ))}
            </div>

            <td className={styles.validData}>
                {validData ?
                    <i className={styles.buttonImage} id={styles.validIcon}>
                        {circleCheck}
                    </i>
                    :
                    <i className={styles.buttonImage} id={styles.invalidIcon}>
                        {circleXmark}
                    </i>
                }
            </td>
        </tr>
    );
}

export default StaticField;
