import { useState, useEffect } from 'react'
import styles from "./EventStatusManager.module.css"
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'

// ------ Services ------ //
import * as clientService from "../../../services/clientManagementService"
import * as eventService from "../../../services/eventManagementService"
import * as siteService from "../../../services/siteManagementService"

// ------ Components ------ //
import SearchBar from "../../../components/Miscellaneous/SearchBar"
import ClientsSM from "../../../components/Home/EventStatusManager/ClientsSM"
import ConfirmationPopup from '../../../components/Miscellaneous/ConfirmationPopup'

const EventStatusManager = () => {

    // ------ State Variables ------ //
    const [clients, setClients] = useState([])
    const [events, setEvents] = useState({})
    const [ERSList, setERSList] = useState([])
    const [clientNameFilter, setClientNameFilter] = useState("")
    const [ERSNameFilter, setERSNameFilter] = useState("")
    const [isCleared, setIsCleared] = useState(false)
    const [clientsLoaded, setClientsLoaded] = useState(false)
    const [eventsLoadedCompiled, setEventsLoadedCompiled] = useState([]) // compiled object of all clients' events' loading statuses
    const [eventsLoaded, setEventsLoaded] = useState(false)

    // ------ Constants ------ //
    const combinedFilteredClients = []

    // ------ Name Search ------ //
    const nameFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        clients.forEach((client) => {
            const name = client.clientName.toLowerCase()

            if (!filterString || name.includes(filterString)) {
                filtered.push(client)
            }
        })
        return filtered
    }

    const filteredByNameClients = nameFilter(clientNameFilter)

    // ------ ERS Filter ------ //
    const ERSFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
            console.log(filterString)
        }
        let filtered = []
        let holder = "";
        clients.forEach((client) => {
            client.ersList.forEach((ersItem) => {
                holder += holder + ersItem + ';';
            })

            const ers = holder.slice(0, -1);

            if (!filterString || ers.toLowerCase().includes(filterString) || filterString == "none") {
                filtered.push(client)
            }
        })
        return filtered
    }

    const filteredByERSClients = ERSFilter(ERSNameFilter)

    // ------ useEffect ------ //
    useEffect(() => {
        const fetchData = async () => {
            const data = await clientService.getAllClients()

            const eventsArray = {}

            // Create an array to hold all the fetchEvents promises
            const eventsPromises = data.map(async (client) => {
                let clientData = {
                    ClientId: client.clientId,
                    ClientName: client.clientName
                }
                /*const events = await eventService.loadEvents(clientData)*/
                const events = await eventService.loadLastSixMonthsEvents(clientData) // using last six months call to improve load times on page
                eventsArray[client.clientName] = events
            })

            // Wait for all fetchEvents promises to complete
            await Promise.all(eventsPromises)

            const ERS = await siteService.getAll()

            setClients(data)
            setERSList(ERS)
            setEvents(eventsArray)
        }

        fetchData()
    }, [])


    // Update clientsLoaded state when data load completes
    useEffect(() => {
        if (
            clients.length &&
            ERSList.length &&
            events !== undefined &&
            Object.keys(events).length === clients.length
        ) {
            setClientsLoaded(true)
            setEventsLoaded(true)
        }
    }, [clients, ERSList, events])

    //// Update eventsLoaded state from prop drilling
    //useEffect(() => {
    //    if (eventsLoadedCompiled.length > 0
    //        && eventsLoadedCompiled.length === clients.length
    //        && eventsLoadedCompiled.some(eventsLoadedStatus => eventsLoadedStatus !== false)) {
    //        setEventsLoaded(true)
    //    }
    //}, [eventsLoadedCompiled.length, clientsLoaded])

    // ------ Combine Search Arrays ------ //
    filteredByNameClients.forEach((client) => {
        if (filteredByERSClients.includes(client)) {
            combinedFilteredClients.push(client)
        }
    })

    // ------ Change/Clear Handlers ------ //
    const handleERSChange = e => {
        setERSNameFilter(e.target.value)
    }

    const clearFilters = () => {
        setIsCleared(true)
        setClientNameFilter("")
        setERSNameFilter("")
        setIsCleared(false)
    }

    //// ------ Compile Event Loaded State ------ //
    //const compileEventLoadedStates = (index, eventsLoadedBool) => {
    //    setEventsLoadedCompiled(prevState => {
    //        const newState = [...prevState]
    //        newState[index] = eventsLoadedBool
    //        return newState
    //    })
    //}

    return (
        <div className="page-area">
            {
                clientsLoaded && eventsLoaded ?  
                <>
                    <div className={styles.showArea}>
                        <h1>Client/Event Status Index</h1>

                        <SearchBar
                            searchName={clientNameFilter}
                            handleSearchName={setClientNameFilter}
                            placeholder="Client Name"
                            label="Name"
                            isCleared={isCleared === true || isCleared === false ? isCleared : false}
                        />

                        <div className={styles.ERSSearch}>
                            <label htmlFor="EventRegistrationSystem">Search by ERS: </label>
                            <select
                                name="EventRegistrationSystem"
                                id={styles.ERSListDropdown}
                                value={ERSNameFilter}
                                onChange={handleERSChange}
                            >
                                <option value="" selected="selected">
                                    -- Select an option --
                                </option>

                                {
                                    ERSList.map((ers, idx) => (
                                        <option key={idx}>{ers.eventRegistrationSystem}</option>
                                    ))
                                }
                            </select>
                        </div>

                        {clientNameFilter || ERSNameFilter ?
                            <button onClick={clearFilters} id={styles.clearFilters}>
                                Clear
                            </button>
                            :
                            <>
                            </>
                        }

                        <div className={styles.clientsIndex}>

                            {combinedFilteredClients.length ?
                                combinedFilteredClients.map((client, index) => (
                                    <ClientsSM
                                        index={index}
                                        client={client}
                                        eventsClient={events[client.clientName]}
                                        styles={styles}                                        
                                    />
                                ))
                                :
                                <div className={styles.noneFound}>
                                    No match results found
                                </div>
                            }
                        </div>
                </div>
                </>
                :
                <LoadingSpinner />
            }
        </div>
    );
}

export default EventStatusManager;