import * as tokenService from './tokenService'
const BASE_URL = `/Progress`

/*----- Total Section Progress Calls -----*/

export const getEventManagmentProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventManagmentProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })

        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getEventBadgesManagmentProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventBadgesManagmentProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getExtraBadgesManagmentProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveExtraBadgesManagmentProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getKioskSetupManagmentProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveKioskSetupManagmentProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

/*----- Event Managment Progress Calls -----*/

export const getRegistrantsLoadedProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveRegistrantsLoadedProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })

        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getEventFilteringProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventFilteringProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })

        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getViewGeneratedProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveViewGeneratedProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })

        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getWebhookSetupProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveWebHooksProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getPostToERSProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrievePosttoERSProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

/*----- Event Badges Progress Calls -----*/

export const getEventBadgesMappingProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventBadgesMappingProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getTemplateManagmentProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveTemplateManagmentProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getRegistrationTypeMappingProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveRegistrationTypeMappingProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getQrCodeLogicProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveQrCodeLogicProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getImageProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveImageProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getFieldOrderingProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveFieldOrderingProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

/*----- Extra Badges Progress Calls -----*/

export const getExtraBadgesSetupProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveExtraBadgesSetupProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getExtraBadgesTemplateProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveExtraBadgesTemplateProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getExtraBadgesFieldOrderingProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveExtraBadgesFieldOrderingProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}


export const getExtraBadgesStaticFieldProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveExtraBadgesStaticFieldProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

/*----- Kiosk Progress Calls -----*/

export const getKioskSettingProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveKioskSettingProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getEditScreenProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEditScreenProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getLandingPageProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveLandingPageProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getScannerPageProgress = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveScannerPageProgress`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}