import { useState, useEffect,useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './KioskManager.module.css'
import { Routes, Route, useNavigate } from 'react-router-dom'

// ------ Services ------ //
import * as clientEventService from '../../../services/clientEventService'

const KioskManager = ({ user }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const passedState = location.state
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }

    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])
    return (
        <div id={styles.eventConfigurePage}>
            <h1>Kiosk Setup Manager</h1><br />
            <h2>Create/edit kiosk settings - landing page, scanner page, header and edit screen field layout management.</h2>
            <div id={styles.eventConfigureContainer}>
                <Link to={`/KioskSetup`} state={clientEventInfo} className={styles.eventLinks} title="Badge header image, sponsor image, and other settings.">
                    <h3>Kiosk Settings</h3>
                </Link>
                <Link to={`/KioskEditScreenLayout`} state={clientEventInfo} className={styles.eventLinks} title="Badge edit screen field layout.">
                    <h3>Kiosk Edit Screen Layout</h3>
                </Link>
                <Link to={`/KioskLandingPageSetup`} className={styles.eventLinks} title="Badge landing page management.">
                    <h3>Landing Page Setup</h3>
                </Link>
                <Link to={`/KioskScannerPageSetup`} className={styles.eventLinks} title="Badge scanner page management.">
                    <h3>Scanner Page Setup</h3>
                </Link>
            </div>

            <button id={styles.backButton} onClick={() => navigate(-1)}> Back </button>
        </div>
    );
}

export default KioskManager;