import { useState, useEffect, useRef } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import styles from './EventSetting.module.css'

// ------ Services ------ //
import * as clientEventService from '../../../services/clientEventService'

const EventSetting = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const passedState = location.state
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }
    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])
    return (
        <div id={styles.eventConfigurePage}>
            <h1>Event Management</h1><br />

            <div id={styles.eventConfigureContainer}>
                <Link to={`/EventLoad`} state={clientEventInfo} className={styles.eventLinks} title="Event registrants reload.">
                    <h3>Reload Event Registrants</h3>
                </Link>
                <Link to={`/EventSetting/EventFilterList`} state={clientEventInfo} className={styles.eventLinks} title="Manage event registrant property filtering.">
                    <h3>Event Filtering</h3>
                </Link>
                <Link to={`/EventBadgesManager`} state={clientEventInfo} className={styles.eventLinks} title="Event badges management.">
                    <h3>Event Badges Management</h3>
                </Link>
                <Link to={`/Kiosk/Index`} state={clientEventInfo} className={styles.eventLinks} title="Navigate to kiosk mode.">
                    <h3>Kiosk Mode</h3>
                </Link>
                <Link to={`/AttendanceReport`} state={clientEventInfo} className={styles.eventLinks} title="Event badges attendance report.">
                    <h3>Attendance Report</h3>
                </Link>
                <Link to={`/ViewGenerate`} state={clientEventInfo} className={styles.eventLinks} title="View recreation.">
                    <h3>Rebuild View</h3>
                </Link>
                <Link to={`/EventSetting/WebHookList`} state={clientEventInfo} className={styles.eventLinks} title="Manage event web hooks from ERS.">
                    <h3>Web Hooks</h3>
                </Link>
                <Link to={`/EventLoad`} state={clientEventInfo} className={styles.eventLinks} title="Push ERS registrants to secondary location.">
                    <h3>Post To ERS</h3>
                </Link>
            </div>

            <button id={styles.backButton} onClick={() => navigate(-1)}> Back </button> 
        </div>
    );
}

export default EventSetting;