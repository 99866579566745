import { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import * as clientManagementService from '../../../services/clientManagementService'
import * as siteManagementService from '../../../services/siteManagementService'
import ERSComponent from '../../../components/Home/EventRegSystemsList/ERSComponent';
import styles from "./EventRegSystemsList.module.css"

// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSquareCheck, faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'

// image elements
const pencil = <FontAwesomeIcon icon={faPencil} />
const squareCheck = <FontAwesomeIcon icon={faSquareCheck} />
const squareXmark = <FontAwesomeIcon icon={faSquareXmark} />

const EventRegSystemsList = ({ user }) => {

    // state
    const [AMS, setAMS] = useState([
        {
            id: null,
            eventRegistrationSystem: null,
            rank: null,
            overrideRank: null,
            editable: false
        }
    ])

    const [formData, setFormData] = useState(
        {
            id: "",
            eventRegistrationSystem: "",
            rank: "",
            overrideRank: "",
        }
    )

    const [formDataEdit, setFormDataEdit] = useState(
        {
            Id: "",
            EventRegistrationSystem: "",
        }
    )

    const [message, setMessage] = useState([''])
    const [create, setCreate] = useState(false)
    const [createRefresh, setCreateRefresh] = useState(false)
    const [editRefresh, setEditRefresh] = useState(false)
    const [loaded, setLoaded] = useState(false)

    // useEffect
    useEffect(() => {
        const fetchData = async () => {
            const data = await siteManagementService.getAll()
            const items = Array.from(data)
            for (let i = 0; i < items.length; i++) {
                items[i].editable = false;
            }
            setAMS(items)
            setCreateRefresh(false)
            setEditRefresh(false)
            setLoaded(true)
        }
        fetchData()
    }, [createRefresh, editRefresh])

    // functions
    const handleOnDragEnd = result => {
        if (!result.destination) return

        const items = Array.from(AMS)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        for (let i = 0; i < items.length; i++) {
            items[i].overrideRank = i + 1;
        }

        // handle form data and connect to edit service in siteManagementService.js here
        try {
            siteManagementService.editAll(items)
        } catch (err) {
            setMessage(err.message)
        }

        setAMS(items)
    }

    const editAMS = e => {
        e.preventDefault()

        let divId = e.target.id
        const items = Array.from(AMS)
        let filtered = items.filter(ams => divId === ams.id)[0]

        // Have to add this to maintain one edit at a time, since all will share formDataEdit for editing state
        const editableCheck = i => i.editable
        if (items.some(editableCheck)) return
        filtered.editable = true

        setAMS(items)
    }

    const cancelEdit = e => {
        e.preventDefault()

        let divId = e.target.id
        const items = Array.from(AMS)
        let filtered = items.filter(ams => divId === ams.id)[0]
        filtered.editable = false

        setAMS(items)
        setFormDataEdit({
            Id: "",
            EventRegistrationSystem: ""
        })

    }

    const submitEdit = async e => {
        e.preventDefault()

        // cancel and return if edit is attempted with no name value
        if (!formDataEdit.EventRegistrationSystem) {
            cancelEdit(e)
            return
        }

        // handle form data and connect to edit service in siteManagementService.js here
        try {
            await siteManagementService.edit(formDataEdit)
        } catch (err) {
            setMessage(err.message)
        }

        // reset editable state back to false
        let divId = e.target.id
        const items = Array.from(AMS)
        let filtered = items.filter(ams => divId === ams.id)[0]
        filtered.editable = false
        setAMS(items)

        // setFormDataEdit back to blanks
        clearFormData()

        // Trigger re-render in useEffect dependency array
        setEditRefresh(true)
    }

    const clearFormData = useCallback(
        () => {
            setFormDataEdit({
                Id: "",
                EventRegistrationSystem: "",
            })
        },
        []
    )

    const createERS = e => {
        e.preventDefault()
        setCreate(create ? false : true)
    }

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleChangeEdit = (e, idNumber) => {

        let idReturn = idNumber

        setFormDataEdit({
            ...formDataEdit,
            Id: idReturn,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            await siteManagementService.create(formData)
        } catch (err) {
            setMessage(err.message)
        }
        setCreate(false)
        setFormData({
            id: "",
            eventRegistrationSystem: "",
            rank: "",
            overrideRank: "",
        })
        setCreateRefresh(true)
    }

    const isFormInvalid = () => {
        return !(formData.eventRegistrationSystem)
    }

    const isEditInvalid = () => {
        return !(formDataEdit.EventRegistrationSystem)
    }

    return (
                <>
            {loaded ?
        <div className="page-area">
            <h1>Event Registration Systems (ERS)</h1>
            <h2>Drag and drop to change rank/ordering.</h2>
            <button onClick={createERS}>
                {!create ? "Add New ERS" : "Cancel"}
            </button>

            {
                create ?
                    <>
                        <div className={styles.formContainer}>
                            <form
                                autoComplete="off"
                                onSubmit={handleSubmit}
                                className={styles.container}
                            >

                                <div className={styles.inputContainer}>
                                    <label htmlFor="password" className={styles.label}>New ERS Name</label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        value={formData.eventRegistrationSystem}
                                        name="eventRegistrationSystem"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className={styles.inputContainer}>
                                    <button disabled={isFormInvalid()} className={styles.button}>
                                        Submit
                                    </button>
                                </div>

                            </form>

                        </div>
                    </>
                    :
                    <></>
            }

            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="ERSList">
                    {(provided) => (

                        <ul className={styles.ERSList} {...provided.droppableProps} ref={provided.innerRef}>
                            {AMS.map(({ id, eventRegistrationSystem, rank, overrideRank, editable }, index) => (

                                <Draggable key={id} draggableId={id} index={index}>
                                    {(provided) => (

                                        <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>

                                            <div className={styles.ERSListOptions}>

                                                {!editable ?

                                                    <>
                                                        <div>
                                                            {eventRegistrationSystem}
                                                            <br /><br />
                                                            Rank: {overrideRank ? overrideRank : rank}
                                                        </div>

                                                        <button className={styles.editAMS} onClick={e => editAMS(e)} id={id}>
                                                            <i className={styles.buttonImage}>{pencil}</i>
                                                        </button>
                                                    </>

                                                    :

                                                    <>
                                                        <div>
                                                            <form>
                                                                <input
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    value={formDataEdit.EventRegistrationSystem}
                                                                    name="EventRegistrationSystem"
                                                                    placeholder={eventRegistrationSystem}
                                                                    onChange={e => handleChangeEdit(e, id)}
                                                                    id={id}
                                                                />
                                                                <div className={styles.inputContainer}>
                                                                    <button onClick={e => cancelEdit(e)} id={id} className={styles.editButtons}>
                                                                        <i className={styles.buttonImage} id={styles.editCancel}>
                                                                            {squareXmark}
                                                                        </i>
                                                                    </button>
                                                                    <button onClick={e => submitEdit(e)} id={id} className={styles.editButtons} disabled={isEditInvalid()}>
                                                                        <i className={styles.buttonImage} id={styles.editSubmit}>
                                                                            {squareCheck}
                                                                        </i>
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        </li>

                                    )}
                                </Draggable>
                            ))}
                        </ul>

                    )}
                </Droppable>
            </DragDropContext>

            {/*<ERSComponent />  */}
                </div>
                :
                <LoadingSpinner />
            }
        </>
    );
}

export default EventRegSystemsList;