import { useState, useEffect, useCallback } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import * as badgeService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'
import styles from './ExtraBadgeTemplateFieldOrdering.module.css'
// ------ Components ------ //
import OrderingField from '../../../components/Badging/ExtraBadgeTemplateFieldOrdering/OrderingField'

const ExtraBadgeTemplateFieldOrdering = ({ user }) => {

    // State
    const [extraBadgeFields, setExtraBadgeFields] = useState([])
    const [extraBadgesTable, setExtraBadgesTable] = useState([]) // compiled object of all badges for save process

    const [clientEventInfo, setClientEventInfo] = useState({})
    const [client, setClient] = useState({})
    const navigate = useNavigate()

    const [message, setMessage] = useState([''])
    // --- REST --- //
    const createRequestBody = async () => {

       let requestBody = {}

       if (client.clientId && client.eventId) {
            requestBody =
            {
                "clientId": `${client.clientId}`,
                "eventId": `${client.eventId}`
            }
        }

        return await requestBody;
    }

    const updateCompiledObject = (index, updatedObj) => {
        setExtraBadgesTable(prevState => {
            const newState = [...prevState]
            newState[index] = updatedObj
            return newState
        })
    }
    const fetchExtraBadgesOrderingData = async () => {
        setExtraBadgeFields([])
        const getReqBody = await createRequestBody()
        const data = await badgeService.getExtraBadgeFieldsOrdering(getReqBody)
        setExtraBadgeFields(data)
    }
    // useEffect
    useEffect(() => {
        if (client && client.clientId !== undefined && client.clientName) {
            fetchExtraBadgesOrderingData()
        }

    }, [client])
    useEffect(() => {
        // store into local object
        let localclientevent = clientEventService.getClientEvent()
        if (localclientevent) {
            setClientEventInfo(localclientevent)
            setClient(localclientevent)
        }
    }, [])

    return (
        <div className="page-area">
            <div className={styles.templateIndex}>
                <h1>Extra Badge Template Field Layout Ordering</h1>
                <h2>Drag and drop to change rank/ordering.</h2>
                {extraBadgeFields.map((item, index) => (
                    <OrderingField
                        item={item}
                        client={client}
                        setMessage={setMessage}
                        fetchExtraBadgesOrderingData={fetchExtraBadgesOrderingData}
                        key={index}
                    />
                ))}

            </div>
        </div>
    );
}

export default ExtraBadgeTemplateFieldOrdering;