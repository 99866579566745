import React from 'react';
import styles from "../../../pages/Home/EventFilterList/EventFilterList.module.css"
import EROButton from '../../../components/Badging/BadgeToERSPropertyMapping/EROButton'
import { useState, useEffect } from 'react'

// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-solid-svg-icons'
// image elements
const plus = <FontAwesomeIcon icon={faSquarePlus} />
const minus = <FontAwesomeIcon icon={faSquareMinus} />

const FilterEqualSelection = ({
    setFormData,
    formData,
    filterField,

}) => {
    const [selections, setSelections] = useState((formData.ValuesEqualSelect && formData.ValuesEqualSelect.length > 0 ?
        !Array.isArray(formData.ValuesEqualSelect) ? formData.ValuesEqualSelect.split(';') : formData.ValuesEqualSelect : []))
    const [isActive, setIsActive] = useState(false)

    const length = selections.length

    useEffect(() => {
        setFormData({
            ...formData,
            ValuesEqualSelect: selections,
        })
       
    }, [selections])

    return (<div id={styles.badgingContainer}>
        <div className={styles.badgingContainerSection} id={styles.EROIndex}>

            <div className={styles.badgeField} onClick={() => setIsActive(!isActive)}>
                {filterField.columnName} ({length > 0 ? length + " fields" : "None selected"}) {isActive ? minus : plus}
            </div>

            {isActive &&
                <div className={styles.EROContainer}>

                    {
                        filterField.valueItems.map(items => items.itemValue).length ?
                            filterField.valueItems.map(items => items.itemValue).map((ERO, index) => (
                                <EROButton
                                    index={index}
                                    ERO={ERO}
                                    selections={selections}/*{!filterField.valuesEqualSelect ? [] : filterField.valuesEqualSelect.split(';')} */
                                    setSelections={setSelections}
                                    isActive={isActive}
                                />
                            ))
                            :
                            <h2>
                                No Available Data
                            </h2>
                    }

                </div>
            }

        </div>
    </div>);
}
export default FilterEqualSelection;