import styles from '../../../pages/Badging/AttendanceReport/AttendanceReport.module.css'
const Attendee = ({ registrant}) => {
    const changeDateFormat = dateStr => {
        const parsedDate = Date.parse(dateStr)
        const date = new Date(parsedDate)
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        return date.toLocaleDateString('en-us', options);
    }
    return (
        <>
            <td className={styles.tdAccounts}> {registrant.localRegistrationId}</td>
            <td className={styles.tdAccounts}> {registrant.firstName}</td>
            <td className={styles.tdAccounts}> {registrant.lastName}</td>
            <td className={styles.tdAccounts}> {registrant.organization}</td>
            <td className={styles.tdAccounts}> {registrant.registrationType}</td>
            <td className={registrant.latestPrinted === null || registrant.latestPrinted === undefined ? styles.tdAccountsNotCheckin : styles.tdAccountsCheckin}>
                {registrant.latestPrinted === null || registrant.latestPrinted === undefined ? "Not Checked-In" :
                    "Checked-In (" + changeDateFormat(registrant.latestPrinted) + ")"}
            </td>
            <td className={styles.tdAccounts}> {registrant.printCount && registrant.printCount !== undefined ? registrant.printCount : '0'}</td>
        </>
    );
}

export default Attendee;