import { useState, useRef, useEffect } from 'react'
import styles from "../Miscellaneous/ConfirmationPopup.module.css"

// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

// image elements
const xMark = <FontAwesomeIcon icon={faXmark} />

const ConfirmationPopup = ({
    message,
    onConfirm,
    onCancel,
    confirmationVisible,
    setConfirmationVisible
}) => {
    const confirmRef = useRef(null)

    const handleConfirm = () => {
        onConfirm()
    }

    const handleCancel = () => {
        onCancel()
    }

    const handleClickOutside = e => {
        if (!confirmRef.current?.contains(e.target) && e.target !== "button") {
            setConfirmationVisible(false)
            console.log(e.target, "outside")
        } else {
            console.log(e.target, "Clicked inside DIV!")
            setConfirmationVisible(true)
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true)
    }, [confirmationVisible])

    return (
        confirmationVisible && (
            <div className={styles.confirmationContainer}>
                <div className={styles.confirmationDialog} ref={confirmRef}>
                    <button onClick={handleCancel} id={styles.cancelButtonCorner}>
                        {xMark}
                    </button>
                    <p>{message}</p>
                    <div>
                        <button
                            id={styles.confirmButton}
                            onClick={handleConfirm}
                            className={styles.confirmationButtons}
                        >
                            Confirm
                        </button>
                        <button
                            id={styles.cancelButton}
                            onClick={handleCancel}
                            className={styles.confirmationButtons}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    )
}

export default ConfirmationPopup