import { useState, useEffect } from 'react'
import styles from "./ClientList.module.css"
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'

// ------ Services ------ //
import * as clientService from "../../../services/clientManagementService"
import * as eventService from "../../../services/eventManagementService"
import * as siteService from "../../../services/siteManagementService"

// ------ Components ------ //
import SearchBar from "../../../components/Miscellaneous/SearchBar"
import Clients from "../../../components/Home/ClientList/Clients"
import ConfirmationPopup from '../../../components/Miscellaneous/ConfirmationPopup'

const ClientList = () => {

    // ------ State Variables ------ //
    const [clients, setClients] = useState([])
    const [ERSList, setERSList] = useState([])
    const [clientNameFilter, setClientNameFilter] = useState("")
    const [ERSNameFilter, setERSNameFilter] = useState("")
    const [confirmationVisible, setConfirmationVisible] = useState(false)
    const [isCleared, setIsCleared] = useState(false)
    const [loaded, setLoaded] = useState(false)

    // ------ Constants ------ //
    const combinedFilteredClients = []

    // ------ Name Search ------ //
    const nameFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        clients.forEach((client) => {
            const name = client.clientName.toLowerCase()

            if (!filterString || name.includes(filterString)) {
                filtered.push(client)
            }
        })
        return filtered
    }

    const filteredByNameClients = nameFilter(clientNameFilter)

    // ------ ERS Filter ------ //
    const ERSFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
            console.log(filterString)
        }
        let filtered = []
        let holder = "";
        clients.forEach((client) => {       
            client.ersList.forEach((ersItem) => {
                holder += holder + ersItem + ';';
            })

            const ers = holder.slice(0, -1);

            if (!filterString || ers.toLowerCase().includes(filterString) || filterString == "none") {
                filtered.push(client)
            }
        })
        return filtered
    }

    const filteredByERSClients = ERSFilter(ERSNameFilter)

    // ------ useEffect ------ //
    useEffect(() => {
        const fetchData = async () => {
            const data = await clientService.getAllClients()
            const ERS = await siteService.getAll()
            setClients(data)
            setERSList(ERS)
        }
        fetchData()
    }, [])

    // Update loaded state when data load completes
    useEffect(() => {
        if (clients.length && ERSList.length) setLoaded(true)
    }, [clients, ERSList])

    // ------ Combine Search Arrays ------ //
    filteredByNameClients.forEach((client) => {
        if (filteredByERSClients.includes(client)) {
            combinedFilteredClients.push(client)
        }
    })

    // ------ Change/Clear Handlers ------ //
    const handleConfirm = () => {
        // Perform action on confirmation
        setConfirmationVisible(false)
        console.log("confirmed")
    }

    const handleCancel = () => {
        // Perform action on cancellation
        setConfirmationVisible(false)
        console.log("cancelled")
    }

    const handleERSChange = e => {
        setERSNameFilter(e.target.value)
    }

    const clearFilters = () => {
        setIsCleared(true)
        setClientNameFilter("")
        setERSNameFilter("")
        setIsCleared(false)
    }

    return (
        <div className="page-area">
            {
                loaded ?
                    <>
                        <h1>Client List</h1>

                        <SearchBar
                            searchName={clientNameFilter}
                            handleSearchName={setClientNameFilter}
                            placeholder="Client Name"
                            label="Name"
                            isCleared={isCleared === true || isCleared === false ? isCleared : false}
                        />

                        <div className={styles.ERSSearch}>
                            <label htmlFor="EventRegistrationSystem">Search by ERS: </label>
                            <select
                                name="EventRegistrationSystem"
                                id={styles.ERSListDropdown}
                                value={ERSNameFilter}
                                onChange={handleERSChange}
                            >
                                <option value="" selected="selected">
                                    -- Select an option --
                                </option>

                                {
                                    ERSList.map((ers, idx) => (
                                        <option key={idx}>{ers.eventRegistrationSystem}</option>
                                    ))
                                }
                            </select>
                        </div>

                        {clientNameFilter || ERSNameFilter ?
                            <button onClick={clearFilters} id={styles.clearFilters}>
                                Clear
                            </button>
                        :
                            <>
                            </>
                        }

                        {confirmationVisible && (
                            <ConfirmationPopup
                                message="Delete this client?"
                                onConfirm={handleConfirm}
                                onCancel={handleCancel}
                                confirmationVisible={confirmationVisible}
                                setConfirmationVisible={setConfirmationVisible}
                            />
                        )}

                        <div className={styles.clientsIndex}>
                            <div className={styles.topBar}>
                                <div className={styles.colMD4}>Client Name</div>
                                <div className={styles.colMD4}>ERS</div>
                                <div className={styles.colMD4}>User Actions</div>
                            </div>

                            {combinedFilteredClients.length ?
                                <Clients
                                    clientData={combinedFilteredClients}
                                    confirmationVisible={confirmationVisible}
                                    setConfirmationVisible={setConfirmationVisible}
                                    onConfirm={handleConfirm}
                                    onCancel={handleCancel}
                                />
                            :
                                <div className={styles.noneFound}>
                                    No match results found
                                </div>
                            }
                        </div>
                    </>
                :
                    <LoadingSpinner />
            }
        </div>
  );
}

export default ClientList;