import { useState, useEffect } from 'react'
import * as accountService from '../../../services/accountService'
import * as clientManagementService from '../../../services/clientManagementService'
import styles from './ManageAccountSettings.module.css'
import HoverButton from '../../../components/Miscellaneous/HoverButton'
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'
import ManageAccount from '../../../components/Account/ManageAccountSettings/ManageAccount'

const ManageAccountSettings = ({user }) => {

    // State
    const [loaded, setLoaded] = useState(false)
    const [clientOptions, setClientOptions] = useState([])
    const [eventOptions, setEventOptions] = useState([])
	const [editingId, setId] = useState();

    const [Accounts, setAccounts] = useState([])

    const editAccount = e => {
        e.preventDefault()
        let divId = e.currentTarget.id
        //if (!divId) {
        //    e.current.id
        //}
        const items = Array.from(Accounts)
        let filtered = items[divId]

        // Have to add this to maintain one edit at a time, since all will share formData for editing state
        const editableCheck = i => i.editable
        if (items.some(editableCheck)) {
            return
        }
        filtered.editable = true
        setId(divId)
    }

    const cancelEdit = e => {
        e.preventDefault()
        let divId = e.currentTarget.id
        //if (!divId) {
        //    e.current.id
        //}
        const items = Array.from(Accounts)
        let filtered = items[divId]

        // Have to add this to maintain one edit at a time, since all will share formData for editing state
        const editableCheck = i => i.editable
        if (!items.some(editableCheck)) {
            return
        }
        filtered.editable = false
        setId(null)
    }

    // --- REST --- //
    const fetchAccountSettings = async () => {
        const accountSettings = await accountService.getAccountSettings();
        accountSettings.editable = false;
        const accountArray = [accountSettings]
        setAccounts(accountArray)
        setLoaded(true)
    }
    const fetchClients = async () => {
        const clients = await clientManagementService.getClients();
        setClientOptions(clients);
    }
    useEffect(() => {
        fetchAccountSettings();
        fetchClients();
    }, [])


    return (
        <>
            {loaded ?
                <div className={styles.templateIndex}>
                    <h1>Manage Account Settings</h1>
                    <h2>Assign client and events to account.</h2>
                    <div className={styles.containerAccounts}>
                        {Accounts.length ?
                            <div className={styles.tableAccountsContainer}>
                                <table className={styles.tableAccounts}>
                                    <thead>
                                        <tr className={styles.trAccounts}>
                                            <th className={styles.thAccounts}>
                                                First Name
                                                <HoverButton
                                                    title={'Account first name'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Last Name
                                                <HoverButton
                                                    title={'Account last name'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                UserName
                                                <HoverButton
                                                    title={'Account username'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Default URL
                                                <HoverButton
                                                    title={'Account default url'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Client Name
                                                <HoverButton
                                                    title={'Account client name selected'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Event 1
                                                <HoverButton
                                                    title={'Designated event 1'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Event 2
                                                <HoverButton
                                                    title={'Designated event 2'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Event 3
                                                <HoverButton
                                                    title={'Designated event 3'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.thAccounts}>
                                                Event 4
                                                <HoverButton
                                                    title={'Designated event 4'}
                                                    messageWidth={75}
                                                />
                                            </th>
                                            <th className={styles.validData}></th>
                                        </tr>
                                    </thead>
                                    <tbody key={Accounts.length}>
                                        {Accounts.map((item, index) => (
                                            <ManageAccount
                                                index={index}
                                                accountSetting={item}
                                                clients={clientOptions}
                                                events={eventOptions}
                                                editAccount={editAccount}
                                                cancelEdit={cancelEdit}
                                                key={index}
                                                editingId={editingId}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div>
                                <h2>No Editable Accounts or Missing Permissions</h2>
                            </div>
                        }
                    </div>

                </div>
                :
                <LoadingSpinner />
            }
        </>
    );
}

export default ManageAccountSettings;
