import { useState, useEffect } from 'react'
import styles from "./SiteFilterList.module.css"

// ------ Services ------ //
import * as siteManagementService from "../../../services/siteManagementService"

// ------ Components ------ //
import SearchBar from "../../../components/Miscellaneous/SearchBar"
import SiteFilters from "../../../components/Home/SiteFilterList/SiteFilters"
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'

const SiteFilterList = () => {

    // ------ State Variables ------ //
    const [FFs, setFFList] = useState([])
    const [message, setMessage] = useState([''])
    const [FFNameFilter, setFFNameFilter] = useState("")
    const [create, setCreate] = useState(false)
    const [createRefresh, setCreateRefresh] = useState(false)
    const [formData, setFormData] = useState(
        {
            schemaName: "",
            tableName: "",
            showInUIScreens: ""
        }
    )
    const [loaded, setLoaded] = useState(false)

    // ------ Name Search ------ //
    const nameFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        FFs.forEach((FF) => {
            const schemaName = FF.schemaName.toLowerCase()
            const tableName = FF.tableName.toLowerCase()
            if (!filterString || schemaName.includes(filterString) || tableName.includes(filterString)) {
                filtered.push(FF)
            }
        })
        return filtered
    }

    const createFieldFilter = e => {
        e.preventDefault()
        setCreate(create ? false : true)
    }

    const handleChange = (e, idNumber) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const filteredByFFs = nameFilter(FFNameFilter)

    const handleCreate = async e => {
        e.preventDefault()
        try {
            await siteManagementService.saveSiteField(formData)
        } catch (err) {
            setMessage(err.message)
        }
        setCreate(false)
        setFormData({
            schemaName: "",
            tableName: "",
            showInUIScreens: ""
        })
        setCreateRefresh(true)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            await siteManagementService.saveSiteFields(FFs)
        } catch (err) {
            setMessage(err.message)
        }
        setCreate(false)
        setFormData({
            schemaName: "",
            tableName: "",
            showInUIScreens: ""
        })
        setFFList(FFs)
    }

    const isFormInvalid = () => {
        return (!(formData.schemaName) || !(formData.tableName))
    }

    const cancelSubmit = e => {
        e.preventDefault()

    }

    // ------ useEffect ------ //
    useEffect(() => {
        const fetchData = async () => {
            const FF = await siteManagementService.getAllSiteFields()
            setFFList(FF)
            setCreateRefresh(false)
            setLoaded(true)
        }
        fetchData()
    }, [createRefresh])

    return (
        <>
            {loaded ?
                <div className="page-area">
                    <h1>Site Fields Filter List</h1>
                    <SearchBar
                        handleSearchName={setFFNameFilter}
                        placeholder="Schema or Table Name"
                        label="Name"
                    />
                    <button onClick={createFieldFilter}>
                        {!create ? "Add New Field Filter" : "Cancel"}
                    </button>

                    {
                        create ?
                            <>
                                <div className={styles.formContainer}>
                                    <form
                                        autoComplete="off"
                                        onSubmit={handleCreate}
                                        className={styles.container}
                                    >

                                        <div className={styles.inputContainer}>
                                            <label htmlFor="password" className={styles.label}>New Schema Name</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                value={formData.schemaName}
                                                name="schemaName"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <label htmlFor="password" className={styles.label}>New Table Name</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                value={formData.tableName}
                                                name="tableName"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <label htmlFor="password" className={styles.label}>Show in UI Screens</label>
                                            <input
                                                type="checkbox"
                                                autoComplete="off"
                                                checked={formData.showInUIScreens ? true : false}
                                                name="showInUIScreens"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <button disabled={isFormInvalid()} className={styles.button}>
                                                Submit
                                            </button>
                                        </div>

                                    </form>

                                </div>
                            </>
                            :
                            <></>
                    }

                    <div className={styles.filterFieldsIndex}>
                        <div className={styles.topBar}>
                            <h5>Schema</h5>
                            <h5>Table Name</h5>
                            <h5>Show in UI</h5>
                        </div>

                        {filteredByFFs.length ?
                            <SiteFilters
                                siteData={filteredByFFs}
                            />
                            :
                            <div className={styles.noneFound}>
                                No match results found
                            </div>
                        }


                    </div>
                    <form>
                        <div className={styles.inputContainer}>
                            <button onClick={e => cancelSubmit(e)} className={styles.button}>
                                Cancel
                            </button>
                            <button onClick={e => handleSubmit(e)} className={styles.button}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
                :
                <LoadingSpinner />
            }
        </>
    );
}

export default SiteFilterList;