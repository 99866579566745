import { useState, useEffect } from 'react'
import styles from "../../../pages/Badging/BadgeQrCodeManager/BadgeQrCodeManager.module.css"

// components - form
import HoverButton from '../../../components/Miscellaneous/HoverButton'

const ThirdPartyFormula = ({
    formData,
    qrCodeThirdPartyFormula,
    setQrCodeThirdPartyFormula,
    ThirdPartyList,
}) => {

    const handleChange = e => {
        setQrCodeThirdPartyFormula({
            ...qrCodeThirdPartyFormula, setQrCodeThirdPartyFormula,
            [e.target.name]: e.target.value,
        })
        console.log(qrCodeThirdPartyFormula)
    }

    const isFormValid = () => {
        return (formData.qrChoice === "ThirdParty"
            && qrCodeThirdPartyFormula.existingFormula
            && qrCodeThirdPartyFormula.localEventId
            ? true
            : false)
    }

    return (
        <>
            <div className={styles.inputContainer}>
                <label
                    htmlFor="existingFormula"
                    className={styles.label}
                >
                    Existing Formula
                    <HoverButton
                        title={'Thirdparty = known/coded formulas'}
                        messageWidth={150}
                    />
                    :
                </label>
                <select
                    name="existingFormula"
                    id={styles.ThirdPartyListDropdown}
                    value={qrCodeThirdPartyFormula.existingFormula}
                    onChange={handleChange}
                >
                    <option disabled={true} value="" selected="selected">
                        -- Select an option --
                    </option>
                    {
                        ThirdPartyList.map((qrCodeThirdParty, idx) => (
                            <option key={idx}>{qrCodeThirdParty.name}</option>
                        ))
                    }
                </select>

                <div className={styles.inputContainer}>
                    <label
                        htmlFor="localEventId"
                        className={styles.label}
                    >
                        Local Event Id
                        <HoverButton
                            title={'Event id, comes from ers. May not match our system.'}
                            messageWidth={150}
                        />
                        :
                    </label>
                    <input
                        type="text"
                        autoComplete="off"
                        value={qrCodeThirdPartyFormula.localEventId}
                        name="localEventId"
                        onChange={handleChange}
                    />
                </div>

                
            </div>

            <div className={styles.inputContainer}>
                <button disabled={!isFormValid()} className={styles.button}>
                    Submit
                </button>
            </div>
        </>
    );
}

export default ThirdPartyFormula;