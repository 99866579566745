import stylesSearchBar from "./SearchBar.module.css"
import { useState, useEffect } from "react"


const SearchBar = ({
    searchName,
    handleSearchName,
    placeholder,
    label,
    includeClearButton,
    isCleared,
    setIsCleared,
    styles
}) => {

    const [clearButton, setClearButton] = useState(false)

    const clearFilter = () => {
        setIsCleared(true)
        handleSearchName("")
        setIsCleared(false)
    }

    useEffect(() => {
        console.log(includeClearButton)
        if (includeClearButton !== undefined || includeClearButton === true) setClearButton(true)
    }, [])

    return (
        <div className={styles ? styles.search : stylesSearchBar.search}>
            <label htmlFor="EventRegistrationSystem">Search by {label}: </label>
                <input
                    onChange={(e) => handleSearchName(e.target.value)}
                    value={isCleared ? "" : searchName}
                    type='text'
                    placeholder={placeholder}
                    id={styles ? styles.searchBar : stylesSearchBar.searchBar}
                />
            {searchName && clearButton ?
                <button onClick={clearFilter} id={styles ? styles.clearFilters : stylesSearchBar.clearFilters}>
                    Clear
                </button>
                :
                <>
                </>
            }
        </div>
    );
}

export default SearchBar;