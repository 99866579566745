import { useState, useEffect } from 'react'
import styles from "../../../pages/Home/ClientConfig/ClientConfig.module.css"
import HoverButton from '../../../components/Miscellaneous/HoverButton' 
import * as eventManagementService from '../../../services/eventManagementService'

const ImpexiumConfig = ({
    formData,
    impexiumClientConfig,
    setImpexiumClientConfig,
}) => {

    const isFormValid = () => {
        return formData.ClientName
            && formData.EventRegistrationSystem
            && formData.Industry
            && formData.Categories.length > 0
            && impexiumClientConfig.AccessEndpoint
            && impexiumClientConfig.AppName
            && impexiumClientConfig.AppKey
            && impexiumClientConfig.AppId
            && impexiumClientConfig.AppPassword
            && impexiumClientConfig.AppUserEmail
            && impexiumClientConfig.AppUserPassword
            ? true
            : false
    }
    const isTestFormValid = () => {
        return impexiumClientConfig.AccessEndpoint
            && impexiumClientConfig.AppName
            && impexiumClientConfig.AppKey
            && impexiumClientConfig.AppId
            && impexiumClientConfig.AppPassword
            && impexiumClientConfig.AppUserEmail
            && impexiumClientConfig.AppUserPassword
            ? true
            : false
    }

    const handleChange = e => {
        setImpexiumClientConfig({
            ...impexiumClientConfig,
            [e.target.name]: e.target.value,
        })
        console.log(impexiumClientConfig)
    }
	const [error, setError] = useState(null)
    const errorDiv = (error)
        ?
        <ul>
            {Object.entries(error).length > 0 && Object.entries(error) &&
                Object.entries(error).map(([key, value]) => {
                    console.log("error: " + error)
                    return <div className={value == 'Test Failure' ? 'errorMSG' : 'successMSG'} key={key} >{value}</div>;
                })}
        </ul>
        : '';
    const handleTestConnection = async e => {
        e.preventDefault()
        var holder = { impexiumClientConfig };
		setError(null)
        try {
            await eventManagementService.testSetup(holder)
        } catch (err) {
            console.log(err.message)
			setError(err.internalError);
        }
    }

    return (
        <>
            <div className={styles.inputContainer}>
                <label htmlFor="AccessEndpoint" className={styles.label}>
                    Access Endpoint
                    <HoverButton
                        title={"Access Endpoint is the URL endpoint the data will be retrieved from"}
                        messageWidth={200}
                    />
                    :
                </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={impexiumClientConfig.AccessEndpoint}
                    name="AccessEndpoint"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="AppName" className={styles.label}>Name: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={impexiumClientConfig.AppName}
                    name="AppName"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="AppKey" className={styles.label}>Key: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={impexiumClientConfig.AppKey}
                    name="AppKey"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="AppId" className={styles.label}>Id: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={impexiumClientConfig.AppId}
                    name="AppId"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="AppPassword" className={styles.label}>Password: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={impexiumClientConfig.AppPassword}
                    name="AppPassword"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="AppUserEmail" className={styles.label}>User Email Address: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={impexiumClientConfig.AppUserEmail}
                    name="AppUserEmail"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="AppUserPassword" className={styles.label}>User Password: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={impexiumClientConfig.AppUserPassword}
                    name="AppUserPassword"
                    onChange={handleChange}
                />
            </div>
			{errorDiv}
            <div className={styles.inputContainer}>
                <button disabled={!isFormValid()} className={styles.button}>
                    Submit
                </button>
                <button onClick={handleTestConnection} disabled={!isTestFormValid()} className={styles.buttonRight}>
                    Test Connection
                </button>
            </div>
        </>
    );
}

export default ImpexiumConfig;