import { useState, useEffect } from 'react';

// ------ Services ------ //
import * as eventService from "../../../services/eventManagementService"
import * as siteManagementService from '../../../services/siteManagementService'
import * as clientEventService from '../../../services/clientEventService'
import * as progressService from '../../../services/progressService'

// Components
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'
import EventSM from '../../../components/Home/EventStatusManager/EventSM'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCaretUp, faSquareCaretDown } from '@fortawesome/free-solid-svg-icons'

// ------ Image Elements ------ //
const up = <FontAwesomeIcon icon={faSquareCaretUp} />
const down = <FontAwesomeIcon icon={faSquareCaretDown} />

const ClientsSM = ({
    client,
    eventsClient,
    index,
    styles,
}) => {

    const [visible, setVisible] = useState(true)
    const [loaded, setLoaded] = useState(false)

    const [completionStatus, setCompletionStatus] = useState("Loading")

    //useEffect(() => {
    //    if (events.length) {
    //        setLoaded(true)
    //        compileEventsLoadedState(index, true)
    //    }
    //}, [events])

    //useEffect(() => {
    //    // store into local object
    //    let localclientevent = clientEventService.getClientEvent()
    //    if (localclientevent) {
    //        setClientEventInfo(localclientevent)
    //        setLoadEventsObj(localclientevent)
    //    }
    //    else if (stateEvent) {
    //        setClientEventInfo(stateEvent)
    //        setLoadEventsObj(stateEvent)
    //    }

    //    fetchEventDataReloadStatus()
    //    fetchEventsData()
    //}, [])

    return (
        <div className={styles.clientContainerSM} key={index}>
            <div className={styles.topBarContainer}>
                <h3>Client Name</h3>
                <h3>Event Name</h3>
                <h3>Event Setup</h3>
                <h3>Event Badges</h3>
                <h3>Extra Badges</h3>
                <h3>Kiosk Setup</h3>
            </div>

            <div className={styles.eventsContainer}>
                <h3 className={styles.clientName} onClick={() => setVisible(!visible)}>{client.clientName}</h3>

                {visible ?
                    <div className={styles.eventsSection}>
                        {eventsClient.length ? 
                            eventsClient.map((event, index) => (
                                <EventSM
                                    index={index}
                                    client={client}
                                    event={event}
                                    styles={styles}
                                    visible={visible}
                                />
                            ))
                            :
                            <div className={styles.statusSection} key={index}>
                                <p><b>No Events for {client.clientName}</b></p>
                            </div>
                        }                
                    </div>
                    :
                    <p><b>No Events Found</b></p>
                }
            </div>
        </div>
    );
}

export default ClientsSM;