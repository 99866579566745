import styles from "../../../pages/Home/WebHookList/WebHookList.module.css"

const AuthTokenAuthOption = ({
    formData,
    authTokenOption,
    setAuthTokenOption,
}) => {

    const handleChange = e => {
        setAuthTokenOption({
            ...authTokenOption,
            [e.target.name]: e.target.value,
        })
        console.log(AuthTokenAuthOption)
    }

    const isFormValid = () => {
        return (!(formData.description && formData.webHookAuthenticationOption && formData.authToken) ? false: true)
    }
    return (
        <>
            <div className={styles.inputContainer}>
                <button disabled={!isFormValid()} className={styles.button}>
                    Submit
                </button>
            </div>
        </>
    );
}

export default AuthTokenAuthOption;