import { useState, useEffect } from 'react'
import styles from '../../../pages/Badging/KioskEditScreenLayout/KioskEditScreenLayout.module.css'
// components - form
import HoverButton from '../../../components/Miscellaneous/HoverButton'
const CSSFieldLayout = ({
    index,
    updateCompiledObject,
    kioskField,
    layoutOptions,
}) => {

    const handleInputChange = (e, key) => {
        const updatedFieldData = {
            ...kioskField,
            [e.target.name]: e.target.value,
        }
        updateCompiledObject(index, updatedFieldData)
    }

    return (
        <tr key={index} className={styles.trKiosk}>
            <td className={styles.tdKiosk}>{kioskField.cssFieldName}</td>
            <td className={styles.tdKiosk}>
                <input
                            type="text"
                            autoComplete="off"
                            value={kioskField.fieldLabel}
                            name="fieldLabel"
                            onChange={(e) => handleInputChange(e, "fieldLabel")}
                        />
            </td>
            <td className={styles.tdKiosk}>
                <select
                            name="kioskLayoutOption"
                            id={index}
                            value={kioskField.kioskLayoutOption && !isNaN(+kioskField.kioskLayoutOption) ?
                                layoutOptions[kioskField.kioskLayoutOption].name : kioskField.kioskLayoutOption &&
                                    isNaN(+kioskField.kioskLayoutOption) ? kioskField.kioskLayoutOption :
                                "None"}
                            onChange={(e) => handleInputChange(e, index)}
                        >
                            <option disabled={true} value="">
                                -- Select an option --
                            </option>
                            {
                                kioskField.FieldLayoutOptions.map((cssField, idx) => (
                                    <option key={idx}>{cssField.name}</option>
                                ))
                            }
                        </select>
            </td>
            {/*{validData ?*/}
            {/*    <i className={styles.buttonImage} id={styles.validIcon}>*/}
            {/*        {circleCheck}*/}
            {/*    </i>*/}
            {/*    :*/}
            {/*    <i className={styles.buttonImage} id={styles.invalidIcon}>*/}
            {/*        {circleXmark}*/}
            {/*    </i>*/}
            {/*}*/}
        </tr>
    );
}

export default CSSFieldLayout;