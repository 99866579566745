import * as tokenService from './tokenService'
const BASE_URL = '/Account'

export const changePassword = async (credentials) => {
    try {
        const res = await fetch(`${BASE_URL}/ChangePassword`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tokenService.getToken()}`
            }),
            body: JSON.stringify(credentials),
        })
        const json = await res.json()
        if (json.token) {
            tokenService.removeToken()
            tokenService.setToken(json.token)
        }
        if (json.err) {
            throw new Error(json.err)
        }
    } catch (err) {
        throw err
    }
}

export const getAccountSettings = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveAccountSettings`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getAllAccountSettings = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveAllAccountSettings`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}


export const saveAccountSettings = async (accountinfo) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveAccountSettings`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(accountinfo),
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const addAccountSetting = async (accountinfo) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AddAccountSetting`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(accountinfo),
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}