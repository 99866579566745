import { useState, useEffect, useCallback } from 'react'
import styles from '../../../pages/Badging/ExtraBadgeTemplateFieldOrdering/ExtraBadgeTemplateFieldOrdering.module.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import * as badgeService from '../../../services/badgeManagementService'

const OrderingField = ({
    item,
    setMessage,
    client,
    fetchExtraBadgesOrderingData,
}) => {
    // state
    const [Field, setField] = useState([
        {
            id: null,
            overrideRank: null,
            defaultRank: null,
            cssExtraBadgesFieldName: null,
            badgeName: null,
            cssFieldName: null,
        }
    ])

    // functions
    const handleOnDragEnd = async result => {
        if (!result.destination) return

        const items = Array.from(Field)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        for (let i = 0; i < items.length; i++) {
            items[i].overrideRank = i + 1;
        }
        let saveRequest = await createSaveRequest(items)
        // handle fields and connect to edit service in badgeManagementService.js here
        try {
            await badgeService.saveExtraBadgesFieldsOrdering(saveRequest)
        } catch (err) {
            setMessage(err.message)
        }
        fetchExtraBadgesOrderingData()
    }
    useEffect(() => {
        // store into local object
        if (item.extraBadgeOrderingFields && item.extraBadgeOrderingFields.length) {
            setField(item.extraBadgeOrderingFields)
        }
    }, [])
    const createSaveRequest = (items) => {
        const fieldsSave = {
            clientId: client.clientId,
            eventId: client.eventId, 
            CSSExtraBadgesField: item.cssExtraBadgesField, 
            badgeName: (item.badgeName ? item.badgeName : item.cssExtraBadgesFieldName ),
            CSSExtraBadgesFieldName: item.cssExtraBadgesFieldName,
            orderingFields: items
        }

        return fieldsSave
    }
    return (
        <>
            <div className={styles.OrderingContainer}>{item.badgeName ? item.badgeName : item.cssFieldName}
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId={"OrderList" + (item.badgeName ? item.badgeName : item.cssFieldName)}>
                        {(provided) => (

                            <ul className={styles.FieldList} {...provided.droppableProps} ref={provided.innerRef}>
                                {Field.map(({ id, overrideRank, cssFieldName, defaultRank }, index) => (

                                    <Draggable key={id} draggableId={id} index={index}>
                                        {(provided) => (

                                            <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>

                                                <div className={styles.FieldListOptions}>

                                                    <div>
                                                        {cssFieldName}
                                                        <br /><br />
                                                        Rank: {overrideRank}
                                                    </div>

                                                </div>
                                            </li>

                                        )}
                                    </Draggable>
                                ))}
                            </ul>

                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
         
    );
}

export default OrderingField;