import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import styles from './ForgotPasswordForm.module.css'
import * as homeService from '../../../services/homeService'

const ForgotPasswordForm = () => {

    // state
    const [formData, setFormData] = useState({
        Email: '',
    })
    const [visible, setVisible] = useState(true)
    const [redirectSeconds, setRedirectSeconds] = useState(5)

    // variables
    const navigate = useNavigate()
    const email = formData.Email
    const slicedEmail = email.slice(email.indexOf('.'))
    const emailExtensions = [".com", ".net", ".org", ".cc", ".gov", ".edu"]

    // functions
    useEffect(() => {
        let interval = null
        if (!visible && redirectSeconds > 0) {
            interval = setInterval(() => {
                setRedirectSeconds(redirectSeconds => redirectSeconds - 1)
            }, 1000)
        } else if (visible && redirectSeconds !== 0) {
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [visible, redirectSeconds])

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            await homeService.forgotPassword(formData)
            setVisible(false)
            setTimeout(() => {
                navigate('/')
            }, 5000) 
        } catch (err) {
            console.log(err)
        }
    }

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const isFormInvalid = () => {
        return !(email.includes("@") && emailExtensions.includes(slicedEmail))
            ? true
            : false
    }

    return (
        <>
            {visible ?
                <>
                    <h1>Forgot Password</h1>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        className={styles.container}
                    >
                        <div className={styles.inputContainer}>
                            <label htmlFor="email" className={styles.label}>Email address:</label>
                            <input
                                type="text"
                                autoComplete="off"
                                id="password"
                                value={formData.Email}
                                name="Email"
                                onChange={handleChange}
                            />
                        </div>

                        <button disabled={isFormInvalid()} className={styles.button}>
                            Submit
                        </button>
                    </form>
                </>
            :
                <div className={styles.container}>
                    <h2>Password reset confirmation email has been sent to {email}.</h2>
                    <p>Please check your spam folder if you are unable to find it.</p>

                    <h3>Redirecting automatically back to the <Link to='/' className={styles.login}>login page</Link> in {redirectSeconds}...</h3>
                </div>
            }
        </>
  );
}

export default ForgotPasswordForm