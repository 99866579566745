import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom"

// ------ Styles ------ //
import styles from './KioskPrint.module.css'

const KioskPrint = ({
    user,
}) => {
   
    // useLocation & useNavigate --- Client state from previous page
    const navigate = useNavigate()
    const location = useLocation()
    const [styleForm, setStyleForm] = useState(location.state ? location.state.stylingForm : {}) 
    useEffect(() => {
        navigate('/Kiosk/Index')
    }, [])
    
    return (
        <div className={styles.printKioskPage}>
            <h1>
                Printing badge for {styleForm ? styleForm.firstName + ' ' + styleForm.lastName :null}...
            </h1>
        </div>
    );
}

export default KioskPrint;