import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './ChangePasswordForm.module.css'
import * as accountService from '../../../services/accountService'

// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'

// image elements
const visibleEyeOldPassword = <FontAwesomeIcon icon={faEye} />
const hiddenEyeOldPassword = <FontAwesomeIcon icon={faEyeSlash} />
const visibleEyeNewPassword = <FontAwesomeIcon icon={faEye} />
const hiddenEyeNewPassword = <FontAwesomeIcon icon={faEyeSlash} />
const visibleEyeConfirmPassword = <FontAwesomeIcon icon={faEye} />
const hiddenEyeConfirmPassword = <FontAwesomeIcon icon={faEyeSlash} />

const ChangePasswordForm = ({
    updateMessage,
    handleSignupOrLogin
}) => {

    // state
    const [formData, setFormData] = useState({
        PasswordOld: '',
        PasswordNew: '',
        PasswordConfirmation: '',
    })
    const [passwordShownOld, setPasswordShownOld] = useState(false)
    const [passwordShownNew, setPasswordShownNew] = useState(false)
    const [passwordShownConfirm, setPasswordShownConfirm] = useState(false)

    // variables
    const navigate = useNavigate()
    const { PasswordOld, PasswordNew, PasswordConfirmation } = formData

    // functions
    const togglePasswordVisiblityOld = e => {
        e.preventDefault()
        setPasswordShownOld(passwordShownOld ? false : true)
    }

    const togglePasswordVisiblityNew = e => {
        e.preventDefault()
        setPasswordShownNew(passwordShownNew ? false : true)
    }

    const togglePasswordVisiblityConfirm = e => {
        e.preventDefault()
        setPasswordShownConfirm(passwordShownConfirm ? false : true)
    }

    const handleChange = e => {
        updateMessage('')
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            await accountService.changePassword(formData)
            handleSignupOrLogin()
            navigate('/index')
        } catch (err) {
            updateMessage(err.message)
        }
    }

    const isFormInvalid = () => {
        return !(PasswordOld && PasswordNew && PasswordNew === PasswordConfirmation)
    }

    return (
        <>
            <h1>Change Password</h1>

            <form
                autoComplete="off"
                onSubmit={handleSubmit}
                className={styles.container}
            >

                <div className={styles.inputContainer}>
                    <label htmlFor="password" className={styles.label}>Current Password</label>
                    <input
                        type={passwordShownOld ? "text" : "password"}
                        autoComplete="off"
                        id="password"
                        value={PasswordOld}
                        name="PasswordOld"
                        onChange={handleChange}
                    />
                    <button onClick={togglePasswordVisiblityOld} className="hs-password">
                        {passwordShownOld ? hiddenEyeOldPassword : visibleEyeOldPassword}
                    </button>
                </div>

                <div className={styles.inputContainer}>
                    <label htmlFor="newPassword" className={styles.label}>
                        New Password
                    </label>
                    <input
                        type={passwordShownNew ? "text" : "password"}
                        autoComplete="off"
                        id="newPassword"
                        value={PasswordNew}
                        name="PasswordNew"
                        onChange={handleChange}
                    />
                    <button onClick={togglePasswordVisiblityNew} className="hs-password">
                        {passwordShownNew ? hiddenEyeNewPassword : visibleEyeNewPassword}
                    </button>
                </div>

                <div className={styles.inputContainer}>
                    <label htmlFor="newPasswordConf" className={styles.label}>
                        Confirm New Password
                    </label>
                    <input
                        type={passwordShownConfirm ? "text" : "password"}
                        autoComplete="off"
                        id="newPasswordConf"
                        value={PasswordConfirmation}
                        name="PasswordConfirmation"
                        onChange={handleChange}
                    />
                    <button onClick={togglePasswordVisiblityConfirm} className="hs-password">
                        {passwordShownConfirm ? hiddenEyeConfirmPassword : visibleEyeConfirmPassword}
                    </button>
                </div>

                <div className={styles.inputContainer}>
                    <button disabled={isFormInvalid()} className={styles.button}>
                        Change Password
                    </button>
                    <Link to="/" className={styles.change}>
                        <button className={styles.button}>Cancel</button>
                    </Link>
                </div>

            </form>
        </>
    )
}

export default ChangePasswordForm