import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCaretUp, faSquareCaretDown } from '@fortawesome/free-solid-svg-icons'

// ------ Services ------ //
import * as eventService from "../../../services/eventManagementService"
import * as progressService from '../../../services/progressService'

// ------ Image Elements ------ //
const up = <FontAwesomeIcon icon={faSquareCaretUp} />
const down = <FontAwesomeIcon icon={faSquareCaretDown} />

const EventSM = ({ client, event, styles, index, visible }) => {

    // State
    const [clientEventObject, setClientEventObject] = useState({})
    // State variables - Progress
    const [eventProgress, setEventProgress] = useState("Loading...")
    const [eventBadgeMgmtProgress, setEventBadgeMgmtProgress] = useState("Loading...")
    const [extraBadgesProgress, setExtraBadgeProgress] = useState("Loading...")
    const [kioskProgress, setKioskProgress] = useState("Loading...")

    useEffect(() => {
        const fetchData = async () => {
            //// EventDataReloadStatusEnum fetch
            //const eventDataReloadStatus = await eventService.loadEventDataReloadStatusObjects()
            //await setEventDataReloadStatusEnum(eventDataReloadStatus)

            // Compile object for progress calls
            const buildClientEventObj = () => {
                let obj = {
                    ClientId: client.clientId,
                    EventId: event.eventId
                }
                setClientEventObject(obj)
                return obj
            }

            const clientEventObj = await buildClientEventObj()

            // Total Event Management Progress
            const getEventProgress = await progressService.getEventManagmentProgress(clientEventObj)
            await setEventProgress(getEventProgress)

            // Total Event Badges Management Progress
            const getEventBadgeProgress = await progressService.getEventBadgesManagmentProgress(clientEventObj)
            await setEventBadgeMgmtProgress(getEventBadgeProgress)

            // Total Extra Badges Management Progress
            const getExtraBadgeProgress = await progressService.getExtraBadgesManagmentProgress(clientEventObj)
            await setExtraBadgeProgress(getExtraBadgeProgress)

            // Total Kiosk Management Progress
            const getKioskProgress = await progressService.getKioskSetupManagmentProgress(clientEventObj)
            await setKioskProgress(getKioskProgress)
        }
        fetchData()
    }, [visible])

    return (
        <div className={styles.statusSection} key={index}>
            <p><b>{event.name.substring(0, 30) + "..."}</b></p>
            <p className={
                eventProgress === "Success"
                    ? styles.complete
                    : eventProgress === "Incomplete"
                        ? styles.incomplete
                        : styles.loading
            }>
                {eventProgress === "Success"
                    ? <Link to={`/EventSetupStatusManager/${event.eventId}`} state={{ client: client, event: event }} className={styles.completeLink}>Complete</Link>
                    : eventProgress === "Incomplete"
                        ? <Link to={`/EventSetupStatusManager/${event.eventId}`} state={{ client: client, event: event }} className={styles.incompleteLink}>Incomplete</Link>
                        : eventProgress
                }
            </p>
            <p className={
                eventBadgeMgmtProgress === "Success"
                    ? styles.complete
                    : eventBadgeMgmtProgress === "Incomplete"
                        ? styles.incomplete
                        : styles.loading
            }>
                {eventBadgeMgmtProgress === "Success"
                    ? <Link to={`/EventBadgesStatusManager/${event.eventId}`} state={{ client: client, event: event }} className={styles.completeLink}>Complete</Link>
                    : eventBadgeMgmtProgress === "Incomplete"
                        ? <Link to={`/EventBadgesStatusManager/${event.eventId}`} state={{ client: client, event: event }} className={styles.incompleteLink}>Incomplete</Link>
                        : eventBadgeMgmtProgress
                }
            </p>
            <p className={
                extraBadgesProgress === "Success"
                    ? styles.complete
                    : extraBadgesProgress === "Incomplete"
                        ? styles.incomplete
                        : styles.loading
            }>
                {extraBadgesProgress === "Success"
                    ? <Link to={`/ExtraBadgesStatusManager/${event.eventId}`} state={{ client: client, event: event }} className={styles.completeLink}>Complete</Link>
                    : extraBadgesProgress === "Incomplete"
                        ? <Link to={`/ExtraBadgesStatusManager/${event.eventId}`} state={{ client: client, event: event }} className={styles.incompleteLink}>Incomplete</Link>
                        : extraBadgesProgress
                }
            </p>
            <p className={
                kioskProgress === "Success"
                    ? styles.complete
                    : kioskProgress === "Incomplete"
                        ? styles.incomplete
                        : styles.loading
            }>
                {kioskProgress === "Success"
                    ? <Link to={`/KioskSetupStatusManager/${event.eventId}`} state={{ client: client, event: event }} className={styles.completeLink}>Complete</Link>
                    : kioskProgress === "Incomplete"
                        ? <Link to={`/KioskSetupStatusManager/${event.eventId}`} state={{ client: client, event: event }} className={styles.incompleteLink}>Incomplete</Link>
                        : kioskProgress
                }
            </p>
        </div>
    );
}

export default EventSM;