import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import styles from './ResetPasswordForm.module.css'
import * as homeService from '../../../services/homeService'

// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'

// image elements
const visibleEyeNewPassword = <FontAwesomeIcon icon={faEye} />
const hiddenEyeNewPassword = <FontAwesomeIcon icon={faEyeSlash} />
const visibleEyeConfirmPassword = <FontAwesomeIcon icon={faEye} />
const hiddenEyeConfirmPassword = <FontAwesomeIcon icon={faEyeSlash} />

const ResetPasswordForm = () => {

    // state
    const [formData, setFormData] = useState({
        Username: "",
        Code: "",
        NewPassword: "",
        ConfirmPassword: "",
    })
    const [visible, setVisible] = useState(true)
    const [redirectSeconds, setRedirectSeconds] = useState(5)
    const [passwordShownNew, setPasswordShownNew] = useState(false)
    const [passwordShownConfirm, setPasswordShownConfirm] = useState(false)

    // validation
        // Between 6-20 characters
        // Must contain 

    // variables
    const navigate = useNavigate()
    const newPW = formData.NewPassword
    const confirmPW = formData.ConfirmPassword
    const url = window.location.href
    const usernameIdx = url.lastIndexOf("Username=") + 9 // length of "Username="
    const codeIdx = url.lastIndexOf("Code=") + 5 // length of "code="

    // functions
    const usernameParsed = str => str.substring(usernameIdx, url.length)
    const codeParsed = str => str.substring(codeIdx, url.indexOf("&User"))

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            await homeService.resetPassword(formData)
            setVisible(false)
            setTimeout(() => {
                navigate('/')
            }, 5000)
        } catch (err) {
            console.log(err)
        }
    }

    const isFormInvalid = () => {
        return !(newPW && confirmPW && newPW === confirmPW)
            ? true
            : false
    }

    const togglePasswordVisiblityNew = e => {
        e.preventDefault()
        setPasswordShownNew(passwordShownNew ? false : true);
    }

    const togglePasswordVisiblityConfirm = e => {
        e.preventDefault()
        setPasswordShownConfirm(passwordShownConfirm ? false : true);
    }

    // useEffect

    useEffect(() => {
        const usernameReturn = usernameParsed(url)
        const codeReturn = codeParsed(url)

        // set code and username on initial render
        setFormData({
            ...formData,
            Username: usernameReturn,
            Code: codeReturn,
        })

        let interval = null
        if (!visible && redirectSeconds > 0) {
            interval = setInterval(() => {
                setRedirectSeconds(redirectSeconds => redirectSeconds - 1)
            }, 1000)
        } else if (visible && redirectSeconds !== 0) {
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [visible, redirectSeconds])

  return (
      <div className="page-area">
        {visible ?
            <>
                <h1>Reset Password</h1>
                <h4>Password must be between 6-20 characters, and contain at least one special character</h4>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    className={styles.container}
                >
                    <div className={styles.inputContainer}>
                        <label htmlFor="newpassword" className={styles.label}>New Password:</label>
                        <input
                            type={passwordShownNew ? "text" : "password"}
                            autoComplete="off"
                              id="newPassword"
                            value={formData.NewPassword}
                            name="NewPassword"
                            onChange={handleChange}
                            minLength="6"
                            maxLength="20"
                          />
                          <button onClick={togglePasswordVisiblityNew} className="hs-password">
                              {passwordShownNew ? hiddenEyeNewPassword : visibleEyeNewPassword}
                          </button>
                      </div>

                      <div className={styles.inputContainer}>
                          <label htmlFor="confirmpassword" className={styles.label}>Confirm Password:</label>
                          <input
                              type={passwordShownConfirm ? "text" : "password"}
                              autoComplete="off"
                              id="newPasswordConf"
                              value={formData.ConfirmPassword}
                              name="ConfirmPassword"
                              onChange={handleChange}
                              minLength="6"
                              maxLength="20"
                          />
                          <button onClick={togglePasswordVisiblityConfirm} className="hs-password">
                              {passwordShownConfirm ? hiddenEyeConfirmPassword : visibleEyeConfirmPassword}
                          </button>
                      </div>
        
                    <button disabled={isFormInvalid()} className={styles.button}>
                        Submit
                    </button>
                </form>
            </>
        :
            <div className={styles.container}>
                <h2>Your password has been succesfully reset.</h2>

                <h3>Redirecting automatically back to the <Link to='/' className={styles.login}>login page</Link> in {redirectSeconds}...</h3>
            </div>
        }
    </div>
  );
}

export default ResetPasswordForm;