import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './ExtraBadgeTemplateManager.module.css'
import { Routes, Route, useNavigate } from 'react-router-dom'

// ------ Services ------ //

const ExtraBadgeTemplateManager = () => {
    const navigate = useNavigate()
    return (
        <div id={styles.eventConfigurePage}>
            <h1>Extra Event Badges Template Management</h1><br />
            <h2>Controls extra badge template layout and printing. </h2><br />
            <div id={styles.eventConfigureContainer}>
                <Link to={`/ExtraBadgeManager`} className={styles.eventLinks} title="Additional badges management - badge name, conditionals and expiration.">
                    <h3>Management</h3>
                </Link>
                <Link to={`/ExtraBadgeTemplateFieldOrdering`} className={styles.eventLinks} title="Extra Badge template field layout ordering.">
                    <h3>Template Field Ordering</h3>
                </Link>
                <Link to={`/ExtraBadgeTemplateStyling`} className={styles.eventLinks} title="Extra Badge template styling.">
                    <h3>Template Styling Manager</h3>
                </Link>
                <Link to={`/StaticFieldManager`} className={styles.eventLinks} title="Badge template static field population.">
                    <h3>Static Field Setup</h3>
                </Link>
            </div>

            <button id={styles.backButton} onClick={() => navigate(-1)}> Back </button>
        </div>
    );
}

export default ExtraBadgeTemplateManager;