import { useState, useEffect } from 'react'
import styles from "../../../pages/Home/ClientConfig/ClientConfig.module.css"
import * as eventManagementService from '../../../services/eventManagementService'

const EPlyConfig = ({
    formData,
    eplyClientConfig,
    seteplyClientConfig,
}) => {

    const handleChange = e => {
        seteplyClientConfig({
            ...eplyClientConfig,
            [e.target.name]: e.target.value,
        })
        console.log(eplyClientConfig)
    }
	const [error, setError] = useState(null)
    const errorDiv = (error)
        ?
        <ul>
            {Object.entries(error).length > 0 && Object.entries(error) &&
                Object.entries(error).map(([key, value]) => {
                    console.log("error: " + error)
                    return <div className={value == 'Test Failure' ? 'errorMSG' : 'successMSG'} key={key} >{value}</div>;
                })}
        </ul>
        : '';

    const handleTestConnection = async e => {
        e.preventDefault()
        setError(null)
        var holder = { eplyClientConfig };
        try {
            await eventManagementService.testSetup(holder)
        } catch (err) {
            console.log(err.message)
			setError(err.internalError);
        }
    }

    const isFormValid = () => {
        return formData.ClientName
            && formData.EventRegistrationSystem
            && formData.Industry
            && formData.Categories.length > 0
            && eplyClientConfig.Username
            && eplyClientConfig.Password
            && eplyClientConfig.ApiToken
            && eplyClientConfig.Url
            ? true
            : false
    }
    const isTestFormValid = () => {
        return eplyClientConfig.Username
            && eplyClientConfig.Password
            && eplyClientConfig.ApiToken
            && eplyClientConfig.Url
            ? true
            : false
    }

    return (
        <>
            <div className={styles.inputContainer}>
                <label htmlFor="Username" className={styles.label}>Username: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={eplyClientConfig.Username}
                    name="Username"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="Password" className={styles.label}>Password: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={eplyClientConfig.Password}
                    name="Password"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="ApiToken" className={styles.label}>Api Token: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={eplyClientConfig.ApiToken}
                    name="ApiToken"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="Url" className={styles.label}>Url: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={eplyClientConfig.Url}
                    name="Url"
                    onChange={handleChange}
                />
            </div>
			{errorDiv}
            <div className={styles.inputContainer}>
                <button disabled={!isFormValid()} className={styles.button}>
                    Submit
                </button>
                <button onClick={handleTestConnection} disabled={!isTestFormValid()} className={styles.buttonRight}>
                    Test Connection
                </button>
            </div>
        </>
    );
}

export default EPlyConfig;