import { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import UserActions from "./UserActions.jsx"
import styles from '../../../pages/Home/ClientEventData/ClientEventData.module.css'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-solid-svg-icons'

// ------ Image Elements ------ //
const plus = <FontAwesomeIcon icon={faSquarePlus} />
const minus = <FontAwesomeIcon icon={faSquareMinus} />

const EventsIndex = ({
    categorizedData,
    category,
    changeDateTimeFormat,
    changeDateFormat,
    reloadByERS,
    trimEventName
}) => {

    const [isActive, setIsActive] = useState(true)

    return (
        <>

            <div onClick={() => setIsActive(!isActive)}>
                <h3 className={styles.EventsByERSHeader}>
                    {category} Events {isActive ? minus : plus}
                </h3>
            </div>


            {isActive && <div className={styles.eventsIndex}>

                <button id={styles.reloadAllEventsBtn} onClick={e => reloadByERS(e)} value={category}>
                    Reload All - {category}
                </button>

                <div className={styles.topBar}>
                    <div className={`${styles.indexHeader} ${styles.colMD3}`}>Event Name</div>
                    <div className={`${styles.indexHeader} ${styles.colMD2}`}>Date</div>
                    <div className={`${styles.indexHeader} ${styles.colMD1}`} title="Current total number of registrants.">Count</div>
                    <div className={`${styles.indexHeader} ${styles.colMD2}`} title="Current loading status.">Reloaded Status</div>
                    <div className={`${styles.indexHeader} ${styles.colMD2}`} title="Time it took to load event data.">Reload TimeSpan</div>
                    <div className={`${styles.indexHeader} ${styles.colMD2}`} title="Available actions for the event.">User Actions</div>
                </div>

                {categorizedData[category].map((event, index) => (

                    <div /*className={styles.clientContainer}*/ className={styles.rowIndex} key={index}>
                        <Link
                            to={`/EventLoad/${event.eventId}`}
                            state={event}
                            className={`${styles.eventLinks} ${styles.colMD3}`}
                        >
                            <h3>{trimEventName(event.name)}</h3>
                        </Link>
                        {event.startDate && event.endDate ?
                            <div id={styles.datespan} className={styles.colMD2}>
                                {
                                    changeDateFormat(event.startDate) !== changeDateFormat(event.endDate) ?
                                        `${changeDateFormat(event.startDate)} - ${changeDateFormat(event.endDate)}`
                                    :
                                        `${changeDateFormat(event.startDate)}`
                                }
                            </div>
                            :
                            <div id={styles.datespan} className={styles.colMD2}>Not Configured</div>
                        }
                        <div id={styles.count} className={styles.colMD1}>{event.count}</div>
                        {event.lastRegistrantLoadDate ?
                            <div
                                id={styles.eventDataReloadStatus}
                                key={event.eventDataReloadStatusName.name}
                                className={styles.colMD2}
                            >
                                {event.eventDataReloadStatusName.name}
                                <br />
                                {changeDateTimeFormat(event.lastRegistrantLoadDate)}
                            </div>
                            :
                            <div className={styles.colMD2}>Never</div>
                        }
                        {event.lastReloadTimeSpan
                            ? <div id={styles.reloadspan} className={styles.colMD2}>{event.lastReloadTimeSpan}</div>
                            : <div className={styles.colMD2}>No Data</div>
                        }
                        
                        <UserActions
                            styles={styles}
                            columnWidth="colMD2" // allows for dynamic width
                            description={`Reload data for ${event.name}`}
                            description2={`${event.name} Settings`}
                            contentlink={`/EventSetting/${event.eventId}`}
                            eventlink={`/EventLoad/${event.eventId}`}
                            eventname={`${event.name}`}
                            stateData={event}
                        />
                    </div>
                ))}

            </div>
            }
        </>
    );
};

export default EventsIndex