import styles from "../../../pages/Home/WebHookList/WebHookList.module.css"

const WebHookIdAuthOption = ({
    formData,
    webHookIdOption,
    setWebHookIdOption,
}) => {

    const handleChange = e => {
        setWebHookIdOption({
            ...webHookIdOption,
            [e.target.name]: e.target.value,
        })
        console.log(webHookIdOption)
    }

    const isFormValid = () => {
        return (!(formData.description && formData.webHookAuthenticationOption && formData.authToken) &&
            !(formData.webHookAuthenticationOption === "WebHookId" && formData.authToken && formData.webHookId)
        ?false: true)
    }

    return (
        <>
            <div className={styles.inputContainer}>
                <label htmlFor="WebHookId" className={styles.label}>Web Hook Id: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={webHookIdOption.WebHookId}
                    name="WebHookId"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <button disabled={!isFormValid()} className={styles.button}>
                    Submit
                </button>
            </div>
        </>
    );
}

export default WebHookIdAuthOption;