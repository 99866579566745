import { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import styles from './ExtraBadgeManager.module.css'

// ------ Services ------ //
import * as badgeService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'
// ------ Components ------ //
import ExtraBadge from '../../../components/Badging/ExtraBadgeManager/ExtraBadge'
import HoverButton from '../../../components/Miscellaneous/HoverButton'

const ExtraBadgeManager = () => {

    // State
    const [extraBadgeFields, setExtraBadgeFields] = useState([])
    const [dataValidationObj, setDataValidationObj] = useState([])
    const [extraBadgesTable, setExtraBadgesTable] = useState([]) // compiled object of all badges for save process
    const [continueButtonVisibility, setContinueButtonVisibility] = useState(false)
    const [saveBody, setSaveBody] = useState({})
    const [clientEventInfo, setClientEventInfo] = useState({})
    const [client, setClient] = useState({})
    // useLocation & useNavigate --- Client state from previous page
    const navigate = useNavigate()

    // --- REST --- //
    const createRequestBody = async () => {

        let requestBody = {}

        if (client.clientId && client.eventId) {
            requestBody =
            {
                "ClientId": `${client.clientId}`,
                "EventId": `${client.eventId}`
            }
        }

        // Set future save request body
        setSaveBody({
            ...saveBody,
            ...requestBody
        })

        return await requestBody;
    }

    const updateCompiledObject = (index, updatedObj) => {
        setExtraBadgesTable(prevState => {
            const newState = [...prevState]
            newState[index] = updatedObj
            return newState
        })
    }

    const updateDataValidationObject = (index, updatedObj) => {
        setDataValidationObj(prevState => {
            const newState = [...prevState]
            newState[index] = updatedObj
            return newState
        })
    }

    const handleContinueButtonVisiblity = () => {
        dataValidationObj.includes(false)
            ? setContinueButtonVisibility(false)
            : setContinueButtonVisibility(true)
    }    

    const handleSave = async e => {
        e.preventDefault()

        const getSaveObj = await buildSaveObject()
        console.log("initial save object is: ", getSaveObj)

        const fullSaveObject = {
            ...saveBody,
            extraBadgeTemplates: getSaveObj // append extraBadgeFieldSmalls here
        }
        console.log(fullSaveObject)

        await setSaveBody(fullSaveObject)
        const saveRequest = await badgeService.saveExtraBadgeFields(fullSaveObject)
        //console.log(saveRequest)
        navigate(`/ExtraBadgeTemplateFieldOrdering`)
    }

    const buildSaveObject = () => {
        // Loop through extraBadgesTable to build each extraBadgeSmall object
        let returnArr = []

        for (let i = 0; i < extraBadgesTable.length; i++) {
            let extraBadgeTemplatesObj = {
                CSSField: extraBadgeFields[i].cssField ,
                CSSFieldName: extraBadgesTable[i].BadgeField ? extraBadgesTable[i].BadgeField : extraBadgeFields[i].cssField,
                CSSExtraBadgesField: extraBadgeFields[i].cssField,
                CSSExtraBadgesFieldName: extraBadgesTable[i].BadgeField ? extraBadgesTable[i].BadgeField : extraBadgeFields[i].cssField,
                ExtraBadgeNameLabel: extraBadgesTable[i].OverwriteName,
                ExpirationDate: new Date(extraBadgesTable[i].ExpirationDate), // parse date from string to DateTime format
                FieldName: extraBadgesTable[i].ConditionalField ? extraBadgesTable[i].ConditionalField : extraBadgeFields[i].cssFieldName,
                FieldValuesSelected: extraBadgesTable[i].ConditionalValue,
                AvailableSelections: null,
                OriginalFieldNames: null,
            }

            returnArr.push(extraBadgeTemplatesObj)
        }

        return returnArr
    }
    useEffect(() => {
        if (client && client.clientId !== undefined && client.clientName) {
            const fetchData = async () => {
                const getReqBody = await createRequestBody()
                console.log("req body is: ", getReqBody)
                const EBFs = await badgeService.getExtraBadgeFieldWithSelections(getReqBody)
                console.log("EBFs are: ", EBFs)
                setExtraBadgeFields(EBFs)
            }
            fetchData()
        }

    }, [client])
    useEffect(() => {
        // store into local object
        let localclientevent = clientEventService.getClientEvent()
        if (localclientevent) {
            setClientEventInfo(localclientevent)
            setClient(localclientevent)
        }
    }, [])

    useEffect(() => {
        if (dataValidationObj.length) handleContinueButtonVisiblity()
    }, [dataValidationObj])
    
    return (
        <div id={styles.EBFPage}>
            <h1>Extra Badges Manager</h1>

            <button id={styles.backButton} onClick={() => navigate(-1)}> Back </button>
            {continueButtonVisibility ?
                <button id={styles.saveButton} onClick={(e) => handleSave(e)}>
                    Save
                </button>
                :
                <p id={styles.invalidDataHeader}>
                    Please provide values for <b>Overwrite Name</b> and <b>Conditional Value</b>
                </p>
            }

            <div id={styles.extraBadgeContainer}>
            {extraBadgeFields.length ?
                    <div id={styles.regTypeTableSection}>
                        <table id={styles.regTypeTable}>
                            <thead>
                                <tr id={styles.tableHeading}>
                                    <th className={styles.tableHeaders}>
                                        Badge Field
                                        <HoverButton
                                            title={'Values from ERS'}
                                            messageWidth={200}
                                        />
                                    </th>
                                    <th className={styles.tableHeaders}>
                                        Overwrite Name
                                        <HoverButton
                                            title={'Overwrites the Badge Field name (ExtraBadge01 - 10) to desired name'}
                                            messageWidth={200}
                                        />
                                    </th>
                                    <th className={styles.tableHeaders}>
                                        Conditional Field
                                        <HoverButton
                                            title={'Question/field tied to the Badge Field'}
                                            messageWidth={200}
                                        />
                                    </th>
                                    <th className={styles.tableHeaders}>
                                        Conditional Value(s)
                                        <HoverButton
                                            title={'Possible values for the field/question chosen'}
                                            messageWidth={200}
                                        />
                                    </th>
                                    <th className={styles.tableHeaders}>
                                        Expiration Date
                                        <HoverButton
                                            title={'Date if field on extra badge is time-based (i.e. day pass, session ticket, etc.) to prevent incorrect prints'}
                                            messageWidth={200}
                                        />
                                    </th>
                                    <th className={styles.validData}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {extraBadgeFields.map((item, index) => (
                                    <ExtraBadge
                                        index={index}
                                        item={item}                                        
                                        updateCompiledObject={updateCompiledObject}
                                        updateDataValidationObject={updateDataValidationObject}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>                   
            :
                <div>
                    <h2>No Extra Badges Selected - Please Select from Mapping page</h2>
                    <Link to="/BadgeToERSPropertyMapping"
                        className={styles.eventLinks}
                            state={clientEventInfo}
                    >
                        <h3>Go to Mapping Page</h3>
                    </Link>

                </div>                           
            }
            </div>

        </div>
    );
}

export default ExtraBadgeManager;