import { useState, useEffect, useRef } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// ------ Services ------ //
import * as clientEventService from '../../../services/clientEventService'
// ------ Components ------ //
import EventAttendees from "../../../components/Badging/AttendanceReport/EventAttendees"
import EventAdHocAttendees from "../../../components/Badging/AttendanceReport/EventAdHocAttendees"
import styles from './AttendanceReport.module.css'

const AttendanceReport = () => {
    const location = useLocation()
    const passedState = location.state

    // ------ State Variables ------ //
    const [eventAttendees, setEventAttendees] = useState({})
    const [eventAdHocAttendees, setEventAdHocAttendees] = useState({})
    const [eventAttendeesKey, setEventAttendeesKey] = useState(1)
    const [eventAdHocAttendeesKey, setEventAdHocAttendeesKey] = useState(2)
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            // have to trigger component refresh
            let tempAttendees = eventAttendeesKey;
            setEventAttendeesKey(tempAttendees + 1)
            let tempAdHocAttendees = eventAdHocAttendeesKey;
            setEventAdHocAttendeesKey(tempAdHocAttendees + 1)
        }

    }, [clientEventInfo])
    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])
    // export should be an button for all DS FIX Add charting as user action to each column header, navigate to new page
    return (
        <div className="page-area">
            <>
                <Tabs>
                    <TabList>
                        <Tab>Event Attendees</Tab>
                        <Tab>Event AdHoc Attendees</Tab>
                    </TabList>
                    <TabPanel>
                        <>
                            {clientEventInfo && clientEventInfo.clientId ? 
                                <EventAttendees
                                    key={eventAttendeesKey }
                                    clientEventInfo={clientEventInfo}
                                    setEventAttendees={setEventAttendees}
                                    eventAttendees={eventAttendees}
                                ></EventAttendees>
                                :<></>
                                }
                            
                        </>

                    </TabPanel>
                    <TabPanel>
                        <>
                            {clientEventInfo && clientEventInfo.clientId ?
                                <EventAdHocAttendees
                                    key={eventAdHocAttendeesKey}
                                    clientEventInfo={clientEventInfo}
                                    setEventAdHocAttendees={setEventAdHocAttendees}
                                    eventAdHocAttendees={eventAdHocAttendees}
                                ></EventAdHocAttendees>
                                : <></>
                            }

                        </>
                    </TabPanel>
                </Tabs>


            </>
        </div>
    );
}

export default AttendanceReport;