const SpecialRules = ({
    styles,
    property,
    specialCSSRules,
    handleSpecialCSSRulesChange
}) => {

    const propertySmaller = `${property}Smaller`
    const formValueSmaller = specialCSSRules[propertySmaller]

    const propertySmallest = `${property}Smallest`
    const formValueSmallest = specialCSSRules[propertySmallest]

    const handleCheckBoxChangeSmaller = () => {
        handleSpecialCSSRulesChange(propertySmaller, "active", !formValueSmaller.active)
    }

    const handleCheckBoxChangeSmallest = () => {
        handleSpecialCSSRulesChange(propertySmallest, "active", !formValueSmallest.active)
    }

    return (
        <div className={styles.expandSection}>
            <label htmlFor={`${property}`} className={styles.label}>Use {`${property}Smaller`}?</label>
            <input
                type="checkbox"
                autoComplete="off"
                checked={formValueSmaller.active}
                name={propertySmaller}
                onChange={handleCheckBoxChangeSmaller}
                className={styles.formInput}
                min="1"
            />
            <br />
            {formValueSmaller.active ?
                <>
                    <label htmlFor={`${property}`} className={styles.label}>when character count greater than:</label>
                    <input
                        id={styles.formValueSmaller}
                        type="number"
                        autoComplete="off"
                        value={formValueSmaller.length}
                        name={propertySmaller}
                        onChange={(e) => handleSpecialCSSRulesChange(propertySmaller, "length", e.target.value)}
                        className={styles.formInput}
                        min="1"
                    />
                </>
            :
                <></>
            }

            <label htmlFor={`${property}`} className={styles.label}>Use {`${property}Smallest`}?</label>
            <input
                type="checkbox"
                autoComplete="off"
                checked={formValueSmallest.active}
                name={propertySmallest}
                onChange={handleCheckBoxChangeSmallest}
                className={styles.formInput}
                min="1"
            />
            <br />
            {formValueSmallest.active ?
                <>
                    <label htmlFor={`${property}`} className={styles.label}>when character count greater than:</label>
                    <input
                        id={styles.formValueSmallest}
                        type="number"
                        autoComplete="off"
                        value={formValueSmallest.length}
                        name={propertySmallest}
                        onChange={(e) => handleSpecialCSSRulesChange(propertySmallest, "length", e.target.value)}
                        className={styles.formInput}
                        min="1"
                    />
                </>
                :
                <></>
            }
        </div>   
  );
}

export default SpecialRules;