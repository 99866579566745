import { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as clientManagementService from '../../services/clientManagementService'
import * as eventManagementService from '../../services/eventManagementService'
import styles from './Header.module.css'
import * as clientEventService from '../../services/clientEventService'

const Header = ({ user, continueButtonVisibility }) => {
    const [formData, setFormData] = useState(
        {
            clientName: null,
            client: {},
            event: {},
            eventName: null,
        }
    )
    // State    
    const [clientOptions, setClientOptions] = useState([])
    const [eventOptions, setEventOptions] = useState([])
    const [clientEventInfo, setClientEventInfo] = useState({})
    const [showReload, setShowReload] = useState(false)
    const [showContinueButton, setShowContinueButton] = useState(false)

    const isMounted = useRef(false)

    const createEventsRequestBody = async () => {

        let requestBody = {}

        if (formData.client && formData.client.clientId) {
            requestBody =
            {
                clientId: formData.client.clientId,
                clientName: formData.client.clientName,
            }
        }

        return await requestBody;
    }

    const fetchEvents = async () => {
        if (formData.clientName && formData.client) {
            let req = await createEventsRequestBody();
            const events = await eventManagementService.loadEvents(req);
            if (events && events.length > 0) {
                setEventOptions(events);

            }
            else {
                setShowReload(true)
            }
        }
        
    }

    const fetchClients = async () => {
        const clients = await clientManagementService.getClients();
        setClientOptions(clients);
    }

    useEffect(() => {
        // don't store on initial render
        if (formData.clientName && formData.client && formData.event && formData.eventName && isMounted.current) {
            // save to localstorage
            clientEventService.setClientEvent({
                clientId: formData.client.clientId,
                eventId: formData.event.eventId,
                clientName: formData.client.clientName,
                eventName: formData.event.name,
            })
            setShowContinueButton(true)
        }
        else {
            // meant to prevent blank overwrites
            isMounted.current = true;
            setShowContinueButton(false)
        }
    }, [clientEventInfo])

    useEffect(() => {
        // pull events, if clients is populated and selected
        if (formData.clientName && formData.client) {
            setEventOptions([])
            fetchEvents();
        }
    }, [formData.clientName])

    useEffect(() => {
        if (clientOptions && clientOptions.length > 0 && clientEventInfo && clientEventInfo.clientName) {
            setClientInfo(clientEventInfo.clientName)
        }
    }, [clientOptions])

    useEffect(() => {
        //set selections to the saved values
        if (eventOptions && eventOptions.length > 0 && clientEventInfo && clientEventInfo.eventName) {
            //setClientInfo(clientEventInfo.clientName)
            setEventInfo(clientEventInfo.eventName)
            setShowContinueButton(true)
        }
    }, [eventOptions])

    useEffect(() => {
        // check if entry is stored
        // store into local object
        let localclientevent = clientEventService.getClientEvent()
        if (localclientevent) {
            setClientEventInfo(localclientevent)
            if (continueButtonVisibility) {
                setShowContinueButton(true)
            } 
        }
        // pull clients
        fetchClients();
    }, [])

    const clearEvent = () => {
        formData.event = null;
        formData.eventName = null;
    }

    const setClientInfo = (clientname) => {
        let foundClient = clientOptions.find((item) => item.clientName === clientname);
        if (foundClient) {
            formData.client = foundClient;
            formData.clientName = foundClient.clientName;
            //updating React state you must create shallow copies of all state (and nested state) that you are updating.
            setFormData({
                ...formData,
                ['clientName']: foundClient.clientName,
                ['client']: foundClient,
            })
        }
        else {
            formData.clientName = null;
            formData.client = null;
            setFormData({
                ...formData,
                ['clientName']: null,
                ['client']: null,
            })
        }
    }

    const handleChangeClient = (e) => {
        if (e.target.type === 'select-one') {
            setClientInfo(e.target.value)
            // clear old events
            clearEvent();// proper clearing of child state
            if (formData.clientName && formData.client) {
                fetchEvents();
            }
        }
        else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            })
        }
    }

    const setEventInfo = (eventname) => {
        let foundEvent = eventOptions.find((item) => item.name === eventname);
        if (foundEvent) {
            formData.event = foundEvent;
            formData.eventName = foundEvent.name;
            //updating React state you must create shallow copies of all state (and nested state) that you are updating.
            setFormData({
                ...formData,
                ['eventName']: foundEvent.name,
                ['event']: foundEvent,
            })
        }
        else {
            formData.eventName = null;
            formData.event = null;
            setFormData({
                ...formData,
                ['eventName']: null,
                ['event']: null,
            })
        }

    }
    const trimEventName = name => {
        const charCutoff = 50
        const trimmed = name.substring(0, charCutoff) + "..."

        if (name.length <= charCutoff) return name
        return trimmed
    }
    const handleChangeEvent = (e) => {

        if (e.target.type === 'select-one') {
            setEventInfo(e.target.value)
            // save to localstorage
            clientEventService.setClientEvent({
                clientId: formData.client.clientId,
                eventId: formData.event.eventId,
                clientName: formData.client.clientName,
                eventName: formData.event.name,
            })
        }
        else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            })
        }
    }

    return (
        <div>
            
            <div className={styles.headerContainer}>
                {!continueButtonVisibility && 
                    <div className={styles.leftPane}>
                        <b>Select Client and Event:</b>
                    </div>                
                }

                <div className={styles.middlePane}>
                    Client: <br/>
                    {
                        clientOptions && clientOptions.length > 1 ? 
                            <select
                                name="clientName"
                                value={formData.clientName}
                                onChange={handleChangeClient}
                                key={formData.clientName}
                            >
                                <option disabled={true} value="" selected="selected">
                                    -- Select an option --
                                </option>
                                {
                                    clientOptions.map((ci, idx) => (
                                        <option key={idx}>{ci.clientName}</option>
                                    ))
                                }
                            </select>
                        :
                            <></>
                    }                        
                </div>

                <div className={styles.middlePane}>
                    Event: 
                    <br />
                    {
                        formData.clientName && formData.client && eventOptions && eventOptions.length > 0 ?
                            <select
                                name="eventName"
                                value={formData.eventName}
                                onChange={handleChangeEvent}
                                key={formData.eventName}
                            >
                                <option disabled={true} value="" selected="selected">
                                    -- Select an option --
                                </option>
                                {
                                    eventOptions.map((ci, idx) => (
                                        <option key={idx}>{trimEventName(ci.name)}</option>
                                    ))
                                }
                            </select>
                        :
                            <>
                                {
                                    showReload ?
                                        <div>
                                            <label>No Events found (
                                                <Link to={`/ClientEventData/${formData.client.clientId}`}>Load Events...)</Link>
                                            </label>                                
                                        </div>
                                    :
                                        <></>
                                }
                            </>
                    }
                    </div>

                {showContinueButton &&
                    <div className={styles.rightPane}>
                        <br />
                        <button id={styles.continueButton}>
                            <Link
                                to={`/EventSpecificStatusPage/${decodeURIComponent(clientEventInfo.eventName).trim().replace(/ /g, '-')}`}
                                state={clientEventInfo}
                                id={styles.continueLink}
                            >
                                Continue Setup
                            </Link>
                        </button>
                    </div>
                }  
            </div>

            {showContinueButton && !continueButtonVisibility &&
                <>
                    <br /><hr />
                </>
            }            
        </div>
    );
}

Header.defaultProps = {
    continueButtonVisibility: false,
}

export default Header;
