import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import styles from './AccordionNav.module.css'

const AccordionNav = ({ header, content, description, icon, isExpanded, /*isHovered*/ }) => {

    const [isActive, setIsActive] = useState(false)

    return (
        <div className={styles.accordionitem} title={description}>

            <div onClick={() => setIsActive(!isActive)}>
                <label
                    className={styles.accordionheader}
                >
                    {icon}
                    {/*<span id={isHovered ? styles.active : styles.inactive}>*/}
                    {/*    {header}*/}
                    {/*</span>*/}
                    <span id={isExpanded ? styles.active : styles.inactive}>
                        {header}
                    </span>
                </label>
            </div>

            {isActive && isExpanded /*&& isHovered*/ && (<div className={styles.accordioncontent}>
                {content.map(({ contentheader, contentlink, contentdescription, passedstate }) => (
                    <Link
                        title={contentdescription}
                        className={styles.accordionlinks}
                        target={contentlink && (contentlink.toLowerCase().includes('elmah') || contentlink.toLowerCase().includes('hangfire')) ? '_blank' : '_self'}
                        to={contentlink}
                        state={passedstate ?? null}
                    >
                        <label className={styles.accordionlabel}>
                            {contentheader}
                        </label>
                    </Link>

                ))}

            </div>)}

        </div>
    );
};

AccordionNav.defaultProps = {
    passedState: "",
}

export default AccordionNav;