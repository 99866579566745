import { useNavigate } from 'react-router-dom'

// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs } from '@fortawesome/free-solid-svg-icons'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import * as clientEventService from '../../../services/clientEventService'

// image elements
const cog = <FontAwesomeIcon icon={faCogs} />
const sync = <FontAwesomeIcon icon={faSyncAlt} />

const UserActions = ({
    styles,
    columnWidth,
    description,
    description2,
    contentlink,
    stateData,
    eventlink
}) => {

    const navigate = useNavigate()
    const navigateAndStoreEvent = e => {
        e.preventDefault()
        if (stateData.clientName && stateData.clientId && stateData.eventId && stateData.name) {
            // save to localstorage
            clientEventService.setClientEvent({
                clientId: stateData.clientId,
                eventId: stateData.eventId,
                clientName: stateData.clientName,
                eventName: stateData.name,
            })
        }
        navigate(`${contentlink}`, { state: stateData });
    }
    return (
        <div id={styles.inputContainer} className={`${styles[columnWidth]}`}>

            <button
                className={styles.UAButtons}
                onClick={navigateAndStoreEvent}
                title={description2}
            >
                <i id={styles.UAEvents}>
                    {cog}
                </i>
            </button>
            <button
                className={styles.UAButtons}
                onClick={() => navigate(`${eventlink}`, { state: stateData })}
                title={description}
            >
                <i id={styles.UAEvents}>
                    {sync}
                </i>
            </button>

        </div>
    );
}

export default UserActions;