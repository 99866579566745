import { useState, useEffect, useCallback, useRef } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import styles from "./WebHookList.module.css"
// ------ Services ------ //
import * as eventManagementService from "../../../services/eventManagementService"
import * as clientEventService from '../../../services/clientEventService'
// components - authentication options
import AuthTokenAuthOption from '../../../components/Home/WebHookAuthOptions/AuthTokenAuthOption'
import UserNameAndPasswordAuthOption from '../../../components/Home/WebHookAuthOptions/UserNameAndPasswordAuthOption'
import WebHookIdAuthOption from '../../../components/Home/WebHookAuthOptions/WebHookIdAuthOption'

// Components - form
import HoverButton from '../../../components/Miscellaneous/HoverButton'

// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSquareCheck, faSquareXmark } from '@fortawesome/free-solid-svg-icons'

// image elements
const pencil = <FontAwesomeIcon icon={faPencil} />
const squareCheck = <FontAwesomeIcon icon={faSquareCheck} />
const squareXmark = <FontAwesomeIcon icon={faSquareXmark} />

const WebHookList = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const passedState = location.state
    const [clientEventInfo, setClientEventInfo] = useState({})
    const [message, setMessage] = useState([''])
    const [create, setCreate] = useState(false)
    const [createRefresh, setCreateRefresh] = useState(false)
    const [editRefresh, setEditRefresh] = useState(false)
    // state
    const [reloadWebHookAuthenticationOptions, setReloadWebHookAuthenticationOptions] = useState([])
    const [WHLO, setWebHook] = useState([
        {
            clientId: clientEventInfo.clientId,
            eventId: clientEventInfo.eventId,
            webHookAuthenticationOption: null,
            id: null,
            userName: null,
            password: null,
            authToken: null,
            webHookId: null,
            payloadString: null,
            description: null,
            active: null,
            editable: null
        }
    ])

    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }


    // state
    const [WebHookList, setWebHookList] = useState([])

    const [formData, setFormData] = useState({
        clientId: clientEventInfo.clientId,
        eventId: clientEventInfo.eventId,
        webHookAuthenticationOption: null,
	    id: null,
        authToken: null,
        payloadString: null,
        description: null,
        active: false,
        editable: null
    })

    const [formDataEdit, setFormDataEdit] = useState(
        {
            clientId: clientEventInfo.clientId,
            eventId: clientEventInfo.eventId,
            WebHookAuthenticationOption: null,
            Id: null,
            AuthToken: null,
            PayLoadString: null,
            Description: null,
            Active: false
        }
    )

    const [authTokenOption, setAuthTokenOption] = useState({

    })

    const [userNameAndPasswordOption, setUserNameAndPasswordOption] = useState({ 
        UserName: null,
        Password: null
    })

    const [webHookIdOption, setWebHookIdOption] = useState({
        WebHookId: null
    })

    // state --- clearing functions
    const clearAuthTokenOptionData = () => setAuthTokenOption({
    })

    const clearUserNameAndPasswordOptionData = () => setUserNameAndPasswordOption({
        UserName: null,
        Password: null
    })

    const clearWebHookIdOptionData = () => setWebHookIdOption({
        WebHookId: null
    })

    const clearAllWebHookForms = () => {
        clearAuthTokenOptionData()
        clearWebHookIdOptionData()
        clearUserNameAndPasswordOptionData()
    }

    // state --- change functions
    const createWebHook = e => {
        e.preventDefault()
        setCreate(create ? false : true)
    }
    // Ensures state resets if user selects different auth option after partially filling out another
    const handleAuthChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

        clearAllWebHookForms()
        handleWebHookEditLoad(e)
    }

    const handleSubmit = async e => {
        e.preventDefault()

        const appended = await appendWebHookCredentials()

        try {
            await eventManagementService.editWebHook({ ...formData, ...appended })
        } catch (err) {
            setMessage(err.message)
        }
        setCreate(false)
        setFormData({
            clientId: clientEventInfo.clientId,
            eventId: clientEventInfo.eventId,
            authToken: '',
            description: '',
            webHookAuthenticationOption: '',
            payLoadString: '',
            active: '',
        })
        // set webform back to blanks
        clearAllWebHookForms()

        // Trigger re-render in useEffect dependency array
        setCreateRefresh(true)
    }

    const appendWebHookCredentials = () => {
        let result = {}
        if (formData.webHookAuthenticationOption === "AuthToken") {
            // add nothing
        }
        else if (formData.webHookAuthenticationOption === "UserNameAndPassword") {
            // add username and password
            result = { "userName": (userNameAndPasswordOption.UserName), "password": (userNameAndPasswordOption.Password) }
        }
        else if (formData.webHookAuthenticationOption === "WebHookId") {
            // add WebHookId
            result = { "webHookId": (webHookIdOption.WebHookId) }
        }
        else {
            console.log("Error: No WebHook data provided")
        }

        return result
    }

    const handleChange = (e, idNumber) => {
        let idReturn = idNumber
        if (e.target.type === 'checkbox') {
            setFormData({
                ...formData,
                Id: idReturn,
                [e.target.name]: e.target.checked,
            })
        }
        else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            })
        }
       
    }

    const handleChangeEdit = (e, idNumber) => {

        let idReturn = idNumber
        if (e.target.type === 'checkbox') {
            setFormDataEdit({
                ...formDataEdit,
                Id: idReturn,
                [e.target.name]: e.target.checked,
            })
        }
        else if (e.target.type === 'select-one') {
            let reloadOptionSelectedIndex = reloadWebHookAuthenticationOptions.filter(ro => ro.name === e.target.value)[0]
            setFormDataEdit({
                ...formDataEdit,
                Id: idReturn,
                [e.target.name]: reloadOptionSelectedIndex.name,
            })
        }
        else {
            setFormDataEdit({
                ...formDataEdit,
                Id: idReturn,
                [e.target.name]: e.target.value,
            })
        }

    }

    const isEditInvalid = () => {
        return (!(formDataEdit.Description && formDataEdit.WebHookAuthenticationOption && formDataEdit.AuthToken) &&
            (!(formDataEdit.WebHookAuthenticationOption === "UserNameAndPassword" && formDataEdit.UserName && formDataEdit.Password)) &&
            !(formDataEdit.WebHookAuthenticationOption === "WebHookId" && formDataEdit.AuthToken && formDataEdit.WebHookId)
        )
    }
    // useEffect
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined) {
            const fetchData = async () => {
                const data = await eventManagementService.loadWebHooks(clientEventInfo)
                const items = Array.from(data)
                for (let i = 0; i < items.length; i++) {
                    items[i].editable = false;
                }
                setWebHook(items)
                setCreateRefresh(false)
                setEditRefresh(false)
            }
            fetchData()
        }
       
    }, [createRefresh, editRefresh])

    useEffect(() =>  {
        if (clientEventInfo && clientEventInfo.clientId && clientEventInfo.clientId!== undefined) {
            // set WebHookAuthenticationOptions
            if (reloadWebHookAuthenticationOptions === null || reloadWebHookAuthenticationOptions.length < 1) {
                const fetchData = async () => {
                    const authOptions = await eventManagementService.getWebHookAuthenticationOptions()
                    setReloadWebHookAuthenticationOptions(authOptions);
                    setWebHookList(authOptions)
                }
                fetchData()
                
            }
        }

    }, [clientEventInfo])
    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])

    const editWebHook = e => {
        e.preventDefault()

        let divId = e.target.id
        const items = Array.from(WHLO)
        let filtered = items.filter(whlo => divId === whlo.id)[0]

        // Have to add this to maintain one edit at a time, since all will share formDataEdit for editing state
        const editableCheck = i => i.editable
        if (items.some(editableCheck)) return
        filtered.editable = true

        setWebHook(items)
    }

    const cancelEdit = e => {
        e.preventDefault()

        let divId = e.target.id
        const items = Array.from(WHLO)
        let filtered = items.filter(whlo => divId === whlo.id)[0]
        filtered.editable = false

        setWebHook(items)
        setFormDataEdit({
            clientId: clientEventInfo.clientId,
            eventId: clientEventInfo.eventId,
            AuthToken: '',
            Description: '',
            WebHookAuthenticationOption: '',
            PayLoadString: '',
            Active: '',
        })
        clearAllWebHookForms()
    }

    const handleWebHookEditLoad = e => {

        if (e.target.value == "AuthToken") {
            setAuthTokenOption({
            })
        }

        if (e.target.value == "WebHookId") {
            setWebHookIdOption({
                WebHookId: webHookIdOption.WebHookId
            })
        }

        if (e.target.value == "UserNameAndPassword") {
            setUserNameAndPasswordOption({
                UserName: userNameAndPasswordOption.UserName,
                Password: userNameAndPasswordOption.Password
            })
        }

    }
    
    const submitEdit = async e => {
        e.preventDefault()
        let divId = e.target.id
        const items = Array.from(WHLO)
        let filtered = items.filter(whlo => divId === whlo.id)[0]
        if (!formDataEdit.UserName) {
            formDataEdit.UserName = filtered.userName
        }
        if (!formDataEdit.Password) {
            formDataEdit.Password = filtered.password
        }
        if (!formDataEdit.AuthToken) {
            formDataEdit.AuthToken = filtered.authToken
        }
        if (!formDataEdit.WebHookId) {
            formDataEdit.WebHookId = filtered.webHookId
        }
        if (!formDataEdit.PayLoadString) {
            formDataEdit.PayLoadString = filtered.payloadString
        }
        if (!formDataEdit.Description) {
            formDataEdit.Description = filtered.description
        }
        if (!formDataEdit.WebHookAuthenticationOption) {
            formDataEdit.WebHookAuthenticationOption = filtered.webHookAuthenticationOption
        }
        if (!formDataEdit.Active) {
            formDataEdit.Active = filtered.active
        }
        // cancel and return if edit is attempted with no values or if the same as before
        if ((!formDataEdit.UserName && !formDataEdit.Password && !formDataEdit.AuthToken && !formDataEdit.WebHookId && !formDataEdit.PayLoadString && !formDataEdit.Description
            && !formDataEdit.WebHookAuthenticationOption&& !formDataEdit.Active)
            || ((formDataEdit.UserName && formDataEdit.UserName === filtered.userName) && (formDataEdit.Password && formDataEdit.Password === filtered.password)
            && (formDataEdit.AuthToken && formDataEdit.AuthToken === filtered.authToken) && (formDataEdit.WebHookId && formDataEdit.WebHookId === filtered.webHookId)
            && (formDataEdit.WebHookId && formDataEdit.WebHookId === filtered.webHookId) && (formDataEdit.PayLoadString && formDataEdit.PayLoadString === filtered.payloadString)
            && (formDataEdit.Description && formDataEdit.Description === filtered.description) && (formDataEdit.WebHookAuthenticationOption && formDataEdit.WebHookAuthenticationOption === filtered.webHookAuthenticationOption)
            && (formDataEdit.Active && formDataEdit.Active == filtered.active))) {
            cancelEdit(e)
            return
        }

        // handle form data and connect to edit service in eventManagementService.js here
        try {
            await eventManagementService.editWebHook(formDataEdit)
        } catch (err) {
            setMessage(err.message)
        }

        // reset editable state back to false
        filtered.editable = false
        setWebHook(items)

        // setFormDataEdit back to blanks
        clearFormData()

        // Trigger re-render in useEffect dependency array
        setEditRefresh(true)
    }

    const clearFormData = useCallback(
        () => {
            setFormDataEdit({
                clientId: clientEventInfo.clientId,
                eventId: clientEventInfo.eventId,
                webHookAuthenticationOption: null, 
                Id: null,
                UserName: null, 
                Password: null,
                AuthToken: null,
                WebHookId: null,
                PayLoadString: null,
                Description: null,
                Active: false
            })
        },
        []
    )
    return (
        <div className="page-area">
            <h1>Client Event Web Hooks</h1>

            <button onClick={createWebHook}>
                {!create ? "Add New Web Hook" : "Cancel"}
            </button>

            {
                create ?
                    <>
                        <div className={styles.formContainer}>
                            <form
                                autoComplete="off"
                                onSubmit={handleSubmit}
                                className={styles.container}
                            >
                                *Note- not all fields need to be defined, only those that pertain to the webhook authentication type!
                                <div className={styles.inputContainer} title="Simple summary for quick identification.">
                                    <label htmlFor="description" className={styles.label}>Description: </label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        value={formData.description}
                                        name="description"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className={styles.inputContainer} title="Authentication token (encoded 64bit) to use for validation, from 3rd party.">
                                    <label htmlFor="authToken" className={styles.label}>AuthToken</label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        value={formData.authToken}
                                        name="authToken"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className={styles.inputContainer} title="Queryurl or body tag used to identify registrant identifier, from 3rd party.">
                                    <label htmlFor="payloadString" className={styles.label}>Payload String</label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        value={formData.payloadString}
                                        name="payloadString"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <strong><label htmlFor="webHookAuthenticationOption">Authentication Option: </label></strong>
                                    <select
                                        name="webHookAuthenticationOption"
                                        id={styles.WebHookListDropDown}
                                        value={formData.webHookAuthenticationOption}
                                        onChange={handleAuthChange}

                                    >
                                        <option disabled={true} value="" selected="selected">
                                            -- Select an option --
                                        </option>
                                        {
                                            WebHookList.map((webhook, idx) => (
                                                <option key={idx}>{webhook.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className={styles.inputContainer} title="Set wobhook to active or inactive.">
                                    <label htmlFor="active" className={styles.label}>Active</label>
                                    <input
                                        type="checkbox"
                                        value={formData.active}
                                        checked={formData.active}
                                        name="active"
                                        onChange={handleChange}
                                    />
                                </div>
                                {formData.webHookAuthenticationOption === "AuthToken" ?
                                    <AuthTokenAuthOption
                                        formData={formData}
                                        authTokenOption={authTokenOption}
                                        setAuthTokenOption={setAuthTokenOption}
                                    />
                                    : <></>
                                }

                                {formData.webHookAuthenticationOption === "UserNameAndPassword" ?
                                    <UserNameAndPasswordAuthOption
                                        formData={formData}
                                        userNameAndPasswordOption={userNameAndPasswordOption}
                                        setUserNameAndPasswordOption={setUserNameAndPasswordOption}
                                    />
                                    : <></>
                                }


                                {formData.webHookAuthenticationOption === "WebHookId" ?
                                    <WebHookIdAuthOption
                                        formData={formData}
                                        webHookIdOption={webHookIdOption}
                                        setWebHookIdOption={setWebHookIdOption}
                                    />
                                    : <></>
                                }
                            </form>

                        </div>
                    </>
                    :
                    <></>
            }
            <div className={styles.webHooksIndex}>
                <div className={styles.topBar}>
                    <h5>Description</h5>
                    <h5>AuthToken</h5>
                    <h5>PayloadString</h5>
                    <h5>Authentication Option</h5>
                    <h5>UserName</h5>
                    <h5>Password</h5>
                    <h5>WebhookId</h5>
                    <h5>Active</h5>
                    <h5>Action</h5>
                </div>

                {WHLO.length ?

                    WHLO.map(({ id, description, userName, password, authToken, webHookId, payloadString, webHookAuthenticationOption, active, editable }, index) => (
                        <div className={styles.webHooksListContainer} key={index}>


                            {!editable ?

                                <>
                                    {/* <h3>{id}</h3>*/}
                                    <h3>{description}</h3>
                                    <h3>{authToken}</h3>
                                    <h3>{payloadString}</h3>
                                    <h3>{webHookAuthenticationOption === 0 ? "UserNameAndPassword" : webHookAuthenticationOption === 1 ? "WebHookId" : "AuthToken" }</h3>
                                    <h3>{userName}</h3>
                                    <h3>{password}</h3>
                                    <h3>{webHookId}</h3>
                                    <h3>{active ? "Active" : "Inactive"}</h3>
                                    <h3><button onClick={e => editWebHook(e)} id={id}>
                                        <i className={styles.buttonImage}>{pencil}</i>
                                    </button></h3>

                                </>

                                :

                                <>
                                    <form className={styles.formDataEdit} key={index}>
                                        <h3><input
                                            type="text"
                                            autoComplete="off"
                                            value={formDataEdit.Description}
                                            name="Description"
                                            placeholder={description}
                                            onChange={e => handleChangeEdit(e, id)}
                                            id={id}
                                        /></h3>
                                        <h3><input
                                            type="text"
                                            autoComplete="off"
                                            value={formDataEdit.AuthToken}

                                            name="AuthToken"
                                            placeholder={authToken}
                                            onChange={e => handleChangeEdit(e, id)}
                                            id={id}
                                        /></h3>
                                        <h3> <input
                                            type="text"
                                            autoComplete="off"
                                            value={formDataEdit.PayLoadString}
                                            name="PayLoadString"
                                            placeholder={payloadString}
                                            onChange={e => handleChangeEdit(e, id)}
                                            id={id}
                                        /></h3>
                                        <h3><select
                                            name="WebHookAuthenticationOption"
                                            value={formDataEdit.WebHookAuthenticationOption}
                                            onChange={handleChangeEdit}
                                        >
                                            <option value="" selected="selected">
                                                -- Select an option --
                                            </option>

                                            {
                                                reloadWebHookAuthenticationOptions.map((reloadOption, idx) => (
                                                    <option key={idx}>{reloadOption.name}</option>
                                                ))
                                            }
                                        </select></h3>
                                        <h3><input
                                            type="text"
                                            autoComplete="off"
                                            value={formDataEdit.UserName}
                                            name="UserName"
                                            placeholder={userName}
                                            onChange={e => handleChangeEdit(e, id)}
                                            id={id}
                                        /></h3>
                                        <h3><input
                                            type="text"
                                            autoComplete="off"
                                            value={formDataEdit.Password}
                                            name="Password"
                                            placeholder={userName}
                                            onChange={e => handleChangeEdit(e, id)}
                                            id={id}
                                        /></h3>
                                        
                                        <h3><input
                                            type="text"
                                            autoComplete="off"
                                            value={formDataEdit.WebHookId}

                                            name="WebHookId"
                                            placeholder={webHookId}
                                            onChange={e => handleChangeEdit(e, id)}
                                            id={id}
                                        /></h3>
                                       
                                        <h3><input
                                            type="checkbox"
                                            autoComplete="off"
                                            checked={formDataEdit.Active}
                                            name="Active"
                                            placeholder={active}
                                            onChange={e => handleChangeEdit(e, id)}
                                            id={id}
                                        /></h3>


                                        <div className={styles.inputContainer}>
                                            <button onClick={e => cancelEdit(e)} id={id} className={styles.editButtons}>
                                                <i className={styles.buttonImage} id={styles.editCancel}>
                                                    {squareXmark}
                                                </i>
                                            </button>
                                            <button onClick={e => submitEdit(e)} id={id} className={styles.editButtons} disabled={isEditInvalid()}>
                                                <i className={styles.buttonImage} id={styles.editSubmit}>
                                                    {squareCheck}
                                                </i>
                                            </button>
                                        </div>
                                    </form>

                                </>
                            }

                        </div>
                    ))
                    :
                    <div className={styles.noneFound}>
                        No web hooks setup
                    </div>
                }
            </div>

        </div>
    );
}

export default WebHookList;