const ReloadERSNoneFound = ({
    categorizedData,
    loadEventsObj,
    styles,
    reloadByERS
}) => {
  return (
      <>
          {
              (!categorizedData || !categorizedData.Impexium || categorizedData.Impexium.length === 0)
                  && loadEventsObj && loadEventsObj.impexiumClientConfigSmall ?
                  <div className={styles.eventsIndex}>
                      <button id={styles.reloadAllEventsBtn} onClick={e => reloadByERS(e)} value="Impexium">
                          Reload All - Impexium
                      </button>
                  </div>
                  :
                  < > </>
          }
          {
              (!categorizedData || !categorizedData.Stova || categorizedData.Stova.length === 0)
                  && loadEventsObj && loadEventsObj.stovaClientConfigSmall ?
                  <div className={styles.eventsIndex}>
                      <button id={styles.reloadAllEventsBtn} onClick={e => reloadByERS(e)} value="Stova">
                          Reload All - Stova
                      </button>
                  </div>
                  :
                  < > </>
          }
          {
              (!categorizedData || !categorizedData.EPly || categorizedData.EPly.length === 0)
                  && loadEventsObj && loadEventsObj.eplyClientConfigSmall ?
                  <div className={styles.eventsIndex}>
                      <button id={styles.reloadAllEventsBtn} onClick={e => reloadByERS(e)} value="EPly">
                          Reload All - EPly
                      </button>
                  </div>
                  :
                  < > </>
          }
          {
              (!categorizedData || !categorizedData.MemberSuite || categorizedData.MemberSuite.length === 0)
                  && loadEventsObj && loadEventsObj.memberSuiteClientConfigSmall ?
                  <div className={styles.eventsIndex}>
                      <button id={styles.reloadAllEventsBtn} onClick={e => reloadByERS(e)} value="MemberSuite">
                          Reload All - MemberSuite
                      </button>
                  </div>
                  :
                  < > </>
          }
          {
              (!categorizedData || !categorizedData.GTR || categorizedData.GTR.length === 0)
                  && loadEventsObj && loadEventsObj.GTRClientConfigSmall ?
                  <div className={styles.eventsIndex}>
                      <button id={styles.reloadAllEventsBtn} onClick={e => reloadByERS(e)} value="GTR">
                          Reload All - GTR
                      </button>
                  </div>
                  :
                  < > </>
          }
          {
              (!categorizedData || !categorizedData.EdgeReg || categorizedData.EdgeReg.length === 0)
                  && loadEventsObj && loadEventsObj.edgeRegClientConfigSmall ?
                  <div className={styles.eventsIndex}>
                      <button id={styles.reloadAllEventsBtn} onClick={e => reloadByERS(e)} value="EdgeReg">
                          Reload All - EdgeReg
                      </button>
                  </div>
                  :
                  < > </>
          }
          {
              (!categorizedData || !categorizedData.Cvent || categorizedData.Cvent.length === 0)
                  && loadEventsObj && loadEventsObj.CventClientConfigSmall ?
                  <div className={styles.eventsIndex}>
                      <button id={styles.reloadAllEventsBtn} onClick={e => reloadByERS(e)} value="Cvent">
                          Reload All - Cvent
                      </button>
                  </div>
                  :
                  < > </>
          }
          {
              (!categorizedData || !categorizedData.YourMembership || categorizedData.YourMembership.length === 0)
                  && loadEventsObj && loadEventsObj.YourMembershipClientConfigSmall ?
                  <div className={styles.eventsIndex}>
                      <button id={styles.reloadAllEventsBtn} onClick={e => reloadByERS(e)} value="YourMembership">
                          Reload All - YourMembership
                      </button>
                  </div>
                  :
                  < > </>
          }
          {
              (!categorizedData || !categorizedData.HigherLogic || categorizedData.HigherLogic.length === 0)
                  && loadEventsObj && loadEventsObj.higherLogicClientConfigSmall ?
                  <div className={styles.eventsIndex}>
                      <button id={styles.reloadAllEventsBtn} onClick={e => reloadByERS(e)} value="HigherLogic">
                          Reload All - HigherLogic
                      </button>
                  </div>
                  :
                  < > </>
          }
          {
              (!categorizedData || !categorizedData.Rhythm || categorizedData.Rhythm.length === 0)
                  && loadEventsObj && loadEventsObj.rhythmClientConfigSmall ?
                  <div className={styles.eventsIndex}>
                      <button id={styles.reloadAllEventsBtn} onClick={e => reloadByERS(e)} value="Rhythm">
                          Reload All - Rhythm
                      </button>
                  </div>
                  :
                  < > </>
          }
      </>
  );
}

export default ReloadERSNoneFound;