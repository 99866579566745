import { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import * as badgeService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'
import styles from './KioskScannerPageSetup.module.css'

const KioskScannerPageSetup = () => {

    // State

    // useLocation --- Client state from previous page
    const [clientEventInfo, setClientEventInfo] = useState({})
    const [client, setClient] = useState({})

    /* -------- Functions -------- */

    // --- REST --- //

    const fetchKioskSetup = async () => {
        //const data = await badgeService.getKioskSetup()
    }

    // useEffect - placeholder fetch function until controller & backend func is created
    useEffect(() => {
        if (client && client.clientId !== undefined && client.clientName) {
            fetchKioskSetup()
        }

    }, [client])
    useEffect(() => {
        // store into local object
        let localclientevent = clientEventService.getClientEvent()
        if (localclientevent) {
            setClientEventInfo(localclientevent)
            setClient(localclientevent)
        }
    }, [])


    return (
        <div className={styles.templateIndex}>
            <h1>Event Kiosk Scanner Page Setup</h1>


        </div>
    );
}

export default KioskScannerPageSetup;