import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import styles from './Accordion.module.css'

// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-solid-svg-icons'

// image elements
const plus = <FontAwesomeIcon icon={faSquarePlus} />
const minus = <FontAwesomeIcon icon={faSquareMinus} />

const Accordion = ({ header, content, description }) => {
    const [isActive, setIsActive] = useState(false)

    return (
        <div className={styles.accordionitem} title={description}>

            <div onClick={() => setIsActive(!isActive)}>
                <label className={styles.accordionheader}>
                    {header} {isActive ? minus : plus}
                </label>
            </div>

            {isActive && <div className={styles.accordioncontent}>
                {content.map(({ contentheader, contentlink, contentdescription }) => (
                    <Link
                        title={contentdescription}
                        className={styles.homelinks}
                        target={contentlink && (contentlink.toLowerCase().includes('elmah') || contentlink.toLowerCase().includes('hangfire')) ? '_blank' : '_self'}
                        to={contentlink}
                    >
                        <label className={styles.accordionlabel}>
                            {contentheader}
                        </label>
                    </Link>

                ))}

                <br />

            </div>}

        </div>
    );
};

export default Accordion;