import { useEffect, useState } from 'react';

// Styling
import styles from '../../../pages/Badging/ExtraBadgeManager/ExtraBadgeManager.module.css'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import ConditionalValueSelector from './ConditionalValueSelector'

// image elements
const circleCheck = <FontAwesomeIcon icon={faCircleCheck} />
const circleXmark = <FontAwesomeIcon icon={faCircleXmark} />

// ------ Components ------ //


const ExtraBadge = ({
    index,
    item,
    updateCompiledObject,
    updateDataValidationObject,
}) => {

    /*console.log("item", item)*/

    const [rowData, setRowData] = useState({
        BadgeField: null,
        OverwriteName: null,
        ConditionalField: null,
        ConditionalValue: [],
        ExpirationDate: null
    })
    const [conditionalSelections, setConditionalSelections] = useState([])
    const [validData, setValidData] = useState(false)

    const handleInputChange = (e, key) => {
        const updatedRowData = {
            ...rowData,
            [key]: e.target.value,
        }
        setRowData(updatedRowData)
        updateCompiledObject(index, updatedRowData)
    }

    const handleSavedData = obj => {
        let updatedTable = { ...rowData }

        if (obj.cssFieldName) {
            updatedTable = {
                ...updatedTable,
                BadgeField: obj.cssFieldName
            }
        }
        if (obj.badgeName) {
            updatedTable = {
                ...updatedTable,
                OverwriteName: obj.badgeName
            }
        }
        if (obj.originalFieldNames) {
            updatedTable = {
                ...updatedTable,
                ConditionalField: obj.originalFieldNames.split("||")[0] // grabs first option, will remove this if all options listed are desired
            }
        }
        if (obj.fieldValuesSelected) {
            updatedTable = {
                ...updatedTable,
                ConditionalValue: obj.fieldValuesSelected
            }
            setConditionalSelections(obj.fieldValuesSelected) // handle saved selections
        }
        if (obj.expirationDate) {
            updatedTable = {
                ...updatedTable,
                ExpirationDate: obj.expirationDate
            }
        }

        return updatedTable
    }

    const handleValidData = () => {
        if (rowData.OverwriteName && rowData.ConditionalValue.length > 0) {
            return true
        }
        return false
    }

    const compileSelections = (selectionsArr) => {
        setConditionalSelections(selectionsArr)
        console.log("selections are: ", selectionsArr)
    }

    useEffect(() => {
        const handleSavedDataOnRender = async () => {
            const updatedTable = await handleSavedData(item)
            setRowData(updatedTable)
        }
        handleSavedDataOnRender(item)
    }, [])

    useEffect(() => {
        const validityCheck = async () => {
            const validBool = await handleValidData()
            setValidData(validBool)
            updateDataValidationObject(index, validBool)
        }
        validityCheck()
    }, [rowData])

    useEffect(() => {
        if (!rowData.BadgeField && item.cssField) // ensures form population
        {
            const handleSavedDataOnRender = async () => {
                const updatedTable = await handleSavedData(item)
                setRowData(updatedTable)
            }
            handleSavedDataOnRender(item)
        }
        const updatedRowDataForConditionals = {
            ...rowData,
            ConditionalValue: conditionalSelections
        }
        setRowData(updatedRowDataForConditionals)
        updateCompiledObject(index, updatedRowDataForConditionals)
    }, [conditionalSelections.length])

    return (
        <tr key={index}>
            <td className={styles.tableData}>{rowData.BadgeField}</td>
            <td className={styles.tableData}>
                <input
                    type="text"
                    value={rowData.OverwriteName}
                    onChange={(e) => handleInputChange(e, "OverwriteName")}
                />
            </td>
            <td className={styles.tableData}>
                {rowData.ConditionalField}
            </td>
            <td>
                <ConditionalValueSelector
                    field={rowData.ConditionalValue}
                    savedSelections={item.fieldValuesSelected || []} // this part is needed/may have to be changed to whichever field will hold the saved selections coming from DB
                    availableSelections={item.availableSelections}
                    compileSelections={compileSelections}
                />
            </td>
            <td className={styles.tableData}>
                <input
                    className={styles.datePicker}
                    type="date"
                    value={rowData.ExpirationDate ? rowData.ExpirationDate.split('T')[0] : ''}
                    onChange={(e) => handleInputChange(e, "ExpirationDate")}
                />
            </td>
            <td className={styles.validData}>
                {validData ?
                    <i className={styles.buttonImage} id={styles.validIcon}>
                        {circleCheck}
                    </i>
                    :
                    <i className={styles.buttonImage} id={styles.invalidIcon}>
                        {circleXmark}
                    </i>
                }
            </td>
        </tr>
    );
}

export default ExtraBadge;