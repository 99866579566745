import { useState, useEffect } from 'react'
import * as accountService from '../../../services/accountService'
import * as eventManagementService from '../../../services/eventManagementService'
import styles from '../../../pages/Account/ManageAccountSettings/ManageAccountSettings.module.css'
import SelectEvent from './SelectEvent'
// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSquareCheck, faSquareXmark } from '@fortawesome/free-solid-svg-icons'

// image elements
const pencil = <FontAwesomeIcon icon={faPencil} />
const squareCheck = <FontAwesomeIcon icon={faSquareCheck} />
const squareXmark = <FontAwesomeIcon icon={faSquareXmark} />

const ManageAccount = ({ index, accountSetting, clients, editAccount, cancelEdit, editingId }) => {

    // State
    //const [accountSettings, setAccountSettings] = useState([])
    //const [clientOptions, setClientOptions] = useState([])
    const [eventOptions, setEventOptions] = useState([])

    const [formData, setFormData] = useState(
        {
            id: null,
            firstName: null,
            lastName: null,
            defaultURL: null,
            event1: {},
            eventName: null,
            event2: {},
            eventName2: null,
            event3: {},
            eventName3: null,
            event4: {},
            eventName4: null,
            clientName: null,
            client: {},
        }
    )

    const [firstEvent, setFirstEvent] = useState({
        event: {},
        eventName: null,
    })

    const [secondEvent, setSecondEvent] = useState({
        event: {},
        eventName: null,
    })

    const [thirdEvent, setThirdEvent] = useState({
        event: {},
        eventName: null,
    })

    const [fourthEvent, setFourthEvent] = useState({
        event: {},
        eventName: null,
    })
    /*   Functions  */
    const clearFirstEvent = () => setFirstEvent({
        event: null,
        eventName: null,
    })
    const clearSecondEvent = () => setSecondEvent({
        event: null,
        eventName: null,
    })
    const clearThirdEvent = () => setThirdEvent({
        event: null,
        eventName: null,
    })
    const clearFourthEvent = () => setFourthEvent({
        event: null,
        eventName: null,
    })

    const ClearAllEvents = () => {
        clearFirstEvent();
        clearSecondEvent();
        clearThirdEvent();
        clearFourthEvent();
    }

    // --- REST --- //
    const createEventsRequestBody = async () => {

        let requestBody = {}

        if (formData.client && formData.client.clientId) {
            requestBody =
            {
                clientId: formData.client.clientId,
                clientName: formData.client.clientName,
            }
        }

        return await requestBody;
    }
    const fetchEvents = async () => {
        let req = await createEventsRequestBody();
        const events = await eventManagementService.loadEvents(req);
        setEventOptions(events);
    }
    useEffect(() => {
        if (formData.clientName && eventOptions && eventOptions.length > 0) {
            if (formData.eventName) {
                var foundEvent = eventOptions.find((item) => item.name === formData.eventName);
                if (foundEvent) {
                    let eventObject = ({
                        event: foundEvent,
                        eventName: formData.eventName
                    })
                    setFirstEvent(eventObject);
                    formData.event1 = foundEvent;
                }
                else {
                    formData.event1 = null;
                }
            }

            if (formData.eventName2) {
                var foundEvent2 = eventOptions.find((item) => item.name === formData.eventName2);
                if (foundEvent2) {
                    let eventObject = ({
                        event: foundEvent,
                        eventName: formData.eventName
                    })
                    setSecondEvent(eventObject);
                    formData.event2 = foundEvent2;
                }
                else {
                    formData.event2 = null;
                }
            }

            if (formData.eventName3) {
                var foundEvent3 = eventOptions.find((item) => item.name === formData.eventName3);
                if (foundEvent3) {
                    let eventObject = ({
                        event: foundEvent,
                        eventName: formData.eventName
                    })
                    setThirdEvent(eventObject);
                    formData.event3 = foundEvent3;
                }
                else {
                    formData.event3 = null;
                }
            }

            if (formData.eventName4) {
                var foundEvent4 = eventOptions.find((item) => item.name === formData.eventName4);
                if (foundEvent4) {
                    let eventObject = ({
                        event: foundEvent,
                        eventName: formData.eventName
                    })
                    setFourthEvent(eventObject);
                    formData.event4 = foundEvent4;
                }
                else {
                    formData.event4 = null;
                }
            }
        }

    }, [eventOptions && eventOptions.length > 0])

    useEffect(() => {
        if (formData.clientName && formData.client) {
            fetchEvents();
        }

    }, [formData.client])

    //useEffect(() => {
    //    if (formData.clientName && formData.client) {
    //        // if populated, get the events for the client
    //        fetchEvents();
    //    }
    //}, [editingId === index.toString()])

    useEffect(() => {
        if (formData.clientName && clients && clients.length > 0) {
            var foundClient = clients.find((item) => item.clientName === formData.clientName);
            if (foundClient) {
                formData.client = foundClient;
            }
            else {
                formData.clientName = null;
            }
        }

    }, [formData.clientName])

    useEffect(() => {
        setFormData(accountSetting);
        if (accountSetting.clientName && clients && clients.length > 0) {
            // try to set client, for event retreval, may not work due to object setting/timing
            var foundClient = clients.find((item) => item.clientName === accountSetting.clientName);
            if (foundClient) {
                formData.client = foundClient;
            }
            else {
                formData.clientName = null;
            }
        }
    }, [])

    const handleChange = (e) => {

        if (e.target.type === 'select-one') {
            var foundClient = clients.find((item) => item.clientName === e.target.value);
            if (foundClient) {
                formData.client = foundClient;
            }
            else {
                formData.clientName = null;
            }
            formData.clientName = e.target.value;
            // clear old events
            ClearAllEvents();// proper clearing of child state
        }
        else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            })
        }
    }

    const createSaveRequest = (items) => {
        if (firstEvent) {
            formData.event1 = firstEvent.event;
            formData.eventName = firstEvent.eventName;
        }
        if (secondEvent) {
            formData.event2 = secondEvent.event;
            formData.eventName2 = secondEvent.eventName;
        }
        if (thirdEvent) {
            formData.event3 = thirdEvent.event;
            formData.eventName3 = thirdEvent.eventName;
        }
        if (fourthEvent) {
            formData.event4 = fourthEvent.event;
            formData.eventName4 = fourthEvent.eventName;
        }

        const fieldsSave = {
            'Id': formData.id,
            'FirstName': formData.firstName,
            'LastName': formData.lastName,
            'DefaultURL': formData.defaultURL,
            'ClientId': formData.client.clientId,
            'ClientName': formData.client.clientName,
            'EventId1': formData.event1 && formData.event1.eventId ? formData.event1.eventId : null,
            'EventName1': formData.eventName ? formData.eventName : null,
            'EventId2': formData.event2 && formData.event2.eventId ? formData.event2.eventId : null,
            'EventName2': formData.eventName2 ? formData.eventName2 : null,
            'EventId3': formData.event3 && formData.event3.eventId ? formData.event3.eventId : null,
            'EventName3': formData.eventName3 ? formData.eventName3 : null,
            'EventId4': formData.event4 && formData.event4.eventId ? formData.event4.eventId : null,
            'EventName4': formData.eventName4 ? formData.eventName4 : null,
        }
        return fieldsSave
    }

    const isEditInvalid = () => {
        return !(formData.firstName && formData.lastName);
    }
    const submitEdit = async e => {
        e.preventDefault()
        let saverequest = createSaveRequest();
        try {
            await accountService.saveAccountSettings(saverequest)
        } catch (err) {
            console.log(err)
        }
        cancelEdit();
    }
    return (
        <>
            {editingId !== index.toString() ?
                <tr className = {styles.trAccounts}>
                    <td className={styles.tdAccounts}> {formData.firstName}</td>
                    <td className={styles.tdAccounts}> {formData.lastName}</td>
                    <td className={styles.tdAccounts}> {formData.userName}</td>
                    <td className={styles.tdAccounts}> {formData.defaultURL}</td>{/*max-width: 13ch;*/}
                    <td className={styles.tdAccounts}> {formData.clientName}</td>
                    <td className={styles.tdAccounts}> {formData.eventName}</td>{/*max-width: 13ch;*/}
                    <td className={styles.tdAccounts}> {formData.eventName2}</td>{/*max-width: 13ch;*/}
                    <td className={styles.tdAccounts}> {formData.eventName3}</td>{/*max-width: 13ch;*/}
                    <td className={styles.tdAccounts}> {formData.eventName4}</td>{/*max-width: 13ch;*/}
                    <td className={styles.tdAccounts}>
                        <button className={styles.editButton} onClick={e => editAccount(e)} id={index} title="Edit account.">
                            <i className={styles.buttonImage}>{pencil}</i>
                        </button>
                    </td>
                </tr>
                :
                <tr className={styles.trAccounts}>
                    <td className={styles.tdAccounts}> {formData.firstName}</td>
                    <td className={styles.tdAccounts}> {formData.lastName}</td>
                    <td className={styles.tdAccounts}> {formData.userName}</td>
                    <td className={styles.tdAccounts}>
                        <label title="Default url - used for navigation/url redirects.">
                            Default URL: <input
                                type="text"
                                value={formData.defaultURL}
                                name="defaultURL"
                                onChange={handleChange}
                            />

                        </label>
                    </td>
                    <td className={styles.tdAccounts}>
                        Select Client:
                        <select
                            name="clientName"
                            id={styles.ThirdPartyListDropdown}
                            value={formData.clientName}
                            onChange={handleChange}
                        >
                            <option disabled={true} value="" selected="selected">
                                -- Select an option --
                            </option>
                            {
                                clients.map((ci, idx) => (
                                    <option key={idx}>{ci.clientName}</option>
                                ))
                            }
                        </select>
                    </td>
                    <td className={styles.tdAccounts}>
                        {formData.clientName && formData.client ?
                            <SelectEvent
                                formData={formData}
                                eventInfo={firstEvent}
                                setEvent={setFirstEvent}
                                listEvents={eventOptions}
                                key={formData.clientName ? formData.clientName + "1" : "1"}
                            />
                            : <></>
                        }

                    </td>
                    <td className={styles.tdAccounts}>
                        {formData.clientName && formData.client && firstEvent && firstEvent.eventName ?
                            <SelectEvent
                                formData={formData}
                                eventInfo={secondEvent}
                                setEvent={setSecondEvent}
                                listEvents={eventOptions}
                                key={formData.clientName ? formData.clientName + "2" : "2"}
                            />
                            : <></>
                        }
                    </td>
                    <td className={styles.tdAccounts}>
                        {formData.clientName && formData.client && secondEvent && secondEvent.eventName ?
                            <SelectEvent
                                formData={formData}
                                eventInfo={thirdEvent}
                                setEvent={setThirdEvent}
                                listEvents={eventOptions}
                                key={formData.clientName ? formData.clientName + "3" : "3"}
                            />
                            : <></>
                        }

                    </td>
                    <td className={styles.tdAccounts}>
                        {formData.clientName && formData.client && thirdEvent && thirdEvent.eventName ?
                            <SelectEvent
                                formData={formData}
                                eventInfo={fourthEvent}
                                setEvent={setFourthEvent}
                                listEvents={eventOptions}
                                key={formData.clientName ? formData.clientName + "4" : "4"}
                            />
                            : <></>
                        }

                    </td>
                    <td className={styles.tdAccounts}>
                        <button onClick={e => cancelEdit(e)} id={index} className={styles.editButtons} title="Cancel Edit">
                            <i className={styles.buttonImage} id={styles.editCancel}>
                                {squareXmark}
                            </i>
                        </button>
                        <button onClick={e => submitEdit(e)} id={index} className={styles.editButtons} disabled={isEditInvalid()} title="Submit Edit">
                            <i className={styles.buttonImage} id={styles.editSubmit}>
                                {squareCheck}
                            </i>
                        </button>
                    </td>
                </tr>
            }


        </>
    );
}

export default ManageAccount;
