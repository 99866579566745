import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from "react-router-dom"
import * as clientManagementService from '../../../services/clientManagementService'
import * as siteManagementService from '../../../services/siteManagementService'
import styles from "./ClientConfig.module.css"

// components - client config
import StovaConfig from '../../../components/Home/ClientConfig/StovaConfig'
import CventConfig from '../../../components/Home/ClientConfig/CventConfig'
import EPlyConfig from '../../../components/Home/ClientConfig/EPlyConfig'
import EdgeRegConfig from '../../../components/Home/ClientConfig/EdgeRegConfig'
import GTRConfig from '../../../components/Home/ClientConfig/GTRConfig'
import ImpexiumConfig from "../../../components/Home/ClientConfig/ImpexiumConfig"
import MembersuiteConfig from '../../../components/Home/ClientConfig/MembersuiteConfig'
import YourMembershipConfig from '../../../components/Home/ClientConfig/YourMembershipConfig'
import HigherLogicConfig from '../../../components/Home/ClientConfig/HigherLogicConfig'
import RhythmConfig from '../../../components/Home/ClientConfig/RhythmConfig'

// components - form
import HoverButton from '../../../components/Miscellaneous/HoverButton'

const ClientConfig = () => {

    // state
    const [ERSList, setERSList] = useState([])
    const [industryList, setIndustryList] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [clientData, setClientData] = useState([])
    const [clientSettings, setClientSettings] = useState([])

    const [formData, setFormData] = useState({
        ClientName: null,
        EventRegistrationSystem: null,
        Industry: null,
        Categories: [],
    })

    const [impexiumClientConfigSmall, setImpexiumClientConfig] = useState({
        AccessEndpoint: null,
        AppName: null,
        AppKey: null,
        AppId: null,
        AppPassword: null,
        AppUserEmail: null,
        AppUserPassword: null,
    })

    const [stovaClientConfigSmall, setStovaClientConfig] = useState({
        AccountId: null,
        Key: null,
    })

    const [eplyClientConfigSmall, seteplyClientConfig] = useState({
        Username: null,
        Password: null,
        ApiToken: null,
        Url: null,
    })

    const [memberSuiteClientConfigSmall, setMembersuiteClientConfig] = useState({
        tenantID: null,
        username: null,
        password: null,
        url: null,
        EventSQL: null,
        EventRegistrationSQL: null,
        SessionSQL: null,
        SessionRegistrationSQL: null
    })

    const [YourMembershipClientConfigSmall, setYourMembershipClientConfig] = useState({
        YourMembershipClientID: null,
        UserType: null,
        UserName: null,
        Password: null,
        Url: null,
    })

    const [GTRClientConfigSmall, setGTRClientConfig] = useState({
        ApiKey: null,
        Url: null
    })

    const [EdgeRegClientConfigSmall, setEdgeRegClientConfig] = useState({
        AccountToken: null,
        Url: null
    })

    const [CventClientConfigSmall, setCventClientConfig] = useState({
        AccountNumber: null,
        UserName: null,
        Password: null,
    })

    const [HigherLogicClientConfigSmall, setHigherLogicClientConfig] = useState({
        ApiKey: null,
        ApiSecret: null,
        BaseUrl: null,
    })

    const [RhythmClientConfigSmall, setRhythmClientConfig] = useState({
        RhythmClientID: null,
        ClientSecret: null,
        TenantID: null,
        AccessToken: null,
        Auth0Domain: null,
        Auth0Audience: null,
    })

    // state --- clearing functions
    const clearImpexiumData = () => setImpexiumClientConfig({
        AccessEndpoint: null,
        AppName: null,
        AppKey: null,
        AppId: null,
        AppPassword: null,
        AppUserEmail: null,
        AppUserPassword: null,
    })

    const clearStovaData = () => setStovaClientConfig({
        AccountId: null,
        Key: null,
    })

    const clearePlyData = () => seteplyClientConfig({
        Username: null,
        Password: null,
        ApiToken: null,
        Url: "https://api.eply.com",
    })

    const clearMembersuiteData = () => setMembersuiteClientConfig({
        tenantID: null,
        username: null,
        password: null,
        url: "https://rest.membersuite.com/",
        EventSQL: "Select Id, Name,LocalId, StartDate, EndDate" +
            " from [Event]",
        EventRegistrationSQL: "Select ID, LocalID,Individual.FirstName, Individual.LastName, Individual.EmailAddress, Individual.LocalId, Fee.Name," +
            " Individual.Designation, Individual._Preferred_PhoneNumber,Individual._Preferred_Address_Line1, Individual._Preferred_Address_Line2," +
            " Individual._Preferred_Address_PostalCode, PreferredName__q, PreferredName__c, BadgeName, BadgeOrganization, BadgeTitle, BadgeCity," +
            "BadgeState, BadgeCountry, Individual.PrimaryOrganization.Name, Individual.PrimaryOrganizationOrCompanyName," +
            " CancellationDate, CancellationReason, [Order].AmountPaid, [Order].BalanceDue, [Order].LocalId, CSR_Team__c," +
            "FirstTime__c, FirstTime__q, FocusGroupAttendee__c, FirstEvent__q, RSVP__q, FirstConference__q, Pronoun__q, Pro__q," +
            "BadgePronoun__c, Whatdayareyouattending___q, Whichpre_conferenceworkshopareyouattending___q from EventRegistration " +
            " where Event.LocalID = {EVENTID}",
        SessionSQL: "Select Id, Name,LocalId, StartDate, EndDate, Room.Name" +
            " from Session",
        SessionRegistrationSQL: "select ID, [Order].LocalID, [Order].AmountPaid, [Order].BalanceDue, Individual.LocalId, Individual.ID, " + //[Event].Name,
            "[Event].LocalId, CancellationDate, LocalId, OnWaitList, ParentRegistration.LocalID, ParentRegistration.ID from SessionRegistration"
    })

    const clearYourMembershipData = () => setYourMembershipClientConfig({
        YourMembershipClientID: null,
        UserType: null,
        UserName: null,
        Password: null,
        Url: "https://ws.yourmembership.com"
    })

    const clearGTRData = () => setGTRClientConfig({
        ApiKey: null,
        Url: "https://api.gtrnow.com",
    })

    const clearEventRebelsData = () => setEdgeRegClientConfig({
        AccountToken: null,
        Url: "https://rptsvr.eventrebels.com/er/API",
    })

    const clearCventData = () => setCventClientConfig({
        AccountNumber: null,
        UserName: null,
        Password: null,
    })

    const clearHigherLogicData = () => setHigherLogicClientConfig({
        ApiKey: null,
        ApiSecret: null,
        BaseUrl: null,
    })

    const clearRhythmData = () => setRhythmClientConfig({
        RhythmClientID: null,
        ClientSecret: null,
        TenantID: null,
        AccessToken: null,
        Auth0Domain: null,
        Auth0Audience: null,
    })

    const clearAllERSForms = () => {
        clearStovaData()
        clearCventData()
        clearePlyData()
        clearEventRebelsData()
        clearGTRData()
        clearImpexiumData()
        clearMembersuiteData()
        clearYourMembershipData()
        clearHigherLogicData()
        clearRhythmData()
    }

    // state --- change functions
    const handleCategorySelect = e => {
        const value = e.target.value

        if (e.target.checked) {
            setSelectedCategories([...selectedCategories, value])
        } else {
            setSelectedCategories(selectedCategories.filter(category => category !== value))
        }
    }

    // Ensures state resets if user selects different ERS after partially filling out another
    const handleERSChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

        clearAllERSForms()
        handleERSEditLoad()
    }

    const updateStateForCategories = () => {
        setFormData({
            ...formData,
            Categories: selectedCategories,
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()

        const appended = await appendERSCredentials()

        console.log(appended)

        // call back end service here to POST form data
        try {
            clientManagementService.createClient(appended)
        } catch (err) {
            console.log(err)
        }

        setFormData({
            ClientName: '',
            EventRegistrationSystem: '',
            Industry: '',
            Categories: [],
        })
        setSelectedCategories([])
        clearAllERSForms()
    }

    const appendERSCredentials = () => {

        const ERS = formData.EventRegistrationSystem
        let result = {}

        result = ERS === "Impexium" ? { ...formData, impexiumClientConfigSmall }
            : ERS === "Stova" ? { ...formData, stovaClientConfigSmall }
                : ERS === "EPly" ? { ...formData, eplyClientConfigSmall }
                    : ERS === "MemberSuite" ? { ...formData, memberSuiteClientConfigSmall }
                        : ERS === "GTR" ? { ...formData, GTRClientConfigSmall }
                            : ERS === "EdgeReg" ? { ...formData, EdgeRegClientConfigSmall }
                                : ERS === "Cvent" ? { ...formData, CventClientConfigSmall }
                                    : ERS === "YourMembership" ? { ...formData, YourMembershipClientConfigSmall }
                                        : ERS === "HigherLogic" ? { ...formData, HigherLogicClientConfigSmall }
                                            : ERS === "Rhythm" ? { ...formData, RhythmClientConfigSmall }
                                            : console.log("Error: No ERS data provided")

        return result
    }

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }


    // Edit functionality
    const { ClientId } = useParams()
    const location = useLocation()
    console.log("location state is: ", location.state)
    const client = location.state
    //setClientData(client)
    //console.log("client data: ", clientData)

    // useEffect
    useEffect(() => {

        const fetchData = async () => {
            const ERS = await siteManagementService.getAll()
            const categories = await siteManagementService.getAllCategories()
            const industries = await siteManagementService.getAllIndustries()

            setERSList(ERS)
            setCategories(categories)
            setIndustryList(industries)
            setClientData(client)
        }

        const fetchClientState = async () => {
            if (client) {
                const requestBody =
                {
                    "clientId": `${client.clientId}`,
                    "EventRegistrationSystem": `${client.ersList}`
                }
                const clientSettingFetch = await clientManagementService.getClientSetting(requestBody)

                setClientSettings(clientSettingFetch)

                const redoFetch = () => {
                    if (clientSettings.length == 0) {
                        setClientSettings(clientSettingFetch)
                        redoFetch()
                    }
                }
                handleERSEditLoad()
            }
        }

        fetchData()
        fetchClientState()
    }, [])

    const handleERSEditLoad = () => {

        const client = location.state

        const impexiumEROS = client && client.impexiumClientConfigSmall ? client.impexiumClientConfigSmall : null
        const higherLogicEROS = client &&  client.higherLogicClientConfigSmall ? client.higherLogicClientConfigSmall : null
        const edgeRegEROS = client &&  client.edgeRegClientConfigSmall ? client.edgeRegClientConfigSmall : null
        const memberSuiteEROS = client &&  client.memberSuiteClientConfigSmall ? client.memberSuiteClientConfigSmall : null
        const stovaEROS = client &&  client.stovaClientConfigSmall ? client.stovaClientConfigSmall : null
        const GTREROS = client &&  client.GTRClientConfigSmall ? client.GTRClientConfigSmall : null
        const CventEROS = client &&  client.cventlientConfigSmall ? client.cventClientConfigSmall : null
        const eplyEROS = client &&  client.eplyClientConfigSmall ? client.eplyClientConfigSmall : null
        const yourMembershipEROS = client && client.yourMembershipClientConfigSmall ? client.yourMembershipClientConfigSmall : null
        const rhythmEROS = client && client.rhythmClientConfigSmall ? client.rhythmClientConfigSmall : null

        //console.log("impexium EROS is: ", impexiumEROS)
        //console.log("HigherLogic EROS is: ", higherLogicEROS)
        //console.log("edgeReg EROS is: ", edgeRegEROS)
        //console.log("Membersuite EROS is: ", memberSuiteEROS)
        //console.log("Stova EROS is: ", stovaEROS)
        //console.log("GTR EROS is: ", GTREROS)
        //console.log("Cvent EROS is: ", CventEROS)
        //console.log("ePly EROS is: ", eplyEROS)
        //console.log("Your Membership EROS is: ", yourMembershipEROS)
        //console.log("Rhythm EROS is: ", rhythmEROS)

        if (impexiumEROS) {
            setImpexiumClientConfig({
                AccessEndpoint: impexiumEROS.accessEndpoint,
                AppName: impexiumEROS.appName,
                AppKey: impexiumEROS.appKey,
                AppId: impexiumEROS.appId,
                AppPassword: impexiumEROS.appPassword,
                AppUserEmail: impexiumEROS.appUserEmail,
                AppUserPassword: impexiumEROS.appUserPassword,
            })
        }

        if (higherLogicEROS) {
            setHigherLogicClientConfig({
                ApiKey: higherLogicEROS.apiKey,
                ApiSecret: higherLogicEROS.apiSecret,
                BaseUrl: higherLogicEROS.baseUrl,
            })
        }

        if (edgeRegEROS) {
            setEdgeRegClientConfig({
                AccountToken: edgeRegEROS.accountToken,
                Url: edgeRegEROS.url
            })
        } 

        if (memberSuiteEROS) {
            setMembersuiteClientConfig({
                TenantId: memberSuiteEROS.tenantID,
                Username: memberSuiteEROS.username,
                Password: memberSuiteEROS.password,
                URL: memberSuiteEROS.url,
                EventSQL: memberSuiteEROS.eventSQL,
                EventRegistrationSQL: memberSuiteEROS.eventRegistrationSQL,
                SessionSQL: memberSuiteEROS.sessionSQL,
                SessionRegistrationSQL: memberSuiteEROS.SessionRegistrationSQL
            })
        }

        if (stovaEROS) {
            setStovaClientConfig({
                AccountId: stovaEROS.accountId,
                Key: stovaEROS.key,
            })
        }
        if (GTREROS) {
            setGTRClientConfig({
                ApiKey: GTREROS.apiKey,
                Url: GTREROS.url,
            })
        }

        if (CventEROS) {
            setCventClientConfig({
                AccountNumber: CventEROS.accountNumber,
                UserName: CventEROS.userName,
                Password: CventEROS.password,
            })
        }

        if (eplyEROS) {
            seteplyClientConfig({
                Username: eplyEROS.username,
                Password: eplyEROS.password,
                ApiToken: eplyEROS.ApiToken,
                Url: "https://api.eply.com",
            })
        }

        if (yourMembershipEROS) {
            setYourMembershipClientConfig({
                YourMembershipClientID: yourMembershipEROS.yourMembershipClientID,
                UserType: yourMembershipEROS.userType,
                UserName: yourMembershipEROS.username,
                Password: yourMembershipEROS.password,
                Url: "https://ws.yourmembership.com"
            })
        }

        if (rhythmEROS) {
            setRhythmClientConfig({
                RhythmClientID: rhythmEROS.rhythmClientID,
                ClientSecret: rhythmEROS.clientSecret,
                TenantID: rhythmEROS.tenantID,
                AccessToken: rhythmEROS.accessToken,
                Auth0Domain: rhythmEROS.auth0Domain,
                Auth0Audience: rhythmEROS.auth0Audience,
            })
        }
    }

    const fillFormEdit = async () => {
        if (clientSettings.length !== 0 && formData.Categories.length == 0) {

            const newFormData = await {
                ClientName: client.clientName,
                EventRegistrationSystem: client.ersList[0],
                Industry: clientSettings.clientIndustryAndCategories.find((item) => item.type === "Industry").name,
                Categories: clientSettings.clientIndustryAndCategories.find((item) => item.type === "Category").name,
            }
            setFormData(newFormData)
            setSelectedCategories([newFormData.Categories])
        }
        console.log("new form data is: ", formData.Categories)
    }

    // Catch client setting fetch and feed into form whenever load finishes
    useEffect(() => {
        fillFormEdit()
    }, [clientSettings])

    useEffect(() => {
        updateStateForCategories()
    }, [selectedCategories])


    return (
        <>
            {ClientId ?
                <h1>Edit Client</h1>
                :
                <h1>Add Client</h1>
            }

            <div id={styles.ClientConfigContainer}>

                <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    className={styles.container}
                >

                    <div className={styles.inputContainer}>
                        <label htmlFor="clientName" className={styles.label}>Client Name: </label>
                        <input
                            type="text"
                            autoComplete="off"
                            value={formData.ClientName}
                            name="ClientName"
                            onChange={handleChange}
                        />
                    </div>

                    <div className={styles.inputContainer}>
                        <strong><label htmlFor="EventRegistrationSystem">Event Registration System (ERS): </label></strong>
                        <select
                            name="EventRegistrationSystem"
                            id={styles.ERSListDropdown}
                            value={formData.EventRegistrationSystem}
                            onChange={handleERSChange}

                        >
                            <option disabled={true} value="" selected="selected">
                                -- Select an option --
                            </option>
                            {
                                ERSList.map((ers, idx) => (
                                    <option key={idx}>{ers.eventRegistrationSystem}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className={styles.inputContainer}>
                        <label
                            htmlFor="clientIndustry"
                            className={styles.label}
                        >
                            Industry Type
                            <HoverButton
                                title={'TBU = Tech Business Unit\nHBU = Healthcare Business Unit\nBTBU = Business and Trade Business Unit'}
                                messageWidth={350}
                            />
                            :
                        </label>
                        <select
                            name="Industry"
                            id={styles.ERSListDropdown}
                            value={formData.Industry}
                            onChange={handleChange}
                        >
                            <option disabled={true} value="" selected="selected">
                                -- Select an option --
                            </option>
                            {
                                industryList.map((industry, idx) => (
                                    <option key={idx}>{industry.name}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className={styles.inputContainer}>
                        <label htmlFor="Categories" className={styles.label}>Categories: </label>
                        {categories.map(category => (
                            <label key={category.name} title={category.description}>
                                <input
                                    type="checkbox"
                                    value={category.name}
                                    checked={selectedCategories.includes(category.name)}
                                    onChange={handleCategorySelect}
                                />
                                {category.name}
                            </label>
                        ))}
                    </div>

                    {formData.EventRegistrationSystem === "Impexium" ?
                        <ImpexiumConfig
                            formData={formData}
                            impexiumClientConfig={impexiumClientConfigSmall}
                            setImpexiumClientConfig={setImpexiumClientConfig}
                        />
                        : <></>
                    }

                    {formData.EventRegistrationSystem === "Stova" ?
                        <StovaConfig
                            formData={formData}
                            stovaClientConfig={stovaClientConfigSmall}
                            setStovaClientConfig={setStovaClientConfig}
                        />
                        : <></>
                    }

                    {formData.EventRegistrationSystem === "EPly" ?
                        <EPlyConfig
                            formData={formData}
                            eplyClientConfig={eplyClientConfigSmall}
                            seteplyClientConfig={seteplyClientConfig}
                        />
                        : <></>
                    }

                    {formData.EventRegistrationSystem === "MemberSuite" ?
                        <MembersuiteConfig
                            formData={formData}
                            memberSuiteClientConfig={memberSuiteClientConfigSmall}
                            setMembersuiteClientConfig={setMembersuiteClientConfig}
                        />
                        : <></>
                    }

                    {formData.EventRegistrationSystem === "YourMembership" ?
                        <YourMembershipConfig
                            formData={formData}
                            YourMembershipClientConfig={YourMembershipClientConfigSmall}
                            setYourMembershipClientConfig={setYourMembershipClientConfig}
                        />
                        : <></>
                    }

                    {formData.EventRegistrationSystem === "GTR" ?
                        <GTRConfig
                            formData={formData}
                            GTRClientConfig={GTRClientConfigSmall}
                            setGTRClientConfig={setGTRClientConfig}
                        />
                        : <></>
                    }

                    {formData.EventRegistrationSystem === "EdgeReg" ?
                        <EdgeRegConfig
                            formData={formData}
                            EdgeRegClientConfig={EdgeRegClientConfigSmall}
                            setEdgeRegClientConfig={setEdgeRegClientConfig}
                        />
                        : <></>
                    }

                    {formData.EventRegistrationSystem === "Cvent" ?
                        <CventConfig
                            formData={formData}
                            CventClientConfig={CventClientConfigSmall}
                            setCventClientConfig={setCventClientConfig}
                        />
                        : <></>
                    }

                    {formData.EventRegistrationSystem === "HigherLogic" ?
                        <HigherLogicConfig
                            formData={formData}
                            HigherLogicClientConfig={HigherLogicClientConfigSmall}
                            setHigherLogicClientConfig={setHigherLogicClientConfig}
                        />
                        : <></>
                    }
                    {formData.EventRegistrationSystem === "Rhythm" ?
                        <RhythmConfig
                            formData={formData}
                            rhythmClientConfig={RhythmClientConfigSmall}
                            setRhythmClientConfig={setRhythmClientConfig}
                        />
                        : <></>
                    }
                </form>

            </div>

        </>
    );
}

export default ClientConfig;