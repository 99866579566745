import styles from "./HoverButton.module.css"

const HoverButton = ({ title, messageWidth }) => {
    return (
        <div className={styles.hoverButton}>
            <button className={styles.hoverButtonIcon}>?</button>
            <div className={styles.hoverButtonPopup} style={{ width: messageWidth }}>
                <span className={styles.hoverButtonPopupTitle}>
                    {title}
                </span>
            </div>
        </div>
    );
};

export default HoverButton;