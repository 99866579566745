import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LoginForm from "../../components/Home/LoginForm/LoginForm"
import "./Landing.css"
import Header from '../../components/Header/Header'
import * as clientEventService from '../../services/clientEventService'

const Landing = ({
    user,
    handleSignupOrLogin,
    updateMessage,
    message,
    setMessage
}) => {

    return (
        <>
            <main className="page-area">
                <div id="landing-area">
                    {user ?
                        <div id="logged-in">
                            <h1 id="landing-header">
                                Hello, {user ? user.Role : 'please sign in'}
                            </h1>

                            <div>
                                <h4>Begin by creating a new client:</h4>
                                <br /><br />
                                <button id="landing-newClientButton">
                                    <Link
                                        id="landing-newClientLink"
                                        to={`/ClientConfig`}
                                    >
                                        Create New Client
                                    </Link>
                                </button>
                            </div>

                            <p>
                                <b><i>or</i></b>
                            </p>

                            <div id="landingHeaderSection">
                                <h4>Select an existing client/event to complete:</h4>
                                <Header
                                    continueButtonVisibility={true}
                                />
                            </div>                            
                        </div>                    
                    :
                        <div id="not-logged-in">
                            <h1 className="landing-header">
                                Hello, {user ? user.Role : 'please sign in'}
                            </h1>
                            <LoginForm
                                handleSignupOrLogin={handleSignupOrLogin}
                                updateMessage={updateMessage}
                                message={message}
                                setMessage={setMessage}
                            />
                        </div>
                    }
                </div>
            </main>
        </>
    )
}

export default Landing