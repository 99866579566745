import Cookies from 'js-cookie';
const LOCAL_STORAGE_KEY = "designatedclientevent"

const setClientEvent = (eventInfo) => {
    /* should look like this:
    { 
        clientId: '95e829d9-1dc3-4b23-a08d-2a25786c48b5', 
        eventId: '330436f5-e3de-ed11-a81c-00224853b513', 
        clientName: 'NAWLA', 
        eventName: '2023 Traders Market', 
        expiration: 1718399263431
    }
    */
    // set expiration date
    let dateExpires = new Date();
    dateExpires.setTime(dateExpires.getTime() + (1000 * 60 * 60 * 24 * 3)); // expires in 3 days
    //eventInfo.expires = dateExpires.toUTCString() 
    //localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(eventInfo))
    Cookies.set(LOCAL_STORAGE_KEY, JSON.stringify(eventInfo), { path: '/', expires: dateExpires });
}

const getClientEvent = () => {
    let eventInfo = Cookies.get(LOCAL_STORAGE_KEY);
    //let eventInfo = localStorage.getItem(LOCAL_STORAGE_KEY)
    //if (eventInfo) {
    //    const payload = JSON.parse(eventInfo)
    //    //payload.exp < Date.now() / 1000
    //    if (payload.expiration < Date.now() / 1000) {
    //        localStorage.removeItem(LOCAL_STORAGE_KEY)
    //        eventInfo = null
    //    }
    //} else {
    //    localStorage.removeItem(LOCAL_STORAGE_KEY)
    //}
    return eventInfo && eventInfo !== undefined? JSON.parse(eventInfo) : null
}

const getClientIdFromSaved = () => {
    const clientevent = getClientEvent()
    return clientevent
        ? clientevent.clientId
        : null
}

const getClientNameFromSaved = () => {
    const clientevent = getClientEvent()
    return clientevent
        ? clientevent.clientName
        : null
}

const getEventIdFromSaved = () => {
    const clientevent = getClientEvent()
    return clientevent
        ? clientevent.eventId
        : null
}

const getEventNameFromSaved = () => {
    const clientevent = getClientEvent()
    return clientevent
        ? clientevent.eventName
        : null
}

const removeClientEvent = () => {
    let eventInfo = Cookies.get(LOCAL_STORAGE_KEY);
    if (eventInfo && eventInfo !== undefined) {
        //localStorage.removeItem(LOCAL_STORAGE_KEY)
        Cookies.remove(LOCAL_STORAGE_KEY);
    }
}


export { setClientEvent, getClientIdFromSaved, getEventIdFromSaved, getClientNameFromSaved, getEventNameFromSaved,  getClientEvent, removeClientEvent }