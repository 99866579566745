import styles from '../../../pages/Badging/AttendanceReport/AttendanceReport.module.css'
const AdHocAttendee = ({ registrant}) => {
    const changeDateTimeFormat = dateStr => {
        const parsedDate = Date.parse(dateStr)
        const date = new Date(parsedDate)
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric' };
        return date.toLocaleDateString('en-us', options);
    }

    return (
        <>
            <td className={styles.tdAccounts}> {registrant.firstName}</td>
            <td className={styles.tdAccounts}> {registrant.lastName}</td>
            <td className={styles.tdAccounts}> {registrant.organization}</td>
            <td className={styles.tdAccounts}> {registrant.registrationType}</td>
            <td className={styles.tdAccounts}> {changeDateTimeFormat(registrant.printedDate)}</td>
        </>
    );
}

export default AdHocAttendee;