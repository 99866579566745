import styles from "../../../pages/Home/WebHookList/WebHookList.module.css"

const UserNameAndPasswordAuthOption = ({
    formData,
    userNameAndPasswordOption,
    setUserNameAndPasswordOption,
}) => {

    const handleChange = e => {
        setUserNameAndPasswordOption({
            ...userNameAndPasswordOption,
            [e.target.name]: e.target.value,
        })
        console.log(UserNameAndPasswordAuthOption)
    }

    const isFormValid = () => {
        return (!(formData.description && formData.webHookAuthenticationOption && formData.authToken) &&
            (!(formData.webHookAuthenticationOption === "UserNameAndPassword" && formData.userName && formData.password))
            ? false: true)
    }
    return (
        <>
            <div className={styles.inputContainer}>
                <label htmlFor="UserName" className={styles.label}>User Name: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={userNameAndPasswordOption.UserName}
                    name="UserName"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="Password" className={styles.label}>Password: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={userNameAndPasswordOption.Password}
                    name="Password"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <button disabled={!isFormValid()} className={styles.button}>
                    Submit
                </button>
               
            </div>
        </>
    );
}

export default UserNameAndPasswordAuthOption;