import React from 'react';
import styles from "../../../pages/Home/EventFilterList/EventFilterList.module.css"
import { useState, useEffect, useCallback } from 'react'
import * as eventService from "../../../services/eventManagementService"
import * as badgeService from "../../../services/badgeManagementService"


// ------ Components ------ //
import FilterEqualSelection from '../../../components/Home/EventFilterList/FilterEqualSelection'
import FilterContainsSelection from '../../../components/Home/EventFilterList/FilterContainsSelection'

// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faUndo, faSquareCheck, faSquareXmark } from '@fortawesome/free-solid-svg-icons'

// image elements
const pencil = <FontAwesomeIcon icon={faPencil} />
const undo = <FontAwesomeIcon icon={faUndo} />
const squareCheck = <FontAwesomeIcon icon={faSquareCheck} />
const squareXmark = <FontAwesomeIcon icon={faSquareXmark} />


const EventFilters = ({
    filteredByEFs,
    filterField,
    filterOptions,
    index,
    siteData
}) => {
    // state
    const [message, setMessage] = useState([''])
    const [EF, setEF] = useState([
        {
            id: null,
            eventFilteringOption: null,
            valuesContain: null,
            valuesEqualSelect: null,
            required:false,
            editable: false
        }
    ])
    const [formData, setFormData] = useState({
        Id: null,
        EventFilteringOption: null,
        ValuesContain: null,
        ValuesEqualSelect: null,
        Required: null
    })

    // state --- change functions
    const editEF = e => {
        e.preventDefault()

        let divId = e.currentTarget.id
        //if (!divId) {
        //    e.current.id
        //}
        const items = Array.from(filteredByEFs)
        let filtered = items.filter(ef => divId === ef.id)[0]

        // Have to add this to maintain one edit at a time, since all will share formData for editing state
        const editableCheck = i => i.editable
        if (items.some(editableCheck)) {
            return
        }
        filtered.editable = true
        setFormData({
            Id: filtered.id,
            EventFilteringOption: filtered.eventFilteringOption,
            ValuesContain: filtered.valuesContain,
            ValuesEqualSelect: filtered.valuesEqualSelect,
            Required: filtered.required
        })
        
        //setEF(items)
    }

    const isEditInvalid = () => {
        return !((formData.Required) || (formData.EventFilteringOption && (
            ((formData.EventFilteringOption === 'IncludeUseEqual' || formData.EventFilteringOption === 'ExcludeUseEqual') && formData.ValuesEqualSelect && formData.ValuesEqualSelect.length > 0)
            || ((formData.EventFilteringOption === 'IncludeUseContain' || formData.EventFilteringOption === 'ExcludeUseContain') && formData.ValuesContain))))
    }
    const handleChange =e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const cancelEdit = e => {
        e.preventDefault()

        let divId = e.currentTarget.id
        const items = Array.from(filteredByEFs)
        let filtered = items.filter(ef => divId === ef.id)[0]
        filtered.editable = false

        setEF(items)
        setFormData({
            Id: "",
            EventFilteringOption: "",
            ValuesContain: "",
            ValuesEqualSelect: [],
            Required: null
        })

    }

    const submitEdit = async e => {
        e.preventDefault()

        // cancel and return if edit is attempted with no filtering option value
        if (!formData.EventFilteringOption ||
            ((formData.EventFilteringOption === "IncludeUseEqual" || formData.EventFilteringOption === "ExcludeUseEqual") && !formData.ValuesEqualSelect) ||
            ((formData.EventFilteringOption === "IncludeUseContain" || formData.EventFilteringOption === "ExcludeUseContain") && !formData.ValuesContain)) {
            cancelEdit(e)
            return
        }
        // handle form data and connect to edit service in siteManagementService.js here
        try {
            await badgeService.saveEventFilter(formData)
        } catch (err) {
            setMessage(err.message)
        }

        // reset editable state back to false
        let divId = e.target.id
        if (!divId) {
            // use id from opbject
            divId = formData.Id
        }
        const items = Array.from(filteredByEFs)
        let filtered = items.filter(ef => divId === ef.id)[0]
        filtered.editable = false
        filtered.eventFilteringOption = formData.EventFilteringOption
        filtered.valuesContain = formData.ValuesContain
        filtered.valuesEqualSelect = formData.ValuesEqualSelect
        filtered.required = formData.Required

        setEF(items)

        // setFormData back to blanks
        clearFormData()
    }

    const submitFieldDelete = async e => {
        e.preventDefault()
        let divId = e.currentTarget.id
        // handle form data and connect to edit service in siteManagementService.js here
        try {
            await badgeService.deleteEvenFilterObject(divId)
        } catch (err) {
            setMessage(err.message)
        }

        // reset editable state back to false
        const items = Array.from(siteData)
        let filtered = items.filter(ef => divId === ef.id)[0]
        filtered.editable = false
        setEF(items)

        // setFormData back to blanks
        clearFormData()

    }

    const clearFormData = useCallback(
        () => {
            setFormData({
                Id: "",
                EventFilteringOption: "",
                ValuesContain: "",
                ValuesEqualSelect: [],
                Required: null
            })
        },
        []
    )
    // Ensures state resets if user selects different filtering option after partially filling out another
    const handleSelectChange = (e, idNumber) => {
        let idReturn = idNumber
        
        if (e.target.type === 'select-one') {
            let filteringOptionSelectedIndex = filterOptions.filter(ro => ro.name === e.target.value)[0]
            setFormData({
                ...formData,
                Id: idReturn,
                [e.target.name]: filteringOptionSelectedIndex.name,
            })
        }
        else if (e.target.type === 'checkbox') {
            setFormData({
                ...formData,
                Id: idReturn,
                [e.target.name]: e.target.checked,
            })
        }
    }

    const fillForm = async () => {
        const newFormData = await {
            Id: filterField.Id,
            EventFilteringOption: filterField.EventFilteringOption,
            ValuesContain: filterField.ValuesContain,
            ValuesEqualSelect: filterField.ValuesEqualSelect,
            Required: filterField.required
        }
        setFormData(newFormData)
    }

    //  feed into form whenever load finishes
    useEffect(() => {
        fillForm()
    }, [])

    return (
            <div className={styles.filterFieldsListContainer} key={index}>

            {!filterField.editable ?

                    <>
                        {/* <h3>{id}</h3>*/}
                    <h3>{filterField.objectName}</h3>
                    <h3>{filterField.columnName}</h3>
                    <h3>{filterField.required}</h3>
                    <h3>{filterField.eventFilteringOption}</h3>
                    <h3>{(filterField.valuesEqualSelect && filterField.valuesEqualSelect.length > 0 ? Array.isArray(filterField.valuesEqualSelect) ?
                        filterField.valuesEqualSelect.join(';') : filterField.valuesEqualSelect : filterField.valuesEqualSelect)}</h3>
                    <h3>{filterField.valuesContain}</h3>
                        <div className={styles.editButtonContainer}>
                        <button className={styles.editButton} onClick={e => editEF(e)} id={filterField.id} title="Edit filter.">
                            <i className={styles.buttonImage}>{pencil}</i>
                        </button>
                        <button className={styles.editButton} onClick={e => submitFieldDelete(e)} id={filterField.id} title="Remove saved filter values and conditional.">
                            <i className={styles.buttonImage}>{undo}</i>
                        </button>
                        </div>
                    </>

                    :

                    <>
                    <h3>{filterField.objectName}</h3>
                    <h3>{filterField.columnName}</h3>
                    <form>
                        <label key={filterField.id} title="Handles whether null entries are allowed (true filters out null/blank entries).">
                            <input
                                name="Required"
                                type="checkbox"
                                value={filterField.Required}
                                checked={filterField.Required}
                                onChange={e => handleSelectChange(e, filterField.id)}
                            />
                            Required
                        </label>
                            <h3>

                               <select
                                name="EventFilteringOption"
                                    id={styles.ERSListDropdown}
                                value={formData.EventFilteringOption}
                                onChange={e => handleSelectChange(e, filterField.id)}
                                >   
                                    <option value="" selected="selected">
                                        -- Select an option --
                                    </option>

                                    {
                                    filterOptions.map((efo, idx) => (
                                            <option key={idx}>{efo.name}</option>
                                        ))
                                    }
                                </select>
                            </h3>
                        {formData.EventFilteringOption === 'IncludeUseEqual' || formData.EventFilteringOption === 'ExcludeUseEqual' ?
                            <FilterEqualSelection index={index}
                                filterField={filteredByEFs[index]}
                                setFormData={setFormData}
                                formData={ formData}
                            >
                            </FilterEqualSelection>
                            : <></>
                        }

                        {formData.EventFilteringOption === 'IncludeUseContain' || formData.EventFilteringOption === 'ExcludeUseContain' ?
                            <FilterContainsSelection
                                formData={formData}
                                handleChange={handleChange}
                            >
                            </FilterContainsSelection>
                                
                                : <></>
                            }
                        <div className={styles.inputContainer}>
                            <button onClick={e => cancelEdit(e)} id={filterField.id} className={styles.editButtons} title="Cancel Edit">
                                <i className={styles.buttonImage} id={styles.editCancel}>
                                    {squareXmark}
                                </i>
                            </button>
                            <button onClick={e => submitEdit(e)} id={filterField.id} className={styles.editButtons} disabled={isEditInvalid()} title="Submit Edit">
                                <i className={styles.buttonImage} id={styles.editSubmit}>
                                    {squareCheck}
                                </i>
                            </button>
                        </div>
                        </form>
                    </>
                }
                
            </div>
             
    );
}

export default EventFilters;