import { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import * as siteManagementService from '../../../services/siteManagementService'
import ClientCategoryComponent from '../../../components/Home/ClientCategoryList/ClientCategoryComponent';
import styles from "./ClientCategoryList.module.css"

// Font Awesome assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSquareCheck, faSquareXmark } from '@fortawesome/free-solid-svg-icons'

// image elements
const pencil = <FontAwesomeIcon icon={faPencil} />
const squareCheck = <FontAwesomeIcon icon={faSquareCheck} />
const squareXmark = <FontAwesomeIcon icon={faSquareXmark} />

const ClientCategoryList = ({ user }) => {

    // state
    const [CCLO, setCategory] = useState([
        {
            id: null,
            name: null,
            description: null,
            type: "category",
            editable: false
        }
    ])

    const [formData, setFormData] = useState(
        {
            id: "",
            name: "",
            description: "",
            type: "category",
        }
    )

    const [formDataEdit, setFormDataEdit] = useState(
        {
            Id: "",
            Name: "",
            Description:"",
            Type:"category"
        }
    )

    const [message, setMessage] = useState([''])
    const [create, setCreate] = useState(false)
    const [createRefresh, setCreateRefresh] = useState(false)
    const [editRefresh, setEditRefresh] = useState(false)

    // useEffect
    useEffect(() => {
        const fetchData = async () => {
            const data = await siteManagementService.getAllCategories()
            const items = Array.from(data)
            for (let i = 0; i < items.length; i++) {
                items[i].editable = false;
            }
            setCategory(items)
            setCreateRefresh(false)
            setEditRefresh(false)
        }
        fetchData()
    }, [createRefresh, editRefresh])

    // functions

    const editCategory = e => {
        e.preventDefault()

        let divId = e.target.id
        const items = Array.from(CCLO)
        let filtered = items.filter(cclo => divId === cclo.id)[0]

        // Have to add this to maintain one edit at a time, since all will share formDataEdit for editing state
        const editableCheck = i => i.editable
        if (items.some(editableCheck)) return
        filtered.editable = true

        setCategory(items)
    }

    const cancelEdit = e => {
        e.preventDefault()

        let divId = e.target.id
        const items = Array.from(CCLO)
        let filtered = items.filter(cclo => divId === cclo.id)[0]
        filtered.editable = false

        setCategory(items)
        setFormDataEdit({
            Id: "",
            Name: "",
            Description: "",
            Type: "Category"
        })

    }

    const submitEdit = async e => {
        e.preventDefault()
        let divId = e.target.id
        const items = Array.from(CCLO)
        let filtered = items.filter(cclo => divId === cclo.id)[0]
        if (!formDataEdit.Name) {
            formDataEdit.Name = filtered.name
        }
        if (!formDataEdit.Description) {
            formDataEdit.Description = filtered.description
        }
        // cancel and return if edit is attempted with no name value or no description value, or if the same as before
        if ((!formDataEdit.Name || !formDataEdit.Description) || ((formDataEdit.Name && formDataEdit.Name == filtered.name)
            && (formDataEdit.Description && formDataEdit.Description == filtered.description))) {
            cancelEdit(e)
            return
        }

        // handle form data and connect to edit service in siteManagementService.js here
        try {
            await siteManagementService.editCategory(formDataEdit)
        } catch (err) {
            setMessage(err.message)
        }

        // reset editable state back to false
        //divId = e.target.id
        //items = Array.from(CCLO)
        //filtered = items.filter(cclo => divId === cclo.id)[0]
        filtered.editable = false
        setCategory(items)

        // setFormDataEdit back to blanks
        clearFormData()

        // Trigger re-render in useEffect dependency array
        setEditRefresh(true)
    }

    const clearFormData = useCallback(
        () => {
            setFormDataEdit({
                Id: "",
                Name: "",
                Description: "",
                Type:"Category"
            })
        },
        []
    )

    const createERS = e => {
        e.preventDefault()
        setCreate(create ? false : true)
    }

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleChangeEdit = (e, idNumber) => {

        let idReturn = idNumber

        setFormDataEdit({
            ...formDataEdit,
            Id: idReturn,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            await siteManagementService.createCategory(formData)
        } catch (err) {
            setMessage(err.message)
        }
        setCreate(false)
        setFormData({
            id: "",
            name: "",
            description: ""
        })
        setCreateRefresh(true)
    }

    const isFormInvalid = () => {
        return (!(formData.name) || !(formData.description))
    }

    const isEditInvalid = () => {
        return (!(formDataEdit.Name) && !(formDataEdit.Description))
    }

    return (
        <div className="page-area">
            <h1>Client Categories</h1>

            <button onClick={createERS}>
                {!create ? "Add New Category" : "Cancel"}
            </button>

            {
                create ?
                    <>
                        <div className={styles.formContainer}>
                            <form
                                autoComplete="off"
                                onSubmit={handleSubmit}
                                className={styles.container}
                            >

                                <div className={styles.inputContainer}>
                                    <label htmlFor="password" className={styles.label}>New Category Name</label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        value={formData.name}
                                        name="name"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <label htmlFor="password" className={styles.label}>New Description</label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        value={formData.description}
                                        name="description"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className={styles.inputContainer}>
                                    <button disabled={isFormInvalid()} className={styles.button}>
                                        Submit
                                    </button>
                                </div>

                            </form>

                        </div>
                    </>
                    :
                    <></>
            }

            <ul className={styles.CategoryList}>
                {CCLO.map(({ id, name, description, editable }, index) => (

                    <li>

                        <div className={styles.CategoryListOptions}>

                            {!editable ?

                                <>
                                    <div>
                                        {name}
                                        <br /><br />
                                        {description}
                                    </div>

                                    <button className={styles.editCategory} onClick={e => editCategory(e)} id={id}>
                                        <i className={styles.buttonImage}>{pencil}</i>
                                    </button>
                                </>

                                :

                                <>
                                    <div>
                                        <form className={styles.formEdit}>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                value={formDataEdit.Name}

                                                name="Name"
                                                placeholder={name}
                                                onChange={e => handleChangeEdit(e, id)}
                                                id={id}
                                            />
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                value={formDataEdit.Description}
                                                name="Description"
                                                placeholder={description}
                                                onChange={e => handleChangeEdit(e, id)}
                                                id={id}
                                            />
                                            <div className={styles.inputContainer}>
                                                <button onClick={e => cancelEdit(e)} id={id} className={styles.editButtons}>
                                                    <i className={styles.buttonImage} id={styles.editCancel}>
                                                        {squareXmark}
                                                    </i>
                                                </button>
                                                <button onClick={e => submitEdit(e)} id={id} className={styles.editButtons} disabled={isEditInvalid()}>
                                                    <i className={styles.buttonImage} id={styles.editSubmit}>
                                                        {squareCheck}
                                                    </i>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </>
                            }



                        </div>


                    </li>
                ))}
            </ul>

            {/*<ClientCategoryComponent />  */}
        </div>
    );
}

export default ClientCategoryList;