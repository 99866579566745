import { useState, useEffect } from 'react'
import styles from "../../../pages/Home/ClientConfig/ClientConfig.module.css"
import * as eventManagementService from '../../../services/eventManagementService'

const YourMembershipConfig = ({
    formData,
    YourMembershipClientConfig,
    setYourMembershipClientConfig,
}) => {

    const handleChange = e => {
        setYourMembershipClientConfig({
            ...YourMembershipClientConfig,
            [e.target.name]: e.target.value,
        })
    }
	const [error, setError] = useState(null)
    const errorDiv = (error)
        ?
        <ul>
            {Object.entries(error).length > 0 && Object.entries(error) &&
                Object.entries(error).map(([key, value]) => {
                    console.log("error: " + error)
                    return <div className={value == 'Test Failure' ? 'errorMSG' : 'successMSG'} key={key} >{value}</div>;
                })}
        </ul>
        : '';
    const handleTestConnection = async e => {
        e.preventDefault()
        setError(null)
        const holder = { YourMembershipClientConfig };
        try {
            await eventManagementService.testSetup(holder)
        } catch (err) {
            console.log(err.message)
			setError(err.internalError);
        }
    }

    const isFormValid = () => {
        return formData.ClientName
            && formData.EventRegistrationSystem
            && formData.Industry
            && formData.Categories.length > 0
            && YourMembershipClientConfig.YourMembershipClientID
            && YourMembershipClientConfig.UserType
            && YourMembershipClientConfig.UserName
            && YourMembershipClientConfig.Password
            && YourMembershipClientConfig.Url
            ? true
            : false
    }
    const isTestFormValid = () => {
        return YourMembershipClientConfig.YourMembershipClientID
            && YourMembershipClientConfig.UserType
            && YourMembershipClientConfig.UserName
            && YourMembershipClientConfig.Password
            && YourMembershipClientConfig.Url
            ? true
            : false
    }

    return (
        <>
            <div className={styles.inputContainer}>
                <label htmlFor="YourMembershipClientID" className={styles.label}>Client ID: </label>
                <input
                    type="number"
                    autoComplete="off"
                    value={YourMembershipClientConfig.YourMembershipClientID}
                    name="YourMembershipClientID"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="UserType" className={styles.label}>User Type: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={YourMembershipClientConfig.UserType}
                    name="UserType"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="UserName" className={styles.label}>Username: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={YourMembershipClientConfig.UserName}
                    name="UserName"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="Password" className={styles.label}>Password: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={YourMembershipClientConfig.Password}
                    name="Password"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="Url" className={styles.label}>URL: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={YourMembershipClientConfig.Url}
                    name="Url"
                    onChange={handleChange}
                />
            </div>
			{errorDiv}
            <div className={styles.inputContainer}>
                <button disabled={!isFormValid()} className={styles.button}>
                    Submit
                </button>
                <button onClick={handleTestConnection} disabled={!isTestFormValid()} className={styles.buttonRight}>
                    Test Connection
                </button>
            </div>
        </>
    );
}

export default YourMembershipConfig;