import { useState, useEffect, useRef } from 'react'

// Styling
import styles from '../../../pages/Badging/ExtraBadgeManager/ExtraBadgeManager.module.css'

const CVButton = ({
    ERO,
    index,
    selections,
    setSelections,
    isActive,
}) => {

    // useRef
    const selectedRef = useRef(false) // Used to prevent initial firing of useEffects on component render, since dependency arrays will have "state change" just by initially rendering child component

    // State
    const [selected, setSelected] = useState(false)
    const [selectTrigger, setSelectTrigger] = useState(false) // for useEffect dependency array --- isolated variable to maintain selected state integrity

    // Helper functions
    const handleFieldSelect = e => {
        e.preventDefault()
        setSelected(selected => !selected)
        setSelectTrigger(selectTrigger => !selectTrigger)
    }

    // useEffect - Ensures selections' state update timing to correctly adjust fields selected number
    useEffect(() => {
        if (selectedRef.current) {
            selected
                ? setSelections([...selections, ERO])
                : setSelections(selections.filter(field => field !== ERO))
        } else selectedRef.current = true
    }, [selectTrigger])

    // useEffect - Maintains selected state when user collapses section
    useEffect(() => {
        console.log("is this field found: ", selections.includes(ERO), "if false this is it")
        selections.includes(ERO)
            ? setSelected(true)
            : setSelected(false)
    }, [isActive])

    return (
        <>
            <button
                id={selected ? styles.EROHeaderSelected : styles.EROHeader}
                onClick={e => handleFieldSelect(e)}
                value={ERO}
                key={index}
            >
                {ERO}
            </button>
        </>
    );
}

export default CVButton;