/*---------- Deps, Hooks & CSS ----------*/
import { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import styles from "./KioskSetupStatusManager.module.css"

/*---------- Services ----------*/
import * as progressService from '../../../services/progressService'

/*---------- Components ----------*/
import Card from "../../../components/Home/StatusSubpages/Card"
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'
import ProgressSteps from '../../../components/Miscellaneous/ProgressSteps'

/*---------- Images ----------*/
import KioskSettings from "../../../components/Home/StatusSubpages/KioskSetupImages/KioskSettings.png"
import KioskEditScreen from "../../../components/Home/StatusSubpages/KioskSetupImages/KioskEditScreen.png"
import LandingPage from "../../../components/Home/StatusSubpages/KioskSetupImages/LandingPage.png"
import Scanner from "../../../components/Home/StatusSubpages/KioskSetupImages/Scanner.jpg"

const KioskSetupStatusManager = () => {

    const location = useLocation()
    const passedState = location.state
    console.log(passedState)

    /*---------- State ----------*/
    const [client, setClient] = useState(passedState.client)
    const [event, setEvent] = useState(passedState.event)
    const [clientEventObject, setClientEventObject] = useState({})
    const [loaded, setLoaded] = useState(false)

    // Required Setup
    const [kioskSetupProgress, setKioskSetupProgress] = useState("Loading...")
    const [editScreenProgress, setEditScreenProgress] = useState("Loading...")

    // Optional Setup
    const [landingPageProgress, setLandingPageProgress] = useState("Loading...")
    const [scannerPageProgress, setScannerPageProgress] = useState("Loading...")

    // Progress Steps
    const [requiredCompletionCount, setRequiredCompletionCount] = useState(1)
    const [optionalCompletionCount, setOptionalCompletionCount] = useState(1)

    /*---------- Constants ----------*/
    // General
    const clientName = passedState.client && passedState.client.clientName
        ? passedState.client.clientName
        : ""

    const eventName = passedState.event && passedState.event.name
        ? passedState.event.name
        : passedState.eventName
            ? passedState.eventName
            : ""

    const ERSName = passedState.event && passedState.event.eventRegistrationSystemOptionSmall.eventRegistrationSystem != undefined
        ? passedState.event.eventRegistrationSystemOptionSmall.eventRegistrationSystem
        : "ERS"

    const clientId = passedState.client && passedState.client.clientId !== undefined
        ? passedState.client.clientId
        : passedState.clientId
            ? passedState.clientId
            : ""

    const eventId = passedState.event && passedState.event.eventId
        ? passedState.event.eventId
        : passedState.eventId
            ? passedState.eventId
            : ""
    // Progress Steps 
    const requiredSteps = [
        { label: 'Kiosk Settings', step: 1 },
        { label: 'Edit Screen Setup', step: 2 },
    ]

    const optionalSteps = [
        { label: 'Landing Page Setup', step: 1 },
        { label: 'Scanner Page Setup', step: 2 },
    ]

    /*---------- Functions ----------*/
    // Step Calculations
    const checkRequiredCompletion = () => {
        if (kioskSetupProgress === "Success") setRequiredCompletionCount(count => count + 1)
        if (editScreenProgress === "Success") setRequiredCompletionCount(count => count + 1)
    }

    const checkOptionalCompletion = () => {
        if (landingPageProgress === "Success") setOptionalCompletionCount(count => count + 1)
        if (scannerPageProgress === "Success") setOptionalCompletionCount(count => count + 1)
    }

    const checkLoaded = () => {
        if (kioskSetupProgress !== "Loading..."
            && editScreenProgress !== "Loading..."
            && landingPageProgress !== "Loading..."
            && scannerPageProgress !== "Loading..."
        )
            setLoaded(true)
    }

    useEffect(() => {
        const fetchData = async () => {
            // Compile object for progress calls
            const buildClientEventObj = () => {
                let obj = {
                    ClientId: clientId,
                    EventId: eventId,
                }

                setClientEventObject(obj)
                return obj
            }

            const clientEventObj = await buildClientEventObj()

            // Kiosk Setup Progress
            const kioskProgress = await progressService.getKioskSettingProgress(clientEventObj)
            console.log(kioskProgress)
            await setKioskSetupProgress(kioskProgress)

            // Edit Screen Progress
            const editScreenStatus = await progressService.getEditScreenProgress(clientEventObj)
            console.log(editScreenStatus)
            await setEditScreenProgress(editScreenStatus)

            // Landing Page Progress
            const landingProgress = await progressService.getLandingPageProgress(clientEventObj)
            console.log(landingProgress)
            await setLandingPageProgress(landingProgress)

            // Scanner Page Progress
            const scannerProgress = await progressService.getScannerPageProgress(clientEventObj)
            console.log(scannerProgress)
            await setScannerPageProgress(scannerProgress)
        }
        fetchData()
    }, [])

    useEffect(() => {
        checkLoaded()
        checkRequiredCompletion()
        checkOptionalCompletion()
    }, [scannerPageProgress])

    return (
        <>
            {
                loaded ?
                    <div id={styles.statusSubpage}>
                        <h1>{passedState.client && passedState.client.clientName !== undefined ? "Status Manager - Kiosk Setup" : ""}</h1>
                        <h2 id={styles.CEtitle}>
                            {passedState.client && passedState.client.clientName
                                ? passedState.client.clientName
                                : ""
                            }
                            {" "}
                            {passedState.event && passedState.event.eventName
                                ? passedState.event.eventName
                                : ""
                            }
                        </h2>

                        <div className={styles.requiredSetupHeader}>
                            <h2 className={styles.requiredSetupHeading}>Required Setup</h2>
                            <ProgressSteps
                                steps={requiredSteps}
                                activeStep={requiredCompletionCount}
                            />
                        </div>

                        <div className={styles.container}>
                            <Card
                                dataImage={KioskSettings}
                                header="Kiosk Settings"
                                content={`Configure kiosk settings for the ${eventName}`}
                                styles={styles}
                                linkName={`/KioskSetup`}
                                linkState={passedState.event}
                                disabledBool={false} // hardcoded as it's the first required step of an event --- can't be disabled
                            />
                            <Card
                                dataImage={KioskEditScreen}
                                header="Edit Screen Setup"
                                content={`Manage kiosk edit screen settings for the ${eventName}`}
                                styles={styles}
                                linkName={`/KioskEditScreenLayout`}
                                linkState={passedState.event}
                                disabledBool={kioskSetupProgress !== "Success" ? true : false}
                            />
                        </div>

                        <div className={styles.optionalSetupHeader}>
                            <h2 className={styles.optionalSetupHeading}>Optional Setup</h2>
                            <ProgressSteps
                                steps={optionalSteps}
                                activeStep={optionalCompletionCount}
                            />
                        </div>

                        <div className={styles.container}>
                            <Card
                                dataImage={LandingPage}
                                header="Landing Page Setup"
                                content={`Configure kiosk landing page for the ${eventName}`}
                                styles={styles}
                                linkName={`/KioskLandingPageSetup`}
                                linkState={passedState.event}
                                disabledBool={editScreenProgress !== "Success" ? true : false}
                            />
                            <Card
                                dataImage={Scanner}
                                header="Scanner Setup"
                                content={`Attach an external or third-party barcode scanner to the kiosk`}
                                styles={styles}
                                linkName={`/KioskScannerPageSetup`} // There's no page for this yet
                                linkState={passedState.event}
                                disabledBool={editScreenProgress !== "Success" ? true : false}
                            />
                        </div>
                    </div>
                    :
                    <LoadingSpinner />
            }
        </>
    );
}

export default KioskSetupStatusManager;