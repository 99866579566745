/*---------- Deps, Hooks & CSS ----------*/
import { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import styles from "./EventSetupStatusManager.module.css"

/*---------- Services ----------*/
import * as progressService from '../../../services/progressService'

/*---------- Components ----------*/
import Card from "../../../components/Home/StatusSubpages/Card"
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'
import ProgressSteps from '../../../components/Miscellaneous/ProgressSteps'

/*---------- Images ----------*/
import LoadRegistrants from "../../../components/Home/StatusSubpages/EventSetupImages/RegistrantsLoaded.jpg"
import DataFiltering from "../../../components/Home/StatusSubpages/EventSetupImages/dataFiltering.jpg"
import DataTable from "../../../components/Home/StatusSubpages/EventSetupImages/dataTable.jpg"
import Webhook from "../../../components/Home/StatusSubpages/EventSetupImages/Webhook.png"
import PostToERS from "../../../components/Home/StatusSubpages/EventSetupImages/PostToERS.jpg"

const EventSetupStatusManager = () => {

    const location = useLocation()
    const passedState = location.state
    console.log(passedState)

    /*---------- State ----------*/
    const [client, setClient] = useState(
        passedState.client
            ? passedState.client
            : passedState.clientId
                ? passedState.clientId
                : ""
    ) 
    const [event, setEvent] = useState(
        passedState.event
            ? passedState.event
            : passedState.eventId
                ? passedState.eventId
                : ""
    ) 
    const [clientEventObject, setClientEventObject] = useState({})
    const [loaded, setLoaded] = useState(false)

    // Required Setup
    const [registrantsLoadedProgress, setRegistrantsLoadedProgress] = useState("Loading...")
    const [eventFilteringProgress, setEventFilteringProgress] = useState("Loading...")
    const [viewGenerationProgress, setViewGenerationProgress] = useState("Loading...")

    // Optional Setup
    const [webhookSetupProgress, setWebhookSetupProgress] = useState("Loading...")
    const [postToERSSetupProgress, setPostToERSSetupProgress] = useState("Loading...")

    // Progress Steps
    const [requiredCompletionCount, setRequiredCompletionCount] = useState(1)
    const [optionalCompletionCount, setOptionalCompletionCount] = useState(1)

    /*---------- Constants ----------*/
    // General
    const clientName = passedState.client && passedState.client.clientName
        ? passedState.client.clientName
        : ""

    const eventName = passedState.event && passedState.event.name
        ? passedState.event.name
        : passedState.eventName
            ? passedState.eventName
            : ""

    const ERSName = passedState.event && passedState.event.eventRegistrationSystemOptionSmall.eventRegistrationSystem != undefined
        ? passedState.event.eventRegistrationSystemOptionSmall.eventRegistrationSystem
        : "ERS"

    const clientId = passedState.client && passedState.client.clientId !== undefined
        ? passedState.client.clientId
        : passedState.clientId
            ? passedState.clientId
            : ""

    const eventId = passedState.event && passedState.event.eventId
        ? passedState.event.eventId
        : passedState.eventId
            ? passedState.eventId
            : ""

    // Progress Steps 
    const requiredSteps = [
        { label: 'Load Event Registrants', step: 1 },
        { label: 'Event Filtering', step: 2 },
        { label: 'View Generation', step: 3 },
    ]

    const optionalSteps = [
        { label: 'Webhook Setup', step: 1 },
        { label: 'ERS Posting', step: 2 },
    ]

    /*---------- Functions ----------*/
    // Step Calculations
    const checkRequiredCompletion = () => {
        if (registrantsLoadedProgress === "Success") setRequiredCompletionCount(count => count + 1)
        if (eventFilteringProgress === "Success") setRequiredCompletionCount(count => count + 1)
        if (viewGenerationProgress === "Success") setRequiredCompletionCount(count => count + 1)
    }

    const checkOptionalCompletion = () => {
        if (webhookSetupProgress === "Success") setOptionalCompletionCount(count => count + 1)
        if (postToERSSetupProgress === "Success") setOptionalCompletionCount(count => count + 1)
    }

    const checkLoaded = () => {
        if (registrantsLoadedProgress !== "Loading..."
            && eventFilteringProgress !== "Loading..."
            && viewGenerationProgress !== "Loading..."
            && webhookSetupProgress !== "Loading..."
            && postToERSSetupProgress !== "Loading..."
        )
            setLoaded(true)
    }

    useEffect(() => {
        const fetchData = async () => {
            // Compile object for progress calls
            const buildClientEventObj = () => {
                let obj = {
                    ClientId: clientId,
                    EventId: eventId,
                }

                setClientEventObject(obj)
                return obj
            }

            const clientEventObj = await buildClientEventObj()

            // Registrants Loaded Progress
            const registrantsProgress = await progressService.getRegistrantsLoadedProgress(clientEventObj)
            console.log(registrantsProgress)
            await setRegistrantsLoadedProgress(registrantsProgress)

            // Event Filtering Progress
            const filteringProgress = await progressService.getEventFilteringProgress(clientEventObj)
            console.log(filteringProgress)
            await setEventFilteringProgress(filteringProgress)

            // View Generation Progress
            const viewProgress = await progressService.getViewGeneratedProgress(clientEventObj)
            console.log(viewProgress)
            await setViewGenerationProgress(viewProgress)

            // Webhook Setup Progress
            const webhookProgress = await progressService.getWebhookSetupProgress(clientEventObj)
            console.log(webhookProgress)
            await setWebhookSetupProgress(webhookProgress)

            // Post to ERS Progress
            const postToERSProgress = await progressService.getPostToERSProgress(clientEventObj)
            console.log(postToERSProgress)
            await setPostToERSSetupProgress(postToERSProgress)
        }
        fetchData()
    }, [])

    useEffect(() => {
        checkLoaded()
        checkRequiredCompletion()
        checkOptionalCompletion()
    }, [postToERSSetupProgress])
    
    return (
        <>
        {
                loaded ?                
                    <div id={styles.statusSubpage}>
                        <h1>{passedState.client && passedState.client.clientName !== undefined ? "Status Manager - Event Setup" : ""}</h1>
                        <h2 id={styles.CEtitle}>
                            { passedState.client && passedState.client.clientName
                                ? passedState.client.clientName
                                : ""
                            }
                            {" "}
                            { passedState.event && passedState.event.eventName
                                ? passedState.event.eventName
                                : ""
                            }
                        </h2>

                        <div className={styles.requiredSetupHeader}>
                            <h2 className={styles.requiredSetupHeading}>Required Setup</h2>
                            <ProgressSteps
                                steps={requiredSteps}
                                activeStep={requiredCompletionCount}
                            />
                        </div>

                        <div className={styles.container}>
                            <Card
                                dataImage={LoadRegistrants}
                                header="Load Event Registrants"
                                content={`Load registrants for the ${eventName}`}
                                styles={styles}
                                linkName={`/EventLoad/${eventId}`}
                                linkState={passedState.event}
                                disabledBool={false} // hardcoded as it's the first required step of an event --- can't be disabled
                            />
                            <Card
                                dataImage={DataFiltering}
                                header="Event Filtering"
                                content={`Filter data from ${ERSName} for the ${eventName}`}
                                styles={styles}
                                linkName={`/EventSetting/EventFilterList`}
                                linkState={passedState.event}
                                disabledBool={registrantsLoadedProgress !== "Success" ? true : false}
                            />
                            <Card
                                dataImage={DataTable}
                                header="View Generation"
                                content={`Configure filtered attendee data structure for the ${eventName}`}
                                styles={styles}
                                linkName={`/ViewGenerate`}
                                linkState={passedState.event}
                                disabledBool={eventFilteringProgress !== "Success" ? true : false}
                            />
                        </div>

                        <div className={styles.optionalSetupHeader}>
                            <h2 className={styles.optionalSetupHeading}>Optional Setup</h2>
                            <ProgressSteps
                                steps={optionalSteps}
                                activeStep={optionalCompletionCount}
                            />
                        </div>

                        <div className={styles.container}>
                            <Card
                                dataImage={Webhook}
                                header="Webhook Setup"
                                content={`Setup webhook data transferal for ${eventName}`}
                                styles={styles}
                                linkName={`/EventSetting/WebHookList`}
                                linkState={passedState.event}
                                disabledBool={viewGenerationProgress !== "Success" ? true : false}
                            />
                            <Card
                                dataImage={PostToERS}
                                header="ERS Posting"
                                content={`Configure PostToERS capabilities for the ${eventName}`}
                                styles={styles}
                                linkName={`/EventLoad/${eventId}`} // There's no page for this yet
                                linkState={passedState.event}
                                disabledBool={webhookSetupProgress !== "Success" ? true : false}
                            />
                        </div>
                    </div>
            :
                <LoadingSpinner />
        }
        </>
    );
}

export default EventSetupStatusManager;