import { useState, useEffect } from 'react'
import styles from "../../../pages/Badging/BadgeQrCodeManager/BadgeQrCodeManager.module.css"

const CustomFormula = ({
    formData,
    qrCodeCustomFormulaForm,
    setQrCodeCustomFormula,
}) => {

    const handleChange = e => {
        setQrCodeCustomFormula({
            ...qrCodeCustomFormulaForm,
            [e.target.name]: e.target.value,
        })
        console.log(qrCodeCustomFormulaForm)
    }

    const isFormValid = () => {
        return (formData.qrChoice === "Custom"
            && qrCodeCustomFormulaForm.qrCodeFormula
            ? true
            : false)
    }

    return (
        <>
            <div className={styles.inputContainer}>
                <h2>
                    Reliant on CSSField names, to be replaced - encapsulated with % tag (%CSSFieldName%).<br /> <br />
                </h2>
                <h3>
                    <ul>Examples of simple:</ul>
                    <li>RegistrationId=%ScannerField% </li>
                    <li>%ScannerField% </li>
                    <br />
                    <ul>Examples of complex:</ul>
                    <li>%ScannerField%\u001E\u001F123\u001F%FirstName%\u001F%LastName%\u001F%Organization%\u001F </li>
                    <li>RegistrationId:%ScannerField%FirstName:%FirstName%LastName:%LastName%Organization:%Organization%\u001F </li>
                </h3>
                
            </div>
            <div className={styles.inputContainer} style={{
                width: '80%',
            }}>
                <label htmlFor="qrCodeFormula" className={styles.label}>Custom Formula: </label>
                <input
                    style={{
                        width: '50%',
                    }}
                    type="text"
                    autoComplete="off"
                    value={qrCodeCustomFormulaForm.qrCodeFormula}
                    name="qrCodeFormula"
                    onChange={handleChange}
                />
            </div>
            <div className={styles.inputContainer}>
                <button disabled={!isFormValid()} className={styles.button}>
                    Submit
                </button>
            </div>
        </>
    );
}

export default CustomFormula;