import styles from '../../../pages/Badging/AdminPrint/AdminPrint.module.css'
// components
import { AsyncPaginate } from 'react-select-async-paginate';

const RegistrationId = ({ loadOptions, onChange, onInputChange }) => {

    return (
        <div className={styles.inputContainer}>
            <AsyncPaginate
                //value={searchTerm} // don't use with loadOptions, one or other
                loadOptions={loadOptions}//activates selections
                //getOptionValue={option => option.value}
                //getOptionLabel={option => option.label}
                onInputChange={onInputChange} // detects typing entry
                onChange={onChange} // option selected
                isClearable={true}
                isSearchable={true}
                placeholder={'Enter registration id'}
            /*additional={{
                page: 0,
            }}*/
            />
        </div>
    );
}

export default RegistrationId;