import * as tokenService from './tokenService'
const BASE_URL = `/Clientmanagement`

export const createClient = async (client) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AddClientSetting`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(client),
        })
        return await res.json()
    } catch (error) {
        console.log(error)
		throw error
    }
}

export const getAllClients = async () => {
    const token = tokenService.getToken()
    console.log(token)
    try {
        const res = await fetch(`${BASE_URL}/GetClientsAll`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getClients = async () => {
    const token = tokenService.getToken()
    console.log(token)
    try {
        const res = await fetch(`${BASE_URL}/GetClientsList`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getClientSetting = async (client) => {
    const token = tokenService.getToken()
    console.log(token)
    try {
        const res = await fetch(`${BASE_URL}/GetClientSetting2`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(client),
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

// commit this page