import { useState, useEffect } from 'react'
import styles from "../../../pages/Home/ClientConfig/ClientConfig.module.css"
import * as eventManagementService from '../../../services/eventManagementService'

const StovaConfig = ({
    formData,
    stovaClientConfig,
    setStovaClientConfig,
}) => {

    const handleChange = e => {
        setStovaClientConfig({
            ...stovaClientConfig,
            [e.target.name]: e.target.value,
        })
        console.log(stovaClientConfig)
    }
	const [error, setError] = useState(null)
    const errorDiv = (error)
        ?
        <ul>
            {Object.entries(error).length > 0 && Object.entries(error) &&
                Object.entries(error).map(([key, value]) => {
                    console.log("error: " + error)
                    return <div className={value == 'Test Failure' ? 'errorMSG' : 'successMSG'} key={key} >{value}</div>;
                })}
        </ul>
        : '';
    const handleTestConnection = async e => {
    	setError(null)
        e.preventDefault()
        var holder = { stovaClientConfig };
        try {
            await eventManagementService.testSetup(holder)
        } catch (err) {
            console.log(err.message)
			setError(err.internalError);
        }
    }

    const isFormValid = () => {
        return formData.ClientName
            && formData.EventRegistrationSystem
            && formData.Industry
            && formData.Categories.length > 0
            && stovaClientConfig.AccountId
            && stovaClientConfig.Key
            ? true
            : false
    }
    const isTestFormValid = () => {
        return stovaClientConfig.AccountId
            && stovaClientConfig.Key
            ? true
            : false
    }

    return (
        <>
            <div className={styles.inputContainer}>
                <label htmlFor="AccountId" className={styles.label}>Account Id: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={stovaClientConfig.AccountId}
                    name="AccountId"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="Key" className={styles.label}>Key: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={stovaClientConfig.Key}
                    name="Key"
                    onChange={handleChange}
                />
            </div>
			{errorDiv}
            <div className={styles.inputContainer}>
                <button disabled={!isFormValid()} className={styles.button}>
                    Submit
                </button>
                <button onClick={handleTestConnection} disabled={!isTestFormValid()} className={styles.buttonRight}>
                    Test Connection
                </button>
            </div>
        </>
    );
}

export default StovaConfig;