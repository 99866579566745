import { useState, useEffect } from 'react'

// Styling
import styles from '../../../pages/Badging/ExtraBadgeManager/ExtraBadgeManager.module.css'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-solid-svg-icons'
import CVButton from './CVButton'

// ------ Image Elements ------ //
const plus = <FontAwesomeIcon icon={faSquarePlus} />
const minus = <FontAwesomeIcon icon={faSquareMinus} />

// ------ Components ------ //


const ConditionalValueSelector = ({
    savedSelections,
    availableSelections,
    compileSelections,
}) => {

    const [isActive, setIsActive] = useState(false)
    const [selections, setSelections] = useState([])

    const length = selections.length

    useEffect(() => {
        compileSelections(selections)
    }, [length])

    useEffect(() => {
        if (savedSelections.length) {
            console.log("selected fields are", savedSelections)
            setSelections(savedSelections)
            /*compileSelections(selectedFields)*/
        }
    }, [])

    return (
        <>
            <div className={styles.badgingContainerSection} id={styles.EROIndex}>

                <div className={styles.badgeField} onClick={() => setIsActive(!isActive)}>
                    ({length > 0 ? length + " fields" : "None selected"}) {isActive ? minus : plus}
                </div>

                {isActive &&
                    <div className={styles.EROContainer}>

                        {
                            availableSelections.length ?
                                availableSelections
                                    .sort((a, b) => a > b ? 1 : -1)
                                    .map((ERO, index) => (
                                        <CVButton
                                            index={index}
                                            ERO={ERO}
                                            selections={selections}
                                            setSelections={setSelections}
                                            isActive={isActive}
                                        />
                                    ))
                                :
                                <h2>
                                    No Available Data
                                </h2>
                        }

                    </div>
                }

            </div>
        </>
    );
}

export default ConditionalValueSelector;