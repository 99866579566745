import styles from './LoadingSpinner.module.css'

const LoadingSpinner = () => {
  return (
      <div id={styles.pageBackground}>
          <div className={styles.spinContainer}>
              <div className={styles.spin} id={styles.loader}></div>
              <div className={styles.spin} id={styles.loader2}></div>
              <div className={styles.spin} id={styles.loader3}></div>
              <div className={styles.spin} id={styles.loader4}></div>
              <span id={styles.text}>LOADING...</span>
          </div>
      </div>
  );
}

export default LoadingSpinner;