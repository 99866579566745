import React from 'react';
import ResetPasswordForm from '../../../components/Home/ResetPasswordForm/ResetPasswordForm'

const ResetPassword = () => {
    return (
        <>
            <ResetPasswordForm />
        </>
  );
}

export default ResetPassword;