import * as tokenService from './tokenService'
const BASE_URL = `/Sitemanagement`

export const getAll = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/GetERSAll`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const create = async (ERS) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AddUpdateERSRecord`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(ERS),
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const edit = async (ERS) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AddUpdateERSRecord`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(ERS),
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}
export const editAll = async (ERS) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/UpdateERSAllRecords`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(ERS),
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}
export const createCategory = async (Category) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AddUpdateCICRecord`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(Category),
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const editCategory = async (Category) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AddUpdateCICRecord`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(Category),
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getAllCategories = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/GetAllCategories`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getAllIndustries = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/GetAllIndustries`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getAllSiteFields = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/GetAllSiteFields`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const saveSiteFields = async (SiteFieldList) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveSiteFields`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: '{"fields":' + JSON.stringify(SiteFieldList) + '}'
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}
export const saveSiteField = async (SiteField) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveSiteField`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: '{"field":' + JSON.stringify(SiteField) + '}'
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getCountries = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/GetAllCountries`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getStates = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/GetAllStates`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
