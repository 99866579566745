import { useState, useEffect } from 'react'
import styles from '../../../pages/Kiosk/KioskEdit/KioskEdit.module.css'

const EditField = ({
    option,
    states,
    countries,
    handleChange,
}) => {
    return (
        <>
            {option.cssFieldName === 'Country' && countries.some(item => item.countryName === option.fieldValue) ? 
                    <select
                    name='country'
                        value={option.fieldValue}
                    onChange={handleChange}
                    key='country'
                    >
                        <option disabled={true} value="" selected="selected">
                            -- Select an option --
                        </option>
                        {
                            countries.map((ci, idx) => (
                                <option key={idx}>{ci.countryName}</option>
                            ))
                        }
                    </select>
                : option.cssFieldName === 'State' && states.some(item => item.stateName === option.fieldValue) ? 
                    <select
                        name='state'
                        value={option.fieldValue}
                        onChange={handleChange}
                        key='state'
                    >
                        <option disabled={true} value="" selected="selected">
                            -- Select an option --
                        </option>
                        {
                            states.map((ci, idx) => (
                                <option key={idx}>{ci.stateName}</option>
                            ))
                        }
                    </select>
                    : <input
                        className={styles.fillTable}
                        type="text"
                        autoComplete="off"
                        id={option.fieldLabel}
                        value={option.fieldValue}
                        name={option.fieldLabel}
                        onChange={handleChange}
                        key={option.fieldLabel}
                    />
            }

        </>
        
    );
}

export default EditField;