import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import styles from './BadgeToERSPropertyMapping.module.css'

// ------ Components ------ //
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'
import BadgeField from '../../../components/Badging/BadgeToERSPropertyMapping/BadgeField'
import ContinueButton from '../../../components/Badging/BadgeToERSPropertyMapping/ContinueButton'
import SearchBar from "../../../components/Miscellaneous/SearchBar"

// ------ Services ------ //
import * as badgeService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'

const BadgeToERSPropertyMapping = () => {

    // State
    const [badgeFields, setBadgeFields] = useState([])
    const [EROs, setEROs] = useState([])
    const [view, setView] = useState([])
    const [savedTemplate, setSavedTemplate] = useState([])
    const [fieldSelections, setFieldSelections] = useState([]) // compiled grouping of all selections
    const [loaded, setLoaded] = useState(false)
    const [badgeFilterSearchTerm, setBadgeFilterSearchTerm] = useState("")
    const [isFieldNameCleared, setIsFieldNameCleared] = useState(false)
    const [continueButtonVisibility, setContinueButtonVisibility] = useState(false)
    const location = useLocation()
    const passedState = location.state
    // Continue Button - Constants & State
    const requiredBadgeFields = "FirstName, LastName, Organization, RegistrationType, City, State, Country"
    const [requiredFieldsRemaining, setRequiredFieldsRemaining] = useState([requiredBadgeFields.split(', ')])

    // useLocation & useNavigate --- Client state from previous page
    const navigate = useNavigate()
    const [clientEventInfo, setClientEventInfo] = useState({})
    /* -------- Functions -------- */
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }
    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (500)) // run events fetch every .5 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
            setLoaded(false)
        }
    }
    // --- REST --- //

    const createViewReqBody = () => {

        const reqBodyData = {
            ClientId: clientEventInfo.clientId,
            EventId: clientEventInfo.eventId
        }

        return reqBodyData
    }

    //const fetchERO = async body => {
    //    console.log("EROReqBody is: ", body)
    //    const EROsData = await badgeService.loadEventObjects(body)
    //    console.log("EROS are: ", EROsData)
    //    setEROs(EROsData)
    //}

    const fetchView = async body => {
        console.log("EROReqBody is: ", body)
        const viewRequestJSON = await badgeService.getView(body)
        console.log(viewRequestJSON)
        if (viewRequestJSON && viewRequestJSON !== 'None Found') {
            setEROs(viewRequestJSON)
        }
        else {
            // shutdown loader
            setLoaded(true)
        }
    }

    const handleBadgeFieldFetch = obj => {
        const arr = []
        obj.forEach(field => {
            const item = field.name
            arr.push(item)
        })
        return arr
    }

    const handleBadgeFieldFetchFromSave = obj => {
        const res = {}
        obj.forEach(field => {
            const item = field.name
            const selections = field.selectedFields

            if ((selections !== undefined && selections !== null) && selections.length) {
                res[item] = selections
            } else {
                res[item] = []
            }
        })
        return res
    }

    const grabSelectionsFromSaved = obj => {
        let arr = []
        obj.forEach(field => {
            const item = field.selectedFields
            arr.push(item)
        })
        return arr
    }

    const buildSelectionsObj = arr => {
        const res = {}

        arr.forEach(item => {
            res[item] = []
        })

        return res
    }

    const compileSelections = (fieldName, selectionsArr) => {
        setFieldSelections({
            ...fieldSelections,
            [fieldName]: selectionsArr
        })
        console.log("selections are: ", selectionsArr)
    }

    const handleSaveAndNavigate = async () => {
        const fieldsSave = await createSaveRequest()
        await badgeService.saveBadgeTemplateFieldSelections(fieldsSave)
        navigate(`/RegTypeMapping`, { state: { clientEventInfo } })
    }

    const createSaveRequest = () => {
        let templateFieldsHolder = []

        for (const [key, value] of Object.entries(fieldSelections)) {
            if (value.length > 0) {
                let fieldEntry = {
                    CSSFieldName: key,
                    SelectedFields: value
                }
                templateFieldsHolder.push(fieldEntry)
            }

        }

        const fieldsSave = {
            ClientId: clientEventInfo.clientId,
            EventId: clientEventInfo.eventId,
            templateFields: templateFieldsHolder
        }

        return fieldsSave
    }

    const handleContinueButtonVisiblity = () => {
        fieldSelections.FirstName.length
            && fieldSelections.LastName.length
            && fieldSelections.Organization.length
            && fieldSelections.RegistrationType.length
            && (fieldSelections.City.length
                || fieldSelections.State.length
                || fieldSelections.Country.length)
            ? setContinueButtonVisibility(true)
            : setContinueButtonVisibility(false)
    }

    //const handleRequiredFieldDisplayObj = (field, selections) => {
    //    if (requiredBadgeFields.includes(field)) {
    //        if (selections.length) {
    //            setRequiredFieldsRemaining(requiredFieldsRemaining.filter(item => item !== field))
    //        }
    //        else {
    //            setRequiredFieldsRemaining([...selections, field])
    //        }
    //    }
    //}

    // ------ Styling Form: Badge Field Search ------ //
    const badgeFieldFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        badgeFields.forEach((field) => {
            const name = field.toLowerCase()

            if (!filterString || name.includes(filterString)) {
                filtered.push(field)
            }
        })
        return filtered
    }

    const filteredBadgeFields = badgeFieldFilter(badgeFilterSearchTerm) // Breaks field selection when cleared

    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId) {
            const fetchData = async () => {
                //fetchERO(reqBody)
                const badgeFieldsFetch = await badgeService.getEventBadgeFieldsWithSelected(createRequestBody())
                const formattedBadgeFields = await handleBadgeFieldFetch(badgeFieldsFetch)
                const fieldSelectionObj = await handleBadgeFieldFetchFromSave(badgeFieldsFetch)
                if (badgeFieldsFetch) {
                    const filtered = badgeFieldsFetch.filter(badgeFields => !badgeFields.name.toLowerCase().includes('_combo') &&
                        !badgeFields.name.toLowerCase().includes('smaller') &&
                        !badgeFields.name.toLowerCase().includes('smallest'));
                    setSavedTemplate(filtered) // set saved template with selections
                }
                if (formattedBadgeFields) {
                    const filtered = formattedBadgeFields.filter(badgeFields => !badgeFields.toLowerCase().includes('_combo') &&
                        !badgeFields.toLowerCase().includes('smaller') &&
                        !badgeFields.toLowerCase().includes('smallest'));
                    setBadgeFields(filtered) // set badge fields list
                } 
              
                setFieldSelections(fieldSelectionObj) // set field selections object
                const viewReqBody = await createViewReqBody()
                fetchView(viewReqBody)
            }
            fetchData()
        }
       
    }, [clientEventInfo])

    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
            else {
                setLoaded(true)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])
    const createRequestBody = () => {

        const requestBody =
        {
            "clientId": `${clientEventInfo.clientId}`,
            "eventId": `${clientEventInfo.eventId}`
        };

        return requestBody;
    }

    // Update loaded state when data load completes
    useEffect(() => {
        if (EROs.length) {
            const viewArray = Object.keys(EROs[0])
            setView(viewArray)
        }
    }, [EROs])

    useEffect(() => {
        if (view.length) setLoaded(true)
    }, [view])

    useEffect(() => {
        if (badgeFields.length) handleContinueButtonVisiblity()
    }, [fieldSelections])

    return (
        <div id={styles.createBadgeArea}>
            {
                loaded ?
                    <>
                        <h1>Badge to ERS Property Mapping</h1>

                        <button className={styles.CBTbuttons} id={styles.CBTBack} onClick={() => navigate(-1)}>
                            Back
                        </button>
                        <ContinueButton
                            continueButtonVisibility={continueButtonVisibility}
                            requiredBadgeFields={requiredBadgeFields}
                            handleSaveAndNavigate={handleSaveAndNavigate}
                            styles={styles}
                        />

                        {view.length ? <h4 id={styles.EROInstructions}>Select fields to add:</h4> : <>Unable to find view entries</>}

                        <div id={styles.badgingContainer}>

                            {/*<SearchBar*/}
                            {/*    searchName={badgeFilterSearchTerm}*/}
                            {/*    handleSearchName={setBadgeFilterSearchTerm}*/}
                            {/*    placeholder="Field Name"*/}
                            {/*    label="Name"*/}
                            {/*    isCleared={isFieldNameCleared}*/}
                            {/*    setIsCleared={setIsFieldNameCleared}*/}
                            {/*    styles={styles}*/}
                            {/*    includeClearButton={true}*/}
                            {/*/>*/}

                            {
                                savedTemplate.length ?
                                    savedTemplate
                                        .map(({ id, name, selectedFields }) => (
                                            <BadgeField
                                                key={id}
                                                field={name}
                                                selectedFields={selectedFields || []}
                                                EROs={view}
                                                compileSelections={compileSelections}
                                            /*handleRequiredFieldDisplayObj={handleRequiredFieldDisplayObj}*/
                                            />
                                        ))
                                    :
                                    <h2>
                                        No Badge Fields
                                    </h2>
                            }
                        </div>

                        <button className={styles.CBTbuttons} id={styles.CBTBack} onClick={() => navigate(-1)}>
                            Back
                        </button>
                        <ContinueButton
                            continueButtonVisibility={continueButtonVisibility}
                            requiredBadgeFields={requiredBadgeFields}
                            handleSaveAndNavigate={handleSaveAndNavigate}
                            styles={styles}
                        />

                    </>
                    :
                    <LoadingSpinner />
            }
        </div>
    );
}

export default BadgeToERSPropertyMapping;