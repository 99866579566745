import { useState, useEffect, useRef } from 'react'
import { useLocation, useParams, Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import styles from './EventLoad.module.css'
import { AsyncPaginate } from 'react-select-async-paginate';
// ------ Services ------ //
import * as eventService from '../../../services/eventManagementService'
import * as clientEventService from '../../../services/clientEventService'
import * as kioskService from '../../../services/kioskService'

const EventLoad = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const passedState = location.state
    const [loadEventsObj, setLoadEventsObj] = useState({})
    const [selectedRegistrant, setSelectedRegistrant] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [reloadSelectionOptions, setReloadSelectionOptions] = useState([])
    const [reloadNameSelected, setReloadNameSelected] = useState("")
    const [reloadValueSelected, setReloadValueSelected] = useState(0)
    const [reloadLazyLoadSelected, setReloadLazyLoadSelected] = useState(true)
    const [reloadEventRequestBody, setReloadEventRequestBody] = useState({})
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }

    let reloadOptionSelectedIndex 
    const reloadAttendees = async e => {
        e.preventDefault()

        const appended = appendReloadOption()

        try {
            eventService.reloadEvent(appended)
        } catch (err) {
            console.log(err)
        }
        navigate(-1); // navigates back
    }
    const reloadAttendee = async e => {
        e.preventDefault()

        const appended = appendReloadOptionInd()

        try {
            eventService.reloadIndividual(appended)
        } catch (err) {
            console.log(err)
        }
        navigate(-1); // navigates back
    }

    const fetchRegistrantsReloadSelections = async () => {
        const registrantsReloadSelections = await eventService.getRegistrantReloadSelections()
        setReloadSelectionOptions(registrantsReloadSelections)

    }

    const fetchEventDetials = async () => {
        const eventDetail = await eventService.getEvent(appendEventRetrieval())
        setLoadEventsObj(eventDetail)
        // load event data reload status items
        fetchRegistrantsReloadSelections();

    }
    const appendEventRetrieval = () => {
        let result = {}
        result = {
            clientId: clientEventInfo.clientId,
            eventid: clientEventInfo.eventId,
        }
        return result
    }
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId && clientEventInfo.clientId !== undefined) {
            fetchEventDetials();
        }
    }, [clientEventInfo])
    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }
        document.getElementById('fullCheck').checked = true;
    }, [])

    const appendReloadOption = () => {
        let result = {}
        result = {
            clientId: loadEventsObj.clientId,
            eventid: loadEventsObj.eventId,
            RegistrantDataReloadSelections: reloadValueSelected,
            UseLazyLoad: reloadLazyLoadSelected,
        }
        return result
    }
    const appendReloadOptionInd = () => {
        let result = {}
        result = {
            clientId: loadEventsObj.clientId,
            eventid: loadEventsObj.eventId,
            RegistrantValue: selectedRegistrant.localRegistrationId,
        }
        return result
    }

    const createSearchBody = async (searchQuery) => {
        let requestBody = {}
        if (clientEventInfo && clientEventInfo.clientId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`,
                "searchValue": `${searchQuery}`,
                "index": `${currentPage}`,
                "pageSize": `${itemsPerPage}`,
            }
        }

        return await requestBody;
    }


    const loadOptions = async (searchQuery) => {
        const items = await kioskService.getAttendeesSearchPaged(await createSearchBody(searchQuery));
        items.forEach((item) => {
            // has to have value and label defined
            item.value = item.localRegistrationId;
            item.label = item.firstName + ' ' + item.lastName + (item.organization ? ' (' + item.organization + ')' : '');

        })
        setSearchQuery(searchQuery)
        setCurrentPage(currentPage + 1)
        return {
            options: items,
            hasMore: items.length >= 1,
            //additional: {
            //    page: searchQuery ? 2 : page + 1,
            //},
        };

    };
    const onChange = option => {
        // triggered when option selected
        setSelectedRegistrant(option)
    };

    const onInputChange = entry => {
        // triggered when typing changes
        if (entry !== searchQuery) {
            // reset pages
            setCurrentPage(1)
            //setSelectedRegistrant({})
        }
    };
    const reloadSelectionCheck = option => {
        if (document.getElementById('fullCheck').checked) {
            document.getElementById('ifFull').style.display = 'block';
            document.getElementById('ifInd').style.display = 'none';
        } else {
            document.getElementById('ifFull').style.display = 'none';
            document.getElementById('ifInd').style.display = 'block';
        }
    };
    const handleROChange = e => {
        // determine selected value's index to match numeric values on controller method for reload
        reloadOptionSelectedIndex = reloadSelectionOptions.indexOf(e.target.value)

        setReloadNameSelected(e.target.value)
        setReloadValueSelected(reloadOptionSelectedIndex)
    }
    const isFormInvalid = () => {
        return !(reloadNameSelected )
    }
    const isIndividualFormInvalid = () => {
        return !(selectedRegistrant && selectedRegistrant.firstName !== undefined)
    }
    const changeDateFormat = dateStr => {
        const parsedDate = Date.parse(dateStr)
        const date = new Date(parsedDate)
        const options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };
        const dateFormatted = date.toLocaleDateString('en-us', options);
        const returnDate = dateFormatted.split(' ').slice(1).join('/')
        return returnDate
    }
    return (
        <div className="page-area">
            {
                loadEventsObj?
                    <h1>{ loadEventsObj.name }</h1>
                :
                    <h1>Client Events</h1>
            }

            <div id={styles.reloadSection}>
                <h3> {changeDateFormat(loadEventsObj.startDate)} -  {changeDateFormat(loadEventsObj.endDate)}</h3>
                <h3>Attendee Count: {loadEventsObj.count}</h3>
                <div className={styles.reloadContainer}>
                    Select Full Event or Individual Registrant :<br />
                    <label htmlFor="fullCheck">
                        <input type="radio" onClick={reloadSelectionCheck} name="reloadSelection" id="fullCheck"/>Full Event
                    </label>
                    <label htmlFor="indCheck">
                        <input type="radio" onClick={reloadSelectionCheck} name="reloadSelection" id="indCheck" />Individual Registrant
                    </label>
                        <br />
                    <div id="ifFull" >
                            <h4>Full Event Reload Option </h4>
                            <select
                                name="EventRegistrationSystem"
                                id={styles.ERSListDropdown}
                                value={reloadNameSelected}
                            onChange={handleROChange}
                        >       <label>Set Reload Option: </label>
                                <option value="" selected="selected">
                                    -- Select an option --
                                </option>

                                {
                                    reloadSelectionOptions.map((reloadOption, idx) => (
                                        <option key={idx}>{reloadOption.name}</option>
                                    ))
                                }
                            </select>
                            <div>
                                <label title="When un-checked, immediate reload is used. When checked, lazy-loader is used.">
                                    <input
                                        name="Required"
                                        type="checkbox"

                                        onClick={() => setReloadLazyLoadSelected(!reloadLazyLoadSelected)}
                                        checked={reloadLazyLoadSelected}

                                    />
                                    Use lazy-loading
                                </label>
                            </div>

                        <br /><br />
                            <button onClick={reloadAttendees} disabled={isFormInvalid()}>
                                Reload Attendees
                            </button>
                        </div>
                    <div id="ifInd" className={styles.HideIndElement}>
                                <h4>Individual Registrant Reload </h4>
                                <label>Select Registrant: </label>
                                <AsyncPaginate
                                    //value={searchTerm} // don't use with loadOptions, one or other
                                    loadOptions={loadOptions}//activates selections
                                    //getOptionValue={option => option.value}
                                    //getOptionLabel={option => option.label}
                                    onInputChange={onInputChange} // detects typing entry
                                    onChange={(option) => // option selected, otherwise clears entries
                                        !option ? onChange("") : onChange(option)
                                    }
                                    isClearable={true}
                                    isSearchable={true}
                                    placeholder='Search for Registrant'
                                /*additional={{
                                    page: 0,
                                }}*/
                        />
                        <br /><br />
                        <button onClick={reloadAttendee} disabled={isIndividualFormInvalid()}>
                                    Reload Attendee
                                </button>
                            </div>
                        </div>
            </div>
            <br /> <br /> <br />
            <button onClick={() => navigate(-1)}> Back </button> 
        </div>
    );
}

export default EventLoad;