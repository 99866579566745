import { useState } from 'react'
import SpecialRules from './SpecialRules'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

// ------ Image Elements ------ //
const bars = <FontAwesomeIcon icon={faBars} />

const StylingFormField = ({
    property,
    stylingForm,
    styles,
    handleChange,
    stylingFormExpandableFields,
    specialCSSRules,
    handleSpecialCSSRulesChange
}) => {

    const [expandSection, setExpandSection] = useState(false)
    const formValue = stylingForm[property]

    const expandCSSClass = e => {
        e.preventDefault()
        setExpandSection(!expandSection)
    }

    return (
        <>

            <label htmlFor={`${property}`} className={styles.label}>{property}
                <button className={stylingFormExpandableFields.includes(property) ? styles.bars : styles.noBars} onClick={e => expandCSSClass(e)}>
                    {stylingFormExpandableFields.includes(property) ? bars : ""}
                </button>
            </label>

            <input
                type="text"
                autoComplete="off"
                value={formValue}
                name={`${property}`}
                onChange={handleChange}
                className={styles.formInput}
            />

            {expandSection ?
                <SpecialRules
                    styles={styles}
                    property={property}
                    specialCSSRules={specialCSSRules}
                    handleSpecialCSSRulesChange={handleSpecialCSSRulesChange}
                />
                :
                <>
                </>
            }

        </>
    );
}

export default StylingFormField;