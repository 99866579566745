import { useEffect, useState } from 'react';

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'

// image elements
const circleCheck = <FontAwesomeIcon icon={faCircleCheck} />
const circleXmark = <FontAwesomeIcon icon={faCircleXmark} />

const RegType = ({
    index,
    item,
    updateCompiledObject,
    updateDataValidationObject,
    styles
}) => {

    console.log("item", item)

    const [rowData, setRowData] = useState({
        RegistrationTypeValue: null,
        RegistrationTypeOverride: null,
        RegistrationTypeColor: null,
    })
    const [validData, setValidData] = useState(false)

    const handleInputChange = (e, key) => {
        const updatedRowData = {
            ...rowData,
            [key]: e.target.value,
        }
        setRowData(updatedRowData)
        updateCompiledObject(index, updatedRowData)
    }

    const handleSavedData = obj => {
        let updatedTable = { ...rowData }

        if (obj.registrationTypeValue) {
            updatedTable = {
                ...updatedTable,
                RegistrationTypeValue: obj.registrationTypeValue
            }
        }
        if (obj.registrationTypeOverride) {
            updatedTable = {
                ...updatedTable,
                RegistrationTypeOverride: obj.registrationTypeOverride
            }
        }
        if (obj.registrationTypeColor) {
            updatedTable = {
                ...updatedTable,
                RegistrationTypeColor: obj.registrationTypeColor
            }
        }

        return updatedTable
    }

    const handleValidData = () => {
        if (rowData.RegistrationTypeOverride && rowData.RegistrationTypeOverride.trim().length >= 0
            /*|| !rowData.RegistrationTypeColor || rowData.RegistrationTypeColor.trim().length === 0*/) {
            return true
        }
        return false
    }

    useEffect(() => {
        const handleSavedDataOnRender = async () => {
            const updatedTable = await handleSavedData(item)
            setRowData(updatedTable)
        }
        handleSavedDataOnRender(item)    
    }, [])

    useEffect(() => {
        const validityCheck = async () => {
            const validBool = await handleValidData()
            setValidData(validBool)
            updateDataValidationObject(index, validBool)
        }
        validityCheck()
    }, [rowData])

    return (
        <tr key={index}>
            <td>{rowData.RegistrationTypeValue}</td>
            <td>
                <input
                    type="text"
                    value={rowData.RegistrationTypeOverride}
                    onChange={(e) => handleInputChange(e, "RegistrationTypeOverride")}
                />
            </td>
            <td>
                <input
                    type="text"
                    value={rowData.RegistrationTypeColor}
                    onChange={(e) => handleInputChange(e, "RegistrationTypeColor")}
                />
            </td>
            {validData ?
                <i className={styles.buttonImage} id={styles.validIcon}>
                    {circleCheck}
                </i>
                :
                <i className={styles.buttonImage} id={styles.invalidIcon}>
                    {circleXmark}
                </i>
            }
        </tr>
    );
}

export default RegType;