import { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom"
import styles from "./EventFilterList.module.css"

// ------ Services ------ //
import * as badgeManagementService from "../../../services/badgeManagementService"
import * as clientEventService from '../../../services/clientEventService'

// ------ Components ------ //
import SearchBar from "../../../components/Miscellaneous/SearchBar"
import EventFilters from "../../../components/Home/EventFilterList/EventFilters"
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'


const EventFilterList = ({ user }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const passedState = location.state
    // ------ State Variables ------ //
    const [EFs, setEFList] = useState([])
    const [EFOs, setEFOList] = useState([])
    const [EFNameFilter, setEFNameFilter] = useState("")
    const [editRefresh, setEditRefresh] = useState(false)
    const [message, setMessage] = useState([''])
    const [clientEventInfo, setClientEventInfo] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [viewDetails, setViewDetails] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    // ------ useEffect ------ //
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            const fetchData = async () => {
                const data = await badgeManagementService.loadEventFilters(await createRequest())
                const EFO = await badgeManagementService.getEventFilteringOptions()
                const EF = Array.from(data)
                for (let i = 0; i < EF.length; i++) {
                    EF[i].editable = false;
                    EF[i].eventFilteringOption = !EFO[EF[i].eventFilteringOption] ? null : EFO[EF[i].eventFilteringOption].name;
                    if (EF[i].eventFilteringOption && (EF[i].eventFilteringOption === 'IncludeUseEqual' || EF[i].eventFilteringOption === 'ExcludeUseEqual')) {
                        EF[i].valuesEqualSelect = EF[i].values
                    }
                    else if (EF[i].eventFilteringOption && (EF[i].eventFilteringOption === 'IncludeUseContain' || EF[i].eventFilteringOption === 'ExcludeUseContain')) {
                        EF[i].valuesContain = EF[i].values
                    }
                }

                setEFList(EF) // set the event filters
                setEFOList(EFO) // set the event filter options
                setEditRefresh(false)
                await fetchViewDetails(await createRequest())
                setLoaded(true)
            }
            fetchData()
        }

    }, [editRefresh || clientEventInfo])

    const fetchViewDetails = async body => {
        const viewRequestJSON = await badgeManagementService.getViewDetails(body)
        setViewDetails(viewRequestJSON)
    }
    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])
    // ------ Name Search ------ //
    const nameFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        EFs.forEach((EF) => {
            const objectName = EF.objectName.toLowerCase()
            const columnName = EF.columnName.toLowerCase()
            if (!filterString || objectName.includes(filterString) || columnName.includes(filterString)) {
                filtered.push(EF)
            }
        })
        return filtered
    }

    const filteredByEFs = nameFilter(EFNameFilter)
    const createRequest = async () => {

        const requestBody =
        {
            "clientId": `${clientEventInfo.clientId}`,
            "eventId": `${clientEventInfo.eventId}`
        }

        return await requestBody;
    }

    const handleSubmit = async e => {
        e.preventDefault()

        try {
            await badgeManagementService.generateView(await createRequest())
        } catch (err) {
            setMessage(err.message)
        }
        setEFList(EFs)
        await fetchViewDetails(await createRequest())
    }
    const handleClearFiltering = async e => {
        e.preventDefault()

        try {
            await badgeManagementService.clearViewFilters(await createRequest())
        } catch (err) {
            setMessage(err.message)
        }
        setEFList(EFs)
    }
    
    const cancelSubmit = e => {
        e.preventDefault()

    }
    const changeDateFormat = dateStr => {
        const parsedDate = Date.parse(dateStr)
        const date = new Date(parsedDate)
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        return date.toLocaleDateString('en-us', options);
    }
    return (
        <div className="page-area">
            {
                loaded ?
                    <>
            <h1>Client Event Fields Filter List</h1>
            <h2> NOTE!!! Generating View is lazy-loading process. Not immediate.</h2>
            <br/>
            <h2> * When true, null/blank entries will be filtered out/not show in registration list.</h2>
            <SearchBar
                handleSearchName={setEFNameFilter}
                placeholder="Object name or column name"
                label="Name"
                        />
                        {viewDetails ? <h2>
                            View Name: {viewDetails.viewName}<br />Last Built: {viewDetails.modifiedDate !== null && viewDetails.modifiedDate !== undefined ?
                                changeDateFormat(viewDetails.modifiedDate) : 'Not Built!'}
                        </h2> : 
                            <h2> View not found.<br />
                                <Link to={`/EventLoad`} state={clientEventInfo}>Reload Data ...</Link>
                            </h2>
                    }
                        
            <form>
                <div className={styles.inputContainer}>
                    <button className={styles.button} onClick={() => navigate(-1)}>
                        Back
                    </button>
                    <button onClick={e => handleSubmit(e)} className={styles.button} title="Generate View">
                        Generate View
                    </button>
                    <button onClick={e => handleClearFiltering(e)} className={styles.button} title="Remove all filters">
                        Clear Filtering
                    </button>
                </div>
                <br />
                <br />
            </form>
            <div className={styles.filterFieldsIndex}>
                <div className={styles.topBar}>
                    <h5>Object</h5>
                    <h5>Field Name</h5>
                    <h5>Required Field(*)</h5>
                    <h5>Filtering Options</h5>
                    <h5>Field Values</h5>
                </div>
                {filteredByEFs.length ?
                    Object.keys(filteredByEFs).map( index => (
                        <EventFilters
                            index={index}
                            filteredByEFs={filteredByEFs}
                            filterField={filteredByEFs[index]}
                            filterOptions={EFOs}
                        />
                    ))
                    :
                    <div className={styles.noneFound}>
                        No match results found
                    </div>
                }
            </div>
            <form>
                <div className={styles.inputContainer}>
                    <button className={styles.button} onClick={() => navigate(-1)}>
                        Back
                    </button>
                    <button onClick={e => handleSubmit(e)} className={styles.button} title="Generate View">
                        Generate View
                    </button>
                </div>
                        </form>
                    </>
                    :
                    <LoadingSpinner />
            }
        </div>
    );
}

export default EventFilterList;