import { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom"
import styles from './BadgeQrCodeManager.module.css'

// ------ Services ------ //
import * as badgeManagementService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'

// components - form
import HoverButton from '../../../components/Miscellaneous/HoverButton'
import CustomFormula from '../../../components/Badging/BadgeQrCodeManager/CustomFormula'
import ThirdPartyFormula from '../../../components/Badging/BadgeQrCodeManager/ThirdPartyFormula'
const BadgeQrCodeManager = () => {

    // State
    const [qrCodeChoicesList, setQrCodeChoicesList] = useState([])
    const [qrCodeThirdPartyFormulaList, setQrCodeThirdPartyFormulaList] = useState([])
    const [clientEventInfo, setClientEventInfo] = useState({})

    // useLocation & useNavigate --- Client state from previous page
    const navigate = useNavigate()
    const location = useLocation()
    const passedState = location.state
    const [formData, setFormData] = useState({
        clientId: clientEventInfo.clientId,
        eventId: clientEventInfo.eventId,
        //QRCodeFormula: null, // custom formula
        //{qrCodeFormula: '%ScannerField%', qRChoice: 0, existingFormulas: 0, active: false, createdDate: '2024-04-29T23:11:51.3089929', �}
        qrChoice: null,//dropdown selection to use custom or thirdparty
        //existingFormula: null, // thirdparty selection
        active:true, // control usage of qrcode, incase want it configured but not used
    })
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }

    // --- REST --- //
    const createRequestBody = async () => {

        let requestBody = {}

        if (clientEventInfo.clientId && clientEventInfo.eventId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`
            }
        }

        return await requestBody;
    }

    const [customFormulaConfig, setCustomFormula] = useState({
        qrCodeFormula: "RegistrationId=%ScannerField%" // default to example
    })

    const [thirdPartyConfig, setThirdPartyFormula] = useState({
        existingFormula: null,
        localEventId: null
    })

    const clearCustomFormulaConfig = () => setCustomFormula({
        qrCodeFormula: "RegistrationId=%ScannerField%"
    })

    const clearThirdPartyFormula = () => setThirdPartyFormula({
        existingFormula: null,
        localEventId: null
    })

    // Ensures state resets if user selects different qrchoice after partially filling out another
    const clearAllForms = () => {
        clearCustomFormulaConfig()
        clearThirdPartyFormula()
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.checked,
            })
            formData.active = !formData.active
        }
        else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            })
        }
        
    }

    const appendQrCodeFields = () => {
        // meant to attach all fields to object, before post
        const qrc = formData.qrChoice;
        if (qrc === "Custom") {
            formData.qrCodeFormula = customFormulaConfig.qrCodeFormula
        }
        else if (qrc === "ThirdParty") {
            formData.existingFormula = thirdPartyConfig.existingFormula
            formData.localEventId = thirdPartyConfig.localEventId
        }

        //result = qrc === "Custom" ? { ...customFormulaConfig, qrFormula }
        //    : qrc === "ThirdParty" ?
        //        { ...thirdPartyConfig, existingFormula: existingFormula, localEventId : localEventId }
        //        : console.log("Error: No QrCode formula data provided")

        return formData
    }


    const handleSubmitAndNavigate = async e => {
        e.preventDefault()

        const appended = await appendQrCodeFields()

        console.log(appended)

        // call back end service here to POST form data
        try {
            badgeManagementService.saveQrCodeSetup(appended)
        } catch (err) {
            console.log(err)
        }
        clearAllForms();
        navigate(`/EventBadgesManager`, { state: clientEventInfo })
    }

    // Catch client setting fetch and feed into form whenever load finishes
    //useEffect(() => {
    //    appendQrCodeFields()
    //}, [formData.qRChoice]) // trigger when qRChoice changes

    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            const fetchQrCodeThirdPartyFormula = async () => {
                const qrFormulas = await badgeManagementService.getQrCodeThirdPartyFormula()
                console.log("qrcodeFormula are: ", qrFormulas)
                setQrCodeThirdPartyFormulaList(qrFormulas)
            }
            //getQrCodeChoices
            const fetchQrCodeChoices = async () => {
                const qrcodeChoices = await badgeManagementService.getQrCodeChoices()
                console.log("qrcodeChoices are: ", qrcodeChoices)
                setQrCodeChoicesList(qrcodeChoices)
            }
            const fetchSetupData = async () => {
                const getReqBody = await createRequestBody()
                const qrcodeSetup = await badgeManagementService.getQrCodeSetup(getReqBody)
                if (qrcodeSetup) {
                    // probably better way to handle, but switch to text
                    if (qrcodeSetup && qrcodeSetup.qrChoice !== undefined) {
                        if (qrcodeSetup.qrChoice === 0) {
                            qrcodeSetup.qrChoice = "Custom";
                        }
                        else {
                            qrcodeSetup.qrChoice = "ThirdParty";
                        }
                    }

                    setFormData(qrcodeSetup)
                    setCustomFormula(qrcodeSetup)
                    setThirdPartyFormula(qrcodeSetup)
                }
                else {
                    setFormData(formData)
                }
            }
            fetchQrCodeThirdPartyFormula()
            fetchQrCodeChoices()
            fetchSetupData()
        }
        
    }, [clientEventInfo])
    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])
    return (
        <div>
            <h1>Badge QrCode Manager</h1>
            <h2>*Note: ScannerField must be mapped in Badge to ERS Property Mapping.<br />
                Only use if intent on using QrCodes! </h2>

            <form
                autoComplete="off"
                onSubmit={handleSubmitAndNavigate}
                className={styles.container}
            >
                <div className={styles.inputContainer}>
                    <label
                        htmlFor="active"
                        className={styles.label}
                    >
                        Active
                        <HoverButton
                            title={'Control usage of qrcode\ntrue: use setup\nfalse: do not use setup'}
                            messageWidth={350}
                        />
                        :
                    </label>
                    <input
                        type="checkbox"
                        value={formData.active}
                        checked={formData.active}
                        name="active"
                        onChange={handleChange}
                    />
                </div>
                <div className={styles.inputContainer}>
                    <label
                        htmlFor="qrChoice"
                        className={styles.label}
                    >
                        Algorithm Choice
                        <HoverButton
                            title={'Custom = Use CSSField names to create own formula\nThirdparty = known/coded formulas'}
                            messageWidth={150}
                        />
                        :
                    </label>

                    <select
                        name="qrChoice"
                        id={styles.ThirdPartyListDropdown}
                        value={formData.qrChoice}
                        onChange={handleChange}
                    >
                        <option disabled={true} value="" selected="selected">
                            -- Select an option --
                        </option>
                        {
                            qrCodeChoicesList.map((qrCodeChoice, idx) => (
                                <option key={idx}>{qrCodeChoice.name}</option>
                            ))
                        }
                    </select>
                </div>
                {formData.qrChoice === "Custom" ?
                    <CustomFormula
                        formData={formData}
                        qrCodeCustomFormulaForm={customFormulaConfig}
                        setQrCodeCustomFormula={setCustomFormula}
                    />
                    : <></>
                }
                {formData.qrChoice === "ThirdParty" ?
                    <ThirdPartyFormula
                        formData={formData}
                        qrCodeThirdPartyFormula={thirdPartyConfig}
                        setQrCodeThirdPartyFormula={setThirdPartyFormula}
                        ThirdPartyList={qrCodeThirdPartyFormulaList}
                    />
                    : <></>
                }
            </form>
        </div>

    );
}

export default BadgeQrCodeManager;