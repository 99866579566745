import { useState, useEffect, useRef } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
// ------ Services ------ //
import * as clientEventService from '../../../services/clientEventService'
import * as badgeService from '../../../services/badgeManagementService'
import styles from './BadgeTemplateFieldOrdering.module.css'

const BadgeTemplateFieldOrdering = ({ user }) => {
    const location = useLocation()
    const passedState = location.state
    const navigate = useNavigate()
    const [message, setMessage] = useState([''])
    // state
    const [Field, setField] = useState([
        {
            id: null,
            overrideRank: null,
            defaultRank: null,
            cssField: null,
        }
    ])

    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    // --- REST --- //
    const createRequestBody = async () => {

       let requestBody = {}

        if (clientEventInfo.clientId && clientEventInfo.eventId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`
            }
        }

        return await requestBody;
    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }

    // useEffect
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            const fetchData = async () => {
                const getReqBody = await createRequestBody()
                const data = await badgeService.getRegFieldsOrdering(getReqBody)
                const items = Array.from(data)
                setField(items)
            }
            fetchData()
        }

    }, [clientEventInfo])

    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])

    // functions
    const handleOnDragEnd = async result => {
        if (!result.destination) return

        const items = Array.from(Field)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        for (let i = 0; i < items.length; i++) {
            items[i].overrideRank = i + 1
        }
        let saveRequest = await createSaveRequest(items)
        // handle fields and connect to edit service in badgeManagementService.js here
        try {
            await badgeService.saveRegFieldsOrdering(saveRequest)
        } catch (err) {
            setMessage(err.message)
        }
        setField(items)
    }

    const createSaveRequest = (items) => {
        const fieldsSave = {
            ClientId: clientEventInfo.clientId,
            EventId: clientEventInfo.eventId,
            registrationOrdering: items
        }

        return fieldsSave
    }

    return (
        <div className="page-area">
            <div className={styles.templateIndex}>
                <h1>Badge Template Field Layout Ordering</h1>
                <h2>Drag and drop to change rank/ordering.</h2>

                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="FieldList">
                        {(provided) => (

                            <ul className={styles.FieldList} {...provided.droppableProps} ref={provided.innerRef}>
                                {Field.map(({ id, overrideRank, cssFieldName,defaultRank }, index) => (

                                    <Draggable key={id} draggableId={id} index={index}>
                                        {(provided) => (

                                            <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>

                                                <div className={styles.FieldListOptions}>

                                                    <div>
                                                        {cssFieldName}
                                                        <br /><br />
                                                        Rank: {overrideRank }
                                                    </div>

                                                </div>
                                            </li>

                                        )}
                                    </Draggable>
                                ))}
                            </ul>

                        )}
                    </Droppable>
                </DragDropContext>

            </div>
        </div>
    );
}

export default BadgeTemplateFieldOrdering;