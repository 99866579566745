import styles from './ProgressSteps.module.css'

const ProgressSteps = ({ steps, activeStep }) => {
    const totalSteps = steps.length
    const isLastStep = activeStep > totalSteps
    const width = isLastStep ? 'calc(100% - 20px)' : `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`

    return (
        <div className={styles.mainContainer}>
            <div className={styles.stepContainer} style={{ '--width': width }}>
                {steps.map(({ step, label }) => (
                    <div key={step} className={styles.stepWrapper}>
                        <div className={`${styles.stepStyle} ${activeStep > step ? styles.completed : ''}`}>
                            {activeStep > step ? (
                                <div className={styles.checkMark}>L</div>
                            ) : (
                                <span className={styles.stepCount}>{step}</span>
                            )}
                        </div>
                        <div className={styles.stepsLabelContainer}>
                            <span className={styles.stepLabel}>{label}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default ProgressSteps;