import { useState, useEffect } from 'react'
import styles from "../../../pages/Account/ManageAccountSettings/ManageAccountSettings.module.css"
import HoverButton from '../../../components/Miscellaneous/HoverButton'

const SelectEvent = ({
    formData,
    eventInfo,
    setEvent,
    listEvents,
}) => {

    const handleChange = e => {
        var foundEvent = listEvents.find((item) => item.name === e.target.value);
        setEvent({
            ...eventInfo,
            [e.target.name]: e.target.value,
            event: foundEvent,
        })
    }
    return (
        <>
            <div className={styles.inputContainer}>
                <label
                    htmlFor="event"
                    className={styles.label}
                >
                    Select Event
                    <HoverButton
                        title={'Event = defines event access for account.'}
                        messageWidth={150}
                    />
                    :
                </label>
                <select
                    name="eventName"
                    id={styles.ThirdPartyListDropdown}
                    value={eventInfo && eventInfo.eventName ? eventInfo.eventName : null} // "One" as initial value for react-select
                    onChange={handleChange}
                    disabled={formData.client && formData.client.clientName ? false : true}
                >
                    <option disabled={true} value="" selected="selected">
                        -- Select an option --
                    </option>
                    {
                        listEvents.map((evt, idx) => (
                            <option key={idx}>{evt.name}</option>
                        ))
                    }
                </select>
            </div>
        </>
    );
}

export default SelectEvent;