/*---------- Deps, Hooks & CSS ----------*/
import { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import styles from "./ExtraBadgesStatusManager.module.css"

/*---------- Services ----------*/
import * as progressService from '../../../services/progressService'

/*---------- Components ----------*/
import Card from "../../../components/Home/StatusSubpages/Card"
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'
import ProgressSteps from '../../../components/Miscellaneous/ProgressSteps'

/*---------- Images ----------*/
import ExtraBadgesSetup from "../../../components/Home/StatusSubpages/ExtraBadgesImages/ExtraBadgesSetup.png"
import ExtraBadgeTemplateStyling from "../../../components/Home/StatusSubpages/ExtraBadgesImages/ExtraBadgeTemplateStyling.png"
import StaticFieldManager from "../../../components/Home/StatusSubpages/ExtraBadgesImages/StaticFieldManager.png"
import FieldOrdering from "../../../components/Home/StatusSubpages/ExtraBadgesImages/ExtraBadgesFieldOrdering.png"

const ExtraBadgesStatusManager = () => {

    const location = useLocation()
    const passedState = location.state
    console.log(passedState)

    /*---------- State ----------*/
    const [client, setClient] = useState(passedState.client)
    const [event, setEvent] = useState(passedState.event)
    const [clientEventObject, setClientEventObject] = useState({})
    const [loaded, setLoaded] = useState(false)

    // Required Setup
    const [extraBadgesSetupProgress, setExtraBadgesSetupProgress] = useState("Loading...")
    const [templateProgress, setTemplateProgress] = useState("Loading...")

    // Optional Setup
    const [staticFieldProgress, setStaticFieldProgress] = useState("Loading...")
    const [fieldOrderingProgress, setFieldOrderingProgress] = useState("Loading...")

    // Progress Steps
    const [requiredCompletionCount, setRequiredCompletionCount] = useState(1)
    const [optionalCompletionCount, setOptionalCompletionCount] = useState(1)

    /*---------- Constants ----------*/
    // General
    const clientName = passedState.client && passedState.client.clientName
        ? passedState.client.clientName
        : ""

    const eventName = passedState.event && passedState.event.name
        ? passedState.event.name
        : passedState.eventName
            ? passedState.eventName
            : ""

    const ERSName = passedState.event && passedState.event.eventRegistrationSystemOptionSmall.eventRegistrationSystem != undefined
        ? passedState.event.eventRegistrationSystemOptionSmall.eventRegistrationSystem
        : "ERS"

    const clientId = passedState.client && passedState.client.clientId !== undefined
        ? passedState.client.clientId
        : passedState.clientId
            ? passedState.clientId
            : ""

    const eventId = passedState.event && passedState.event.eventId
        ? passedState.event.eventId
        : passedState.eventId
            ? passedState.eventId
            : ""

    // Progress Steps 
    const requiredSteps = [
        { label: 'Extra Badges Setup', step: 1 },
        { label: 'Template Management', step: 2 },
    ]

    const optionalSteps = [
        { label: 'Static Field Mapping', step: 1 },
        { label: 'Field Ordering', step: 2 },
    ]

    /*---------- Functions ----------*/
    // Step Calculations
    const checkRequiredCompletion = () => {
        if (extraBadgesSetupProgress === "Success") setRequiredCompletionCount(count => count + 1)
        if (templateProgress === "Success") setRequiredCompletionCount(count => count + 1)
    }

    const checkOptionalCompletion = () => {
        if (staticFieldProgress === "Success") setOptionalCompletionCount(count => count + 1)
        if (fieldOrderingProgress === "Success") setOptionalCompletionCount(count => count + 1)
    }

    const checkLoaded = () => {
        if (extraBadgesSetupProgress !== "Loading..."
            && templateProgress !== "Loading..."
            && staticFieldProgress !== "Loading..."
            && fieldOrderingProgress !== "Loading..."
        )
            setLoaded(true)
    }

    useEffect(() => {
        const fetchData = async () => {
            // Compile object for progress calls
            const buildClientEventObj = () => {
                let obj = {
                    ClientId: clientId,
                    EventId: eventId,
                }

                setClientEventObject(obj)
                return obj
            }

            const clientEventObj = await buildClientEventObj()

            // Extra Badge Mapping Progress
            const extraBadgeProgress = await progressService.getExtraBadgesSetupProgress(clientEventObj)
            console.log(extraBadgeProgress)
            await setExtraBadgesSetupProgress(extraBadgeProgress)

            // Template Management Progress
            const templateManagementProgress = await progressService.getTemplateManagmentProgress(clientEventObj)
            console.log(templateManagementProgress)
            await setTemplateProgress(templateManagementProgress)

            // Registration Type Mapping Progress
            const staticFieldStatus = await progressService.getExtraBadgesStaticFieldProgress(clientEventObj)
            console.log(staticFieldStatus)
            await setStaticFieldProgress(staticFieldStatus)

            // Field Ordering Progress
            const fieldProgress = await progressService.getExtraBadgesFieldOrderingProgress(clientEventObj)
            console.log(fieldProgress)
            await setFieldOrderingProgress(fieldProgress)
        }
        fetchData()
    }, [])

    useEffect(() => {
        checkLoaded()
        checkRequiredCompletion()
        checkOptionalCompletion()
    }, [fieldOrderingProgress])

    return (
        <>
            {
                loaded ?
                    <div id={styles.statusSubpage}>
                        <h1>{passedState.client && passedState.client.clientName !== undefined ? "Status Manager - Extra Badges" : ""}</h1>
                        <h2 id={styles.CEtitle}>
                            {passedState.client && passedState.client.clientName
                                ? passedState.client.clientName
                                : ""
                            }
                            {" "}
                            {passedState.event && passedState.event.eventName
                                ? passedState.event.eventName
                                : ""
                            }
                        </h2>

                        <div className={styles.requiredSetupHeader}>
                            <h2 className={styles.requiredSetupHeading}>Required Setup</h2>
                            <ProgressSteps
                                steps={requiredSteps}
                                activeStep={requiredCompletionCount}
                            />
                        </div>

                        <div className={styles.container}>
                            <Card
                                dataImage={ExtraBadgesSetup}
                                header="Extra Badge Setup"
                                content={`Map fields from ${ERSName} for ${eventName}'s extra badges`}
                                styles={styles}
                                linkName={`/ExtraBadgeTemplateManager`}
                                linkState={passedState.event}
                                disabledBool={false} // hardcoded as it's the first required step of an event --- can't be disabled
                            />
                            <Card
                                dataImage={ExtraBadgeTemplateStyling}
                                header="Template Management"
                                content={`Create/manage event badge templates`}
                                styles={styles}
                                linkName={`/ExtraBadgeTemplateStyling`}
                                linkState={passedState.event}
                                disabledBool={extraBadgesSetupProgress !== "Success" ? true : false}
                            />
                        </div>

                        <div className={styles.optionalSetupHeader}>
                            <h2 className={styles.optionalSetupHeading}>Optional Setup</h2>
                            <ProgressSteps
                                steps={optionalSteps}
                                activeStep={optionalCompletionCount}
                            />
                        </div>

                        <div className={styles.container}>
                            <Card
                                dataImage={StaticFieldManager}
                                header="Static Field Mapping"
                                content={`Manage QR Code configuration for ${eventName}`}
                                styles={styles}
                                linkName={`/StaticFieldManager`}
                                linkState={passedState.event}
                                disabledBool={templateProgress !== "Success" ? true : false}
                            />
                            <Card
                                dataImage={FieldOrdering}
                                header="Field Ordering"
                                content={`Arrange mapped fields in order you want them to be presented on your extra badges`}
                                styles={styles}
                                linkName={`/ExtraBadgeTemplateFieldOrdering`} // There's no page for this yet
                                linkState={passedState.event}
                                disabledBool={templateProgress !== "Success" ? true : false}
                            />
                        </div>
                    </div>
                    :
                    <LoadingSpinner />
            }
        </>
    );
}

export default ExtraBadgesStatusManager;