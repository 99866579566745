import { useState, useEffect } from 'react'
import styles from "../../../pages/Home/ClientConfig/ClientConfig.module.css"
import * as eventManagementService from '../../../services/eventManagementService'

const CventConfig = ({
    formData,
    CventClientConfig,
    setCventClientConfig,
}) => {

    const handleChange = e => {
        setCventClientConfig({
            ...CventClientConfig,
            [e.target.name]: e.target.value,
        })
        console.log(CventClientConfig)
    }

	const [error, setError] = useState(null)
    const errorDiv = (error)
        ?
        <ul>
            {Object.entries(error).length > 0 && Object.entries(error) &&
                Object.entries(error).map(([key, value]) => {
                    console.log("error: " + error)
                    return <div className={value == 'Test Failure' ? 'errorMSG' : 'successMSG'} key={key} >{value}</div>;
                })}
        </ul>
        : '';
    const handleTestConnection = async e => {
        e.preventDefault()
        setError(null)
        var holder = { CventClientConfig };
        try {
            await eventManagementService.testSetup(holder)
        } catch (err) {
            console.log(err.message)
			setError(err.internalError);
        }
    }

    const isFormValid = () => {
        return formData.ClientName
            && formData.EventRegistrationSystem
            && formData.Industry
            && formData.Categories.length > 0
            && CventClientConfig.AccountNumber
            && CventClientConfig.Username
            && CventClientConfig.Password
            ? true
            : false
    }
    const isTestFormValid = () => {
        return CventClientConfig.AccountNumber
            && CventClientConfig.Username
            && CventClientConfig.Password
            ? true
            : false
    }

    return (
        <>
            <div className={styles.inputContainer}>
                <label htmlFor="AccountNumber" className={styles.label}>Account Number: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={CventClientConfig.AccountNumber}
                    name="AccountNumber"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="Username" className={styles.label}>Username: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={CventClientConfig.Username}
                    name="Username"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="Password" className={styles.label}>Password: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={CventClientConfig.Password}
                    name="Password"
                    onChange={handleChange}
                />
            </div>
			{errorDiv}
            <div className={styles.inputContainer}>
                <button disabled={!isFormValid()} className={styles.button}>
                    Submit
                </button>
                <button onClick={handleTestConnection} disabled={!isTestFormValid()} className={styles.buttonRight}>
                    Test Connection
                </button>
            </div>
        </>
    );
}

export default CventConfig;