import { useState, useEffect } from 'react'
import styles from "../../../pages/Home/ClientConfig/ClientConfig.module.css"
import * as eventManagementService from '../../../services/eventManagementService'

const MembersuiteConfig = ({
    formData,
    memberSuiteClientConfig,
    setMembersuiteClientConfig,
}) => {

    const handleChange = e => {
        setMembersuiteClientConfig({
            ...memberSuiteClientConfig,
            [e.target.name]: e.target.value,
        })
        console.log(memberSuiteClientConfig)
    }
	const [error, setError] = useState(null)
    const errorDiv = (error)
        ?
        <ul>
            {Object.entries(error).length > 0 && Object.entries(error) &&
                Object.entries(error).map(([key, value]) => {
                    console.log("error: " + error)
                    return <div className={value == 'Test Failure' ? 'errorMSG' : 'successMSG'} key={key} >{value}</div>;
                })}
        </ul>
        : '';
    const handleTestConnection = async e => {
        e.preventDefault()
        setError(null)
        var holder = { memberSuiteClientConfig };
        try {
            await eventManagementService.testSetup(holder)
        } catch (err) {
            console.log(err.message)
			setError(err.internalError);
        }
    }

    const isFormValid = () => {
        return formData.ClientName
            && formData.EventRegistrationSystem
            && formData.Industry
            && formData.Categories.length > 0
            && memberSuiteClientConfig.tenantID
            && memberSuiteClientConfig.username
            && memberSuiteClientConfig.password
            && memberSuiteClientConfig.url
            && memberSuiteClientConfig.EventSQL
            && memberSuiteClientConfig.EventRegistrationSQL
            && memberSuiteClientConfig.SessionSQL
            && memberSuiteClientConfig.SessionRegistrationSQL
            ? true
            : false
    }
	const isTestFormValid = () => {
        return memberSuiteClientConfig.tenantID
            && memberSuiteClientConfig.username
            && memberSuiteClientConfig.password
            && memberSuiteClientConfig.url
            ? true
            : false
    }
    return (
        <>
            <div className={styles.inputContainer}>
                <label htmlFor="tenantID" className={styles.label}>TenantId: </label>
                <input
                    type="number"
                    autoComplete="off"
                    value={memberSuiteClientConfig.tenantID}
                    name="tenantID"
                    onChange={handleChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <label htmlFor="username" className={styles.label}>Username: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={memberSuiteClientConfig.username}
                    name="username"
                    onChange={handleChange}
                />
            </div>
             <div className={styles.inputContainer}>
                <label htmlFor="password" className={styles.label}>Password: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={memberSuiteClientConfig.password}
                    name="password"
                    onChange={handleChange}
                />
            </div>
            <div className={styles.inputContainer}>
                <label htmlFor="url" className={styles.label}>URL: </label>
                <input
                    type="text"
                    autoComplete="off"
                    value={memberSuiteClientConfig.url}
                    name="url"
                    onChange={handleChange}
                />
            </div>
            <div className={styles.inputContainer}>
                <label htmlFor="EventSQL" className={styles.label}>EventSQL: </label>
                <textarea
                    autoComplete="off"
                    value={memberSuiteClientConfig.EventSQL}
                    name="EventSQL"
                    rows="4"
                    cols="100"
                    onChange={handleChange}
                />
            </div>
            <div className={styles.inputContainer}>
                <label htmlFor="EventRegistrationSQL" className={styles.label}>EventRegistrationSQL: </label>
                <textarea
                    type="textarea"
                    autoComplete="off"
                    value={memberSuiteClientConfig.EventRegistrationSQL}
                    name="EventRegistrationSQL"
                    rows="4"
                    cols="100"
                    onChange={handleChange}
                />
            </div>
            <div className={styles.inputContainer}>
                <label htmlFor="SessionSQL" className={styles.label}>SessionSQL: </label>
                <textarea
                    type="textarea"
                    autoComplete="off"
                    value={memberSuiteClientConfig.SessionSQL}
                    name="SessionSQL"
                    rows="4"
                    cols="100"
                    onChange={handleChange}
                />
            </div>
            <div className={styles.inputContainer}>
                <label htmlFor="SessionRegistrationSQL" className={styles.label}>SessionRegistrationSQL: </label>
                <textarea
                    type="textarea"
                    autoComplete="off"
                    value={memberSuiteClientConfig.SessionRegistrationSQL}
                    name="SessionRegistrationSQL"
                    rows="4"
                    cols="100"
                    onChange={handleChange}
                />
            </div>
			 {errorDiv}
            <div className={styles.inputContainer}>
                <button disabled={!isFormValid()} className={styles.button}>
                    Submit
                </button>
                <button onClick={handleTestConnection} disabled={!isTestFormValid()} className={styles.buttonRight}>
                    Test Connection
                </button>
            </div>
        </>
    );
}

export default MembersuiteConfig;