import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as badgeService from "../../../services/badgeManagementService"
import * as clientEventService from '../../../services/clientEventService'
import styles from './ViewGenerate.module.css'

const ViewGenerate = () => {
    const [generateViewObj, setGenerateViewObj] = useState({})
    const navigate = useNavigate()
    // useLocation & useNavigate --- Client state from previous page
    const location = useLocation()
    const passedState = location.state
    const [generateLazyLoadSelected, setgenerateLazyLoadSelected] = useState(true)
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            const fetchData = async () => {
                // get the view information
                const viewRequestJSON = await badgeService.getViewDetails( appendgenerateOption())
                setGenerateViewObj(viewRequestJSON)
            }
            fetchData()
        }
    }, [clientEventInfo])
    const generateView = async e => {
        e.preventDefault()

        const appended = appendgenerateOption()
        try {
            badgeService.generateView(appended)
        } catch (err) {
            console.log(err)
        }
        navigate(-1); // navigates back
    }

    const appendgenerateOption = () => {
        let result = {}
        result = {
            clientId: clientEventInfo.clientId,
            eventid: clientEventInfo.eventId,
            UseLazyLoad: generateLazyLoadSelected,
        }
        return result
    }
    const changeDateFormat = dateStr => {
        const parsedDate = Date.parse(dateStr)
        const date = new Date(parsedDate)
        const options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };
        const dateFormatted = date.toLocaleDateString('en-us', options);
        const returnDate = dateFormatted.split(' ').slice(1).join('/')
        return returnDate
    }
   useEffect(() => {
    // store into local object
    if (!passedState || !passedState.clientName) {
        //let localclientevent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current) {
            setClientEventInfo(clientEventInfoRef.current)
        }
    }
    else {
        let passedData = {
            clientName: passedState.clientName,
            clientId: passedState.clientId,
            eventName: passedState.eventName,
            eventId: passedState.eventId,
        }
        setClientEventInfo(passedData)
        clientEventInfoRef.current = passedData;
    }

}, [])
    return (
        <div className="page-area">
            {
                generateViewObj ?
                    <><h1>{generateViewObj.viewName}</h1>
                        <h3>Created: {generateViewObj.createdDate ? changeDateFormat(generateViewObj.createdDate) : null}
                            <br /> Last Modified: {generateViewObj.modifiedDate ? changeDateFormat(generateViewObj.modifiedDate) : null}</h3>
                        *Note: Last modified shoud be after latest reload of registrants.<br />
                </>
                    :
                    <h1>View hasn't been created!</h1>
            }

            <div id={styles.generateSection}>
                <div>
                    <label title="When un-checked, immediate generation is used. When checked, lazy-loader is used.">
                        <input
                            name={generateLazyLoadSelected}
                            type="checkbox"
                            value={generateLazyLoadSelected}
                            onClick={() => setgenerateLazyLoadSelected(!generateLazyLoadSelected)}
                            checked={generateLazyLoadSelected}
                        />
                        Use lazy-loading
                    </label>
                </div>

                <button onClick={generateView}>
                    Generate View
                </button>

            </div>
            <br /> <br /> <br />
            <button onClick={() => navigate(-1)}> Back </button>
        </div>
    );
}

export default ViewGenerate;