import { useState, useEffect } from 'react'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import *  as homeService from '../../services/homeService'

const Logoff = () => {
    // useEffect - placeholder fetch function until controller & backend func is created
    useEffect(() => {
        homeService.logout()
    }, [])
    return (<Navigate to={"/"} />);
}

export default Logoff