import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

const Card = ({
    dataImage,
    header,
    content,
    styles,
    linkName,
    linkState,
    disabledBool
}) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 })
    const [mouseLeaveDelay, setMouseLeaveDelay] = useState(null)
    const cardRef = useRef(null)

    useEffect(() => {
        setDimensions({
            width: cardRef.current.offsetWidth,
            height: cardRef.current.offsetHeight,
        })
    }, [])

    const handleMouseMove = (e) => {
        const rect = cardRef.current.getBoundingClientRect()
        setMousePos({
            x: e.clientX - rect.left - dimensions.width / 2,
            y: e.clientY - rect.top - dimensions.height / 2,
        })
    }

    const handleMouseEnter = () => {
        clearTimeout(mouseLeaveDelay)
    }

    const handleMouseLeave = () => {
        setMouseLeaveDelay(
            setTimeout(() => {
                setMousePos({ x: 0, y: 0 })
            }, 1000)
        )
    }

    const mousePX = mousePos.x / dimensions.width
    const mousePY = mousePos.y / dimensions.height
    const cardStyle = {
        transform: `rotateY(${mousePX * 30}deg) rotateX(${mousePY * -30}deg)`,
    }
    const cardBgTransform = {
        transform: `translateX(${mousePX * -40}px) translateY(${mousePY * -40}px)`,
    }
    const cardBgImage = {
        backgroundImage: `url(${dataImage})`,
        ...(disabledBool === true ? { filter: 'grayscale(100%)' } : {})
    }

    return (
        <div
            className={styles.cardWrap}
            onMouseMove={handleMouseMove}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={cardRef}
        >
            <Link to={disabledBool ? '#' : { pathname: linkName, state: linkState }} className={styles.cardLink}>
            <div className={styles.card} style={cardStyle}>
                <div className={styles.cardBG} style={{ ...cardBgTransform, ...cardBgImage }}></div>
                <div className={styles.cardInfo}>
                    <h1>{header}</h1>
                    <p>{content}</p>
                </div>
            </div>
                </Link>
        </div>
    );
}

export default Card