import { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom"
import styles from './RegTypeMapping.module.css'

// ------ Components ------ //
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'
import BadgeField from '../../../components/Badging/BadgeToERSPropertyMapping/BadgeField'
import ContinueButton from '../../../components/Badging/BadgeToERSPropertyMapping/ContinueButton'
import SearchBar from "../../../components/Miscellaneous/SearchBar"
import RegType from '../../../components/Badging/RegTypeMapping/RegType'
import HoverButton from '../../../components/Miscellaneous/HoverButton'

// ------ Services ------ //
import * as badgeService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'

const RegTypeMapping = () => {

    // State
    const [badgeFields, setBadgeFields] = useState([])
    const [EROs, setEROs] = useState([])
    const [regTypes, setRegTypes] = useState([])
    const [regTypeMappingTable, setRegTypeMappingTable] = useState([])
    const [dataValidationObj, setDataValidationObj] = useState([])
    const [fieldSelections, setFieldSelections] = useState([]) // compiled grouping of all selections
    const [loaded, setLoaded] = useState(false)
    const [badgeFilterSearchTerm, setBadgeFilterSearchTerm] = useState("")
    const [continueButtonVisibility, setContinueButtonVisibility] = useState(false)

    // useLocation & useNavigate --- Client state from previous page
    const navigate = useNavigate()
    const location = useLocation()
    const passedState = location.state
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }

    /* ---------------- FUNCTIONS ---------------- */

    // ------ Save & Data Validation ------ //
    const handleSaveAndNavigate = async () => {
        const regTypeMappingSave = await createSaveRequest()
        await badgeService.saveRegistrationTypeMappings(regTypeMappingSave)
        navigate(`/MainBadgeTemplateStyling`, { state: clientEventInfo })
    }

    const createSaveRequest = () => {
        const fieldsSave = {
            ClientId: EROs.clientId,
            EventId: EROs.eventId,
            registrationTypeMaps: regTypeMappingTable
        }

        return fieldsSave
    }

    const handleContinueButtonVisiblity = () => {
        console.log(dataValidationObj.includes(false))
        dataValidationObj.includes(false)
            ? setContinueButtonVisibility(false)
            : setContinueButtonVisibility(true)
    }

    // ------ Styling Form: Badge Field Search ------ //
    const badgeFieldFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        badgeFields.forEach((field) => {
            const name = field.toLowerCase()

            if (!filterString || name.includes(filterString)) {
                filtered.push(field)
            }
        })
        return filtered
    }

    const filteredBadgeFields = badgeFieldFilter(badgeFilterSearchTerm) // Breaks field selection when cleared

    // --- REST --- //
    const createRequestBody = async () => {

        let requestBody = {}


        if (clientEventInfo.clientId && clientEventInfo.eventId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`
            }
        }

        return await requestBody;
    }

    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            const fetchData = async () => {
                const getReqBody = await createRequestBody()
                const regTypes = await badgeService.getRegTypesForMapping(getReqBody)
                console.log("regTypes are: ", regTypes)
                if (regTypes && regTypes.length) {
                    setRegTypes(regTypes)
                }
                else {
                    setRegTypes([])
                }
                setLoaded(true)
            }
            fetchData()
        }

    }, [clientEventInfo])

    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])

    const updateCompiledObject = (index, updatedObj) => {
        setRegTypeMappingTable(prevState => {
            const newState = [...prevState]
            newState[index] = updatedObj
            return newState
        })
    }

    const updateDataValidationObject = (index, updatedObj) => {
        setDataValidationObj(prevState => {
            const newState = [...prevState]
            newState[index] = updatedObj
            return newState
        })
    }

    useEffect(() => {
        if (regTypes.length) setLoaded(true)
    }, [regTypes])

    useEffect(() => {
        if (dataValidationObj.length) handleContinueButtonVisiblity()
    }, [dataValidationObj])

    return (
        <div id={styles.createBadgeArea}>
            {
                loaded ?
                    <>
                        <h1>Registration Type Mapping</h1>

                        <button className={styles.CBTbuttons} id={styles.CBTBack} onClick={() => navigate(-1)}>
                            Back
                        </button>

                        {continueButtonVisibility ?
                            <button className={styles.CBTbuttons} id={styles.CBTContinue} onClick={handleSaveAndNavigate}>
                                Continue
                            </button>
                            :
                            <div className={styles.incomplete}>
                                Please provide values for required properties:
                                <br />
                                <b>
                                    Registration Type Override
                                </b>
                            </div>
                        }

                        <div id={styles.badgingContainer}>

                            {/*<SearchBar*/}
                            {/*    searchName={badgeFilterSearchTerm}*/}
                            {/*    handleSearchName={setBadgeFilterSearchTerm}*/}
                            {/*    placeholder="Field Name"*/}
                            {/*    label="Name"*/}
                            {/*    isCleared={isFieldNameCleared}*/}
                            {/*    setIsCleared={setIsFieldNameCleared}*/}
                            {/*    styles={styles}*/}
                            {/*    includeClearButton={true}*/}
                            {/*/>*/}

                            {
                                regTypes.length ?
                                    <div id={styles.regTypeTableSection}>
                                        <table id={styles.regTypeTable}>
                                            <thead id={styles.tableHeaders}>
                                                <tr>
                                                    <th>
                                                        Registration Type Value
                                                        <HoverButton
                                                            title={'Values from ERS'}
                                                            messageWidth={200}
                                                        />
                                                    </th>
                                                    <th>
                                                        Registration Type Override
                                                        <HoverButton
                                                            title={'Text to display on badge for corresponding Registation Type Value'}
                                                            messageWidth={200}
                                                        />
                                                    </th>
                                                    <th>
                                                        Registration Type Color
                                                        <HoverButton
                                                            title={'Optional - Letter/acronym to appear on badge for registration type ribbon color'}
                                                            messageWidth={200}
                                                        />
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {regTypes.map((item, index) => (
                                                    <RegType
                                                        index={index}
                                                        item={item}
                                                        updateCompiledObject={updateCompiledObject}
                                                        updateDataValidationObject={updateDataValidationObject}
                                                        styles={styles}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <h2>
                                        <div className={styles.noneFound}>
                                            No Registration Types/Data found - check mapping and that View is built!<br />
                                            <Link to="/BadgeToERSPropertyMapping"
                                                state={clientEventInfo}
                                            >
                                                Check Mapping...
                                            </Link>
                                        </div>
                                    </h2>
                            }
                        </div>

                        <button className={styles.CBTbuttons} id={styles.CBTBack} onClick={() => navigate(-1)}>
                            Back
                        </button>
                        {continueButtonVisibility ?
                            <button className={styles.CBTbuttons} id={styles.CBTContinue} onClick={handleSaveAndNavigate}>
                                Continue
                            </button>
                            :
                            <div className={styles.incomplete}>
                                Please provide values for required properties:
                                <br />
                                <b>
                                    Registration Type Override
                                </b>
                            </div>
                        }
                    </>
                    :
                    <LoadingSpinner />
            }
        </div>
    );
}

export default RegTypeMapping;