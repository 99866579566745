import * as tokenService from './tokenService'
const BASE_URL = `/Eventmanagement`

export const testSetup = async (ClientConfig) => {
    let error = new Error();
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/TestConnectionSetup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(ClientConfig),
        })
        if (res.status == 200) {
            // create own object to pass errors correctly
            Object.assign(error, { internalError: JSON.parse('{"Error":["Test Successful"]}') });
            throw error;
        }
        else {
            // create own object to pass errors correctly
            Object.assign(error, { internalError: JSON.parse('{"Error":["Test Failure"]}') });
            throw error;
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const loadEvents = async (client) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/GetEventsByClient`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(client),
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const loadLastSixMonthsEvents = async (client) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/GetLastSixMonthsEventsByClient`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(client),
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const loadWebHooks = async (event) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveWebHooks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(event),
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const loadEventDataReloadStatusObjects = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventDataReloadStatus`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getRegistrantReloadSelections = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveRegistrantDataReloadSelections`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}


export const getWebHookAuthenticationOptions = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveWebHookAuthenticationOption`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const editWebHook = async (webhook) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AddUpdateWebHook`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(webhook),
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const reloadEvent = async (client) => {
    const token = tokenService.getToken()
    console.log(token)
    try {
        const res = await fetch(`${BASE_URL}/ReloadEvent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(client),
        })
        return await console.log("Status Code: ", res.status)
    } catch (error) {
        throw error
    }
}

export const reloadEventsByERS = async (client) => {
    const token = tokenService.getToken()
    console.log(token)
    try {
        const res = await fetch(`${BASE_URL}/ReloadClient`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(client),
        })
        return await console.log("Status Code: ", res.status)
    } catch (error) {
        throw error
    }
}

export const getEvent = async (client) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventByIds`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(client),
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const reloadIndividual = async (client) => {
    const token = tokenService.getToken()
    console.log(token)
    try {
        const res = await fetch(`${BASE_URL}/ReloadIndividual`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(client),
        })
        return await console.log("Status Code: ", res.status)
    } catch (error) {
        throw error
    }
}