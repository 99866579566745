import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import styles from '../../../pages/Badging/AttendanceReport/AttendanceReport.module.css'
// ------ Services ------ //
import * as badgeService from "../../../services/badgeManagementService"

// ------ Components ------ //
import SearchBar from "../../../components/Miscellaneous/SearchBar"
import LoadingSpinner from "../../../components/Miscellaneous/LoadingSpinner"
import Attendee from "../../../components/Badging/AttendanceReport/Attendee"
import HoverButton from '../../../components/Miscellaneous/HoverButton'
import PrintPage from '../../../components/Miscellaneous/PrintPage'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCaretUp, faSquareCaretDown } from '@fortawesome/free-solid-svg-icons'
// ------ Image Elements ------ //
const up = <FontAwesomeIcon icon={faSquareCaretUp} />
const down = <FontAwesomeIcon icon={faSquareCaretDown} />

const EventAttendees = ({ clientEventInfo, setEventAttendees, eventAttendees }) => {
    // ------ State Variables ------ //
    const [isCleared, setIsCleared] = useState(false)
    const [loaded, setLoaded] = useState(false)

    // ------ Constants ------ //
    const [registrants, setRegistrants] = useState({})
    const [registrantNameFilter, setRegistrantNameFilter] = useState("")
    const [combinedFilteredRegistrants, setCombinedFilteredRegistrants] = useState([])
    const [registrationTypeFilter, setRegistrationTypeFilter] = useState("")
    const [registrantIDFilter, setRegistrantIDFilter] = useState("")
    const [emailAddressFilter, setEmailAddressFilter] = useState("")
    const [totalRegistrants, setTotalRegistrants] = useState(0)
    const [checkedInPercentage, setCheckedInPercentage] = useState(0)
    const [notCheckedInPercentage, setNotCheckedInPercentage] = useState(0)
    const [sorting, setSorting] = useState({ key: "lastName", ascending: true });
    const [templateSetting, setTemplateSetting] = useState();
    const [stylingForm, setStylingForm] = useState();
    const [cSSValues, setCSSValues] = useState();
    const [badgeDimensions, setBadgeDimensions] = useState();
    const [specialCSSRules, setSpecialCSSRules] = useState();
    const [fieldOrdering, setFieldOrdering] = useState({})
    const [registrantPrintSettings, setRegistrantPrintSettings] = useState();
    const [printRecord, setPrintRecord] = useState([])
    // ------ Name Search ------ //
    const NameFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        registrants.forEach((registrant) => {
            const nameFirst = registrant.firstName.toLowerCase()
            const nameLast = registrant.lastName.toLowerCase()
            if (!filterString || nameFirst.includes(filterString) || nameLast.includes(filterString)) {
                filtered.push(registrant)
            }
        })
        return filtered
    }

    const filteredByName = registrants && registrants.length ? NameFilter(registrantNameFilter) : []

    // ------ RegistrationType Search ------ //
    const RegistrationTypeFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        registrants.forEach((registrant) => {
            const regtype = registrant.registrationType ? registrant.registrationType.toLowerCase() : ''
            if (!filterString || regtype.includes(filterString)) {
                filtered.push(registrant)
            }
        })
        return filtered
    }

    const filteredByRegistrationType = registrants && registrants.length ? RegistrationTypeFilter(registrationTypeFilter) : []

    // ------ ID Search ------ //
    const IDFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        registrants.forEach((registrant) => {
            const registrantID = registrant.localRegistrationId
            const scannerField = registrant.scannerField ? registrant.scannerField.toLowerCase() : ''
            if (!filterString || registrantID.includes(filterString) || scannerField.includes(filterString)) {

                filtered.push(registrant)
            }
        })
        return filtered
    }

    const filteredByIDRegistrants = registrants && registrants.length ? IDFilter(registrantIDFilter) : []
    // ------ EmailAddress Search ------ //
    const EmailAddressFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        registrants.forEach((registrant) => {
            const regtype = registrant.emailAddress ? registrant.emailAddress.toLowerCase() : ''
            if (!filterString || regtype.includes(filterString)) {
                filtered.push(registrant)
            }
        })
        return filtered
    }

    const filteredByEmailAddress = registrants && registrants.length ? EmailAddressFilter(emailAddressFilter) : []
    // ------ Helper Functions ------ //
    const clearFilters = () => {
        setIsCleared(true)
        setRegistrantNameFilter("")
        setRegistrationTypeFilter("")
        setRegistrantIDFilter("")
        setEmailAddressFilter("")
        setIsCleared(false)
    }

    const renderHeader = () => {
        let result = {}
        result = {
            clientId: clientEventInfo.clientId,
            eventid: clientEventInfo.eventId,
        }
        return result
    }
    function applySorting(key) {
        if (sorting.key === key) {
            setSorting({ ...sorting, ascending: !sorting.ascending });
        } else {
            setSorting({ key: key, ascending: true });
        }
    }
    // React-to-Print
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Badge Styling - Print Preview",
        removeAfterPrint: true,

        onAfterPrint: () => removePrintReady()
    })

    useEffect(() => {
        if (printRecord && printRecord.printReady && printRecord.printReady === true) {
            setPrintRecord({})
            handlePrint()
        }
    }, [printRecord])


    const handlePrintReady = (reg) => {

        let divId = reg.localRegistrationId
        const items = Array.from(combinedFilteredRegistrants)
        let filtered = items.filter(ef => divId === ef.localRegistrationId)[0]

        // Have to add this to maintain one edit at a time, since all will share formData for editing state
        const printableCheck = i => i.printReady
        if (items.some(printableCheck)) {
            return
        }
        filtered.printReady = true
        setStylingForm(filtered)
        setCombinedFilteredRegistrants(combinedFilteredRegistrants)
        let regTemp = [
            filtered
        ]
        setPrintRecord(regTemp)
    }

    const removePrintReady = () => {
        let holder = []
        if (combinedFilteredRegistrants && combinedFilteredRegistrants.length > 0) {
            combinedFilteredRegistrants.forEach((registrant) => {
                registrant.printReady = false
                holder.push(registrant)
            })
        }
        setCombinedFilteredRegistrants(holder)
        setPrintRecord([])
        // clear saved entries to update, could just printed DS FIX
        setEventAttendees({})
    }

    // ------ useEffect ------ //
    useEffect(() => {
        if (registrants && registrants.length > 0) {
            let regs = [...registrants];
            let filteredResults = []
            // apply filtering
            regs.forEach((reg) => {
                reg.printReady = false
                if (filteredByName.includes(reg)) {
                    if (filteredByRegistrationType.includes(reg)) {
                        if (filteredByIDRegistrants.includes(reg)) {
                            if (filteredByEmailAddress.includes(reg)) {
                                filteredResults.push(reg)
                            }
                        }
                    }
                }
            })
            // apply sorting
            let sortedResults = filteredResults.sort((a, b) => {
                const optionA = a[sorting.key] && a[sorting.key] !== undefined ? a[sorting.key].toString().toLowerCase() : ''

                const optionB = b[sorting.key] && b[sorting.key] !== undefined ? b[sorting.key].toString().toLowerCase() : ''

                if (optionA < optionB) {
                    return sorting.ascending ? -1 : 1;
                }
                if (optionA > optionB) {
                    return sorting.ascending ? 1 : -1;
                }
                return 0;
            })
            setCombinedFilteredRegistrants(sortedResults);
        }

    }, [registrants, registrantNameFilter, registrationTypeFilter, registrantIDFilter, emailAddressFilter, sorting])

    // have to fetch template settings and field ordering
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId) {
            const fetchData = async () => {
                // set template settings
                const req = await renderHeader();
                try {
                    const templateSettings = await badgeService.getMainTemplate(req)
                    setTemplateSetting(templateSettings)
                    //comes through as strings
                    var savedStylingForm = templateSettings && templateSettings.stylingForm ? JSON.parse(templateSettings.stylingForm) : null;
                    setStylingForm(savedStylingForm)
                    var savedCssValues = templateSettings && templateSettings.cssValues ? JSON.parse(templateSettings.cssValues) : null;
                    setCSSValues(savedCssValues)
                    var savedBadgeDimensions = templateSettings && templateSettings.badgeDimensions ? JSON.parse(templateSettings.badgeDimensions) : null;
                    setBadgeDimensions(savedBadgeDimensions)
                    var savedSpecialCssRules = templateSettings && templateSettings.specialCSSRules ? JSON.parse(templateSettings.specialCSSRules) : null;
                    setSpecialCSSRules(savedSpecialCssRules)
                    const data = await badgeService.getRegFieldsOrdering(req)
                    const items = Array.from(data)
                    setFieldOrdering(items)
                    let regPrintSettings = {
                        cSSValues: savedCssValues,
                        specialCSSRules: savedSpecialCssRules,
                        fieldOrdering: items
                    }
                    setRegistrantPrintSettings(regPrintSettings)
                }
                catch (error) {
                    console.error('Error fetching data templates:', error);
                }
               
            }
            fetchData()
        }
    }, [])
    const fetchRegistrantData = async () => {
        // use from parent
        if (eventAttendees && eventAttendees.length > 0) {
            setRegistrants(eventAttendees)
            setEventAttendees(eventAttendees)
            setTotalRegistrants(eventAttendees.length)
            setPercentages()
        }
        else {
            try {
                let registrants = await badgeService.retrieveAttendanceReport(renderHeader())
                if (!registrants || registrants.length === 0) {
                    setCheckedInPercentage(0)
                    setNotCheckedInPercentage(0)
                    setTotalRegistrants(0)
                    setRegistrants({})
                    setEventAttendees({})
                    setLoaded(true)
                }
                else {
                    setRegistrants(registrants)
                    setEventAttendees(registrants)
                    setTotalRegistrants(registrants.length)
                    setPercentages()

                }
                clearFilters()
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }


    }
    const setPercentages = async () => {
        let printedCount = 0;
        let notPrintedCount = 0;
        if (registrants && registrants.lengh) {
            registrants.map(reg => {
                if (reg.latestPrinted && reg.latestPrinted !== undefined) {
                    return printedCount++;
                }
                else {
                    return notPrintedCount++;
                }
            });
            if (printedCount > 0) {
                let checkedinHolder = (printedCount / registrants.length) * 100
                setCheckedInPercentage(checkedinHolder.toFixed(2))
            }
            else {
                setCheckedInPercentage(0)
            }
            if (notPrintedCount > 0) {
                let notCheckedinHolder = (notPrintedCount / registrants.length) * 100
                setNotCheckedInPercentage(notCheckedinHolder.toFixed(2))
            }
            else {
                setNotCheckedInPercentage(0)
            }
        }
        else {
            eventAttendees.map(reg => {
                if (reg.latestPrinted && reg.latestPrinted !== undefined) {
                    return printedCount++;
                }
                else {
                    return notPrintedCount++;
                }
            });
            if (printedCount > 0) {
                let checkedinHolder = (printedCount / eventAttendees.length) * 100
                setCheckedInPercentage(checkedinHolder.toFixed(2))
            }
            else {
                setCheckedInPercentage(0)
            }
            if (notPrintedCount > 0) {
                let notCheckedinHolder = (notPrintedCount / eventAttendees.length) * 100
                setNotCheckedInPercentage(notCheckedinHolder.toFixed(2))
            }
            else {
                setNotCheckedInPercentage(0)
            }
        }
    }
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            fetchRegistrantData()
        }

    }, [])
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            // refresh if data has been updated
            if (registrants && registrants.length > 0) {
                fetchRegistrantData()
            }
        }

    }, [eventAttendees])

    useEffect(() => {
        if (registrants.length) {
            setLoaded(true)
        }
    }, [registrants])
    // export should be an button for all DS FIX Add charting as user action to each column header, navigate to new page
    return (
        <>{loaded ? <div className="page-area">
            <><SearchBar
                searchName={registrantNameFilter}
                handleSearchName={setRegistrantNameFilter}
                placeholder="Registrant First/Last Name"
                label="Name"
                isCleared={isCleared === true || isCleared === false ? isCleared : false}
            />
                <SearchBar
                    searchName={registrationTypeFilter}
                    handleSearchName={setRegistrationTypeFilter}
                    placeholder="Registration type"
                    label="RegType"
                    isCleared={isCleared === true || isCleared === false ? isCleared : false}
                />

                <SearchBar
                    searchName={registrantIDFilter}
                    handleSearchName={setRegistrantIDFilter}
                    placeholder="Local Registration Id/Scanner Field"
                    label="ID"
                    isCleared={isCleared === true || isCleared === false ? isCleared : false}
                />
                <SearchBar
                    searchName={emailAddressFilter}
                    handleSearchName={setEmailAddressFilter}
                    placeholder="Email Address"
                    label="EmailAddress"
                    isCleared={isCleared === true || isCleared === false ? isCleared : false}
                />

                {registrantNameFilter || registrationTypeFilter || registrantIDFilter || emailAddressFilter ?
                    <button onClick={clearFilters} id={styles.clearFilters}>
                        Clear
                    </button>
                    :
                    <>
                    </>
                }
                {combinedFilteredRegistrants.length ?
                    <div>
                        <table className={styles.tableReport}>
                            <thead>
                                <tr>
                                    <th colSpan={8} className={styles.tableReportHeader} >
                                        <h2>{totalRegistrants} Total Registrations</h2> </th></tr>
                                <tr>
                                    <th colSpan={4} className={styles.tableReportPercentangeHeader} key={checkedInPercentage}>Attendees Checked In: {checkedInPercentage} %)</th>
                                    <th colSpan={4} className={styles.tableReportNotPercentangeHeader} key={notCheckedInPercentage}>Attendees Not Checked In: {notCheckedInPercentage} %)</th>
                                </tr>
                                <tr className={styles.trReport}>
                                    <th className={styles.thReport} onClick={() => applySorting('localRegistrationId')}>
                                        Id/Scanner Field {sorting.key === 'localRegistrationId' && (sorting.ascending ? up : down)}
                                        <HoverButton
                                            title={'Registrant Id/Scanner Field entry'}
                                            messageWidth={75}
                                        />
                                    </th>
                                    <th className={styles.thReport} onClick={() => applySorting('firstName')}>
                                        First Name {sorting.key === 'firstName' && (sorting.ascending ? up : down)}
                                        <HoverButton
                                            title={'Registrant first name'}
                                            messageWidth={75}
                                        />
                                    </th>
                                    <th className={styles.thReport} onClick={() => applySorting('lastName')}>
                                        Last Name {sorting.key === 'lastName' && (sorting.ascending ? up : down)}
                                        <HoverButton
                                            title={'Registrant last name'}
                                            messageWidth={75}
                                        />
                                    </th>
                                    <th className={styles.thReport} onClick={() => applySorting('organization')}>
                                        Organization {sorting.key === 'organization' && (sorting.ascending ? up : down)}
                                        <HoverButton
                                            title={'Registrant organization'}
                                            messageWidth={75}
                                        />
                                    </th>
                                    <th className={styles.thReport} onClick={() => applySorting('registrationType')}>
                                        Registration Type {sorting.key === 'registrationType' && (sorting.ascending ? up : down)}
                                        <HoverButton
                                            title={'Registrant registration type'}
                                            messageWidth={75}
                                        />
                                    </th>
                                    <th className={styles.thReport} onClick={() => applySorting('latestPrinted')}>
                                        Status {sorting.key === 'latestPrinted' && (sorting.ascending ? up : down)}
                                        <HoverButton
                                            title={'Registrant printing status'}
                                            messageWidth={75}
                                        />
                                    </th>
                                    <th className={styles.thReport} onClick={() => applySorting('printCount')}>
                                        Print Count {sorting.key === 'printCount' && (sorting.ascending ? up : down)}
                                        <HoverButton
                                            title={'Registrant print count'}
                                            messageWidth={75}
                                        />
                                    </th>
                                    <th className={styles.thReport}>
                                        Print Attendee
                                        <HoverButton
                                            title={'Registrant print'}
                                            messageWidth={75}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody key={combinedFilteredRegistrants.length}>
                                {combinedFilteredRegistrants.map((reg, idx) => (
                                    <tr className={styles.trAccounts}>
                                        <Attendee
                                            registrant={reg}
                                        ></Attendee>
                                        <td className={styles.tdAccounts} key={reg.localRegistrationId}>
                                            {reg.printReady && reg.printReady === true ?
                                                 <div style={{ display: 'none' }} >
                                                    <PrintPage
                                                        badgeDimensions={badgeDimensions}
                                                        registrants={printRecord}
                                                        registrantPrintSettings={registrantPrintSettings}
                                                        componentRef={componentRef}
                                                    />
                                                </div>
                                                
                                                : <></>}
                                            <button className={styles.PrintForm}
                                                id={styles.PrintButton}
                                                disabled={templateSetting && templateSetting.length > 0} 
                                                title={templateSetting && templateSetting.length > 0 ? 'Event template not setup!' : 'Print ' + reg.firstName + ' ' + reg.lastName}
                                                onClick={() => handlePrintReady(reg)}>
                                                Print
                                            </button>
                                        </td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                        <br /></div>
                    : <>
                        <div className={styles.noneFound}>
                            No match results found - check mapping and that View is built!<br/>
                            <Link to={`/BadgeToERSPropertyMapping`} state={clientEventInfo}>Check Mapping ...</Link>
                        </div>
                    </>
                }
            </>
        </div>
            :
            <LoadingSpinner />
        }
        </>
        
    );
}

export default EventAttendees;